//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { VPopover } from 'v-tooltip';
export default {
  name: "popover",
  components: {
    VPopover: VPopover
  },
  props: {
    isOpen: {
      type: Boolean,
      default: null
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    // https://github.com/Akryum/v-tooltip#directive
    offset: {
      type: Number,
      default: 0
    },
    wrapperClass: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      localOpen: false,
      windowHeight: null
    };
  },
  computed: {
    linkedIsOpen: function linkedIsOpen() {
      return this.isOpen === null ? this.localOpen : this.isOpen;
    },
    baseClass: function baseClass() {
      var classes = ['ui-tooltip'];
      if (this.wrapperClass) classes.push(this.wrapperClass);
      return classes;
    }
  },
  methods: {
    open: function open() {
      this._setOpen(true);
    },
    close: function close() {
      this._setOpen(false);
    },
    _setOpen: function _setOpen(open) {
      this.localOpen = open;
      this.$emit('update:isOpen', open);
    }
  }
};