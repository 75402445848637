//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import uiModal from './ui-modal.vue';
import { api } from '../index.js';
export default {
  name: "rce-export-settings",
  components: {
    uiModal: uiModal
  },
  data: function data() {
    return {
      mode: null,
      version: null,
      modeValues: [],
      versionValues: []
    };
  },
  methods: {
    open: function open() {
      if (this.modeValues.length === 0 || this.versionValues.length === 0) return;
      if (this.mode === null) this.mode = this.modeValues[0][0];
      if (this.version === null) this.version = this.versionValues[0][0];
      this.$refs.modal.open();
    },
    _submit: function _submit() {
      this.$emit('select', {
        mode: this.mode,
        version: this.version
      });
    },
    _updatePossibleValues: function _updatePossibleValues() {
      var _this = this;

      api.getRCEExportValues(function (settings) {
        _this.modeValues = settings.mode;
        _this.versionValues = settings.version;
      });
    }
  },
  created: function created() {
    this._updatePossibleValues();
  }
};