var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      ref: "modal",
      attrs: {
        title: "Select Base Central Data Schema",
        "confirm-close": false,
        "action-title": _vm.actionTitle,
        action: _vm._selectSchema,
        "action-disabled": _vm.actionDisabled
      },
      on: { opened: _vm._opened, closed: _vm._closed }
    },
    [
      !_vm.useAvailable || _vm.hasAvailable
        ? _c("div", { staticClass: "instructions" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.useAvailable ? "Select" : "Upload") +
                " or edit a central data schema instance as a basis\n        to make it easier to edit the connections of analysis tools.\n    "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasBaseSchema && _vm.useAvailable && !_vm.hasAvailable
        ? _c("div", { staticClass: "instructions" }, [
            _vm._v(
              "\n        No base central data schemas available in " +
                _vm._s(_vm.remoteName) +
                ", base schema upload not available.\n    "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasBaseSchema
        ? _c("div", { staticClass: "has-schema row" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: { click: _vm._editSchema }
                },
                [
                  _vm._v("Edit base schema\n                "),
                  _c("span", { staticClass: "fas fa-edit" })
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  on: { click: _vm._removeSchema }
                },
                [
                  _vm._v("Remove base schema\n                "),
                  _c("span", { staticClass: "far fa-trash-alt" })
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.useAvailable
        ? _c("uploader", {
            ref: "uploader",
            staticClass: "uploader",
            attrs: {
              "n-files": 1,
              "n-files-min": 0,
              "drop-zone-message":
                "Upload central data schema instance: click or drag here"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasBaseSchema && _vm.useAvailable && _vm.hasAvailable
        ? _c("div", { staticClass: "form-horizontal schema-select" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedBaseSchema,
                        expression: "selectedBaseSchema"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { name: "selectedSchema" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedBaseSchema = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("Choose a base schema...")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.availableBaseSchemas, function(file) {
                      return _c(
                        "option",
                        { key: file, domProps: { value: file } },
                        [_vm._v(_vm._s(file))]
                      )
                    })
                  ],
                  2
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ui-dialog",
        { ref: "confirmRemoveSchema", attrs: { "action-title": "Remove" } },
        [
          _vm._v(
            "\n        Are you sure you want to remove the existing base schema?\n    "
          )
        ]
      ),
      _vm._v(" "),
      _c("var-list-modal", {
        ref: "varListModal",
        attrs: {
          "graph-def": _vm.graphDef,
          "var-editable": true,
          editable: true,
          deletable: true,
          "create-function": _vm._createVarFunction,
          "delete-function": _vm._deleteVarFunction,
          "edit-function": _vm._editVarFunction
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }