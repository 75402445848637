/**
 * This file contains functions used for communicating between the browser and the CEF window running the browser. Such
 * communication is only possible if the frontend is managed by the backend directly (so not if the backend is running
 * as a server only).
 *
 * Python/Javascript bindings are defined in browser_window.py::MainFrame.embed_browser
 */
import b64ToBlob from 'b64-to-blob';
import blobToBase64 from 'blob-to-base64';
import saveAs from 'file-saver';
import fileDialog from "file-dialog";
/**
 * @callback filePathCallback
 * @param {(string|null)} filePath
 */

/**
 * Display an open file dialog asking to open a project file.
 *
 * @param {filePathCallback} callback
 */

export function projectOpenBackend(callback) {
  ui_ask_open_project_file(callback);
}
/**
 * Display a save-as file dialog asking to save a project file.
 *
 * @param {string} filename
 * @param {filePathCallback} callback
 */

export function projectSaveBackend(filename, callback) {
  ui_ask_save_project_file(filename, callback);
}
/**
 * Open a save-as dialog for saving a file, given base64-encoded data.
 *
 * @param {string} name
 * @param {string} data
 * @param {string} mimeType
 */

export function saveFile(name, data, mimeType) {
  // Create Blob object
  var blob = b64ToBlob(data, mimeType); // Open save-as dialog

  saveAs(blob, name);
}
/**
 * Opens a file open dialog and returns base64-encoded data.
 *
 * @param callback
 */

export function openFile(callback) {
  fileDialog().then(function (files) {
    var file = files[0];
    blobToBase64(file, function (err, base64Data) {
      // base64 string starts with: data:...;base64,...
      base64Data = base64Data.split(';base64,', 2)[1];
      callback(base64Data);
    });
  });
}