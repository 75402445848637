//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import uiModal from './ui-items/ui-modal.vue';
import uploader from './ui-items/uploader.vue';
import { api } from './index.js';
export default {
  name: "import-modal",
  components: {
    uiModal: uiModal,
    uploader: uploader
  },
  data: function data() {
    return {
      api: api,
      finishCallback: null,
      uploaderComponent: null,
      fileNames: [],
      isValid: true
    };
  },
  computed: {
    uploaderIsValid: function uploaderIsValid() {
      return this.uploaderComponent ? this.uploaderComponent.uploadValid : true;
    },
    hasFiles: function hasFiles() {
      return this.fileNames.length > 0;
    }
  },
  methods: {
    open: function open(callback) {
      this.finishCallback = callback || null;
      this.isValid = true;
      this.$refs.modal.open();
    },
    close: function close() {
      this.uploaderComponent = null;
      this.$refs.modal.close();
    },
    _opened: function _opened() {
      this.uploaderComponent = this.$refs.uploader;

      this._resetUploader();
    },
    _checkUploader: function _checkUploader() {
      return this.uploaderComponent.validate();
    },
    _resetUploader: function _resetUploader() {
      this.uploaderComponent.reset();
    },
    _doImport: function _doImport() {
      if (!this._checkUploader()) {
        this.isValid = false;
        return false;
      }

      this.isValid = true;

      var _c = this;

      this.api.ioImport(function (projects) {
        _c.close();

        if (_c.finishCallback) _c.finishCallback(projects);
      }, function () {
        // Error
        _c._resetUploader();
      });
      return false;
    }
  }
};