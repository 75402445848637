//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * A component for rendering XDSM graphs. The div containing the XDSM graph should have a fixed width/height, as the
 * XDSM will automatically resize to fit the div. Internally, absolute positioning is used to make sure that the
 * untransformed XDSM matrix does not affect the sizing of parent elements, so any sizing will not propagate up to
 * the container.
 *
 * Usage:
 * <xdsm
 *      :graph-def="graphDefObj"
 *      :enable-pan-zoom="true"
 *      :zoom.sync="zoom"
 *      :function-classes="functionClasses"
 *      :data-classes="dataClasses"
 *      :clickable="clickable"
 *      :show-add-button="showAddButton"
 *      :sortable="sortable"
 *      :deletable="deletable"
 *      :show-process="showProcess"
 *      :left-align="leftAlign"
 * >
 *
 * </xdsm>
 *
 * where:
 * - graphDefObj is the object defining the XDSM graph, see below for details on the format
 * - enablePanZoom determines whether to enable panning and zooming or not
 * - zoom is an optionally synced property that changes the zoom of the container if set, and gets fed back (through
 *   the sync update mechanism) the current zoom level (1 = original scale)
 * - functionClasses is a list of classes to assign to the function blocks: [[id, 'class list'], ...]
 * - dataClasses is a list of classes to assign to the data connection blocks: [[id_i, id_j, 'class list'], ...]
 * - clickable determines whether the function and data blocks are clickable
 * - showAddButton determines whether or not to append an add-function-button at the end of the matrix
 * - sortable determines whether or not the functions are sortable
 * - deletable determines whether or not the functions are deletable
 * - showProcess determines whether or not the process line is shown (if available)
 * - leftAlign determines whether to position the XDSM on the left by default or not
 *
 * Events:
 * - functionClick(id): when function is clicked, function ID passed
 * - dataClick(id_i, id_j): when data connection block is clicked, row and column function IDs are passed
 * - addClick: when add function button is clicked
 * - sort: when functions have been sorted, an updated nesting array (same format as graphDef.nesting) is passed
 *
 * == graphDefObj format ==
 * {
 *     functions: [ // The list of functions
 *         {
 *             id: 1, // ID to reference the function by (also known in the backend)
 *             title: "Function name",
 *             process_title: "1 → 2", // Optional: text placed above title to denote process flow
 *             rounded: true|false, // Optional: whether the function block needs XDSM-style rounded corners or not
 *             bg: "css_color", // Optional: CSS background style declaration
 *             out: { // Mapping defining the output data connections
 *                 2: 'label', // Denotes a data connection to the function with ID=2, text is used as block label
 *                 ...
 *             },
 *         },
 *         ...
 *     ],
 *     process: [ // Optional process definition
 *         [0], // Each step in the process references one or more function IDs
 *         [1, 2], // More than one index denotes parallel execution
 *         [0],
 *         ...
 *     ],
 *     nesting: [
 *         0, // A function (referenced by ID) in the current nesting level
 *         1,
 *         [2, [ // A function with a sub-nest
 *             3, // A function in the sub-nest
 *             ...
 *         ]],
 *         ...
 *     ],
 * }
 *
 * NOTE: the first function is assumed to be the "coordinator" and is therefore not rendered! Any data connections
 * coming out or going into this function are not connected to the (0,0) location, but are left free!
 * */
import _ from '../../lodash.custom.js';
import panzoom from 'panzoom';
import { panzoomCenterFit } from "../../utils";
import xdsmFunction from './xdsm-function.vue';
import xdsmData from './xdsm-data.vue';
import functionDrag from './function-drag.vue';
import wrappingSelect from './wrapping-select.vue';
import Vue from 'vue';
Vue.directive('no-pan', function (el, binding, vnode) {
  var enabled = binding.value;
  var component = vnode.context;
  if (!component.hasOwnProperty('pausePanZoom')) return;

  if (enabled) {
    if (!_.includes(el.classList, 'no-pan')) {
      el.addEventListener('mousedown', component.pausePanZoom);
      el.classList.add('no-pan');
    }
  } else {
    if (_.includes(el.classList, 'no-pan')) {
      el.removeEventListener('mousedown', component.pausePanZoom);
      el.classList.remove('no-pan');
    }
  }
});
export default {
  name: "xdsm",
  components: {
    xdsmFunction: xdsmFunction,
    xdsmData: xdsmData,
    functionDrag: functionDrag,
    wrappingSelect: wrappingSelect
  },
  props: {
    graphDef: {
      type: Object,
      default: null
    },
    zoom: {
      type: Number,
      default: null
    },
    functionClasses: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // [[i, 'class list'], ...]
    dataClasses: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // [[i, j, 'class list'], ...]
    enablePanZoom: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    },
    showAddButton: {
      type: Boolean,
      default: false
    },
    sortable: {
      type: Boolean,
      default: false
    },
    sortableIds: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    sortTargetIds: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    deletable: {
      type: Boolean,
      default: false
    },
    showProcess: {
      type: Boolean,
      default: true
    },
    leftAlign: {
      type: Boolean,
      default: false
    },
    wrappingFunctionId: {
      default: null
    },
    lastWrappedFunctionId: {
      default: null
    },
    wrappingNesting: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    immediateRerender: {
      type: Boolean,
      default: false
    },
    wrappedFunctionIds: {
      default: null
    },
    showNesting: {
      type: Boolean,
      default: false
    },
    renderCollapsed: {
      type: Boolean,
      default: false
    },
    collapsible: {
      type: Boolean,
      default: false
    },
    showIssues: {
      type: Boolean,
      default: false
    },
    showEnter: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      xdsmId: 'xdsm-' + Math.random().toString(36).slice(2),
      panzoomController: null,
      visible: true,
      inUpdateZoom: false,
      inExternalZoomUpdate: false,
      localNesting: null,
      functionPos: {},
      cancelPositionChanged: false,
      showDragFunctions: false,
      localZoom: 1,
      containerEl: null,
      moveEl: null
    };
  },
  watch: {
    graphDef: {
      handler: function handler() {
        this._setConsistentNesting();

        setTimeout(this._updateFunctionPos, 0);
      },
      deep: true
    },
    enablePanZoom: function enablePanZoom(enable) {
      if (enable) {
        this._resumePanZoom();
      } else {
        this.pausePanZoom();
      }
    },
    zoom: function zoom(_zoom) {
      // Only perform zoom if we are not in the update:zoom event
      if (this.inUpdateZoom) return;
      this.inExternalZoomUpdate = true;

      this._centeredZoomTo(_zoom);

      this.endExternalZoomUpdate(); // Debounced
    }
  },
  computed: {
    graph: function graph() {
      return this.graphDef ? this.graphDef : {};
    },
    functions: function functions() {
      var functions = _.map(this.graph.functions, function (f, i) {
        var func = _.cloneDeep(f);

        if (!func.hasOwnProperty('process_title')) {
          func.process_title = null;
        }

        func.isAddButton = false;
        func.isCoordinator = i == 0;
        if (!func.hasOwnProperty('out')) func.out = {};
        return func;
      }); // If there is only one function, this is the coordinator, remove it from the list


      if (functions.length == 1) {
        functions = [];
      } // Add function button


      if (this.showAddButton) {
        functions.push({
          id: -1,
          isAddButton: true,
          isCoordinator: false,
          out: {}
        });
      }

      return functions;
    },
    functionIdMap: function functionIdMap() {
      return _.fromPairs(_.map(this.functions, function (f, i) {
        return [f.id, i];
      }));
    },
    flatSortedIds: function flatSortedIds() {
      // Flatten the nesting array (which determines the function order)
      if (!this.localNesting) return [];

      function getFlatIds(nestedIds) {
        var flatIds = [];

        _.forEach(nestedIds, function (nestingData) {
          flatIds.push(nestingData.id);

          _.forEach(getFlatIds(nestingData.wraps), function (id) {
            return flatIds.push(id);
          });
        });

        return flatIds;
      }

      return getFlatIds(this.localNesting);
    },
    sortedFunctionIdMap: function sortedFunctionIdMap() {
      var functions = this.functions;
      var sortingIds = this.flatSortedIds;
      var coordinatorId = this.coordId; // Determine function order

      var functionOrder = _.sortBy(_.map(functions, function (f, i) {
        if (sortingIds.length == 0) return [f.id, i]; // No sorting order defined
        // Ignore ordering of the coordinator

        if (f.id == coordinatorId) return [f.id, 0];
        var idx = sortingIds.indexOf(f.id);

        if (idx == -1) {
          return [f.id, functions.length + 1 + i]; // Fallback
        } else {
          return [f.id, idx + 1];
        }
      }), function (item) {
        return item[1];
      }); // Turn order into indices and turn into map


      return _.fromPairs(_.map(functionOrder, function (item, idx) {
        return [item[0], idx];
      }));
    },
    coordId: function coordId() {
      var functions = this.functions;
      return functions.length > 0 ? functions[0].id : 0;
    },
    sortedFunctions: function sortedFunctions() {
      var functions = this.functions;
      var fIdx = this.sortedFunctionIdMap;
      var dataClasses = this.xdsmDataClasses;
      var clickable = this.clickable;
      var collapsedIdMap = this.collapsedIdMap;
      var invertedCollapsedIdMap = this.invertedCollapsedIdMap;
      var coordId = this.coordId; // Map destination blocks to their wrapping block (if collapsed)

      var collapsedOut = _.fromPairs(_.map(functions, function (f) {
        var fOut = {};

        _.forEach(f.out, function (varNames, fInId) {
          var collapsingInId = fInId in invertedCollapsedIdMap ? invertedCollapsedIdMap[fInId] : fInId;
          if (!(collapsingInId in fOut)) fOut[collapsingInId] = [];
          fOut[collapsingInId] = _.concat(fOut[collapsingInId], varNames);
        });

        return [f.id, fOut];
      }));

      var _c = this;

      return _.sortBy(_.map(functions, function (f) {
        f = _.cloneDeep(f);

        var outArray = _.map(new Array(functions.length), function () {
          return null;
        });

        var dataClassMap = dataClasses[f.id] ? dataClasses[f.id] : {}; // Merge data connections of collapsed functions

        if (f.id in invertedCollapsedIdMap) {
          f.out = {};
        } else if (f.id in collapsedOut) {
          var outMap = _.clone(collapsedOut[f.id]);

          _.forEach(collapsedIdMap[f.id], function (collapsedId) {
            _.forEach(collapsedOut[collapsedId], function (varNames, fInId) {
              if (!(fInId in outMap)) outMap[fInId] = [];
              outMap[fInId] = _.concat(outMap[fInId], varNames);
            });
          }); // Connect internal unconnected variables to the global output


          if (f.id in outMap) {
            var connectedVarNames = _.uniq(_.flatten(_.map(_.filter(outMap, function (varNames, fId) {
              return fId != coordId && fId != f.id;
            }), function (varNames) {
              return varNames;
            })));

            var internalVarNames = _.filter(outMap[f.id], function (varName) {
              return !_.includes(connectedVarNames, varName);
            });

            if (internalVarNames.length > 0) {
              if (!(coordId in outMap)) outMap[coordId] = [];
              outMap[coordId] = _.concat(outMap[coordId], internalVarNames);
            }

            delete outMap[f.id];
          }

          f.out = outMap;
        } // Process output data labels


        _.forEach(f.out, function (label, id) {
          var inFunctionIdx = fIdx[id];
          if (_.isUndefined(inFunctionIdx)) return;
          var classes = dataClassMap[id] ? dataClassMap[id] : [];
          if (clickable) classes.push('clickable');
          outArray[inFunctionIdx] = {
            label: _c._formatLabel(label),
            fInId: id,
            class: classes
          };
        });

        f.outArray = outArray;
        f.sortIdx = fIdx[f.id];
        return f;
      }), function (f) {
        return f.sortIdx;
      });
    },
    sortedFunctionsRender: function sortedFunctionsRender() {
      var dataLines = this.dataLines;
      var processLines = this.processLines;
      var xdsmFunctionClasses = this.xdsmFunctionClasses;
      var cl = this.clickable ? ' clickable' : '';
      var invertedCollapsedIdMap = this.invertedCollapsedIdMap;
      var sortedFunctions = this.sortedFunctions;
      var wrappedCells = this.wrappedCells;

      function getCellStyle(i, j) {
        var nWrapped = wrappedCells[i][j];
        var alpha = .5 * (1. - Math.pow(.9, nWrapped)); // Asymptotically go to 50% alpha

        return {
          'background-color': 'rgba(0,0,0,' + alpha + ')'
        };
      }

      return _.map(sortedFunctions, function (f, i) {
        f.rowHidden = f.id in invertedCollapsedIdMap;
        f.outArray = _.map(f.outArray, function (data, j) {
          if (data !== null && data.hasDataConn === false) return data;

          if (!data || data.hasDataConn === false) {
            data = {};
            data.hasDataConn = false;
          } else {
            data.hasDataConn = true;
          }

          data.cellStyle = getCellStyle(i, j);
          data.colHidden = sortedFunctions[j].id in invertedCollapsedIdMap; // Process and data line divs

          var divs = [];
          var dataClass = 'cell-line data-line ';
          var vertClass = 'vertical input ';
          var horClass = 'horizontal output ';
          var dataIn = dataLines.inputs[j];
          var dataOut = dataLines.outputs[i];

          if (dataIn[0] < i && i <= dataIn[1]) {
            divs.push({
              data: true,
              class: dataClass + vertClass + 'up' + cl,
              i: i,
              j: j
            });
          }

          if (dataIn[0] <= i && i < dataIn[1]) {
            divs.push({
              data: true,
              class: dataClass + vertClass + 'down' + cl,
              i: i,
              j: j
            });
          }

          if (dataOut[0] < j && j <= dataOut[1]) {
            divs.push({
              data: true,
              class: dataClass + horClass + 'left' + cl,
              i: i,
              j: j
            });
          }

          if (dataOut[0] <= j && j < dataOut[1]) {
            divs.push({
              data: true,
              class: dataClass + horClass + 'right' + cl,
              i: i,
              j: j
            });
          }

          var proClass = 'cell-line process-line ';
          var proIn = processLines.inputs[j];
          var proOut = processLines.outputs[i];

          if (proIn[0] < i && i <= proIn[1]) {
            divs.push({
              data: false,
              class: proClass + vertClass + 'up' + cl
            });
          }

          if (proIn[0] <= i && i < proIn[1]) {
            divs.push({
              data: false,
              class: proClass + vertClass + 'down' + cl
            });
          }

          if (proOut[0] < j && j <= proOut[1]) {
            divs.push({
              data: false,
              class: proClass + horClass + 'left' + cl
            });
          }

          if (proOut[0] <= j && j < proOut[1]) {
            divs.push({
              data: false,
              class: proClass + horClass + 'right' + cl
            });
          }

          data.divs = divs;
          return data;
        });
        f.class = xdsmFunctionClasses[f.id];
        return f;
      });
    },
    dataLines: function dataLines() {
      var functions = this.sortedFunctions;
      var dataLines = {
        inputs: _.map(functions, function (f, idx) {
          return [idx, idx];
        }),
        // [i_start, i_end]
        outputs: _.map(functions, function (f, idx) {
          return [idx, idx];
        }) // [j_start, j_end]

      };
      var fIdx = this.sortedFunctionIdMap; // Loop over functions (rows)

      var _loop = function _loop(i) {
        var f = functions[i];
        if (!f.hasOwnProperty('out')) return "continue"; // Loop over output connections (columns)

        var jStart = i;
        var jEnd = i;

        _.forEach(f.out, function (label, inFunctionId) {
          var j = fIdx[inFunctionId];
          if (_.isUndefined(j)) return;
          if (i == j) return;
          if (j < jStart) jStart = j;
          if (j > jEnd) jEnd = j; // Add vertical data connections

          if (j != 0) {
            if (j < i) {
              // Feedback
              if (i > dataLines.inputs[j][1]) dataLines.inputs[j][1] = i;
            } else {
              // Feedforward
              if (i < dataLines.inputs[j][0]) dataLines.inputs[j][0] = i;
            }
          }
        }); // Add horizontal data connections


        if (i != 0 && jStart != jEnd) {
          dataLines.outputs[i] = [jStart, jEnd];
        }
      };

      for (var i = 0; i < functions.length; i++) {
        var _ret = _loop(i);

        if (_ret === "continue") continue;
      }

      return dataLines;
    },
    processLines: function processLines() {
      var functions = this.sortedFunctions;
      var processLines = {
        inputs: _.map(functions, function (f, idx) {
          return [idx, idx];
        }),
        // [i_start, i_end]
        outputs: _.map(functions, function (f, idx) {
          return [idx, idx];
        }) // [j_start, j_end]

      };
      var fIdx = this.sortedFunctionIdMap;
      var invertedCollapsedIdMap = this.invertedCollapsedIdMap;
      /* Process is a list of connections between functions (graph edges):
          E.g:
         [[0, 1], [1, 2]] : function 0 --> function 1, function 1 --> function 2
       */

      var process = this.graph.process;

      if (process) {
        for (var iProcess = 0; iProcess < process.length; iProcess++) {
          var fOutId = process[iProcess][0];
          var fInId = process[iProcess][1]; // If the process line is to/from a collapsed function, do not display it

          if (fOutId in invertedCollapsedIdMap || fInId in invertedCollapsedIdMap) continue;
          var i = fIdx[fOutId];
          var j = fIdx[fInId];

          if (j < i) {
            // Feedback
            if (i > processLines.inputs[j][1]) processLines.inputs[j][1] = i;
            if (j < processLines.outputs[i][0]) processLines.outputs[i][0] = j;
          } else {
            // Feedforward
            if (i < processLines.inputs[j][0]) processLines.inputs[j][0] = i;
            if (j > processLines.outputs[i][1]) processLines.outputs[i][1] = j;
          }
        }
      }

      return processLines;
    },
    wrappedCells: function wrappedCells() {
      var functions = this.sortedFunctions;

      var wrappedCells = _.map(Array(functions.length), function () {
        return _.map(Array(functions.length), function () {
          return 0;
        });
      });

      if (!this.localNesting || this.localNesting.length == 0) return wrappedCells;
      var fIdx = this.sortedFunctionIdMap;
      var nFunc = this.graph.functions.length;
      var collapsedIdMap = this.collapsedIdMap;

      function processNestingSpec(nestingSpec) {
        var lastWrappedId = _.last(nestingSpec).id;

        _.forEach(nestingSpec, function (_ref, idx) {
          var id = _ref.id,
              wraps = _ref.wraps;
          if (wraps.length == 0) return; // If this nesting level is collapsed, do not increment wrapping levels, and do not recursively
          // render wrapping levels

          if (id in collapsedIdMap) return; // Find wrapping start and end

          var startId = id;
          var lastId = processNestingSpec(wraps);
          if (idx == nestingSpec.length - 1) lastWrappedId = lastId;
          var startIdx = fIdx[startId];
          var endIdx = fIdx[lastId]; // Do not display if wrapping all

          if (startIdx == 1 && endIdx == nFunc - 1) return; // Increment wrapping levels

          for (var i = startIdx; i <= endIdx; i++) {
            for (var j = startIdx; j <= endIdx; j++) {
              wrappedCells[i][j]++;
            }
          }
        });

        return lastWrappedId;
      }

      if (this.localNesting) processNestingSpec(this.localNesting);
      return wrappedCells;
    },
    xdsmFunctionClasses: function xdsmFunctionClasses() {
      var classLists = _.fromPairs(_.map(this.functions, function (f) {
        return [f.id, []];
      }));

      _.forEach(this.functionClasses, function (item) {
        var functionId = item[0];
        var classes = item[1];
        classLists[functionId].push(classes);
      });

      if (this.clickable) {
        _.forEach(classLists, function (list) {
          return list.push('clickable');
        });
      }

      return classLists;
    },
    xdsmDataClasses: function xdsmDataClasses() {
      var classLists = {};

      _.forEach(this.dataClasses, function (item) {
        var iId = item[0];
        var jId = item[1];
        var classes = item[2];

        if (!(iId in classLists)) {
          classLists[iId] = {};
        }

        if (!(jId in classLists[iId])) {
          classLists[iId][jId] = [];
        }

        classLists[iId][jId].push(classes);
      });

      return classLists;
    },
    visibility: function visibility() {
      return this.visible ? 'visible' : 'hidden';
    },
    isSortable: function isSortable() {
      return this.sortable && this.localNesting && this.localNesting.length;
    },
    endUpdateZoom: function endUpdateZoom() {
      var _this = this;

      return _.debounce(function () {
        return _this.inUpdateZoom = false;
      }, 50);
    },
    endExternalZoomUpdate: function endExternalZoomUpdate() {
      var _this2 = this;

      return _.debounce(function () {
        return _this2.inExternalZoomUpdate = false;
      }, 50);
    },
    hideOriginalFunctionBlocks: function hideOriginalFunctionBlocks() {
      return this.isSortable && this.showDragFunctions;
    },
    debouncedShowDragFunctions: function debouncedShowDragFunctions() {
      if (this.immediateRerender) return this._showDragFunctions;
      return _.debounce(this._showDragFunctions, 200);
    },
    debouncedPositionChangeEnd: function debouncedPositionChangeEnd() {
      var _c = this;

      return _.debounce(function () {
        return _c._positionChangeEnd(true);
      }, 500);
    },
    isWrapping: function isWrapping() {
      return this.wrappingFunctionId !== null;
    },
    consistentWrappingNesting: function consistentWrappingNesting() {
      return this._getConsistentNesting(this.wrappingNesting);
    },
    collapsibleMap: function collapsibleMap() {
      if (!this.localNesting || !this.collapsible) return {};
      var collapsibleMap = {};

      function traverseNesting(nestingSpec) {
        _.forEach(nestingSpec, function (_ref2) {
          var f = _ref2.f,
              wraps = _ref2.wraps;
          if (wraps.length > 0) collapsibleMap[f.id] = true;
          traverseNesting(wraps);
        });
      }

      traverseNesting(this.localNesting);
      return collapsibleMap;
    },
    collapsedMap: function collapsedMap() {
      if (!this.localNesting || !this.renderCollapsed) return {};
      var collapsedMap = {};

      function getAllWrapped(wrapped) {
        return _.flatten(_.map(wrapped, function (_ref3) {
          var f = _ref3.f,
              wraps = _ref3.wraps;
          return _.concat(f, getAllWrapped(wraps));
        }));
      }

      function traverseNesting(nestingSpec) {
        _.forEach(nestingSpec, function (_ref4) {
          var f = _ref4.f,
              wraps = _ref4.wraps;

          if (f.collapsed) {
            collapsedMap[f.id] = getAllWrapped(wraps);
          } else {
            traverseNesting(wraps);
          }
        });
      }

      traverseNesting(this.localNesting);
      return collapsedMap;
    },
    collapsedIdMap: function collapsedIdMap() {
      return _.fromPairs(_.map(this.collapsedMap, function (functionDefs, functionId) {
        return [functionId, _.map(functionDefs, function (f) {
          return f.id;
        })];
      }));
    },
    invertedCollapsedIdMap: function invertedCollapsedIdMap() {
      var invertedCollapsedIdMap = {};

      _.forEach(this.collapsedIdMap, function (fIds, fId) {
        _.forEach(fIds, function (cfId) {
          return invertedCollapsedIdMap[cfId] = fId;
        });
      });

      return invertedCollapsedIdMap;
    }
  },
  methods: {
    pausePanZoom: function pausePanZoom() {
      this.panzoomController.pause();
    },
    resetZoom: function resetZoom() {
      this.visible = false; // Temporarily hide object to prevent visible jumps

      var _c = this;

      setTimeout(function () {
        _c._centerFit();

        _c.visible = true;
      }, 0);
    },
    _setConsistentNesting: function _setConsistentNesting() {
      var graphDef = this.graphDef;
      var nesting = graphDef && graphDef.hasOwnProperty('nesting') ? graphDef.nesting : null;
      this.localNesting = nesting ? this._getConsistentNesting(nesting) : [];
    },
    _getConsistentNesting: function _getConsistentNesting(idDataList) {
      var functions = this.functions;
      var fIdx = this.functionIdMap;

      var _c = this;

      return _.map(idDataList, function (idData) {
        var functionId, wraps;

        if (_.isArray(idData)) {
          functionId = idData[0];
          wraps = _c._getConsistentNesting(idData[1]);
        } else {
          functionId = idData;
          wraps = [];
        }

        return {
          id: functionId,
          wraps: wraps,
          f: functions[fIdx[functionId]]
        };
      });
    },
    _resumePanZoom: function _resumePanZoom() {
      if (this.enablePanZoom) {
        this.panzoomController.resume();
      }
    },
    _containerBoundingBox: function _containerBoundingBox() {
      if (!this.containerEl) return null;
      return this.containerEl.getBoundingClientRect();
    },
    _objectBoundingBox: function _objectBoundingBox() {
      var moveObj = this.$refs.moveObject;
      if (!moveObj) return null;
      return moveObj.getBoundingClientRect();
    },
    _centerFit: function _centerFit() {
      var moveObject = this.$refs.moveObject;
      if (!moveObject) return null;
      var container = moveObject.parentElement;

      var _c = this;

      panzoomCenterFit(container, moveObject, this.panzoomController, function (scale) {
        // Custom code to prevent processing the position-changed event when zooming
        _c.cancelPositionChanged = true;

        _c.panzoomController.zoomAbs(0, 0, scale);

        _c.cancelPositionChanged = false;
      }, this.leftAlign);
    },
    _centeredZoomTo: function _centeredZoomTo(zoom) {
      var bbox = this._containerBoundingBox();

      if (!bbox) return;
      var cw = bbox.width;
      var ch = bbox.height;
      this.panzoomController.zoomAbs(cw / 2, ch / 2, zoom);
    },
    _hideShowDragBlocks: function _hideShowDragBlocks() {
      if (this.showDragFunctions) this.showDragFunctions = false;
      this.debouncedShowDragFunctions();
    },
    _showDragFunctions: function _showDragFunctions() {
      var _c = this;

      this._updateFunctionPos();

      setTimeout(function () {
        return _c.showDragFunctions = true;
      }, 0);
    },
    _positionChanged: function _positionChanged() {
      this._positionChanging();

      this.debouncedPositionChangeEnd();
    },
    _positionChanging: function _positionChanging() {
      if (this.cancelPositionChanged) return;
      if (this.showDragFunctions) this.showDragFunctions = false;
    },
    _positionChangeEnd: function _positionChangeEnd(immediate) {
      if (this.cancelPositionChanged) return;

      if (immediate === true) {
        this._showDragFunctions();
      } else {
        this.debouncedShowDragFunctions();
      }

      var scale = this.panzoomController.getTransform().scale;
      this.localZoom = scale; // Do not publish if the zoom level is already given from outside

      if (this.inExternalZoomUpdate) return;
      this.inUpdateZoom = true;
      this.$emit('update:zoom', scale);
      this.endUpdateZoom(); // Debounced
    },
    _getFunctionBlockEl: function _getFunctionBlockEl(id) {
      var refStr = 'f' + id;
      if (!this.$refs.hasOwnProperty(refStr)) return null;
      return this.$refs[refStr][0].$refs.root;
    },
    _sorted: function _sorted() {
      this._hideShowDragBlocks(); // Report the updated nesting sequence in the original format


      function getOriginalFormatNesting(consistentNesting) {
        return _.map(consistentNesting, function (n) {
          if (n.wraps.length == 0) {
            return n.id;
          } else {
            return [n.id, getOriginalFormatNesting(n.wraps)];
          }
        });
      }

      var originalFormatNesting = getOriginalFormatNesting(this.localNesting);
      this.$emit('sort', originalFormatNesting);
    },
    _collapse: function _collapse(functionId, collapsed) {
      this.$emit('collapse', functionId, collapsed);
    },
    _enter: function _enter(functionId) {
      this.$emit('enter', functionId);
    },
    _updateFunctionPos: function _updateFunctionPos() {
      var obox = this._objectBoundingBox();

      if (!obox) return;
      var scale = this.panzoomController.getTransform().scale;

      var _c = this;

      this.functionPos = _.fromPairs(_.filter(_.map(this.functions, function (f) {
        var el = _c._getFunctionBlockEl(f.id);

        if (!el) return null;
        var bbox = el.getBoundingClientRect();
        var left = (bbox.left - obox.left + 0.5 * bbox.width) / scale;
        var top = (bbox.top - obox.top + 0.5 * bbox.height) / scale;
        var width = bbox.width / scale;
        var height = bbox.height / scale;
        return [f.id, {
          left: left,
          top: top,
          width: width,
          height: height
        }];
      }), function (posDef) {
        return posDef !== null;
      }));
    },
    _getAllFunctionIds: function _getAllFunctionIds(functionId) {
      if (this.collapsedIdMap[functionId]) {
        return _.concat([functionId], this.collapsedIdMap[functionId]);
      }

      return [functionId];
    },
    _dataLineClick: function _dataLineClick(e, i, j) {
      var isInput = _.includes(e.target.classList, 'input');

      var functionId = this.sortedFunctions[isInput ? j : i].id;
      this.$emit('dataLineClick', this._getAllFunctionIds(functionId), isInput);
    },
    _dataBlockClick: function _dataBlockClick(functionId, functionInId) {
      this.$emit('dataClick', this._getAllFunctionIds(functionId), this._getAllFunctionIds(functionInId));
    },
    _formatLabel: function _formatLabel(vars) {
      var uniqueVars = _.uniq(vars);

      var label = _.join(uniqueVars, ',');

      return label.length > 20 ? _.toString(vars.length) : label;
    }
  },
  mounted: function mounted() {
    var el = this.$refs.moveObject;
    this.containerEl = el.parentElement;
    this.moveEl = el;
    el.addEventListener('pan', this._positionChanging);
    el.addEventListener('panend', this._positionChangeEnd);
    el.addEventListener('zoom', this._positionChanged);
    this.panzoomController = panzoom(el, {
      smoothScroll: false,
      zoomSpeed: .5,
      maxZoom: 5,
      bounds: false,
      filterKey: function filterKey() {
        return true;
      } // Do not handle key events (to enable using keys for process animation steps)

    });

    if (!this.enablePanZoom) {
      this.pausePanZoom();
    }

    this.resetZoom(); // Any time we release the mouse button anywhere in the document, we in principle (re-)enable panning
    // Disabling panning is then done on the basis of which element is clicked

    window.addEventListener('mouseup', this._resumePanZoom);
    window.addEventListener('touchend', this._resumePanZoom);
    window.addEventListener('dragend', this._resumePanZoom);
    window.addEventListener('dragleave', this._resumePanZoom);

    this._setConsistentNesting();
  },
  beforeDestroy: function beforeDestroy() {
    this.panzoomController.dispose();
    window.removeEventListener('mouseup', this._resumePanZoom);
    window.removeEventListener('touchend', this._resumePanZoom);
    window.removeEventListener('dragend', this._resumePanZoom);
    window.removeEventListener('dragleave', this._resumePanZoom);
  }
};