var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("select-table", {
    ref: "table",
    attrs: {
      columns: _vm.columns,
      data: _vm.data,
      editable: _vm.editable,
      "editable-rows": true
    },
    on: {
      delete: _vm._deleteHandler,
      edit: _vm._editHandler,
      update: _vm._updateHandler,
      add: _vm._addHandler,
      reset: _vm._resetHandler
    },
    scopedSlots: _vm._u([
      {
        key: "add-out_label",
        fn: function(scope) {
          return [
            _c(
              "div",
              {
                staticClass: "form-group",
                class: { "has-error": _vm.errors.has("eq.out_label") }
              },
              [
                _vm.outLabelFieldShown
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.outLabel,
                          expression: "outLabel"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.outLabelRules,
                          expression: "outLabelRules"
                        }
                      ],
                      staticClass: "form-control",
                      staticStyle: { width: "200px" },
                      attrs: {
                        type: "text",
                        name: "out_label",
                        placeholder: "Child variable name",
                        "data-vv-validate-on": "change|input|blur",
                        "data-vv-scope": "eq"
                      },
                      domProps: { value: _vm.outLabel },
                      on: {
                        keyup: function($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm._addOrUpdate($event)
                        },
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.outLabel = $event.target.value
                          },
                          _vm._hasInput
                        ]
                      }
                    })
                  : _vm._e()
              ]
            )
          ]
        }
      },
      {
        key: "add-expression",
        fn: function(scope) {
          return [
            _c(
              "div",
              {
                staticClass: "form-group",
                class: { "has-error": _vm.errors.has("eq.expression") }
              },
              [
                _vm._v("\n            = "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.expression,
                      expression: "expression"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: _vm.expressionRules,
                      expression: "expressionRules"
                    }
                  ],
                  staticClass: "form-control",
                  staticStyle: { width: "600px", display: "inline" },
                  attrs: {
                    type: "text",
                    name: "expression",
                    placeholder:
                      "Expression (Python syntax, use available parameter aliases as variables)",
                    "data-vv-validate-on": "change|input|blur",
                    "data-vv-scope": "eq"
                  },
                  domProps: { value: _vm.expression },
                  on: {
                    keyup: function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm._addOrUpdate($event)
                    },
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.expression = $event.target.value
                      },
                      _vm._hasInput
                    ]
                  }
                })
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }