//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../../lodash.custom.js';
import { recursiveHasCollision, recursiveHasRoutingCollision, isMultiFileRoot as _isMultiFileRoot } from "../../var-utils.js";
export default {
  name: "var-item",
  props: {
    varDef: {
      type: Object,
      required: true
    },
    // See var-list.vue for format
    searchIds: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    editable: {
      type: Boolean,
      default: false
    },
    deletable: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    multiSelect: {
      type: Boolean,
      default: false
    },
    selectLeaf: {
      type: Boolean,
      default: true
    },
    selectParent: {
      type: Boolean,
      default: false
    },
    selectedIds: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    disabledIds: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    mainIds: {
      type: Array,
      default: null
    },
    paddingLeft: {
      type: Number,
      default: 0
    },
    paddingRight: {
      type: Number,
      default: 0
    },
    parentId: {
      default: null
    },
    isRoot: {
      default: false
    },
    isRealRoot: {
      default: false
    },
    blackBoxCanAddChildVar: {
      default: true
    },
    routingEditable: {
      type: Boolean,
      default: false
    },
    varEditable: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      collapsed: true,
      hasExpanded: false
    };
  },
  computed: {
    isMultiFileRoot: function isMultiFileRoot() {
      return _isMultiFileRoot(this.varDef);
    },
    hasChildren: function hasChildren() {
      return this.varDef && this.varDef.has_children;
    },
    isBlackBox: function isBlackBox() {
      return this.isRoot && !this.hasChildren;
    },
    bbChildCanAddChildVar: function bbChildCanAddChildVar() {
      if (!this.isMultiFileRoot || !this.hasChildren) return true; // Prevent adding more than one white-box root

      var nWhiteBoxRoots = _.filter(this.varDef.children, function (varDef) {
        return varDef.children.length > 0;
      }).length;

      return nWhiteBoxRoots < 1;
    },
    hasGrandChildren: function hasGrandChildren() {
      if (!this.hasChildren) return false;
      var varDef = this.varDef;

      for (var i in varDef.children) {
        if (varDef.children.hasOwnProperty(i)) {
          if (varDef.children[i].has_children) return true;
        }
      }

      return false;
    },
    varId: function varId() {
      return this.varDef.id;
    },
    children: function children() {
      return this.hasChildren ? this.varDef.children : [];
    },
    attr: function attr() {
      return this.varDef ? this.varDef.attrib : {};
    },
    svm: function svm() {
      return this.varDef ? this.varDef.svm || {} : {};
    },
    searchMatches: function searchMatches() {
      return this.searchIds && _.includes(this.searchIds, this.varId);
    },
    selected: function selected() {
      return this.selectedIds && _.includes(this.selectedIds, this.varId);
    },
    itemSelectable: function itemSelectable() {
      return this.selectable && (this.hasChildren && this.selectParent || !this.hasChildren && this.selectLeaf);
    },
    disabled: function disabled() {
      return this.disabledIds && _.includes(this.disabledIds, this.varId);
    },
    selectionDisabled: function selectionDisabled() {
      return this.disabled;
    },
    descendantVarIds: function descendantVarIds() {
      var varIds = [];

      function addVarId(varDef) {
        varIds.push(varDef.id);

        _.forEach(varDef.children, function (childVarDef) {
          return addVarId(childVarDef);
        });
      }

      addVarId(this.varDef);
      return varIds;
    },
    descendantSelected: function descendantSelected() {
      var _this = this;

      return !this.selected && this.selectedIds && _.map(this.descendantVarIds, function (varId) {
        return _.includes(_this.selectedIds, varId);
      }).some(function (v) {
        return v;
      });
    },
    isVector: function isVector() {
      return this.attr.hasOwnProperty('mapType') && this.attr['mapType'] == 'vector';
    },
    isVectorStr: function isVectorStr() {
      return this.isVector ? '<>' : '';
    },
    attrStr: function attrStr() {
      var attrs = [];

      _.forEach(this.attr, function (value, key) {
        if (key == 'mapType' || value.indexOf('.xsd') != -1) return;
        var keyStr = key;
        if (keyStr.length > 13) keyStr = keyStr.substr(0, 10) + '...';
        attrs.push('@' + keyStr + ' = ' + value);
      });

      _.forEach(this.svm, function (value, xpath) {
        var xpathStr = xpath;
        if (xpathStr.length > 13) xpathStr = xpathStr.substr(0, 10) + '...';
        attrs.push(value ? xpathStr + ' = "' + value + '"' : xpathStr);
      });

      return attrs.length === 0 ? '' : '(' + attrs.join(', ') + ')';
    },
    actionsStyle: function actionsStyle() {
      return {
        'margin-right': this.paddingRight + 'px'
      };
    },
    leftStyle: function leftStyle() {
      var padding = this.isMultiFileRoot ? 0 : this.paddingLeft;
      return {
        'margin-left': padding + 'px'
      };
    },
    hasShowInfo: function hasShowInfo() {
      return !this.hasChildren;
    },
    hasCollision: function hasCollision() {
      return this.routingEditable && this.varDef ? recursiveHasCollision(this.varDef) : false;
    },
    hasRoutingCollision: function hasRoutingCollision() {
      return this.routingEditable && this.varDef ? recursiveHasRoutingCollision(this.varDef) : false;
    },
    hasShowRouting: function hasShowRouting() {
      return this.routingEditable; // return this.routingEditable && this.hasCollision;
    },
    hasCreateVar: function hasCreateVar() {
      return this.editable && (!this.isBlackBox || this.isBlackBox && this.blackBoxCanAddChildVar);
    },
    hasEditVar: function hasEditVar() {
      return this.varEditable;
    },
    hasDeleteVar: function hasDeleteVar() {
      if (!this.showDeleteVar && this.hasConnect) return false;
      return this.deletable;
    },
    hasConnect: function hasConnect() {
      if (this.isMain) return false;
      if (this.isBlackBox && this.isRealRoot) return false;
      return this.editable;
    },
    showDeleteVar: function showDeleteVar() {
      if (!this.isMain) return false;
      return this.isBlackBox || !this.isRoot;
    },
    hasMainSel: function hasMainSel() {
      return this.mainIds !== null;
    },
    isMain: function isMain() {
      return !this.hasMainSel || _.includes(this.mainIds, this.varId);
    }
  },
  methods: {
    collapseAll: function collapseAll() {
      this._collapse();

      this.hasExpanded = false; // Destroys all child components (except if multi-file root)

      if (this.isMultiFileRoot && this.hasChildren) {
        _.forEach(this.$refs.childItem, function (childComp) {
          childComp.collapseAll();
        });
      }
    },
    expandAll: function expandAll() {
      this._expand();

      var isMain = this.isMain;

      if (this.hasChildren) {
        var _c = this;

        setTimeout(function () {
          _.forEach(_c.$refs.childItem, function (childComp) {
            if (childComp.isMain != isMain) return;
            childComp.expandAll();
          });
        }, 0);
      }
    },
    expandSearch: function expandSearch() {
      var _c = this;

      this._expandChildList(this.searchIds, function () {
        if (_c.hasChildren) {
          _.forEach(_c.$refs.childItem, function (childComp) {
            childComp.expandSearch();
          });
        }
      });
    },
    expandId: function expandId(varId) {
      if (this.varId == varId) {
        this._expand();
      } else if (this.hasChildren) {
        var _c = this;

        this._expandChildList([varId], function () {
          _.forEach(_c.$refs.childItem, function (childComp) {
            childComp.expandId(varId);
          });
        });
      }
    },
    _collapse: function _collapse() {
      this.collapsed = true;
    },
    _expand: function _expand() {
      this.collapsed = false;
      this.hasExpanded = true;
    },
    _itemClick: function _itemClick() {
      if (this.itemSelectable) {
        // Toggle item selection
        this._toggleSelectVar();
      } else if (this.hasChildren) {
        // Toggle collapsed
        this._expandCollapseClick();
      } else {
        // Open info modal
        this._showInfo();
      }
    },
    _expandCollapseClick: function _expandCollapseClick() {
      // Toggle collapsed
      if (this.collapsed) {
        this._expand();
      } else {
        this._collapse();
      }
    },
    _expandChildList: function _expandChildList(varIds, childExpandCallback) {
      if (!this.hasChildren) return;
      if (!varIds) return;

      function matchesSelfOrDescendant(varDef) {
        var matches = varIds.indexOf(varDef.id) != -1;
        if (matches) return true;
        if (!varDef.children) return false;

        for (var i = 0; i < varDef.children.length; i++) {
          if (matchesSelfOrDescendant(varDef.children[i])) return true;
        }

        return false;
      }

      if (matchesSelfOrDescendant(this.varDef)) {
        this._expand();

        setTimeout(childExpandCallback, 0);
      }
    },
    _showInfo: function _showInfo(eventOrVarDef) {
      if (!eventOrVarDef || !eventOrVarDef.hasOwnProperty('id')) eventOrVarDef = this.varDef;
      this.$emit('info', eventOrVarDef);
    },
    _showRouting: function _showRouting(eventOrVarDef) {
      if (!eventOrVarDef.hasOwnProperty('id')) eventOrVarDef = this.varDef;
      this.$emit('routing', eventOrVarDef);
    },
    _createVar: function _createVar(eventOrParentVarDef) {
      if (!eventOrParentVarDef.hasOwnProperty('id')) eventOrParentVarDef = this.varDef;
      this.$emit('create', eventOrParentVarDef);
    },
    _editVar: function _editVar(eventOrVarDef) {
      if (!eventOrVarDef.hasOwnProperty('id')) eventOrVarDef = this.varDef;
      this.$emit('edit', eventOrVarDef);
    },
    _connectVar: function _connectVar(eventOrVarDef) {
      if (!eventOrVarDef.hasOwnProperty('id')) eventOrVarDef = this.varDef;
      this.$emit('connect', eventOrVarDef);
    },
    _deleteVar: function _deleteVar(eventOrVarDef) {
      if (!eventOrVarDef.hasOwnProperty('id')) eventOrVarDef = this.varDef;
      this.$emit('delete', eventOrVarDef);
    },
    _toggleSelectVar: function _toggleSelectVar() {
      this._setSelectVar(!this.selected);
    },
    _setSelectVar: function _setSelectVar(selected) {
      if (!this.selectionDisabled) {
        this._selectVar(this.varId, selected);
      }
    },
    _selectVar: function _selectVar(varId, selected) {
      this.$emit('select', varId, selected);
    }
  }
};