//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from './lodash.custom.js';
import panzoom from 'panzoom';
import { panzoomCenterFit } from "./utils";
import uiModal from './ui-items/ui-modal.vue';
import branchTree from './ui-items/branch-tree.vue';
import { api, dispatcher } from './index';
export default {
  name: "branches",
  components: {
    uiModal: uiModal,
    branchTree: branchTree
  },
  props: {
    graphs: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      api: api,
      dispatcher: dispatcher,
      project: null,
      selectCallback: null,
      windowHeight: 0,
      canvasTopOffset: 0,
      branchIds: [],
      panzoomController: null,
      scale: 1,
      nGraphUpdate: 0
    };
  },
  computed: {
    projectId: function projectId() {
      return this.project ? this.project.id : null;
    },
    activeBranchId: function activeBranchId() {
      return this.project ? this.project.branch_id : null;
    },
    projectTitle: function projectTitle() {
      return this.project ? this.project.title : '';
    },
    branchList: function branchList() {
      return this.project ? this.project.branches : [];
    },
    branches: function branches() {
      var branches = this.branchList;

      function getBranchTree(branch) {
        var children = _.map(_.filter(branches, function (childBranch) {
          return childBranch.parent_id == branch.id;
        }), function (childBranch) {
          return getBranchTree(childBranch);
        });

        return _.merge({}, branch, {
          children: children
        });
      }

      var baseBranches = _.filter(branches, function (branch) {
        return branch.parent_id === null;
      });

      return _.map(baseBranches, function (branch) {
        return getBranchTree(branch);
      });
    },
    canvasStyle: function canvasStyle() {
      return {
        'max-height': this.windowHeight - this.canvasTopOffset - 2 + 'px'
      };
    }
  },
  methods: {
    open: function open(project, selectCallback) {
      this.project = project;
      this.selectCallback = selectCallback || null;
      this.$refs.modal.open();
    },
    close: function close() {
      this.$refs.modal.close();
    },
    _opened: function _opened() {
      this._setupPanZoom(); // Determine offset from top of canvas


      var canvas = this.$refs.canvas;
      var canvasTop = canvas.getBoundingClientRect().top;
      var modalTop = canvas.parentElement.parentElement.getBoundingClientRect().top;
      this.canvasTopOffset = canvasTop - modalTop; // Update the XDSMs

      this.branchIds = [];

      this._updateBranchGraphs(this.branchList);

      this.dispatcher.onUpdatedProject(this._updatedProjectHandler);
      this.dispatcher.onUpdatedGraph(this._onUpdatedGraph);
    },
    _setupPanZoom: function _setupPanZoom() {
      var el = this.$refs.moveObject;
      el.addEventListener('zoom', this._updateScale);
      el.addEventListener('zoomend', this._updateScale);
      this.panzoomController = panzoom(el, {
        smoothScroll: false,
        zoomSpeed: .2,
        maxZoom: 5,
        minZoom: .1,
        bounds: true,
        boundsPadding: .25
      });
      setTimeout(this._centerFit, 0);
    },
    _centerFit: function _centerFit() {
      var canvas = this.$refs.canvas;
      var el = this.$refs.moveObject;
      panzoomCenterFit(canvas, el, this.panzoomController);
    },
    _updateBranchGraphs: function _updateBranchGraphs(branches) {
      var _this = this;

      var _c = this;

      var t = 50;
      this.dispatcher.setInBatchGraphUpdate(true);

      _.forEach(branches, function (branch) {
        setTimeout(function () {
          _c.nGraphUpdate++;

          _c.branchIds.push(branch.id);

          _c.dispatcher.updateGraph(_this.projectId, branch.id);
        }, t);
        t += 20;
      });
    },
    _onUpdatedGraph: function _onUpdatedGraph() {
      if (this.nGraphUpdate > 0) {
        this.nGraphUpdate--;
        if (this.nGraphUpdate == 0) this.dispatcher.setInBatchGraphUpdate(false);
      }
    },
    _closeSelect: function _closeSelect() {
      this.close();
      if (this.selectCallback) this.selectCallback();
    },
    _closing: function _closing() {
      this.panzoomController.dispose();
      this.dispatcher.offUpdatedProject(this._updatedProjectHandler);
      this.dispatcher.offUpdatedGraph(this._onUpdatedGraph);
      this.$emit('close');
    },
    _projectReloaded: function _projectReloaded(project) {
      this.project = project;
      setTimeout(this.$refs.tree.updated, 0);
      var branchIds = this.branchIds;

      this._updateBranchGraphs(_.filter(this.branchList, function (branch) {
        return !_.includes(branchIds, branch.id);
      }));
    },
    _editBranch: function _editBranch(branchId) {
      this.$emit('edit', this.project, branchId);

      this._closeSelect();
    },
    _exportBranch: function _exportBranch(branchId) {
      this.$emit('export', this.project, branchId);
    },
    _deleteBranch: function _deleteBranch(branch) {
      this.$emit('delete', this.project, branch, this._projectReloaded);
    },
    _duplicateBranch: function _duplicateBranch(branchId) {
      this.$emit('duplicate', this.project, branchId, this._projectReloaded);
    },
    _updatedProjectHandler: function _updatedProjectHandler(project) {
      if (project.id == this.projectId) {
        this._projectReloaded(project);
      }
    },
    _onResize: function _onResize() {
      this.windowHeight = window.innerHeight;
    },
    _updateScale: function _updateScale() {
      this.scale = this.panzoomController.getTransform().scale;
    }
  },
  mounted: function mounted() {
    this._onResize();

    window.addEventListener('resize', this._onResize);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this._onResize);
  }
};