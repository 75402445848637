//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../../lodash.custom.js';
import uiModal from '../ui-modal.vue';
import xdsmFunction from '../xdsm/xdsm-function.vue';
import arrows from '../arrows.vue';
import { functionTypes } from "../mdao/functions.js";
import { getFlatVars } from "../../var-utils.js";
import { api, dispatcher } from '../../index.js';
var COORD_OUTPUT_ID = 9999;
export default {
  name: "routing-select",
  components: {
    uiModal: uiModal,
    xdsmFunction: xdsmFunction,
    arrows: arrows
  },
  props: {
    graphDef: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      api: api,
      dispatcher: dispatcher,

      /** @type {?varDefType} */
      varDef: null,
      functionCon: [],
      // [{left: idx, right: idx}, ...]
      slotPos: [],
      // [{left: {x, y, left, top, width, height}, right: {x, y, left, top, width, height}}, ...]
      slotMargin: 10,
      validConnections: false,
      outSelectedIdx: null,
      canAddConn: {}
    };
  },
  watch: {
    graphDef: {
      handler: function handler() {
        this._updateSlotPos();
      },
      deep: true
    },
    functionCon: {
      handler: function handler() {
        this._updateSlotPos();

        this._updateNewConn();
      },
      deep: true
    }
  },
  computed: {
    varName: function varName() {
      return this.varDef ? this.varDef.name : '';
    },
    xpath: function xpath() {
      return this.varDef ? this.varDef.xpath : '';
    },
    title: function title() {
      return 'Variable Routing: ' + this.varName;
    },
    varId: function varId() {
      return this.varDef ? this.varDef.id : 0;
    },
    allVars: function allVars() {
      return this.varDef ? getFlatVars(this.varDef) : {};
    },
    funCons: function funCons() {
      return _.map(this.allVars, function (varDef) {
        return varDef.fun_con;
      });
    },
    inFunctionIds: function inFunctionIds() {
      var functionIds = _.filter(_.flatten(_.map(this.funCons, function (funCon) {
        return funCon ? funCon.in : null;
      })));

      return _.concat(functionIds, [COORD_OUTPUT_ID]);
    },
    outFunctionIds: function outFunctionIds() {
      return _.filter(_.flatten(_.map(this.funCons, function (funCon) {
        return funCon ? funCon.out : null;
      })));
    },
    coordId: function coordId() {
      var functions = this.functions;
      return functions.length > 0 ? functions[0].id : 0;
    },
    functions: function functions() {
      var functions = this.graphDef ? this.graphDef.functions.slice() : [];
      functions.push({
        id: COORD_OUTPUT_ID,
        title: 'System output',
        rounded: false,
        bg: '#fff',
        process_title: 'end',
        out: []
      });
      return functions;
    },
    functionSlots: function functionSlots() {
      // Map function IDs to function definition blocks (enriched with their index in the functions list)
      var coordId = this.coordId;
      var functions = this.functions;

      var functionMap = _.keyBy(_.map(functions, function (functionDef, idx) {
        var funcDefIdx = _.merge({}, functionDef, {
          idx: idx
        });

        if (funcDefIdx.id == coordId) funcDefIdx.title = 'System input';
        return funcDefIdx;
      }), function (functionDef) {
        return functionDef.id;
      }); // Get the input and output functions, sorted by their index


      var inFunctionIds = _.concat(this.inFunctionIds, [COORD_OUTPUT_ID]);

      var outFunctionIds = _.concat(this.outFunctionIds, [coordId]);

      var inFunctions = _.sortBy(_.filter(_.map(inFunctionIds, function (fId) {
        return functionMap[fId];
      })), function (functionDef) {
        return functionDef.idx;
      });

      var outFunctions = _.sortBy(_.filter(_.map(outFunctionIds, function (fId) {
        return functionMap[fId];
      })), function (functionDef) {
        return functionDef.idx;
      }); // Assign them to rendering slots


      var inIdx = _.map(inFunctions, function (functionDef) {
        return functionDef.idx;
      });

      var outIdx = _.map(outFunctions, function (functionDef) {
        return functionDef.idx;
      });

      var indices = _.sortBy(_.uniq(_.concat(inIdx, outIdx)), function (idx) {
        return idx;
      });

      var slots = _.map(indices, function () {
        return {
          in: null,
          out: null
        };
      });

      _.forEach(inFunctions, function (functionDef) {
        var slotIdx = indices.indexOf(functionDef.idx);
        slots[slotIdx].in = functionDef;
      });

      _.forEach(outFunctions, function (functionDef) {
        var slotIdx = indices.indexOf(functionDef.idx);
        slots[slotIdx].out = functionDef;
      });

      return slots;
    },
    functionSlotIdxMap: function functionSlotIdxMap() {
      var slotIdxMap = {};

      _.forEach(this.functionSlots, function (functionSlot, idx) {
        var inFunc = functionSlot.in,
            outFunc = functionSlot.out;
        if (inFunc) slotIdxMap[inFunc.id] = idx;
        if (outFunc) slotIdxMap[outFunc.id] = idx;
      });

      return slotIdxMap;
    },
    inFunctionSelected: function inFunctionSelected() {
      var inConnected = _.map(this.functionCon, function (conDef) {
        return conDef.right;
      }); // If the first slot both provides input and output, regard it as connected


      var functionSlots = this.functionSlots;

      if (functionSlots.length > 0 && functionSlots[0].out !== null && functionSlots[0].in !== null) {
        inConnected.push(0);
      }

      return _.map(functionSlots, function (slotDef, idx) {
        return _.includes(inConnected, idx);
      });
    },
    isOutSelected: function isOutSelected() {
      return this.outSelectedIdx !== null;
    },
    inFunctionSelectable: function inFunctionSelectable() {
      var outSelected = this.isOutSelected;
      var outSelectedIdx = this.outSelectedIdx;
      var canAddCon = this.canAddConn;
      return _.map(this.functionSlots, function (slotDef, idx) {
        return outSelected && !!slotDef.in && outSelectedIdx in canAddCon && idx in canAddCon[outSelectedIdx] && canAddCon[outSelectedIdx][idx] === true;
      });
    },
    arrows: function arrows() {
      var slotPos = this.slotPos;
      if (!slotPos || slotPos.length == 0) return [];
      var firstSlot = slotPos[0];
      var f = .5;
      var xMid = firstSlot.left.left * (1 - f) + (firstSlot.right.left + firstSlot.right.width) * f;
      var margin = this.slotMargin;

      function moveToX(x1, y1, x2, y2, x) {
        return (x - x1) / (x2 - x1) * (y2 - y1) + y1;
      }

      return _.map(this.functionCon, function (conIdx) {
        var leftIdx = conIdx.left,
            rightIdx = conIdx.right;
        var leftPos = slotPos[leftIdx].left;
        var rightPos = slotPos[rightIdx].right;
        var x01 = leftPos.x,
            y01 = leftPos.y,
            w1 = leftPos.width;
        var x02 = rightPos.x,
            y02 = rightPos.y,
            w2 = rightPos.width;
        var x1 = x01 + .5 * w1 + margin;
        var y1 = moveToX(x01, y01, x02, y02, x1);
        var x2 = x02 - .5 * w2 - margin;
        var y2 = moveToX(x01, y01, x02, y02, x2);
        var yMid = moveToX(x01, y01, x02, y02, xMid);
        return "M".concat(x1, ",").concat(y1, " L").concat(xMid, ",").concat(yMid, " L").concat(x2, ",").concat(y2);
      });
    },
    allInputConnected: function allInputConnected() {
      var functionSlots = this.functionSlots;
      var functionCon = this.functionCon;
      return _.findIndex(this.inFunctionIds, function (fId) {
        // If this is the first function and it also provides output, connection is not needed
        if (functionSlots[0].out !== null && functionSlots[0].out.id == fId) return false; // Not found --> not connected --> return outer findIndex call

        return _.findIndex(functionCon, function (_ref) {
          var right = _ref.right;
          return functionSlots[right].in && functionSlots[right].in.id == fId;
        }) == -1;
      }) == -1;
    },
    isValid: function isValid() {
      return this.validConnections && this.allInputConnected;
    }
  },
  methods: {
    open: function open(varDef) {
      // if (!recursiveHasCollision(varDef)) throw "Variable without collision";
      this.slotPos = [];
      this.varDef = varDef;

      var functionCon = this._getFunctionCon(this._getFlatRouting(varDef));

      this.functionCon = [];
      this.validConnections = false;
      this.canAddConn = {};

      this._setFunctionCon(functionCon);

      this.$refs.modal.open();
    },
    close: function close() {
      this.$refs.modal.close();
    },
    _getFlatRouting: function _getFlatRouting(varDef) {
      var functions = this.functions;

      var convergerIds = _.map(_.filter(functions, function (functionDef) {
        return functionDef.type == functionTypes.MDA;
      }), function (functionDef) {
        return functionDef.id;
      });

      return _.filter(_.flatten(_.map(getFlatVars(varDef), function (varDef) {
        var routing = varDef.routing;
        if (convergerIds.length == 0) return routing; // "Revert" routes going through convergers: a converger basically "routes" a variable through
        // itself, but this is applied *after* applying the collision resolutions (manual variable routing),
        // so we need to derive the graph state before applying the convergers
        // Find converger output

        var fromConvMap = _.fromPairs(_.filter(_.map(routing, function (routeDef) {
          if (!_.includes(convergerIds, routeDef.out[0])) return null;
          var convergerId = routeDef.out[0];
          return [convergerId, routeDef.in];
        })));

        if (Object.keys(fromConvMap).length == 0) return routing;
        return _.filter(_.map(routing, function (routeDef) {
          // Check if coming from converger
          if (_.includes(convergerIds, routeDef.out[0])) return null; // Replace converger entries in the target functions with the out functions of this converger

          var inFunctionIds = [];

          _.forEach(routeDef.in, function (functionId) {
            if (functionId in fromConvMap) {
              _.forEach(fromConvMap[functionId], function (convOutFunctionId) {
                inFunctionIds.push(convOutFunctionId);
              });
            } else {
              inFunctionIds.push(functionId);
            }
          });

          return {
            out: routeDef.out,
            in: inFunctionIds
          };
        }));
      })));
    },
    _getFunctionCon: function _getFunctionCon(routing) {
      var slotIdxMap = this.functionSlotIdxMap;
      var coordId = this.coordId;
      var coordOutConnected = false;
      var routingOutputtingIdx = [];

      var functionCon = _.filter(_.flatten(_.map(routing, function (routeDef) {
        var outIds = routeDef.out.length == 0 ? [coordId] : routeDef.out;
        return _.flatten(_.map(outIds, function (outId) {
          if (!(outId in slotIdxMap)) return null;
          if (outId !== coordId) routingOutputtingIdx.push(slotIdxMap[outId]);
          var inIds = routeDef.in.length == 0 ? [COORD_OUTPUT_ID] : routeDef.in;
          return _.map(inIds, function (inId) {
            if (inId === COORD_OUTPUT_ID) coordOutConnected = true;
            if (!(inId in slotIdxMap)) return null;
            return {
              left: slotIdxMap[outId],
              right: slotIdxMap[inId]
            };
          });
        }));
      })));

      if (!coordOutConnected) {
        var coordOutIdx = slotIdxMap[COORD_OUTPUT_ID]; // Implicit output connection from the last outputting function if routing is specified

        if (routingOutputtingIdx.length >= 1) {
          functionCon.push({
            left: Math.max.apply(Math, routingOutputtingIdx),
            right: coordOutIdx
          }); // Implicit output connection if only one function is outputting to the variable
        } else {
          var outFunctionIds = this.outFunctionIds;

          if (outFunctionIds.length === 1) {
            functionCon.push({
              left: slotIdxMap[outFunctionIds[0]],
              right: coordOutIdx
            });
          }
        }
      }

      return functionCon;
    },
    _setFunctionCon: function _setFunctionCon(functionCon) {
      var _c = this;

      this._validateFunctionCon(functionCon, function (valid) {
        _c.validConnections = valid;
        if (valid) _c.functionCon = functionCon;
      });
    },
    _validateFunctionCon: function _validateFunctionCon(functionCon, callback) {
      this.api.checkVarFunctionCon(_.map(functionCon, function (_ref2) {
        var left = _ref2.left,
            right = _ref2.right;
        return {
          out_idx: parseInt(left),
          in_idx: parseInt(right)
        };
      }), callback);
    },
    _updateNewConn: function _updateNewConn() {
      var coordId = this.coordId;
      var coordIdx = null;
      var coordOutIdx = null; // Get input and output slot indices

      var inFunctionIndices = [];
      var outFunctionIndices = [];

      _.forEach(this.functionSlots, function (functionSlot, idx) {
        if (functionSlot.in) {
          inFunctionIndices.push(idx);
          if (functionSlot.in.id === COORD_OUTPUT_ID) coordOutIdx = idx;
        }

        if (functionSlot.out) {
          outFunctionIndices.push(idx);
          if (functionSlot.out.id === coordId) coordIdx = idx;
        }
      }); // Set initial data status (before querying server)


      var functionCon = this.functionCon;

      var canAddConn = _.fromPairs(_.map(outFunctionIndices, function (outIdx) {
        return [outIdx, _.fromPairs(_.map(inFunctionIndices, function (inIdx) {
          return [inIdx, null];
        }))];
      }));

      _.forEach(functionCon, function (_ref3) {
        var left = _ref3.left,
            right = _ref3.right;
        if (!(left in canAddConn) || !right in canAddConn[left]) return;
        canAddConn[left][right] = false;
      });

      this.canAddConn = canAddConn; // Query server

      var _c = this;

      _.forEach(canAddConn, function (inputConn, outIdx) {
        return _.forEach(inputConn, function (isAllowed, inIdx) {
          if (isAllowed !== null) return;

          _c._validateFunctionCon(_.concat(functionCon, {
            left: outIdx,
            right: inIdx
          }), function (valid) {
            if (outIdx == coordIdx && inIdx == coordOutIdx) valid = false;

            _c.$set(_c.canAddConn[outIdx], inIdx, valid);
          });
        });
      });
    },
    _opened: function _opened() {
      this._updateSlotPos();

      document.addEventListener('click', this._deselectOut);
    },
    _closed: function _closed() {
      this._deselectOut();

      document.removeEventListener('click', this._deselectOut);
    },
    _isOpen: function _isOpen() {
      return this.$refs.modal.opened;
    },
    _updateSlotPos: function _updateSlotPos() {
      if (!this._isOpen()) return;
      if (!this.$refs.arrowsContainer) return;
      var cbox = this.$refs.arrowsContainer.getBoundingClientRect();

      function getSlotFunctionPos(slotEl) {
        var functionEl = slotEl.querySelector('div.function') || slotEl;
        var bbox = functionEl.getBoundingClientRect();
        var left = bbox.left,
            top = bbox.top,
            width = bbox.width,
            height = bbox.height;
        left -= cbox.left;
        top -= cbox.top;
        var x = left + .5 * width;
        var y = top + .5 * height;
        return {
          x: x,
          y: y,
          left: left,
          top: top,
          width: width,
          height: height
        };
      }

      var leftEls = this.$refs.outSlot;
      var rightEls = this.$refs.inSlot;
      this.slotPos = _.map(this.functionSlots, function (_, idx) {
        return {
          left: getSlotFunctionPos(leftEls[idx]),
          right: getSlotFunctionPos(rightEls[idx])
        };
      });
    },
    _selectOut: function _selectOut(idx) {
      if (!this.isOutSelected) this.dispatcher.addEscapeHandler(this._escapePress);

      if (this.outSelectedIdx === idx) {
        this._deselectOut();
      } else {
        this.outSelectedIdx = idx;
      }
    },
    _deselectOut: function _deselectOut() {
      if (this.outSelectedIdx !== null) {
        this.outSelectedIdx = null;
        this.dispatcher.popEscapeHandler();
      }
    },
    _escapePress: function _escapePress() {
      this._deselectOut();
    },
    _clickIn: function _clickIn(idx) {
      if (!this.isOutSelected || !this.inFunctionSelectable[idx]) return;

      this._setFunctionCon(_.concat(this.functionCon, {
        left: this.outSelectedIdx,
        right: idx
      }));

      this._deselectOut();
    },
    _arrowClick: function _arrowClick(conIdx) {
      this._setFunctionCon(_.filter(this.functionCon, function (val, idx) {
        return idx != conIdx;
      }));
    },
    _apply: function _apply() {
      if (!this.isValid) return;
      var coordId = this.coordId; // Create routes from function connections (one route per output function)

      var routes = {};

      _.forEach(this.functionCon, function (_ref4) {
        var left = _ref4.left,
            right = _ref4.right;
        if (!(left in routes)) routes[left] = [];
        routes[left].push(right);
      }); // Convert to proper routing definitions


      var functionSlots = this.functionSlots;

      var routeDefs = _.map(routes, function (inIndices, outIdx) {
        var outId = functionSlots[outIdx].out.id;

        var inIds = _.filter(_.map(inIndices, function (inIdx) {
          return functionSlots[inIdx].in.id;
        }), function (fId) {
          return fId !== COORD_OUTPUT_ID;
        });

        return {
          out: outId == coordId ? [] : [outId],
          in: inIds
        };
      }); // Add input to unconnected first input block


      var n = functionSlots.length;

      if (n > 0 && functionSlots[0].out !== null && functionSlots[0].in !== null) {
        var inConnected = _.map(this.functionCon, function (conDef) {
          return conDef.right;
        });

        if (!_.includes(inConnected, 0)) {
          routeDefs.push({
            out: [],
            in: [functionSlots[0].in.id]
          });
        }
      } // Add output for unconnected last output block


      if (functionSlots[n - 1].out !== null && functionSlots[n - 1].in !== null) {
        var outConnected = _.map(this.functionCon, function (conDef) {
          return conDef.left;
        });

        if (!_.includes(outConnected, n - 1)) {
          var outId = functionSlots[n - 1].out.id;
          routeDefs.push({
            out: outId == coordId ? [] : [outId],
            in: []
          });
        }
      } // Update variable


      var _c = this;

      this.api.editSetVarRouting(this.varId, routeDefs, function () {
        // Trigger state reload
        _c.dispatcher.backendStateUpdated();
      });
    },
    _modalChanged: function _modalChanged() {
      setTimeout(this._updateSlotPos, 0);
    }
  }
};