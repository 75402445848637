var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "draggable-container" },
    _vm._l(_vm.value, function(nestingSpec, idx) {
      return _vm.hasPos[idx]
        ? _c(
            "div",
            {
              staticClass: "nesting-block-wrapper",
              style: _vm.blockWrapperStyle[nestingSpec.id]
            },
            [
              _c(
                "div",
                {
                  staticClass: "drop-target",
                  style: _vm.dropTargetStyle[nestingSpec.id]
                },
                [
                  _c(
                    "div",
                    {
                      ref: "draggable",
                      refInFor: true,
                      staticClass: "drag-wrapper",
                      class: {
                        "no-drop": _vm.droppingDisabled[nestingSpec.id],
                        "drag-allowed": _vm.enableFunctionSort[nestingSpec.id]
                      },
                      on: {
                        dragstart: function($event) {
                          $event.stopPropagation()
                          _vm._onDragStart($event, nestingSpec.id)
                        },
                        dragend: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm._onDragEnd($event)
                        }
                      }
                    },
                    [
                      nestingSpec.f
                        ? _c("xdsm-function", {
                            directives: [
                              {
                                name: "no-pan",
                                rawName: "v-no-pan",
                                value: true,
                                expression: "true"
                              }
                            ],
                            key: nestingSpec.id,
                            staticClass: "function-render-block",
                            class: _vm.functionClasses[nestingSpec.id],
                            attrs: {
                              "function-def": nestingSpec.f,
                              deletable: _vm.functionDeletable,
                              collapsible: _vm.collapsibleMap[nestingSpec.id],
                              "show-issues": _vm.showIssues,
                              "show-enter": _vm.showEnter,
                              "drag-handle":
                                _vm.enableFunctionSort[nestingSpec.id]
                            },
                            on: {
                              delete: function($event) {
                                _vm.$emit("delete", $event)
                              },
                              handleMouseDown: _vm._handleMouseDown,
                              collapse: _vm._collapse,
                              enter: _vm._enter
                            },
                            nativeOn: {
                              click: function($event) {
                                _vm.$emit("functionClick", nestingSpec.id)
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      nestingSpec.wraps.length
                        ? _c("function-drag", {
                            ref: "subDrag",
                            refInFor: true,
                            staticClass: "sub-nesting-block",
                            attrs: {
                              value: nestingSpec.wraps,
                              "parent-function-id": nestingSpec.id,
                              "function-pos": _vm.functionPos,
                              "function-classes": _vm.functionClasses,
                              "drop-disabled":
                                _vm.subDropDisabled || _vm.dropDisabled,
                              "function-clickable": _vm.functionClickable,
                              "function-deletable": _vm.functionDeletable,
                              "sortable-ids": _vm.sortableIds,
                              "sort-target-ids": _vm.sortTargetIds,
                              "container-el": _vm.containerEl,
                              "move-el": _vm.moveEl,
                              scale: _vm.scale,
                              "collapsible-map": _vm.collapsibleMap,
                              "collapsed-map": _vm.collapsedMap,
                              "show-issues": _vm.showIssues,
                              "show-enter": _vm.showEnter
                            },
                            on: {
                              input: function($event) {
                                _vm._subInput(idx, $event)
                              },
                              sort: function($event) {
                                _vm.$emit("sort", $event)
                              },
                              collapse: _vm._collapse,
                              functionClick: function($event) {
                                _vm.$emit("functionClick", $event)
                              },
                              pausePanZoom: _vm.pausePanZoom,
                              dragStart: function($event) {
                                _vm._setDropDisabled(true)
                              },
                              dragStop: function($event) {
                                _vm._setDropDisabled(false)
                              },
                              setDropDisabled: _vm._setDropDisabled,
                              delete: function($event) {
                                _vm.$emit("delete", $event)
                              },
                              enter: _vm._enter
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ]
          )
        : _vm._e()
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }