function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../../lodash.custom.js';
import escape from 'escape-html';
import selectTable from './select-table.vue';
import selectVar from './select-var.vue';
import { getFlatVars, getLeafVars, getParentVars, getNonConflictingVarNames } from '../../var-utils.js'; // https://baianat.github.io/vee-validate/guide/custom-rules.html
// https://baianat.github.io/vee-validate/guide/custom-rules.html#target-dependant-rules

import { Validator } from 'vee-validate';
var a,
    b,
    flt = parseFloat;

var nanIn = function nanIn(value, otherValue) {
  return isNaN(value) || isNaN(otherValue);
};

Validator.extend('>', function (val, _ref) {
  var _ref2 = _slicedToArray(_ref, 1),
      othVal = _ref2[0];

  return nanIn(a = flt(val), b = flt(othVal)) || a > b;
}, {
  hasTarget: true
});
Validator.extend('<', function (val, _ref3) {
  var _ref4 = _slicedToArray(_ref3, 1),
      othVal = _ref4[0];

  return nanIn(a = flt(val), b = flt(othVal)) || a < b;
}, {
  hasTarget: true
});
Validator.extend('>=', function (val, _ref5) {
  var _ref6 = _slicedToArray(_ref5, 1),
      othVal = _ref6[0];

  return nanIn(a = flt(val), b = flt(othVal)) || a >= b;
}, {
  hasTarget: true
});
Validator.extend('<=', function (val, _ref7) {
  var _ref8 = _slicedToArray(_ref7, 1),
      othVal = _ref8[0];

  return nanIn(a = flt(val), b = flt(othVal)) || a <= b;
}, {
  hasTarget: true
});
export default {
  name: "select-vars",
  components: {
    selectTable: selectTable,
    selectVar: selectVar
  },
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // [{id: varId, ...}, ...]
    mode: {
      type: String,
      default: null,
      validator: function validator(val) {
        return _.includes(['x', 'g', 'alias', '', null], val);
      }
    },
    immediateAdd: {
      type: Boolean,
      default: null
    },
    // default: true if in default mode
    rootVarDef: {
      type: Object,
      required: true
    },
    selectLeaf: {
      type: Boolean,
      default: true
    },
    selectParent: {
      type: Boolean,
      default: false
    },
    graphDef: {
      type: Object,
      default: function _default() {
        return null;
      }
    },
    varModalTitle: {
      type: String,
      default: null
    },
    needOne: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    disabledVarIds: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    createVarFunction: {
      type: Function,
      default: null
    },
    disableSelectedVars: {
      type: Boolean,
      default: true
    },
    allowDuplicateRow: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      baseColumns: [['name', 'Variable', this._renderName]],
      selectedVarIds: [],
      editedVarId: null,
      hasValidated: false,
      selectVarComp: null,
      valGroups: ['x', 'g', 'alias'],
      constraintTypes: ['<=', '<', '=', '>', '>='],
      defaultConstraintType: '<=',
      constraintType: null,
      defaultConstraintRef: 0,
      constraintRef: null,
      constraintRefRules: {
        required: true,
        decimal: true
      },
      lowerBound: '',
      nominal: '',
      upperBound: '',
      lowerBoundRules: {
        // required: true,
        decimal: true,
        '<': 'upper'
      },
      nominalRules: {
        // required: true,
        decimal: true,
        '>=': 'lower',
        '<=': 'upper'
      },
      upperBoundRules: {
        // required: true,
        decimal: true,
        '>': 'lower'
      },
      validateEvents: 'change|input|blur',
      alias: '',
      editedAlias: null,
      aliasRules: {
        required: true,
        alpha_dash: true,
        excluded: []
      },
      rowIsEdited: false
    };
  },
  watch: {
    value: function value() {
      this._initForm();
    },
    mode: function mode() {
      this._initForm();
    },
    rootVarDef: function rootVarDef() {
      this._initForm();
    },
    data: function data() {
      this._debouncedRemoveUnavailableVars();
    },
    editedAlias: function editedAlias() {
      this._setExcludedAliases();
    }
  },
  computed: {
    columns: function columns() {
      var extraColumns = [];

      if (this.selCon) {
        extraColumns = [['constraint_type', 'Type'], ['constraint_ref', 'Reference Value', this._formatNrFunc('constraint_ref')]];
      } else if (this.selDesVar) {
        extraColumns = [['lower_bound', 'Lower Bound', this._formatNrFunc('lower_bound')], ['nominal', 'Nominal Value', this._formatNrFunc('nominal')], ['upper_bound', 'Upper Bound', this._formatNrFunc('upper_bound')]];
      } else if (this.selAlias) {
        extraColumns = [['alias', 'Alias']];
      }

      return _.concat(this.baseColumns, extraColumns);
    },
    varIds: function varIds() {
      return _.map(this.value, function (val) {
        return val.id;
      });
    },
    varIdsNotEdited: function varIdsNotEdited() {
      var editedVarId = this.editedVarId;
      return _.filter(this.varIds, function (varId) {
        return varId != editedVarId;
      });
    },
    selectDisabledVarIds: function selectDisabledVarIds() {
      if (!this.disableSelectedVars) return this.disabledVarIds;
      return _.concat(this.varIdsNotEdited, this.disabledVarIds);
    },
    data: function data() {
      var varMap = this.varMap;
      var varNames = getNonConflictingVarNames(_.map(this.value, function (val) {
        return val.id;
      }), varMap);

      var xpaths = _.map(this.value, function (val) {
        return val.id in varMap ? varMap[val.id].xpath : null;
      });

      return _.map(this.value, function (val, idx) {
        var rowObj = _.cloneDeep(val);

        rowObj.name = varNames[idx];
        rowObj.xpath = xpaths[idx];
        return rowObj;
      });
    },
    varNameMap: function varNameMap() {
      return _.fromPairs(_.map(this.data, function (row) {
        return [row.id, row.name];
      }));
    },
    needsSelection: function needsSelection() {
      return this.needOne && this.varIds.length == 0;
    },
    varMap: function varMap() {
      // Leaf vars with references back to their parents
      if (this.selectLeaf && this.selectParent) {
        return getFlatVars(this.rootVarDef);
      } else if (this.selectLeaf) {
        return getLeafVars(this.rootVarDef);
      } else {
        return getParentVars(this.rootVarDef);
      }
    },
    isDefaultMode: function isDefaultMode() {
      return !(this.selCon || this.selDesVar || this.selAlias);
    },
    rowsEditable: function rowsEditable() {
      return !this.isDefaultMode;
    },
    selCon: function selCon() {
      // Selecting constraints?
      return this.mode == 'g';
    },
    selDesVar: function selDesVar() {
      // Select design variables?
      return this.mode == 'x';
    },
    selAlias: function selAlias() {
      // Select aliases of variables?
      return this.mode == 'alias';
    },
    editFocusRefKey: function editFocusRefKey() {
      var mode = this.mode;
      if (mode == 'g') return 'refField';
      if (mode == 'x') return 'lower';
      if (mode == 'alias') return 'aliasField';
      return null;
    },
    varListModalTitle: function varListModalTitle() {
      if (this.varModalTitle) return this.varModalTitle;
      if (this.selCon) return 'Select Constraints';
      if (this.selDesVar) return 'Select Design Variables';
      return 'Select Variables';
    },
    usedAliases: function usedAliases() {
      return _.filter(_.map(this.value, function (val) {
        return val.alias;
      }));
    },
    varEditable: function varEditable() {
      return this.editable && !this.rowIsEdited;
    },
    _debouncedRemoveUnavailableVars: function _debouncedRemoveUnavailableVars() {
      return _.debounce(this._removeUnavailableVars, 200);
    }
  },
  methods: {
    _initForm: function _initForm() {
      this.hasValidated = false;
      this.selectedVarIds = [];
      this.editedVarId = null;
      this.constraintType = this.defaultConstraintType;
      this.constraintRef = this.defaultConstraintRef;
      this.lowerBound = '';
      this.nominal = '';
      this.upperBound = '';
      this.alias = '';
      this.editedAlias = '';

      this._setExcludedAliases();

      this.rowIsEdited = false;

      var _c = this;

      setTimeout(function () {
        _.forEach(_.concat(['var'], _c.valGroups), function (group) {
          _c.errors.clear(group);

          _c.$validator.reset(group);
        });
      }, 0);
    },
    _setExcludedAliases: function _setExcludedAliases() {
      var allowAlias = this.editedAlias;
      this.aliasRules.excluded = _.filter(this.usedAliases, function (alias) {
        return alias != allowAlias;
      });
    },
    _deleteHandler: function _deleteHandler(idx) {
      this.$emit('input', _.filter(this.value, function (v, i) {
        return i != idx;
      }));
    },
    _deleteMultiple: function _deleteMultiple(indices) {
      this.$emit('input', _.filter(this.value, function (v, i) {
        return !_.includes(indices, i);
      }));
    },
    _addOrUpdate: function _addOrUpdate() {
      if (this.$refs.table.editing) {
        this._updateHandler();
      } else {
        this._addHandler();
      }
    },
    _addHandler: function _addHandler() {
      this._processForm(null);
    },
    _processForm: function _processForm(updateIdx, callback) {
      this.hasValidated = true; // Validate other form input

      var _c = this;

      var validationScopes = _.concat(['var'], _.includes(this.valGroups, this.mode) ? [this.mode] : []);

      Promise.all(_.map(validationScopes, function (scope) {
        return _c.$validator.validateAll(scope);
      })).then(function (valid) {
        if (!valid.every(function (val) {
          return val;
        })) return;

        _c._doAdd(updateIdx, callback);
      });
    },
    _editHandler: function _editHandler(idx) {
      var _this = this;

      this._initForm();

      this.rowIsEdited = true;
      var varData = this.value[idx];
      this.selectedVarIds = [varData.id];
      this.editedVarId = varData.id;

      if (this.selCon) {
        this.constraintType = varData.constraint_type;
        this.constraintRef = varData.constraint_ref;
      } else if (this.selDesVar) {
        this.lowerBound = varData.lower_bound;
        this.nominal = varData.nominal;
        this.upperBound = varData.upper_bound;
      } else if (this.selAlias) {
        this.alias = varData.alias;
        this.editedAlias = this.alias;
      }

      if (this.editFocusRefKey) {
        this.$nextTick(function () {
          return _this.$refs[_this.editFocusRefKey].focus();
        });
      }
    },
    _updateHandler: function _updateHandler() {
      var _c = this;

      this._processForm(this.$refs.table.editIdx, function () {
        _c.$refs.table.editingFinished();
      });
    },
    _resetHandler: function _resetHandler() {
      this._initForm();
    },
    _doAdd: function _doAdd(updateIdx, callback) {
      var con = this.selCon;
      var desVar = this.selDesVar;
      var alias = this.selAlias;
      var varMap = this.varMap;
      var usedAliases = this.usedAliases;
      var newUsedAliases = usedAliases.slice();

      var _c = this;

      var newValues = _.filter(_.map(this.selectedVarIds, function (varId) {
        var newValue = {
          id: varId
        };

        if (con) {
          newValue['constraint_type'] = _c.constraintType;
          newValue['constraint_ref'] = _c.constraintRef;
        } else if (desVar) {
          newValue['lower_bound'] = _c.lowerBound;
          newValue['nominal'] = _c.nominal;
          newValue['upper_bound'] = _c.upperBound;
        } else if (alias) {
          // Modify alias names
          var _alias = varMap[varId].name;
          var baseAlias = _alias;
          var i = 2;

          while (_.includes(newUsedAliases, _alias)) {
            _alias = baseAlias + '_' + i;
            i++;
          }

          newUsedAliases.push(_alias);
          newValue['alias'] = _alias;
        }

        return newValue;
      }));

      var newValue = updateIdx !== null ? _.map(this.value, function (v, i) {
        return i == updateIdx ? newValues[0] : v;
      }) : _.concat(this.value, newValues);
      this.$emit('input', newValue);

      this._initForm();

      if (callback) callback();
    },
    _duplicateHandler: function _duplicateHandler(idx) {
      var duplicateRow = _.cloneDeep(this.value[idx]);

      var newValue = _.clone(this.value);

      newValue.splice(idx + 1, 0, duplicateRow);
      this.$emit('input', newValue);
    },
    _showError: function _showError(scope, varName) {
      if (!this.errors.has(scope + '.' + varName)) return false; // https://baianat.github.io/vee-validate/guide/flags.html

      var field = this.fields['$' + scope][varName];
      return field.touched || this.hasValidated;
    },
    _varsSelected: function _varsSelected(varIds) {
      // Pre-fill alias field
      if (this.selAlias && !this.alias) {
        var varDef = this.varMap[varIds[0]];

        if (varDef) {
          this.alias = varDef.name;
        }
      }

      this._addHandler();

      this._hasInput();
    },
    _hasInput: function _hasInput() {
      this.$refs.table.hasInput();
    },
    _renderName: function _renderName(rowObj) {
      return '<span title="XPath: ' + escape(rowObj.xpath) + '">' + escape(rowObj.name) + '</span>';
    },
    _removeUnavailableVars: function _removeUnavailableVars() {
      var deleteIdx = [];

      _.forEach(this.data, function (dataRow, idx) {
        if (!dataRow.name) deleteIdx.push(idx);
      });

      if (deleteIdx.length > 0) {
        this._deleteMultiple(deleteIdx);
      }
    },
    _formatNrFunc: function _formatNrFunc(key) {
      // Needed, otherwise the table will render a 0 as an empty string
      return function (row) {
        return row[key];
      };
    }
  },
  mounted: function mounted() {
    this.selectVarComp = this.$refs.selectVar;

    this._initForm();
  }
};