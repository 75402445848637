var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "var-routing no-select" },
    [
      _c("arrows", {
        staticClass: "arrows",
        attrs: {
          arrows: _vm.clippedArrows,
          "marker-mid": true,
          clickable: _vm.routingEditable,
          "clickable-arrows": _vm.clickableArrows,
          "click-title": "Remove connection"
        },
        on: { click: _vm._arrowClick }
      }),
      _vm._v(" "),
      _c("table", { staticClass: "var-routing-table" }, [
        _c("tr", [
          _c("td", [
            _c(
              "div",
              { staticClass: "function-list-wrapper" },
              [
                _vm._l(_vm.outFunctions, function(functionDef) {
                  return _c(
                    "div",
                    { key: functionDef.id, staticClass: "function-block" },
                    [
                      _c("xdsm-function", {
                        ref: "out",
                        refInFor: true,
                        attrs: {
                          "function-def": functionDef,
                          deletable:
                            functionDef.type == _vm.functionTypes.TOOL &&
                            _vm.routingEditable,
                          "delete-class": "fas fa-unlink",
                          "delete-title": "Remove from tool output"
                        },
                        on: {
                          delete: function($event) {
                            _vm._emitDelete(false, $event.id, functionDef)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _vm.addOutFunctionButton && _vm.routingEditable
                  ? _c("div", { staticClass: "function-block" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default icon",
                          attrs: { title: "Include in tool output" },
                          on: {
                            click: function($event) {
                              _vm.$emit("connect", false)
                            }
                          }
                        },
                        [_c("span", { staticClass: "fas fa-link" })]
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("td", { staticClass: "right-col" }, [
            _c(
              "div",
              { staticClass: "function-list-wrapper" },
              [
                _vm._l(_vm.inFunctions, function(functionDef) {
                  return _c(
                    "div",
                    { key: functionDef.id, staticClass: "function-block" },
                    [
                      _c("xdsm-function", {
                        ref: "in",
                        refInFor: true,
                        attrs: {
                          "function-def": functionDef,
                          deletable:
                            functionDef.type == _vm.functionTypes.TOOL &&
                            _vm.routingEditable,
                          "delete-class": "fas fa-unlink",
                          "delete-title": "Remove from tool input"
                        },
                        on: {
                          delete: function($event) {
                            _vm._emitDelete(true, $event.id, functionDef)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _vm.addInFunctionButton && _vm.routingEditable
                  ? _c("div", { staticClass: "function-block" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default icon",
                          attrs: { title: "Include in tool input" },
                          on: {
                            click: function($event) {
                              _vm.$emit("connect", true)
                            }
                          }
                        },
                        [_c("span", { staticClass: "fas fa-link" })]
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("xdsm-data", {
        ref: "dataBlockEl",
        staticClass: "data-block",
        attrs: { label: _vm.name, external: _vm.isExternal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }