var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor" },
    [
      _c("header", { class: { standalone: _vm.standaloneMode } }, [
        !_vm.standaloneMode
          ? _c("h1", { staticClass: "header-title" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.title) +
                    "\n                "
                ),
                !_vm.standaloneMode
                  ? _c("span", {
                      staticClass: "fas fa-edit link edit-title",
                      attrs: { title: "Edit project name" },
                      on: {
                        click: function($event) {
                          _vm._showSettings("title")
                        }
                      }
                    })
                  : _vm._e()
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.hasBranches || _vm.isInSubWorkflow
          ? _c(
              "h2",
              {
                staticClass: "header-title",
                class: {
                  main: !_vm.standaloneMode,
                  "standalone-title": _vm.standaloneMode
                }
              },
              [
                _c("span", { staticClass: "title" }, [
                  _vm.isInSubWorkflow
                    ? _c(
                        "span",
                        {
                          staticClass: "link",
                          on: { click: _vm._editTopLevelWorkflow }
                        },
                        [_vm._v(_vm._s(_vm.subtitle))]
                      )
                    : _c("span", [_vm._v(_vm._s(_vm.subtitle))]),
                  _vm._v(" "),
                  !_vm.standaloneMode && _vm.hasBranches && !_vm.isInSubWorkflow
                    ? _c("span", {
                        staticClass: "fas fa-edit link edit-title",
                        attrs: { title: "Edit branch name" },
                        on: {
                          click: function($event) {
                            _vm._showSettings("branchTitle")
                          }
                        }
                      })
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm._l(_vm.subWfFunctions, function(funcDef, idx) {
                  return _c("span", { staticClass: "title" }, [
                    _vm._v("\n                >\n\n                "),
                    idx >= _vm.subWfFunctions.length - 1
                      ? _c("span", [_vm._v(_vm._s(funcDef.title))])
                      : _c(
                          "span",
                          {
                            staticClass: "link",
                            on: {
                              click: function($event) {
                                _vm._editParentWorkflowId(funcDef.id)
                              }
                            }
                          },
                          [_vm._v(_vm._s(funcDef.title))]
                        )
                  ])
                }),
                _vm._v(" "),
                _vm.isInSubWorkflow
                  ? _c("span", {
                      staticClass: "fas fa-level-up-alt link",
                      attrs: { title: "View parent workflow" },
                      on: { click: _vm._editParentWorkflow }
                    })
                  : _vm._e()
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "header-buttons" }, [
          _vm.hasBranches
            ? _c(
                "div",
                {
                  staticClass: "version",
                  class: { "version-float": !_vm.standaloneMode }
                },
                [
                  _vm._v("MDAx v" + _vm._s(_vm.version)),
                  _vm.previewMode
                    ? _c("span", [_vm._v(" (light mode)")])
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "spinner"
            },
            [_c("span", { staticClass: "fa fa-spinner fa-spin" })]
          ),
          _vm._v(" "),
          _c("span", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "sep"
          }),
          _vm._v(" "),
          !_vm.standaloneMode
            ? _c(
                "button",
                {
                  staticClass: "btn btn-default icon",
                  class: { mark: _vm.isUnsaved },
                  attrs: { title: "Save (Ctrl+S)" },
                  on: {
                    click: function($event) {
                      _vm.$emit("save")
                    }
                  }
                },
                [_c("span", { staticClass: "far fa-save" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.projectFileMode && _vm.uiManaged && !_vm.standaloneMode
            ? _c(
                "button",
                {
                  staticClass: "btn btn-default icon",
                  attrs: { title: "Save As (Ctrl+Shift+S)" },
                  on: {
                    click: function($event) {
                      _vm.$emit("save as")
                    }
                  }
                },
                [_c("span", { staticClass: "fas fa-save" })]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.standaloneMode
            ? _c(
                "button",
                {
                  staticClass: "btn btn-default icon",
                  attrs: { title: "Project settings" },
                  on: {
                    click: function($event) {
                      _vm._showSettings()
                    }
                  }
                },
                [_c("span", { staticClass: "fas fa-cog" })]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.standaloneMode
            ? _c(
                "button",
                {
                  staticClass: "btn btn-default icon",
                  attrs: { title: "Export" },
                  on: {
                    click: function($event) {
                      _vm.$emit("export")
                    }
                  }
                },
                [_c("span", { staticClass: "fas fa-external-link-alt" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasBranches ? _c("span", { staticClass: "sep" }) : _vm._e(),
          _vm._v(" "),
          _vm.hasBranches && !_vm.standaloneMode
            ? _c(
                "button",
                {
                  staticClass: "btn btn-default icon",
                  attrs: {
                    title: "Show project branch tree",
                    disabled: _vm.previewMode
                  },
                  on: {
                    click: function($event) {
                      _vm.$emit("branches")
                    }
                  }
                },
                [_c("span", { staticClass: "fas fa-code-branch" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "sep" }),
          _vm._v(" "),
          _vm.allowNew && !_vm.standaloneMode
            ? _c(
                "button",
                {
                  staticClass: "btn btn-default icon",
                  attrs: { title: "New project" },
                  on: {
                    click: function($event) {
                      _vm.$emit("new")
                    }
                  }
                },
                [_c("span", { staticClass: "far fa-file" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.allowOpen && _vm.projectFileMode && !_vm.standaloneMode
            ? _c(
                "button",
                {
                  staticClass: "btn btn-default icon",
                  attrs: { title: "Open project" },
                  on: {
                    click: function($event) {
                      _vm.$emit("open")
                    }
                  }
                },
                [_c("span", { staticClass: "far fa-folder-open" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.allowOpen && !_vm.projectFileMode && !_vm.standaloneMode
            ? _c(
                "button",
                {
                  staticClass: "btn btn-default icon",
                  attrs: { title: "Project list" },
                  on: {
                    click: function($event) {
                      _vm.$emit("list")
                    }
                  }
                },
                [_c("span", { staticClass: "fas fa-list-ul" })]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.standaloneMode ? _c("span", { staticClass: "sep" }) : _vm._e(),
          _vm._v(" "),
          !_vm.standaloneMode
            ? _c(
                "button",
                {
                  staticClass: "btn btn-default icon highlight-orange",
                  attrs: { title: "Show help (F1)" },
                  on: { click: _vm.dispatcher.openHelp }
                },
                [_c("span", { staticClass: "fas fa-question" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAuth && _vm.isAuth
            ? _c("span", { staticClass: "sep" })
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAuth && _vm.isAuth
            ? _c(
                "button",
                {
                  staticClass: "btn btn-default icon",
                  attrs: { title: _vm.logoutTitle },
                  on: { click: _vm._logout }
                },
                [_c("span", { staticClass: "fas fa-sign-out-alt" })]
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "editor-buttons",
          class: { "small-screen": _vm.isSmallScreen }
        },
        [
          !_vm.standaloneMode
            ? _c("div", { staticClass: "button-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default icon",
                    attrs: { title: _vm.undoTitle, disabled: !_vm.hasUndo },
                    on: { click: _vm.undo }
                  },
                  [_c("span", { staticClass: "fas fa-undo" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default icon",
                    attrs: { title: _vm.redoTitle, disabled: !_vm.hasRedo },
                    on: { click: _vm.redo }
                  },
                  [_c("span", { staticClass: "fas fa-redo" })]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.canEdit && !_vm.ioMode
            ? _c("div", { staticClass: "button-group" }, [
                _vm.rootVarDef
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-default mdo mdo-rounded mdo-optimizer",
                        attrs: { title: "Add optimizer" },
                        on: { click: _vm._addOptimizer }
                      },
                      [_vm._v("Opt")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.rootVarDef
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-default mdo mdo-rounded mdo-doe",
                        attrs: { title: "Add DOE driver" },
                        on: { click: _vm._addDOE }
                      },
                      [_vm._v("DoE")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.rootVarDef
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-default mdo mdo-rounded mdo-mda",
                        attrs: { title: "Add converger" },
                        on: { click: _vm._addConverger }
                      },
                      [_vm._v("Conv")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showAddButton
                  ? _c("button", {
                      staticClass:
                        "btn btn-default mdo mdo-an-post icon icon-png",
                      style: {
                        "background-image":
                          "url(" + _vm.staticBaseUrl + "img/function.png)"
                      },
                      attrs: { title: "Add math function" },
                      on: { click: _vm._addMath }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showAddButton
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-default mdo mdo-an",
                        attrs: { title: "Add disciplinary analysis tool" },
                        on: { click: _vm._addTool }
                      },
                      [_vm._v("Tool")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showAddButton
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-default mdo mdo-an",
                        attrs: { title: "Batch add disciplinary tools" },
                        on: { click: _vm._addToolsBatch }
                      },
                      [
                        _c("span", { staticClass: "fas fa-list" }),
                        _vm._v(" Tools Batch")
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showAddButton
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-default mdo mdo-an",
                        attrs: { title: "Add sub-workflow" },
                        on: { click: _vm._addSubWorkflow }
                      },
                      [
                        _c("span", { staticClass: "fas fa-project-diagram" }),
                        _vm._v(" Sub")
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.rootVarDef || _vm.canEdit
            ? _c("div", { staticClass: "button-group" }, [
                _vm.rootVarDef && !_vm.ioMode
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-default icon",
                        attrs: { title: "View all variables" },
                        on: { click: _vm._viewAllVars }
                      },
                      [
                        _c("span", { staticClass: "fas fa-code" }),
                        _vm._v(" Vars")
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.rootVarDef && !_vm.ioMode && !_vm.hasIssues
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-default icon",
                        attrs: { title: "View workflow input variables" },
                        on: { click: _vm._viewSystemInputVars }
                      },
                      [_c("span", { staticClass: "fas fa-sign-in-alt" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canEdit
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-default icon",
                        attrs: { title: "Select initial schema" },
                        on: { click: _vm._selectBaseSchema }
                      },
                      [
                        _c("span", { staticClass: "fas fa-database" }),
                        _vm._v(" Base Schema")
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.standaloneMode && _vm.hasBranches
            ? _c("div", { staticClass: "button-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default icon",
                    attrs: {
                      title: "Show project branch tree",
                      disabled: _vm.previewMode
                    },
                    on: {
                      click: function($event) {
                        _vm.$emit("branches")
                      }
                    }
                  },
                  [_c("span", { staticClass: "fas fa-code-branch" })]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.canEdit && _vm.rootVarDef && !_vm.ioMode
            ? _c("div", { staticClass: "button-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default icon",
                    attrs: { title: "Automated sorting" },
                    on: { click: _vm._autoSort }
                  },
                  [
                    _c("span", {
                      staticClass: "fas fa-sort-numeric-down",
                      staticStyle: { transform: "rotate(-45deg)" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("button", {
                  staticClass: "btn btn-default icon icon-png",
                  style: {
                    "background-image":
                      "url(" + _vm.staticBaseUrl + "img/select.png)"
                  },
                  attrs: { title: "Remove unnecessary functions" },
                  on: { click: _vm._autoRemoveFunctions }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.ioMode
            ? _c("div", { staticClass: "button-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default icon",
                    attrs: { title: "Variable routing settings" },
                    on: { click: _vm._showAutoRouting }
                  },
                  [_c("span", { staticClass: "fas fa-route" })]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.canEdit && !_vm.ioMode && !_vm.standaloneMode
            ? _c("div", { staticClass: "button-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default icon",
                    attrs: {
                      title: "Duplicate current branch and start editing"
                    },
                    on: { click: _vm._quickDuplicate }
                  },
                  [
                    _c("span", { staticClass: "fas fa-unlock" }),
                    _vm._v("\n                Start editing...")
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.ioMode
            ? _c("div", { staticClass: "button-group" }, [
                _c(
                  "div",
                  {
                    staticStyle: { display: "inline-block", margin: "-5px 0" }
                  },
                  [
                    _vm.graphDef
                      ? _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.editFunctionId,
                                expression: "editFunctionId"
                              }
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.editFunctionId = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          _vm._l(_vm.toolFunctions, function(functionDef) {
                            return _c(
                              "option",
                              {
                                key: functionDef.id,
                                domProps: { value: functionDef.id }
                              },
                              [_vm._v(_vm._s(functionDef.title))]
                            )
                          })
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _vm.canEdit && _vm.toolFunctions.length > 1
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-default icon",
                        attrs: { title: "Remove selected discipline" },
                        on: { click: _vm._editFunctionDelete }
                      },
                      [
                        _c("span", { staticClass: "far fa-trash-alt" }),
                        _vm._v(" Delete")
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showAddButton
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-default icon",
                        attrs: { title: "Add discipline" },
                        on: { click: _vm._addEmptyTool }
                      },
                      [
                        _c("span", { staticClass: "fas fa-plus" }),
                        _vm._v(" Add")
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.ioMode
            ? _c("div", { staticClass: "buttons-right" }, [
                _vm.hasProcess
                  ? _c("div", { staticClass: "button-group" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default icon",
                          attrs: {
                            disabled: !_vm.processPlaying,
                            title: "Previous process step (left arrow)"
                          },
                          on: { click: _vm.previousProcess }
                        },
                        [_c("span", { staticClass: "fas fa-step-backward" })]
                      ),
                      _vm._v(" "),
                      !_vm.processPlaying || _vm.processPaused
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-default icon",
                              attrs: { title: "Play workflow process (space)" },
                              on: { click: _vm._playProcess }
                            },
                            [_c("span", { staticClass: "fas fa-play" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.processPlaying && !_vm.processPaused
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-default icon",
                              attrs: {
                                title: "Pause workflow process (space)"
                              },
                              on: { click: _vm._pauseProcess }
                            },
                            [_c("span", { staticClass: "fas fa-pause" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default icon",
                          attrs: {
                            disabled: !_vm.processPlaying,
                            title: "Next process step (right arrow)"
                          },
                          on: { click: _vm.nextProcess }
                        },
                        [_c("span", { staticClass: "fas fa-step-forward" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default icon",
                          attrs: {
                            disabled: !_vm.processPlaying,
                            title: "Stop showing workflow process"
                          },
                          on: { click: _vm._stopProcess }
                        },
                        [_c("span", { staticClass: "fas fa-stop" })]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "button-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default icon",
                      attrs: { title: "Show workflow statistics" },
                      on: { click: _vm._openStats }
                    },
                    [_c("span", { staticClass: "fas fa-chart-line" })]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "button-group" },
                  [
                    _c(
                      "popover",
                      {
                        staticClass: "zoom-popover",
                        attrs: {
                          offset: 5,
                          title: "Zoom",
                          "is-open": _vm.zoomPopoverOpen,
                          "wrapper-class": "zoom-popover"
                        },
                        on: {
                          "update:isOpen": function($event) {
                            _vm.zoomPopoverOpen = $event
                          }
                        }
                      },
                      [
                        _c("button", { staticClass: "btn btn-default icon" }, [
                          _c("span", { staticClass: "fas fa-search-plus" })
                        ]),
                        _vm._v(" "),
                        _c("template", { slot: "popover" }, [
                          _vm.delayedZoomPopoverOpen
                            ? _c(
                                "div",
                                { staticClass: "zoom-range" },
                                [
                                  _c("vue-slider", {
                                    attrs: {
                                      value: _vm._mapZoom(_vm.xdsmZoom),
                                      min: _vm.minMapZoom,
                                      max: _vm.maxMapZoom,
                                      speed: 0,
                                      tooltip: false,
                                      height: 200,
                                      width: 5,
                                      "bg-style": { background: "#E0E0E0" },
                                      "process-style": {
                                        background: "#64B5F6"
                                      },
                                      direction: "vertical"
                                    },
                                    on: {
                                      input: function($event) {
                                        _vm.xdsmZoom = _vm._getZoom($event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default icon",
                        attrs: { title: "Reset zoom (Ctrl+0)" },
                        on: { click: _vm.resetZoom }
                      },
                      [_c("span", { staticClass: "fas fa-arrows-alt" })]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "button-group" },
                  [
                    _c(
                      "popover",
                      {
                        staticClass: "issues-popover",
                        attrs: {
                          offset: 5,
                          title: _vm.hasIssues
                            ? "Workflow has issues (click to view details)"
                            : "Workflow is executable"
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "fas issues",
                          class: {
                            "fa-check success": !_vm.hasIssues,
                            "fa-exclamation": _vm.hasIssues,
                            critical: _vm.hasCriticalIssues
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "template",
                          { slot: "popover" },
                          [
                            _c("issues-list", {
                              staticClass: "todo-list",
                              attrs: { issues: _vm.issues },
                              on: { click: _vm._todoClick }
                            })
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "editor-canvas" },
        [
          _c(
            "div",
            { staticClass: "canvas-content" },
            [
              _vm.graphDef && !_vm.ioMode
                ? _c("xdsm", {
                    ref: "xdsm",
                    attrs: {
                      "graph-def": _vm.graphDef,
                      "enable-pan-zoom": true,
                      clickable: true,
                      "show-add-button": _vm.showXdsmAddButton,
                      sortable: _vm.sortable,
                      deletable: _vm.deletable,
                      zoom: _vm.xdsmZoom,
                      "data-classes": _vm.dataClasses,
                      "function-classes": _vm.functionClasses,
                      "show-nesting": true,
                      "render-collapsed": true,
                      collapsible: _vm.collapsible,
                      "show-issues": true,
                      "show-enter": true
                    },
                    on: {
                      "update:zoom": function($event) {
                        _vm.xdsmZoom = $event
                      },
                      functionClick: _vm._functionClick,
                      functionDelete: _vm._functionDelete,
                      dataClick: _vm._dataClick,
                      dataLineClick: _vm._dataLineClick,
                      addClick: _vm._addTool,
                      sort: _vm._sort,
                      collapse: _vm._collapse,
                      enter: _vm._editSubWorkflow
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.ioMode && _vm.editedFunctionDef
                ? _c("function-io-edit", {
                    attrs: {
                      "project-id": _vm.projectId,
                      "branch-id": _vm.branchId,
                      "root-var-def": _vm.rootVarDef,
                      "function-def": _vm.editedFunctionDef,
                      "graph-def": _vm.graphDef
                    },
                    on: { save: _vm._saveEditedFunction }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("logos"),
          _vm._v(" "),
          _vm.previewMode
            ? _c("div", { staticClass: "disclaimer" }, [
                _c("p", [
                  _vm._v(' Disclaimer: "The ‘MDAx Light’ version has '),
                  _c(
                    "span",
                    { staticClass: "link", on: { click: _vm._displayAbout } },
                    [_vm._m(0)]
                  ),
                  _vm._v(" to be used only for non-commercial purposes. For "),
                  _c("b", [_vm._v("access to full version")]),
                  _vm._v(" or any queries, please contact "),
                  _c(
                    "a",
                    {
                      staticClass: "email-link",
                      attrs: { href: "mailto:mdax@dlr.de" }
                    },
                    [_vm._v("mdax@dlr.de")]
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "legal-text" }, [
            _vm.hasLegalText
              ? _c(
                  "span",
                  { staticClass: "link", on: { click: _vm._displayLegal } },
                  [_vm._v("Legal - impressum")]
                )
              : _vm._e(),
            _vm._v("\n            |\n            "),
            _c(
              "span",
              { staticClass: "link", on: { click: _vm._displayAbout } },
              [_vm._v("About MDAx")]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("var-list-modal", {
        ref: "varListModal",
        attrs: {
          "graph-def": _vm.graphDef,
          "routing-editable": _vm.canEdit,
          "var-editable": _vm.canEdit,
          modal: false
        },
        on: {
          info: _vm._showInfo,
          edit: _vm._showEdit,
          routing: _vm._showRouting
        }
      }),
      _vm._v(" "),
      _vm.functions
        ? _c("var-info", {
            ref: "varInfo",
            attrs: {
              functions: _vm.functions,
              "routing-editable": _vm.canEdit,
              "var-editable": _vm.canEdit,
              modal: false
            },
            on: { routing: _vm._showRouting, edit: _vm._showEdit }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("create-var", {
        ref: "createVar",
        on: { create: _vm._editVarFunction }
      }),
      _vm._v(" "),
      _vm.graphDef
        ? _c("routing-select", {
            ref: "routingSelect",
            attrs: { "graph-def": _vm.graphDef }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("workflow-settings", {
        ref: "settings",
        attrs: { "has-branches": _vm.hasBranches },
        on: { opened: _vm._settingsOpened }
      }),
      _vm._v(" "),
      _c("function-modal", {
        ref: "functionModal",
        attrs: { "root-var-def": _vm.rootVarDef, "graph-def": _vm.graphDef },
        on: {
          addBatch: _vm._addToolsBatch,
          editSubWorkflow: _vm._editSubWorkflow,
          mergeSubWorkflow: _vm._mergeSubWorkflow
        }
      }),
      _vm._v(" "),
      _c("tool-batch-add-modal", {
        ref: "toolBatchAddModal",
        on: { added: _vm._addedBatchTools }
      }),
      _vm._v(" "),
      _c(
        "ui-dialog",
        {
          ref: "confirmDeleteFunctionDialog",
          attrs: { "action-title": "Delete" }
        },
        [
          _vm._v(
            "\n        Are you sure you want to delete the following function?"
          ),
          _c("br"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "delete-function-wrapper" },
            [
              _vm.confirmDeleteFunctionDef
                ? _c("xdsm-function", {
                    attrs: { "function-def": _vm.confirmDeleteFunctionDef }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "ui-dialog",
        { ref: "confirmLogoutDialog", attrs: { "action-title": "Logout" } },
        [_vm._v("\n        Are you sure you want to logout?\n    ")]
      ),
      _vm._v(" "),
      !_vm.standaloneMode
        ? _c("auto-sort", {
            ref: "autoSort",
            on: { update: _vm._graphUpdated }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.standaloneMode && _vm.rootVarDef
        ? _c("auto-remove-functions", {
            ref: "autoRemove",
            attrs: { "root-var-def": _vm.rootVarDef, functions: _vm.functions },
            on: { update: _vm._graphUpdated }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("statistics", {
        ref: "stats",
        attrs: {
          project: _vm.project,
          "graph-def": _vm.graphDef,
          "root-var-def": _vm.rootVarDef,
          "has-branches": _vm.hasBranches
        }
      }),
      _vm._v(" "),
      !_vm.standaloneMode
        ? _c("base-schema-modal", {
            ref: "baseSchemaModal",
            attrs: { "graph-def": _vm.graphDef },
            on: { update: _vm._updateGraph }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("auto-routing-modal", {
        ref: "autoRouting",
        attrs: {
          "graph-def": _vm.graphDef,
          project: _vm.project,
          editable: _vm.canEdit,
          "root-var-def": _vm.rootVarDef
        },
        on: { info: _vm._showInfo, routing: _vm._showRouting }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("em", [_c("u", [_vm._v("restricted functionalities")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }