//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../../lodash.custom.js';
import escape from 'escape-html';
import { functionTypes, functionTypeTitles } from './functions.js';
import functionSubFormMixin from './sub-form-mixin.js';
import selectVar from '../select/select-var.vue';
import selectVars from '../select/select-vars.vue';
import varListModal from '../var-list/var-list-modal.vue';
import selectEquations from '../select/select-equations.vue';
import uploader from '../uploader.vue';
import options from '../options.vue';
import { filterName } from './tool-batch-add-modal.vue';
import createVar from '../var-list/create-var.vue';
import { api, dispatcher, remoteName } from '../../index';
import { standaloneMode } from "../../api";
export default {
  name: "analysis-form",
  mixins: [functionSubFormMixin],
  components: {
    selectVar: selectVar,
    selectVars: selectVars,
    varListModal: varListModal,
    selectEquations: selectEquations,
    uploader: uploader,
    options: options,
    createVar: createVar
  },
  props: {
    availableToolIO: {
      default: null
    }
  },
  data: function data() {
    return {
      api: api,
      dispatcher: dispatcher,
      typeVals: [[functionTypes.TOOL, functionTypeTitles.TOOL], [functionTypes.MATH, functionTypeTitles.MATH]],
      remoteName: remoteName,
      selectedOutputVarIds: [],
      outputVarComponent: null,
      ioTypes: [['xml', 'input.xml + output.xml']],
      defaultIoType: 'xml',
      uploadedFiles: [],
      uploaderComponent: null,
      tmpRootFuncId: null,
      tmpRootVarDef: null,
      uploaderToolFiles: [0, 2],
      // min, max
      selectedDiagram: 0,
      uploadedDiagramData: []
    };
  },
  watch: {
    selectedOutputVarIds: function selectedOutputVarIds(varIds) {
      if (varIds.length >= 1) {
        this.def.math.output_var_id = varIds[0];
      } else {
        this.def.math.output_var_id = null;
      }
    },
    selectedDiagram: function selectedDiagram(diagramIdx) {
      this._selectDiagramIdx(diagramIdx);
    },
    isMath: function isMath() {
      setTimeout(this._setOutputVarCompRef, 0);
    },
    hasUploader: function hasUploader() {
      var _this = this;

      this.uploaderComponent = null;
      setTimeout(function () {
        return _this.uploaderComponent = _this.$refs.uploader;
      });
    },
    uploadedFiles: function uploadedFiles() {
      this._fillToolName();

      this._loadUploadedDiagrams();
    },
    uploaderIsValid: function uploaderIsValid() {
      this._loadUploadedDiagrams();
    },
    hasRootVarDef: function hasRootVarDef() {
      var _this2 = this;

      this.$nextTick(function () {
        _this2._setOutputVarCompRef();
      });
    }
  },
  computed: {
    isTool: function isTool() {
      return this.type == functionTypes.TOOL;
    },
    isMath: function isMath() {
      return this.type == functionTypes.MATH;
    },
    isSubWorkflow: function isSubWorkflow() {
      return this.type == functionTypes.SUB_WF;
    },
    validationScope: function validationScope() {
      return '_' + this.type.toLowerCase();
    },
    mathParamVarIds: function mathParamVarIds() {
      var math = this.def.math;
      return math && math.params ? _.map(math.params, function (param) {
        return param.id;
      }) : [];
    },
    varMap: function varMap() {
      return this.outputVarComponent ? this.outputVarComponent.varMap : {};
    },
    expressionAvailableVars: function expressionAvailableVars() {
      return this.type == functionTypes.MATH ? _.map(this.def.math.params, function (param) {
        return param.alias;
      }) : [];
    },
    selectedMathOutputVarId: function selectedMathOutputVarId() {
      return this.type == functionTypes.MATH ? this.def.math.output_var_id : null;
    },
    selectedMathOutputVar: function selectedMathOutputVar() {
      var varId = this.selectedMathOutputVarId;
      if (!varId) return null;
      var varMap = this.varMap;
      return varId in varMap ? varMap[varId] : null;
    },
    selectedMathOutputXPath: function selectedMathOutputXPath() {
      var varObj = this.selectedMathOutputVar;
      return varObj ? varObj.xpath : '';
    },
    hasRootVarDef: function hasRootVarDef() {
      return !!this.usedRootVarDef;
    },
    usedRootVarDef: function usedRootVarDef() {
      return this.tmpRootVarDef !== null ? this.tmpRootVarDef : this.rootVarDef;
    },
    hasIoTypeChoice: function hasIoTypeChoice() {
      return this.ioTypes.length > 1;
    },
    ioType: function ioType() {
      return this.def.tool_io_type;
    },
    isIoXML: function isIoXML() {
      return this.ioType == 'xml';
    },
    useAvailableToolIO: function useAvailableToolIO() {
      return this.availableToolIO !== null;
    },
    hasAvailableToolIO: function hasAvailableToolIO() {
      return this.availableToolIO !== null && this.availableToolIO.length > 0;
    },
    hasUploader: function hasUploader() {
      var hasToolUploader = this.isTool && this.isIoXML && !this.useAvailableToolIO;
      return this.editable && (hasToolUploader || this.isSubWorkflow);
    },
    uploaderMinFiles: function uploaderMinFiles() {
      return this.isTool ? this.uploaderToolFiles[0] : 0;
    },
    uploaderMaxFiles: function uploaderMaxFiles() {
      return this.isTool ? this.uploaderToolFiles[1] : 1;
    },
    hasAvailableToolIOSelect: function hasAvailableToolIOSelect() {
      return this.editable && this.isTool && this.isIoXML && this.useAvailableToolIO;
    },
    uploaderFileNamesValid: function uploaderFileNamesValid() {
      var fileNames = this.uploadedFiles;
      if (fileNames.length == 0) return true;

      if (this.isTool) {
        var inputIdx = _.findIndex(fileNames, function (fileName) {
          return _.includes(fileName.toLowerCase(), 'input');
        });

        var outputIdx = _.findIndex(fileNames, function (fileName) {
          return _.includes(fileName.toLowerCase(), 'output');
        });

        if (fileNames.length == 1) {
          return (inputIdx != -1 || outputIdx != -1) && inputIdx != outputIdx;
        }

        return inputIdx != -1 && outputIdx != -1 && inputIdx != outputIdx;
      } else if (this.isSubWorkflow) {
        if (fileNames.length != 1) return false;
        return _.endsWith(fileNames[0], '.mdax');
      }

      return true;
    },
    uploaderIsValid: function uploaderIsValid() {
      return (this.uploaderComponent ? this.uploaderComponent.uploadValid : true) && this.uploaderFileNamesValid;
    },
    version: function version() {
      return this.functionDef.version || '';
    },
    escapedHtmlVersion: function escapedHtmlVersion() {
      return escape(this.version);
    }
  },
  methods: {
    validateAll: function validateAll(scope) {
      var _this3 = this;

      var promises = [this.$validator.validateAll(scope + this.validationScope)];

      var _c = this;

      if (this.hasUploader) promises.push(new Promise(function (resolve) {
        var valid = _c.$refs.uploader.validate();

        if (!valid) {
          _c.dispatcher.error('I/O files must be selected.');
        }

        resolve(valid);
      }));

      if (this.isMath && !this.hasRootVarDef) {
        promises.push(new Promise(function (resolve) {
          _this3.dispatcher.error('No input and output variables selected.');

          resolve(false);
        }));
      }

      return new Promise(function (resolve) {
        Promise.all(promises).then(function (valid) {
          var isValid = valid.every(function (val) {
            return val;
          });
          resolve(isValid);
        });
      });
    },
    _customPrepareFunctionDef: function _customPrepareFunctionDef(functionDef) {
      if (functionDef.type == functionTypes.TOOL) {
        if (!functionDef.tool_io_type) functionDef.tool_io_type = this.defaultIoType;
      } else if (functionDef.type == functionTypes.MATH) {
        functionDef.math = _.merge({
          params: [],
          output_var_id: null,
          equations: [],
          input_file: null,
          output_file: null
        }, functionDef.math || {});
        var outputVarId = functionDef.math.output_var_id;
        this.selectedOutputVarIds = outputVarId ? [outputVarId] : [];
      } else if (functionDef.type == functionTypes.SUB_WF) {
        if (!functionDef.diagram_idx) functionDef.diagram_idx = 0;
      }
    },
    _resetTempRoot: function _resetTempRoot() {
      if (standaloneMode) return;

      var _c = this;

      this.api.editResetTempRoot(function (tmpRootFuncId) {
        return _c.tmpRootFuncId = tmpRootFuncId;
      });
    },
    _createVarFunction: function _createVarFunction(parentVarId, varData, callback) {
      var _c = this;

      this.api.editCreateVar(this.tmpRootFuncId, false, parentVarId, varData, function (rootVarDef, newVarDef) {
        _c.tmpRootVarDef = rootVarDef;
        callback(newVarDef);
      });
    },
    _setOutputVarCompRef: function _setOutputVarCompRef() {
      this.outputVarComponent = this.$refs.selectOutputVar;
    },
    _createRoot: function _createRoot() {
      if (!this.editable) return;
      this.$refs.createVar.start({
        id: null,
        xpath: null
      }, 'Create Root Variable', 'Create', false);
    },
    _doCreateRoot: function _doCreateRoot(parentVarId, varData) {
      var _this4 = this;

      this._createVarFunction(parentVarId, varData, function () {
        _this4.$refs.createVar.close();
      });
    },
    _resetUploader: function _resetUploader() {
      if (this.$refs.uploader) this.$refs.uploader.reset();
    },
    _fillToolName: function _fillToolName() {
      // Fill the tool name from the uploaded files if there is not already a title
      if (!this.isTool) return;
      if (this.def.title) return;
      var files = this.uploadedFiles;
      if (files.length == 0) return;
      var filteredName = filterName(files[0]);
      var fileName = files[0].substring(0, filteredName.length);
      this.$set(this.def, 'title', fileName);
    },
    _loadUploadedDiagrams: function _loadUploadedDiagrams() {
      var _this5 = this;

      if (!this.isSubWorkflow) return;
      this.uploadedDiagramData = [];
      this.selectedDiagram = -1; // Set to some value to differentiate between this non-value and a real one

      if (!this.uploaderIsValid) return;
      this.api.editGetUploadedDiagrams(function (_ref) {
        var activeBranchIdx = _ref.active_branch_idx,
            diagrams = _ref.diagrams;
        _this5.uploadedDiagramData = diagrams;
        _this5.selectedDiagram = activeBranchIdx;
      });
    },
    _selectDiagramIdx: function _selectDiagramIdx(diagramIdx) {
      var _this6 = this;

      // Hacky solution due to update loops
      setTimeout(function () {
        return _this6.$set(_this6.def, 'diagram_idx', diagramIdx);
      }, 100);
    }
  },
  mounted: function mounted() {
    this._setOutputVarCompRef();

    this._resetUploader();

    this._resetTempRoot();
  }
};