var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wrapper",
      on: { keyup: _vm._keyPress, keydown: _vm._keyPress }
    },
    [
      _c("div", { staticClass: "function-settings" }, [
        !_vm.editingTitle
          ? _c(
              "div",
              { staticClass: "function-title", on: { click: _vm._editTitle } },
              [
                _vm._v(
                  "\n            Discipline: " +
                    _vm._s(_vm.title) +
                    "\n            "
                ),
                _c("span", {
                  staticClass: "fas fa-edit link edit-title",
                  attrs: { title: "Edit discipline name" },
                  on: { click: _vm._editTitle }
                })
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.editingTitle
          ? _c("div", { staticClass: "function-title-edit" }, [
              _vm.editingTitle
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editedTitle,
                        expression: "editedTitle"
                      }
                    ],
                    ref: "titleField",
                    staticClass: "form-control",
                    staticStyle: { width: "80%", display: "inline-block" },
                    domProps: { value: _vm.editedTitle },
                    on: {
                      keyup: [
                        function($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          $event.stopPropagation()
                          return _vm._finishEditingTitle($event)
                        },
                        function($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "esc",
                              27,
                              $event.key,
                              "Escape"
                            )
                          ) {
                            return null
                          }
                          $event.stopPropagation()
                          return _vm._stopEditingTitle($event)
                        }
                      ],
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.editedTitle = $event.target.value
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("span", {
                staticClass: "fas fa-check link",
                staticStyle: { "padding-left": "10px" },
                attrs: { title: "Apply" },
                on: { click: _vm._finishEditingTitle }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "fas fa-ban link",
                attrs: { title: "Cancel" },
                on: { click: _vm._stopEditingTitle }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "tool-form-wrapper" }, [
          _c("div", { staticClass: "tool-form form-horizontal" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-4 control-label" }, [
                _vm._v("Tool Version")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-8" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editedFunctionDef.version,
                      expression: "editedFunctionDef.version"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "version",
                    placeholder: "Tool version"
                  },
                  domProps: { value: _vm.editedFunctionDef.version },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.editedFunctionDef,
                          "version",
                          $event.target.value
                        )
                      },
                      _vm._debouncedSave
                    ]
                  }
                })
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "tool-form-wrapper" }, [
          _c("div", { staticClass: "tool-form form-horizontal" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-4 control-label" }, [
                _vm._v("Tool Execution Name")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-8" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editedFunctionDef.exe_name,
                      expression: "editedFunctionDef.exe_name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "exeName",
                    placeholder: "Default: Tool name"
                  },
                  domProps: { value: _vm.editedFunctionDef.exe_name },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.editedFunctionDef,
                          "exe_name",
                          $event.target.value
                        )
                      },
                      _vm._debouncedSave
                    ]
                  }
                })
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "export" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.exportType,
                  expression: "exportType"
                }
              ],
              staticClass: "form-control",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.exportType = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            _vm._l(_vm.exportTypes, function(type) {
              return _c(
                "option",
                { key: type[0], domProps: { value: type[0] } },
                [_vm._v(_vm._s(type[1]))]
              )
            })
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              attrs: { title: "Export discipline to selected format" },
              on: { click: _vm._export }
            },
            [
              _vm._v("Export\n                "),
              _c("span", { staticClass: "fas fa-external-link-alt" })
            ]
          )
        ])
      ]),
      _vm._v(" "),
      !_vm.rootVarDef
        ? _c("div", { staticClass: "add-root-node" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                on: { click: _vm._addRootNode }
              },
              [_vm._v("Create schema root node")]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.rootVarDef
        ? _c("div", { staticClass: "io-edit-wrapper" }, [
            _c("div", { staticClass: "io-edit-title" }, [
              _vm.isWide
                ? _c("div", { staticClass: "halfwidth" }, [
                    _vm._v("Input\n                "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default icon",
                        attrs: { title: "Upload input file" },
                        on: {
                          click: function($event) {
                            _vm._showUpload(true)
                          }
                        }
                      },
                      [_c("span", { staticClass: "fas fa-upload" })]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isWide
                ? _c("div", { staticClass: "halfwidth" }, [
                    _vm._v("Output\n                "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default icon",
                        attrs: { title: "Upload output file" },
                        on: {
                          click: function($event) {
                            _vm._showUpload(false)
                          }
                        }
                      },
                      [_c("span", { staticClass: "fas fa-upload" })]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isWide
                ? _c("div", { staticClass: "small-title" }, [
                    _c(
                      "span",
                      {
                        staticClass: "title-link",
                        class: { active: _vm.editingInput },
                        attrs: { title: "Edit input" },
                        on: {
                          click: function($event) {
                            _vm.editingInput = true
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "text" }, [_vm._v("Input")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default icon",
                            attrs: { title: "Upload input file" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                _vm._showUpload(true)
                              }
                            }
                          },
                          [_c("span", { staticClass: "fas fa-upload" })]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "title-link",
                        class: { active: !_vm.editingInput },
                        attrs: { title: "Edit output" },
                        on: {
                          click: function($event) {
                            _vm.editingInput = false
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "text" }, [_vm._v("Output")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default icon",
                            attrs: { title: "Upload output file" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                _vm._showUpload(false)
                              }
                            }
                          },
                          [_c("span", { staticClass: "fas fa-upload" })]
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "io-edit-content", class: { wide: _vm.isWide } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isWide || _vm.editingInput,
                        expression: "isWide || editingInput"
                      }
                    ],
                    ref: "inputIOEditPane",
                    staticClass: "io-edit-pane"
                  },
                  [
                    _c("var-list", {
                      ref: "inputVarList",
                      staticClass: "var-list-container",
                      attrs: {
                        "root-var-def": _vm.rootVarDef,
                        "fill-height-auto-scroll": true,
                        "graph-def": _vm.graphDef,
                        editable: _vm.editable,
                        deletable: _vm.editable,
                        "search-ids": _vm.inputSearchIds,
                        "highlight-ids": _vm.highlightIds,
                        "main-ids": _vm.inputMainIds,
                        padding: 10,
                        "padding-bottom": 50,
                        "var-editable": _vm.editable
                      },
                      on: {
                        create: function($event) {
                          _vm._showCreate(true, $event)
                        },
                        search: function($event) {
                          _vm._search(true, $event)
                        },
                        connect: function($event) {
                          _vm._connect(true, $event)
                        },
                        delete: function($event) {
                          _vm._delete(true, $event)
                        },
                        edit: function($event) {
                          _vm._showEdit(true, $event)
                        },
                        info: function($event) {
                          _vm._showInfo(true, $event)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isWide || !_vm.editingInput,
                        expression: "isWide || !editingInput"
                      }
                    ],
                    ref: "outputIOEditPane",
                    staticClass: "io-edit-pane"
                  },
                  [
                    _c("var-list", {
                      ref: "outputVarList",
                      staticClass: "var-list-container",
                      attrs: {
                        "root-var-def": _vm.rootVarDef,
                        "fill-height-auto-scroll": true,
                        "graph-def": _vm.graphDef,
                        editable: _vm.editable,
                        deletable: _vm.editable,
                        "search-ids": _vm.outputSearchIds,
                        "highlight-ids": _vm.highlightIds,
                        "main-ids": _vm.outputMainIds,
                        padding: 10,
                        "padding-bottom": 50,
                        "var-editable": _vm.editable
                      },
                      on: {
                        create: function($event) {
                          _vm._showCreate(false, $event)
                        },
                        search: function($event) {
                          _vm._search(false, $event)
                        },
                        connect: function($event) {
                          _vm._connect(false, $event)
                        },
                        delete: function($event) {
                          _vm._delete(false, $event)
                        },
                        edit: function($event) {
                          _vm._showEdit(false, $event)
                        },
                        info: function($event) {
                          _vm._showInfo(false, $event)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("var-info", {
        ref: "varInfo",
        attrs: { functions: _vm.functions, "var-editable": _vm.editable },
        on: {
          edit: function($event) {
            _vm._showEdit(null, $event)
          }
        }
      }),
      _vm._v(" "),
      _c("create-var", { ref: "createVar", on: { create: _vm._doCreateVar } }),
      _vm._v(" "),
      _c(
        "ui-modal",
        {
          ref: "uploadModal",
          attrs: {
            title: _vm.uploaderTitle,
            "confirm-close": false,
            "action-title": "Upload",
            action: _vm._applyUpload
          },
          on: { opened: _vm._uploadOpened }
        },
        [
          _c("div", { staticClass: "instructions" }, [
            _vm._v(
              "\n            Upload an " +
                _vm._s(_vm.uploaderIO) +
                " definition file to replace the " +
                _vm._s(_vm.uploaderIO) +
                " for this discipline.\n        "
            )
          ]),
          _vm._v(" "),
          _c("uploader", {
            ref: "uploader",
            staticClass: "uploader",
            attrs: {
              "n-files": 1,
              "n-files-min": 1,
              "drop-zone-message": "Upload I/O XML file: click or drag here"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("rce-export-settings", {
        ref: "rceSettings",
        on: { select: _vm._rceSelect }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }