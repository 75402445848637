var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isConverger
        ? _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "col-sm-2 control-label" }, [
              _vm._v("Method")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-10" }, [
              _vm.editable
                ? _c(
                    "div",
                    { staticClass: "options-wrapper" },
                    [
                      _c("options", {
                        attrs: { options: _vm.convergerTypes },
                        model: {
                          value: _vm.def.mdao.converger_type,
                          callback: function($$v) {
                            _vm.$set(_vm.def.mdao, "converger_type", $$v)
                          },
                          expression: "def.mdao.converger_type"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "conv-info" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default icon",
                            on: {
                              click: function($event) {
                                _vm.$refs.convInfoModal.open()
                              }
                            }
                          },
                          [_c("span", { staticClass: "fas fa-question" })]
                        )
                      ])
                    ],
                    1
                  )
                : _c("div", { staticClass: "form-control-static" }, [
                    _vm._v(_vm._s(_vm.convergerTypeTitle))
                  ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ui-modal",
        {
          ref: "convInfoModal",
          attrs: {
            title: "Converger Methods",
            "has-buttons": false,
            "confirm-close": false,
            wide: "60%"
          }
        },
        [
          _c("table", { staticClass: "conv-types-table" }, [
            _c("tr", [
              _c(
                "td",
                {
                  on: {
                    click: function($event) {
                      _vm._selectConvergerType("gauss-seidel")
                    }
                  }
                },
                [
                  _c("h3", [_vm._v("Gauss-Seidel")]),
                  _vm._v(" "),
                  _c("h4", [
                    _c("b", [_vm._v("Sequential")]),
                    _vm._v(" execution of functions")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "img" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.staticBaseUrl + "img/gs.png",
                        alt: "Gauss-Seidel converger"
                      }
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "td",
                {
                  on: {
                    click: function($event) {
                      _vm._selectConvergerType("jacobi")
                    }
                  }
                },
                [
                  _c("h3", [_vm._v("Jacobi")]),
                  _vm._v(" "),
                  _c("h4", [
                    _c("b", [_vm._v("Parallel")]),
                    _vm._v(" execution of functions")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "img" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.staticBaseUrl + "img/jac.png",
                        alt: "Jacobi converger"
                      }
                    })
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "reference" }, [
            _vm._v(
              "\n            Lambe, A.B. & Martins, J.R.R.A. Struct Multidisc Optim (2012) 46: 273.\n            "
            ),
            _c(
              "a",
              {
                attrs: {
                  href: "https://dx.doi.org/10.1007/s00158-012-0763-y",
                  target: "_blank"
                }
              },
              [_vm._v("doi:10.1007/s00158-012-0763-y")]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _vm.editable && _vm.hasWrappingPos
        ? _c("div", { staticClass: "form-group instructions" }, [
            _c("div", { staticClass: "col-sm-offset-2 col-sm-10" }, [
              _c("div", { staticClass: "form-control-static" }, [
                _vm._v(
                  "\n                Move the " +
                    _vm._s(_vm.typeTitle) +
                    " to change its position in the workflow."
                ),
                _c("br"),
                _vm._v(
                  "\n                Drag the handles on the blue lines to change which tools to wrap.\n            "
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.editable && _vm.hasWrappingPos
        ? _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "col-sm-2 control-label" }, [
              _vm._v("Position & Wrapping")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-sm-10 xdsm-fade",
                style: { opacity: _vm.xdsmShown ? 1 : 0 }
              },
              [
                _vm.wrapGraphDef
                  ? _c("xdsm", {
                      ref: "xdsm",
                      staticClass: "xdsm-wrapper",
                      attrs: {
                        "graph-def": _vm.wrapGraphDef,
                        "show-process": false,
                        "enable-pan-zoom": true,
                        "left-align": true,
                        sortable: _vm.sortable,
                        "sortable-ids": _vm.sortableIds,
                        "sort-target-ids": _vm.sortTargetIds,
                        "wrapping-function-id": _vm.wrapFunctionId,
                        "wrapping-nesting": _vm.wrappingNestingSpec,
                        "last-wrapped-function-id": _vm.lastWrappedFunctionId,
                        "immediate-rerender": true,
                        "wrapped-function-ids": _vm.wrappedFunctionIds,
                        "function-classes": _vm.functionClasses
                      },
                      on: {
                        "update:lastWrappedFunctionId": function($event) {
                          _vm.lastWrappedFunctionId = $event
                        },
                        "update:wrappedFunctionIds": function($event) {
                          _vm.wrappedFunctionIds = $event
                        },
                        sort: _vm._sorted
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("span", {
                  staticClass: "link fas fa-arrows-alt reset-zoom",
                  attrs: { title: "Reset zoom" },
                  on: { click: _vm._resetZoom }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasDesVars && _vm.rootVarDefInputVars !== null
        ? _c("div", { staticClass: "form-group instructions" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasDesVars
        ? _c("div", { staticClass: "form-group instructions" }, [
            _c("div", { staticClass: "col-sm-offset-2 col-sm-10" }, [
              _vm.isDOE
                ? _c("div", { staticClass: "form-control-static" }, [
                    _vm._v(
                      "\n                Design variables are the parameters varied by the DOE-driver to explore the design-space.\n            "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isOptimizer
                ? _c("div", { staticClass: "form-control-static" }, [
                    _vm._v(
                      "\n                Design variables are the parameters varied by the optimizer to optimize the design.\n            "
                    )
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasDesVars
        ? _c("div", { staticClass: "form-group" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 control-label",
                class: { "has-error": _vm.errors.has("function.x") }
              },
              [
                _vm._v("Design Variables\n            "),
                _c("span", { staticClass: "error-note" }, [_vm._v("required")])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _vm.rootVarDefInputVars !== null
                  ? _c("select-vars", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      attrs: {
                        mode: "x",
                        "root-var-def": _vm.rootVarDefInputVars,
                        "graph-def": _vm.graphDef,
                        editable: _vm.editable,
                        "need-one": true,
                        "var-modal-title": "Select Design Variables",
                        name: "x",
                        "data-vv-scope": "function",
                        "data-vv-as": "design variables"
                      },
                      model: {
                        value: _vm.def.mdao.x,
                        callback: function($$v) {
                          _vm.$set(_vm.def.mdao, "x", $$v)
                        },
                        expression: "def.mdao.x"
                      }
                    })
                  : _c("div", { staticClass: "problem form-control-static" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticStyle: { display: "none" },
                        attrs: {
                          name: "x",
                          "data-vv-scope": "function",
                          "data-vv-as": "design variables"
                        },
                        domProps: { value: "" }
                      }),
                      _vm._v(" "),
                      _c("b", [
                        _vm._v("Selection of design variables not possible:")
                      ]),
                      _c("br"),
                      _vm._v(
                        "\n                There are no input variables of the wrapped functions, that are not also output variables of one or\n                more of the wrapped functions. Carefully check the function I/O and make sure that any desired\n                design variables are not also written to by any of the wrapped functions.\n            "
                      )
                    ])
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasObj
        ? _c("div", { staticClass: "form-group instructions" }, [_vm._m(1)])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasObj
        ? _c("div", { staticClass: "form-group" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 control-label",
                class: { "has-error": _vm.errors.has("function.f") }
              },
              [
                _vm._v("Objectives\n            "),
                _c("span", { staticClass: "error-note" }, [_vm._v("required")])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _vm.rootVarDefOutputVars
                  ? _c("select-vars", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      attrs: {
                        "root-var-def": _vm.rootVarDefOutputVars,
                        "graph-def": _vm.graphDef,
                        editable: _vm.editable,
                        "need-one": true,
                        "var-modal-title": "Select Objectives",
                        name: "f",
                        "data-vv-scope": "function",
                        "data-vv-as": "objectives"
                      },
                      model: {
                        value: _vm.def.mdao.f,
                        callback: function($$v) {
                          _vm.$set(_vm.def.mdao, "f", $$v)
                        },
                        expression: "def.mdao.f"
                      }
                    })
                  : _c("div", { staticClass: "problem form-control-static" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticStyle: { display: "none" },
                        attrs: {
                          name: "f",
                          "data-vv-scope": "function",
                          "data-vv-as": "objectives"
                        },
                        domProps: { value: "" }
                      }),
                      _vm._v(" "),
                      _c("b", [
                        _vm._v("Selection of objectives not possible:")
                      ]),
                      _c("br"),
                      _vm._v(
                        "\n                The wrapped functions are not providing any output variables. Carefully check the function I/O and\n                make sure that the wrapped functions are outputting the correct variables.\n            "
                      )
                    ])
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasCon
        ? _c("div", { staticClass: "form-group instructions" }, [_vm._m(2)])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasCon
        ? _c("div", { staticClass: "form-group" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 control-label",
                class: { "has-error": _vm.errors.has("function.g") }
              },
              [
                _vm._v("Constraints\n            "),
                _c("span", { staticClass: "error-note" }, [_vm._v("required")])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _vm.rootVarDefOutputVars
                  ? _c("select-vars", {
                      attrs: {
                        mode: "g",
                        "root-var-def": _vm.rootVarDefOutputVars,
                        "graph-def": _vm.graphDef,
                        editable: _vm.editable,
                        "disable-selected-vars": false,
                        "allow-duplicate-row": true,
                        "var-modal-title": "Select Constraints"
                      },
                      model: {
                        value: _vm.def.mdao.g,
                        callback: function($$v) {
                          _vm.$set(_vm.def.mdao, "g", $$v)
                        },
                        expression: "def.mdao.g"
                      }
                    })
                  : _c("div", { staticClass: "problem form-control-static" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticStyle: { display: "none" },
                        attrs: {
                          name: "g",
                          "data-vv-scope": "function",
                          "data-vv-as": "constraints"
                        },
                        domProps: { value: "" }
                      }),
                      _vm._v(" "),
                      _c("b", [
                        _vm._v("Selection of constraints not possible:")
                      ]),
                      _c("br"),
                      _vm._v(
                        "\n                The wrapped functions are not providing any output variables. Carefully check the function I/O and\n                make sure that the wrapped functions are outputting the correct variables.\n            "
                      )
                    ])
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasStateVars
        ? _c("div", { staticClass: "form-group instructions" }, [_vm._m(3)])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasStateVars
        ? _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "col-sm-2 control-label" }, [
              _vm._v("State Variables")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("select-vars", {
                  attrs: {
                    "root-var-def": _vm.rootVarDefOutputVars,
                    "graph-def": _vm.graphDef,
                    editable: _vm.editable,
                    "var-modal-title": "Select State Variables"
                  },
                  model: {
                    value: _vm.def.mdao.y,
                    callback: function($$v) {
                      _vm.$set(_vm.def.mdao, "y", $$v)
                    },
                    expression: "def.mdao.y"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.editable && _vm.hasCouplingVars
        ? _c("div", { staticClass: "form-group instructions" }, [_vm._m(4)])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasCouplingVars
        ? _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "col-sm-2 control-label" }, [
              _vm._v("Convergence Check Variables")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                (_vm.editable || _vm.def.mdao.coupling_vars.length > 0) &&
                _vm.rootVarDefCouplingVars
                  ? _c("select-vars", {
                      attrs: {
                        "root-var-def": _vm.rootVarDefCouplingVars,
                        "graph-def": _vm.graphDef,
                        editable: _vm.editable,
                        "var-modal-title":
                          "Select Variables Checked for Convergence"
                      },
                      model: {
                        value: _vm.def.mdao.coupling_vars,
                        callback: function($$v) {
                          _vm.$set(_vm.def.mdao, "coupling_vars", $$v)
                        },
                        expression: "def.mdao.coupling_vars"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.editable && _vm.def.mdao.coupling_vars.length == 0
                  ? _c("div", { staticClass: "form-control-static" }, [
                      _vm._v("N/A")
                    ])
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-offset-2 col-sm-10" }, [
      _c("div", { staticClass: "form-control-static" }, [
        _vm._v("\n                Only variables that are "),
        _c("i", [_vm._v("exclusively")]),
        _vm._v(
          " input to the wrapped functions are available for\n                selection as design variables. If the desired design variables are not available for selection,\n                carefully check the I/O of the wrapped functions to make sure the design variables are not written\n                to.\n            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-offset-2 col-sm-10" }, [
      _c("div", { staticClass: "form-control-static" }, [
        _vm._v(
          '\n                Objectives are the parameters that should be minimized in order to produce a "better" design.\n            '
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-offset-2 col-sm-10" }, [
      _c("div", { staticClass: "form-control-static" }, [
        _vm._v(
          "\n                Constraints limit the feasible design space in which the optimizer can search for an optimal design.\n            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-offset-2 col-sm-10" }, [
      _c("div", { staticClass: "form-control-static" }, [
        _vm._v(
          "\n                State variables are output parameters that are tracked by the DOE-driver during the design-space\n                exploration.\n            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-offset-2 col-sm-10" }, [
      _c("div", { staticClass: "form-control-static" }, [
        _vm._v(
          "\n                To define the variables that are checked for convergence,\n                select convergence check variables below."
        ),
        _c("br"),
        _vm._v(
          "\n                Otherwise, all variables routed through the converger will be checked for convergence.\n            "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }