var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      ref: "modal",
      attrs: {
        title: "RCE Export Settings",
        "confirm-close": false,
        action: _vm._submit,
        "action-title": "Export"
      }
    },
    [
      _c("div", { staticClass: "form-horizontal settings-form" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "col-sm-3 control-label" }, [
            _vm._v("Export Mode")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-9" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mode,
                    expression: "mode"
                  }
                ],
                staticClass: "form-control",
                attrs: { name: "mode" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.mode = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              _vm._l(_vm.modeValues, function(val) {
                return _c(
                  "option",
                  { key: val[0], domProps: { value: val[0] } },
                  [_vm._v(_vm._s(val[1]))]
                )
              })
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "col-sm-3 control-label" }, [
            _vm._v("RCE Version")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-9" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.version,
                    expression: "version"
                  }
                ],
                staticClass: "form-control",
                attrs: { name: "version" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.version = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              _vm._l(_vm.versionValues, function(val) {
                return _c(
                  "option",
                  { key: val[0], domProps: { value: val[0] } },
                  [_vm._v(_vm._s(val[1]))]
                )
              })
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }