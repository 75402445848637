var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "xdsm-preview-modal",
    {
      ref: "modal",
      attrs: {
        "graph-def": _vm.graphDef,
        title: "Automated Function Sorting",
        "settings-title": "Select Sorting Algorithm",
        action: _vm._apply,
        "action-title": "Apply Sorting",
        "action-disabled": _vm.actionDisabled
      },
      on: { closing: _vm.closing }
    },
    _vm._l(_vm.shownAutoSortMethods, function(method) {
      return _c("div", [
        _c(
          "label",
          {
            on: {
              click: function($event) {
                $event.stopPropagation()
                _vm.selectedMethod = method.key
              }
            }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedMethod,
                  expression: "selectedMethod"
                }
              ],
              attrs: { type: "radio", name: "auto-sort-method" },
              domProps: {
                value: method.key,
                checked: _vm._q(_vm.selectedMethod, method.key)
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                },
                change: function($event) {
                  _vm.selectedMethod = method.key
                }
              }
            }),
            _vm._v("\n            " + _vm._s(method.title)),
            _c("br"),
            _vm._v(" "),
            method.description
              ? _c("div", {
                  staticClass: "description",
                  domProps: { innerHTML: _vm._s(method.description) }
                })
              : _vm._e()
          ]
        )
      ])
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }