function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../../lodash.custom.js';
import selectTable from './select-table.vue';
import { api } from '../../index.js'; // https://logaretm.github.io/vee-validate/guide/basics.html#adding-rules

import { Validator } from 'vee-validate';
Validator.extend('expression', function (value, _ref) {
  var _ref2 = _slicedToArray(_ref, 1),
      validateFunc = _ref2[0];

  if (!_.isFunction(validateFunc)) return true;
  return validateFunc(value);
});
Validator.extend('alpha_dash_slash', function (value) {
  return /^[\/0-9A-Z_-]*$/i.test(value);
});
export default {
  name: "select-equations",
  components: {
    selectTable: selectTable
  },
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    editable: {
      type: Boolean,
      default: true
    },
    expressionAvailableVars: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    excludedOutputLabels: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      api: api,
      columns: [['out_label', 'Child Variable Path'], ['expression', 'Expression (Python)']],
      outLabel: '',
      expression: '',
      editedOutLabel: null,
      outLabelFieldShown: true,
      outLabelRules: {
        required: true,
        alpha_dash_slash: true,
        excluded: []
      },
      validateExpressionDebounced: true,
      expressionRules: {
        required: true,
        expression: null
      }
    };
  },
  watch: {
    excludedOutputLabels: function excludedOutputLabels() {
      this._debouncedSetOutLabelExcluded();
    },
    expressionAvailableVars: function expressionAvailableVars() {
      if (this.expression) {
        this.$validator.validate('eq.expression');
      }
    },
    editedOutLabel: function editedOutLabel() {
      this._setOutLabelExcluded();
    }
  },
  computed: {
    data: function data() {
      return _.map(this.value, function (val) {
        return _.merge({}, val, {
          expression: '= ' + val.expression
        });
      });
    },
    usedOutputLabels: function usedOutputLabels() {
      return _.map(this.value, function (val) {
        return val.out_label;
      });
    },
    outputLabelExcluded: function outputLabelExcluded() {
      return _.concat(this.usedOutputLabels, this.excludedOutputLabels);
    },
    _debouncedValidateExpression: function _debouncedValidateExpression() {
      return _.debounce(this._validateExpression, 400);
    },
    _debouncedSetOutLabelExcluded: function _debouncedSetOutLabelExcluded() {
      var _c = this;

      return _.debounce(function () {
        // Re-render the field, as otherwise the v-validate directive still uses the old settings
        _c.outLabelFieldShown = false;

        _c._setOutLabelExcluded();

        setTimeout(function () {
          return _c.outLabelFieldShown = true;
        }, 0);
      }, 500);
    }
  },
  methods: {
    _initForm: function _initForm() {
      this.outLabel = '';
      this.expression = '';
      this.editIdx = null;
      this.editedOutLabel = null;
      this.validateExpressionDebounced = true;

      this._setOutLabelExcluded();

      var _c = this;

      setTimeout(function () {
        _c.errors.clear('eq');

        _c.$validator.reset('eq');
      }, 0);
    },
    _setOutLabelExcluded: function _setOutLabelExcluded() {
      var allowLabel = this.editedOutLabel;
      this.outLabelRules.excluded = _.filter(this.outputLabelExcluded, function (label) {
        return label != allowLabel;
      });
    },
    _deleteHandler: function _deleteHandler(idx) {
      this.$emit('input', _.filter(this.value, function (v, i) {
        return i != idx;
      }));
    },
    _addHandler: function _addHandler() {
      this._processForm(null);
    },
    _processForm: function _processForm(updateIdx, callback) {
      var _this = this;

      var _c = this;

      this.validateExpressionDebounced = false;
      this.$validator.validateAll('eq').then(function (valid) {
        _c.validateExpressionDebounced = true;
        if (!valid) return;
        var newValue = {
          out_label: _c.outLabel,
          expression: _c.expression
        };
        var newInput = updateIdx !== null ? _.map(_this.value, function (v, i) {
          return i == updateIdx ? newValue : v;
        }) : _.concat(_this.value, newValue);

        _c.$emit('input', newInput);

        _c._initForm();

        if (callback) callback();
      });
    },
    _editHandler: function _editHandler(idx) {
      this._initForm();

      var data = this.value[idx];
      this.outLabel = data.out_label;
      this.editedOutLabel = this.outLabel;
      this.expression = data.expression;
    },
    _updateHandler: function _updateHandler() {
      var _c = this;

      this._processForm(this.$refs.table.editIdx, function () {
        _c.$refs.table.editingFinished();
      });
    },
    _resetHandler: function _resetHandler() {
      this._initForm();
    },
    _addOrUpdate: function _addOrUpdate() {
      if (this.$refs.table.editing) {
        this._updateHandler();
      } else {
        this._addHandler();
      }
    },
    _validateExpressionPromise: function _validateExpressionPromise(expression) {
      var _c = this;

      return new Promise(function (resolve) {
        return _c.validateExpressionDebounced ? _c._debouncedValidateExpression(expression, resolve) : _c._validateExpression(expression, resolve);
      });
    },
    _validateExpression: function _validateExpression(expression, resolve) {
      this.api.validateMath(expression, this.expressionAvailableVars, function () {
        return resolve(true);
      }, // Success
      function (msg) {
        // Error
        console.log('MATH ERROR', msg.msg);
        resolve(false);
      } // Error
      );
    },
    _hasInput: function _hasInput() {
      this.$refs.table.hasInput();
    }
  },
  created: function created() {
    this.expressionRules.expression = this._validateExpressionPromise;
  },
  mounted: function mounted() {
    this._initForm();
  }
};