//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "options",
  props: {
    options: {
      type: Array,
      required: true
    },
    // [[key, value], ...]
    value: {
      type: String,
      default: null
    },
    radioLimit: {
      type: Number,
      default: 3
    },
    name: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      fallbackName: 'radio-' + Math.random().toString(36).slice(2),
      modelValue: null
    };
  },
  watch: {
    value: function value(val) {
      this.modelValue = val;
    }
  },
  computed: {
    isRadio: function isRadio() {
      return this.options.length <= this.radioLimit;
    },
    inputName: function inputName() {
      return this.name || this.fallbackName;
    }
  },
  methods: {
    _input: function _input(e) {
      this.$emit('input', e.target.value);
    }
  },
  created: function created() {
    this.modelValue = this.value;
  }
};