//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Component displaying the variable tree list. The root variable is passed, and this is used to dynamically
 * display the children components. Clicking an element either expands/collapses the children, or (if it is a leaf
 * node) displays the variable info modal. In addition, buttons are added for expanding/collapsing all descendants.
 * Buttons for showing the info and editing the routing are shown for leaf nodes.
 *
 * Usage:
 * <var-list
 *     :root-var-def="rootVarDef"
 *     :search-ids="searchIds"
 *     :editable="editable"
 *     :scroll-container="scrollContainer"
 *     :sticky-to-window="stickyToWindow"
 *     :functions="functions"
 *     :padding="padding"
 *     :search-placeholder="searchPlaceholder"
 *     :selected-ids.sync="selectedIds"
 *     :multi-select="multiSelect"
 *     @create="createFunction"
 *     @search="searchFunction"
 * >
 * </var-list>
 *
 * where:
 * - rootVarDef is the object defining the root node (see below for the definition of the format)
 * - searchIds is a list of varIds matched by the search operation
 * - editable (bool) determines whether additional UI elements are shown for adding and excluding/deleting nodes
 * - scrollContainer is the element that contains the scrollbars when the list gets long, if not given, the window
 *   is taken as the scrollContainer (if a scrollContainer is given, the search field is made sticky by setting
 *   position to absolute with top=0, so the scrollContainer should have a parent with position != static)
 * - stickyToWindow (bool) makes the search bar stick to the window then the scrollContainer passes the search bar
 *   position, instead of sticking it to the parent of the scrollContainer
 * - functions is the list of available XDSM functions (see xdsm.vue for format), needed for rendering the routing
 *   in the info modals
 * - padding (px) adds left/right padding to the elements in the var list
 * - searchPlaceholder (default: "xpath") determines the placeholder in the search field
 * - selectedIds is a list of IDs as selected by the variable list (don't pass or pass null to disable selection)
 * - multiSelect enables the selection of more than one variable
 * - createFunction is the function called when the creation of a new node is requested, it is passed:
 *   - parentVarId: varId of the parent element that should contain the newly created variable node
 *   - varDef: varDef for the new variable
 * - searchFunction is the function called when a search query (debounced) is entered, it is passed the search term
 *   and should update the searchIds list, and it should make sure to empty the list when an empty search query is
 *   passed!
 *
 * Variable object definition (varDef): see var-utils.js for format.
 */
import _ from '../../lodash.custom.js';
import varItem from './var-item.vue';
import varInfo from './var-info.vue';
import routingSelect from './routing-select.vue';
import { filterByVarIds, getNewMultiFileRootVarDef, isMultiFileRoot } from '../../var-utils.js';
import { standaloneMode } from "../../api";
export default {
  name: "var-list",
  components: {
    varItem: varItem,
    varInfo: varInfo,
    routingSelect: routingSelect
  },
  props: {
    rootVarDef: {
      type: Object,
      default: null
    },
    searchIds: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    highlightIds: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    editable: {
      type: Boolean,
      default: false
    },
    deletable: {
      type: Boolean,
      default: false
    },
    selectedIds: {
      type: Array,
      default: null
    },
    disabledIds: {
      type: Array,
      default: null
    },
    mainIds: {
      type: Array,
      default: null
    },
    multiSelect: {
      type: Boolean,
      default: false
    },
    selectLeaf: {
      type: Boolean,
      default: true
    },
    selectParent: {
      type: Boolean,
      default: false
    },
    // Element with non-static positioning containing the element with scrollbars
    scrollContainer: {
      type: HTMLElement,
      default: null
    },
    stickyToWindow: {
      type: Boolean,
      default: false
    },
    graphDef: {
      type: Object,
      default: null
    },
    padding: {
      type: Number,
      default: 0
    },
    paddingBottom: {
      type: Number,
      default: 0
    },
    searchPlaceholder: {
      type: String,
      default: 'xpath'
    },
    routingEditable: {
      type: Boolean,
      default: false
    },
    varEditable: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: true
    },
    stickyCloseButton: {
      type: Boolean,
      default: false
    },
    fillHeightAutoScroll: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      standaloneMode: standaloneMode,
      headerTop: null,
      headerHeight: null,
      headerWidth: null,
      headerSticky: false,
      searchValue: '',
      headerButtonsWidth: 20,
      extraButtonsWidth: 30
    };
  },
  watch: {
    rootVarDef: function rootVarDef() {
      this.searchValue = '';
      setTimeout(this._determineExtraButtonsWidth, 0);
    },
    searchIds: function searchIds() {
      setTimeout(this.$refs.rootVarItem.expandSearch, 0);
    },
    highlightIds: function highlightIds() {
      setTimeout(this.$refs.rootVarItem.expandSearch, 0);
    },
    scrollContainer: function scrollContainer() {
      this._addScrollListener();

      this._initScroll();
    },
    searchValue: function searchValue(value) {
      this.debouncedSearch(value);
    }
  },
  computed: {
    functions: function functions() {
      return this.graphDef ? this.graphDef.functions : [];
    },
    headerStyles: function headerStyles() {
      var style = {};

      if (this.headerSticky) {
        style['width'] = this.headerWidth + 'px';
      }

      return style;
    },
    buttonsRight: function buttonsRight() {
      return this.extraButtonsWidth + this.padding;
    },
    headerButtonStyles: function headerButtonStyles() {
      return {
        right: this.buttonsRight + 'px'
      };
    },
    searchFieldContainerStyle: function searchFieldContainerStyle() {
      return {
        'margin-right': this.buttonsRight + this.headerButtonsWidth + 10 + 'px',
        'padding-left': this.padding + 'px'
      };
    },
    scrollElement: function scrollElement() {
      return this.scrollContainer ? this.scrollContainer : window;
    },
    referenceElement: function referenceElement() {
      return this.scrollContainer ? this.scrollContainer : document.documentElement;
    },
    scrollIsWindow: function scrollIsWindow() {
      return !this.scrollContainer || this.stickyToWindow;
    },
    debouncedSearch: function debouncedSearch() {
      var _c = this;

      return _.debounce(function (term) {
        return _c._search(term);
      }, 500);
    },
    placeholder: function placeholder() {
      if (this.standaloneMode) return 'Search';
      return 'Search: ' + this.searchPlaceholder;
    },
    selectable: function selectable() {
      return this.selectedIds !== null;
    },
    displayedRootVarDef: function displayedRootVarDef() {
      var rootVarDef = this.rootVarDef;
      if (!rootVarDef) return rootVarDef;
      var searchIds = this.searchIds;
      if (!searchIds) return rootVarDef;
      var filteredRootVarDef = filterByVarIds(rootVarDef, searchIds, true);
      return filteredRootVarDef ? filteredRootVarDef : rootVarDef;
    },
    showSearchIds: function showSearchIds() {
      return _.concat(this.searchIds || [], this.highlightIds || []);
    }
  },
  methods: {
    positionUpdated: function positionUpdated() {
      setTimeout(this._initScroll, 0);
    },
    collapseAll: function collapseAll() {
      this.$refs.rootVarItem.collapseAll();
    },
    expandAll: function expandAll() {
      this.$refs.rootVarItem.expandAll();
    },
    expandId: function expandId(varId) {
      this.$refs.rootVarItem.expandId(varId);
    },
    _showInfo: function _showInfo(varDef) {
      this.$emit('info', varDef);
    },
    _showRouting: function _showRouting(varDef) {
      this.$emit('routing', varDef);
    },
    _createVar: function _createVar(parentVarDef) {
      this.$emit('create', parentVarDef);
    },
    _editVar: function _editVar(varDef) {
      this.$emit('edit', varDef);
    },
    _connectVar: function _connectVar(varDef) {
      this.$emit('connect', varDef);
    },
    _deleteVar: function _deleteVar(varDef) {
      this.$emit('delete', varDef);
    },
    _search: function _search(term) {
      this.$emit('search', term);
    },
    _createMultiFileRootVar: function _createMultiFileRootVar() {
      var rootVarDef = this.rootVarDef;

      if (isMultiFileRoot(rootVarDef)) {
        // Already an existing multi-file root
        this._createVar(rootVarDef);
      } else {
        // Need to create a new multi-file root to add to
        this._createVar(getNewMultiFileRootVarDef());
      }
    },
    _selectVar: function _selectVar(varId, selected) {
      var selectedIds = _.cloneDeep(this.selectedIds);

      var includes = _.includes(selectedIds, varId);

      if (selected) {
        if (this.multiSelect) {
          if (!includes) selectedIds.push(varId);
        } else {
          selectedIds = [varId];
        }
      } else {
        if (includes) _.remove(selectedIds, function (val) {
          return val == varId;
        });
      }

      this.$emit('update:selectedIds', selectedIds);
    },
    _determineExtraButtonsWidth: function _determineExtraButtonsWidth() {
      // Width of the header buttons
      if (!this.$refs.hasOwnProperty('headerButtons')) return;
      var headerButtons = this.$refs.headerButtons;
      this.headerButtonsWidth = headerButtons.offsetWidth; // Width of the extra buttons on the right of the collapse/expand all buttons in the first list item

      if (!this.$refs.hasOwnProperty('rootVarItem')) return;
      var item = this.$refs.rootVarItem;

      if (!item.$refs.hasOwnProperty('extraButtons')) {
        if (item.isMultiFileRoot && item.$refs.hasOwnProperty('childItem')) {
          item = item.$refs.childItem[0];
          if (!item.$refs.hasOwnProperty('extraButtons')) return;
        } else {
          return;
        }
      }

      var extraButtons = item.$refs.extraButtons;
      this.extraButtonsWidth = extraButtons.offsetWidth;
    },
    _addScrollListener: function _addScrollListener() {
      this.scrollElement.addEventListener('scroll', this._onScroll);
    },
    _initScroll: function _initScroll() {
      // https://stackoverflow.com/a/18673641
      var headerEl = this.headerSticky ? this.$refs.proxyListHeader : this.$refs.listHeader;
      var topOnPage = headerEl.getBoundingClientRect().y;

      if (this.referenceElement) {
        topOnPage -= this.referenceElement.getBoundingClientRect().y;
      }

      var scrollTop = this._getScrollTop();

      this.headerTop = topOnPage + scrollTop; // Determine current sticky state

      this._onScroll();
    },
    _removeScrollListener: function _removeScrollListener() {
      this.scrollElement.removeEventListener('scroll', this._onScroll);
    },
    _onScroll: function _onScroll() {
      if (this.headerHeight === null) {
        this.headerHeight = this.$refs.listHeader.offsetHeight;
        this.headerWidth = this.$refs.listHeader.offsetWidth;
      }

      if (this.fillHeightAutoScroll) {
        this.headerSticky = false;
        return;
      }

      this.headerSticky = this._getScrollTop() > this.headerTop;
    },
    _getScrollTop: function _getScrollTop() {
      return this.referenceElement.scrollTop;
    },
    displayChanged: function displayChanged() {
      this._determineExtraButtonsWidth();
    }
  },
  created: function created() {
    this._addScrollListener();
  },
  mounted: function mounted() {
    this._initScroll();

    this._determineExtraButtonsWidth();
  },
  destroyed: function destroyed() {
    this._removeScrollListener();
  }
};