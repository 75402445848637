var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      ref: "modal",
      attrs: {
        title: _vm.modalTitle,
        "has-buttons": false,
        "confirm-close": false,
        wide: "60%",
        draggable: !_vm.modal
      },
      on: { opened: _vm._opened, closed: _vm._closed },
      nativeOn: {
        copy: function($event) {
          return _vm._XPathCopy($event)
        }
      }
    },
    [
      _c("div", { staticClass: "form-horizontal data-form" }, [
        _vm.xpath
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-2 control-label" }, [
                _vm._v("XPath")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("p", { staticClass: "form-control-static" }, [
                  _vm._v(_vm._s(_vm._f("xpath")(_vm.xpath)))
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.hasAttr
          ? _c(
              "div",
              _vm._l(_vm.attr, function(value, key, idx) {
                return _c(
                  "div",
                  { key: "key_" + key, staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label attr-key-label",
                        class: {
                          "col-sm-2": !_vm.isLargeAttr[idx],
                          "col-sm-12": _vm.isLargeAttr[idx],
                          "is-large": _vm.isLargeAttr[idx]
                        }
                      },
                      [
                        _c("span", { ref: "attr", refInFor: true }, [
                          _vm._v(_vm._s(key))
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col-sm-10",
                        class: { "col-sm-offset-2": _vm.isLargeAttr[idx] }
                      },
                      [
                        _c("p", { staticClass: "form-control-static" }, [
                          _vm._v(_vm._s(value))
                        ])
                      ]
                    )
                  ]
                )
              })
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.hasSubValueMatch
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-2 control-label" }, [
                _vm._v("Sub Value Matchers")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-10" },
                _vm._l(_vm.svm, function(value, xpath) {
                  return _c(
                    "p",
                    { key: xpath, staticClass: "form-control-static" },
                    [
                      value
                        ? _c("span", [
                            _vm._v(_vm._s(xpath) + " = " + _vm._s(value))
                          ])
                        : _c("span", [_vm._v(_vm._s(xpath))])
                    ]
                  )
                })
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.varEditable
          ? _c(
              "button",
              {
                staticClass: "btn btn-default",
                on: {
                  click: function($event) {
                    _vm.$emit("edit", _vm.varDef)
                  }
                }
              },
              [
                _c("span", { staticClass: "fas fa-edit" }),
                _vm._v("\n            Edit variable\n        ")
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.hasEditRouteButton
        ? _c("div", { staticClass: "routing-button" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default icon",
                class: { collision: _vm.hasRoutingCollision },
                attrs: { title: "Edit variable routing" },
                on: {
                  click: function($event) {
                    _vm.$emit("routing", _vm.varDef)
                  }
                }
              },
              [
                _c("span", { staticClass: "fas fa-route" }),
                _vm._v("\n            Edit routing\n        ")
              ]
            ),
            _vm._v(" "),
            !_vm.hasRoutingCollision
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-default icon",
                    attrs: { title: "Reset variable routing" },
                    on: { click: _vm._resetRouting }
                  },
                  [_c("span", { staticClass: "fas fa-eraser" })]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "var-routing-container" },
        [
          _c("h3", [
            _vm._v("\n            Provided By\n            "),
            _c("span", { staticStyle: { float: "right" } }, [
              _vm._v("\n                Needed By\n            ")
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.routing, function(route, idx) {
            return _c("var-routing", {
              key: idx,
              attrs: {
                name: _vm.name,
                "in-functions": route.in,
                "out-functions": route.out,
                "add-in-function-button":
                  _vm.hasUnusedToolFunctionsIn && idx == _vm.routing.length - 1,
                "add-out-function-button":
                  _vm.hasUnusedToolFunctionsOut &&
                  idx == _vm.routing.length - 1,
                "is-first": idx == 0,
                "routing-editable": _vm.routingEditable
              },
              on: { connect: _vm._connectVar, delete: _vm._deleteToolVar }
            })
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("connect-var", {
        ref: "connectVar",
        attrs: { functions: _vm.functions },
        on: { connect: _vm._doConnect }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }