//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import uiModal from './ui-modal.vue';
import uiDialog from './ui-dialog.vue';
import uploader from './uploader.vue';
import varListModal from './var-list/var-list-modal.vue';
import { api, dispatcher, remoteName } from '../index.js';
export default {
  name: "base-schema-modal",
  components: {
    uiModal: uiModal,
    uiDialog: uiDialog,
    uploader: uploader,
    varListModal: varListModal
  },
  props: {
    graphDef: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      api: api,
      dispatcher: dispatcher,
      remoteName: remoteName,
      uploaderRef: null,
      baseSchemaFunctionId: null,
      hasEditedBaseSchema: false,
      availableBaseSchemas: null,
      selectedBaseSchema: null
    };
  },
  computed: {
    baseSchema: function baseSchema() {
      return this.graphDef ? this.graphDef.base_schema : null;
    },
    hasBaseSchema: function hasBaseSchema() {
      return this.baseSchema !== null;
    },
    actionDisabled: function actionDisabled() {
      if (this.hasEditedBaseSchema) return false;
      if (this.useAvailable) return !this.selectedBaseSchema;
      return this.fileId === null;
    },
    useAvailable: function useAvailable() {
      return this.availableBaseSchemas !== null;
    },
    hasAvailable: function hasAvailable() {
      return this.useAvailable && this.availableBaseSchemas.length > 0;
    },
    uploadedFiles: function uploadedFiles() {
      var uploader = this.uploaderRef;
      if (!uploader) return [];
      return uploader.uploadedFiles;
    },
    fileId: function fileId() {
      var uploadedFiles = this.uploadedFiles;
      if (uploadedFiles.length == 0) return null;
      return uploadedFiles[0].id;
    },
    actionTitle: function actionTitle() {
      return this.hasBaseSchema ? 'Apply' : 'Select';
    }
  },
  methods: {
    open: function open() {
      var _this = this;

      this.hasEditedBaseSchema = false;
      this.api.resetUpload();
      this.api.getBaseSchemas(function (baseSchemas) {
        _this.availableBaseSchemas = baseSchemas;

        _this.$refs.modal.open();
      });
    },
    close: function close() {
      this.$refs.modal.close();
    },
    _opened: function _opened() {
      this.uploaderRef = this.$refs.uploader;
    },
    _closed: function _closed() {
      this.uploaderRef = null;

      if (this.hasEditedBaseSchema) {
        this.$emit('update');
      }
    },
    _selectSchema: function _selectSchema() {
      // Just close the modal if we have an edited base schema
      if (this.hasEditedBaseSchema) return true;

      this._doSelect(this.useAvailable ? this.selectedBaseSchema : this.fileId); // Do not close the modal just yet


      return false;
    },
    _removeSchema: function _removeSchema() {
      var _c = this;

      this.$refs.confirmRemoveSchema.open(function () {
        _c._doSelect(null);
      });
    },
    _doSelect: function _doSelect(fileId) {
      var _c = this;

      this.api.editSetBaseSchema(fileId, function (graphDef) {
        _c.$emit('update', graphDef);

        _c.close();
      });
    },
    _editSchema: function _editSchema() {
      var _this2 = this;

      var modal = this.$refs.varListModal;
      modal.resetModal();
      modal.setTitle('Edit Base Schema');
      modal.open(function () {
        return _this2.baseSchema;
      });
    },
    _createVarFunction: function _createVarFunction(parentVarId, varData, callback) {
      var _c = this;

      this.api.editCreateVar(this.baseSchemaFunctionId, null, parentVarId, varData, function (rootVarDef, newVarData) {
        _c.hasEditedBaseSchema = true;

        _c.$emit('update');

        callback(newVarData);
      });
    },
    _editVarFunction: function _editVarFunction(varId, varData, callback) {
      var _c = this;

      this.api.editEditVar(varId, varData, function (rootVarDef, newVarData) {
        _c.hasEditedBaseSchema = true;

        _c.$emit('update');

        callback(newVarData);
      });
    },
    _deleteVarFunction: function _deleteVarFunction(varDef, callback) {
      var _c = this;

      this.api.editDeleteVar(this.baseSchemaFunctionId, null, varDef.id, function (rootVarDef) {
        _c.hasEditedBaseSchema = true;

        _c.$emit('update');

        callback();
      });
    }
  },
  created: function created() {
    var _this3 = this;

    this.api.editGetBaseSchemaFunctionId(function (functionId) {
      return _this3.baseSchemaFunctionId = functionId;
    });
  }
};