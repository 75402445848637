import { render, staticRenderFns } from "./var-info.vue?vue&type=template&id=467fa7e0&scoped=true&"
import script from "./var-info.vue?vue&type=script&lang=js&"
export * from "./var-info.vue?vue&type=script&lang=js&"
import style0 from "./var-info.vue?vue&type=style&index=0&id=467fa7e0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "467fa7e0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\garg_sp\\Desktop\\Sparsh\\Sparsh\\MDAx\\06_Tool_Installation\\Cloned_20240827\\mdax\\mdax\\ui\\web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('467fa7e0', component.options)
    } else {
      api.reload('467fa7e0', component.options)
    }
    module.hot.accept("./var-info.vue?vue&type=template&id=467fa7e0&scoped=true&", function () {
      api.rerender('467fa7e0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui-items/var-list/var-info.vue"
export default component.exports