//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../lodash.custom.js';
export default {
  name: "arrows",
  props: {
    arrows: {
      type: Array,
      required: true
    },
    // SVG paths
    markerMid: {
      type: Boolean,
      default: false
    },
    displayMarker: {
      type: Boolean,
      default: true
    },
    clickable: {
      type: Boolean,
      default: false
    },
    clickableArrows: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    clickTitle: {
      default: null
    }
  },
  data: function data() {
    return {
      arrowsId: 'arrows-' + Math.random().toString(36).slice(2),
      hoverIdx: null
    };
  },
  computed: {
    pathData: function pathData() {
      var markerMid = this.markerMid;
      var displayMarker = this.displayMarker;
      var hoverIdx = this.hoverIdx;
      var markerId = this.arrowsId + '-arrow-head';
      var clickable = this.clickable;
      var clickableArrows = this.clickableArrows;
      return _.map(this.arrows, function (path, idx) {
        var markerMidDef = null,
            markerEndDef = null;

        if (displayMarker) {
          var hovering = hoverIdx === idx;
          var marker = 'url(#' + (hovering ? markerId + '-hover' : markerId) + ')';

          if (markerMid) {
            markerMidDef = marker;
          } else {
            markerEndDef = marker;
          }
        }

        return {
          path: path,
          markerMid: markerMidDef,
          markerEnd: markerEndDef,
          clickable: clickableArrows.length > 0 ? clickableArrows[idx] : clickable
        };
      });
    }
  }
};