var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      ref: "modal",
      attrs: {
        title: _vm.modalTitle,
        "confirm-close": _vm.confirmClose,
        "has-buttons": _vm.hasButtons,
        action: _vm._submit,
        "action-title": _vm.actionTitle,
        "action-error": !_vm.isValid,
        wide: _vm.editable
      },
      on: { opened: _vm._opened, closed: _vm._closed }
    },
    [
      !_vm.isNew && !(_vm.hasIoDef || _vm.editable)
        ? _c(
            "div",
            { staticClass: "xdsm-function-block" },
            [
              _c("xdsm-function", {
                attrs: { "function-def": _vm.functionDef }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isNew
        ? _c("div", { staticClass: "view-io" }, [
            _vm.hasIoDef || _vm.editable
              ? _c(
                  "div",
                  { staticStyle: { display: "inline-block" } },
                  [
                    _c("var-list-modal", {
                      ref: "varListModal",
                      attrs: {
                        "graph-def": _vm.graphDef,
                        "routing-editable": _vm.editable,
                        "var-editable": _vm.editable,
                        editable: _vm.ioEditable,
                        deletable: _vm.ioEditable,
                        "create-function": _vm._createVarFunction,
                        "delete-function": _vm._deleteVarFunction,
                        "connect-function": _vm._connectVarFunction,
                        "edit-function": _vm._editVarFunction
                      }
                    }),
                    _vm._v(" "),
                    _c("table", [
                      _c("tr", [
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-default",
                              attrs: { title: "Show function input" },
                              on: { click: _vm._showInput }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.ioButtonVerb) +
                                  " input "
                              ),
                              _c("span", { staticClass: "fas fa-sign-in-alt" })
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("xdsm-function", {
                              attrs: { "function-def": _vm.functionDef }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-default",
                              attrs: { title: "Show function output" },
                              on: { click: _vm._showOutput }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.ioButtonVerb) +
                                  " output "
                              ),
                              _c("span", { staticClass: "fas fa-sign-out-alt" })
                            ]
                          )
                        ])
                      ])
                    ])
                  ],
                  1
                )
              : _c("span", [
                  _vm._v(
                    "\n            This function defines no input or output...\n        "
                  )
                ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-horizontal function-form" },
        [
          _vm.isNew && _vm.isTool
            ? _c("div", { staticClass: "right-action" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    on: { click: _vm._addToolsBatch }
                  },
                  [
                    _c("span", { staticClass: "fas fa-list" }),
                    _vm._v(" Add tools in batch\n            ")
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isNew && _vm.isSubWorkflow
            ? _c("div", { staticClass: "right-action" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    class: {
                      "btn-danger": _vm.hasSubWorkflowIssues,
                      "btn-primary": !_vm.hasSubWorkflowIssues
                    },
                    on: { click: _vm._editSubWorkflow }
                  },
                  [
                    _vm.editable
                      ? _c("span", { staticClass: "fas fa-eye" })
                      : _c("span", { staticClass: "fas fa-edit" }),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.editable
                            ? "Edit sub-workflow"
                            : "View sub-workflow"
                        ) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.editable
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: {
                          title: "Merge sub-workflow into the current workflow"
                        },
                        on: { click: _vm._mergeSubWorkflow }
                      },
                      [
                        _c("span", {
                          staticClass: "fas fa-compress-arrows-alt"
                        }),
                        _vm._v(
                          "\n                Merge into current\n            "
                        )
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.editable
            ? _c(
                "div",
                {
                  staticClass: "form-group",
                  class: { "has-error": _vm.errors.has("function.title") }
                },
                [
                  _c("label", { staticClass: "col-sm-2 control-label" }, [
                    _vm._v(
                      _vm._s(_vm.shortTypeTitle) + " Name\n                "
                    ),
                    _c("span", { staticClass: "error-note" }, [
                      _vm._v("required")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.functionDef.title,
                          expression: "functionDef.title"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.titleRules,
                          expression: "titleRules"
                        }
                      ],
                      ref: "nameField",
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "title",
                        placeholder: "Function name",
                        "data-vv-validate-on": "input|change|blur",
                        "data-vv-scope": "function",
                        "data-vv-as": "name"
                      },
                      domProps: { value: _vm.functionDef.title },
                      on: {
                        keyup: function($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm._submit($event)
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.functionDef,
                            "title",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.editable || (!_vm.editable && _vm.escapedHtmlNotes)
            ? _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "col-sm-2 control-label" }, [
                  _vm._v("Notes")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-10" }, [
                  _vm.editable
                    ? _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.functionDef.notes,
                            expression: "functionDef.notes"
                          }
                        ],
                        staticClass: "form-control",
                        domProps: { value: _vm.functionDef.notes },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.functionDef,
                              "notes",
                              $event.target.value
                            )
                          }
                        }
                      })
                    : _c("div", {
                        staticClass: "form-control-static",
                        domProps: { innerHTML: _vm._s(_vm.escapedHtmlNotes) }
                      })
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.functionDef && _vm.isAnalysis
            ? _c("analysis-form", {
                ref: "analysisForm",
                attrs: {
                  "function-def": _vm.functionDef,
                  "is-new": _vm.isNew,
                  editable: _vm.editable,
                  "root-var-def": _vm.rootVarDef,
                  "graph-def": _vm.graphDef,
                  "available-tool-i-o": _vm.availableToolIO
                },
                on: {
                  "update:functionDef": function($event) {
                    _vm.functionDef = $event
                  },
                  submit: _vm._submit
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.functionDef && _vm.isMdao
            ? _c("mdao-form", {
                ref: "mdaoForm",
                attrs: {
                  "function-def": _vm.functionDef,
                  "is-new": _vm.isNew,
                  editable: _vm.editable,
                  "root-var-def": _vm.rootVarDef,
                  "graph-def": _vm.graphDef,
                  "used-names": _vm.usedOtherFunctionNames,
                  "type-title": _vm.shortTypeTitleLC
                },
                on: {
                  "update:functionDef": function($event) {
                    _vm.functionDef = $event
                  },
                  submit: _vm._submit
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("create-var", { ref: "createVar", on: { create: _vm._doCreateVar } }),
      _vm._v(" "),
      _c(
        "ui-dialog",
        {
          ref: "confirmMergeSubWorkflowDialog",
          attrs: { "action-title": "Merge" }
        },
        [
          _vm._v(
            "\n        Are you sure you want to merge the sub-workflow into the current workflow?"
          ),
          _c("br")
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }