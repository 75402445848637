//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../../lodash.custom.js';
import varListModal from '../var-list/var-list-modal.vue';
import { getFlatVars, getLeafVars, getParentVars, getNonConflictingVarNames } from "../../var-utils.js";
import { api, dispatcher } from '../../index.js';
/**
 * Component that displays a button for selecting variables, opens a modal to select variables, and then displays
 * a list of selected variables.
 *
 * Usage:
 * <select-var
 *          v-model="selectedVarIds"
 *          :root-var-def="rootVarDef"
 *          :graph-def="graphDef"
 *          :still-need-selection="stillNeedSelection"
 *          :disabled-var-ids="disabledVarIds"
 *          :disabled-vars-are-selected="disabledVarsAreSelected"
 *          :var-modal-title="varModalTitle"
 *          :multi-select="multiSelect"
 *          :select-leaf="selectLeaf"
 *          :select-parent="selectParent"
 *
 *          name="__name__"
 *          v-validate="veeValidateRules"
 *          data-vv-scope="__scope__"
 *          :class="{'select-var-has-error': errors.has('__scope__.__name__')}"
 * ></select-var>
 *
 * where:
 * - selectedVarIds is a two-way synced list of selected variable IDs
 * - rootVarDef is the root-variable definition (see var-list.vue for format)
 * - stillNeedSelection: if set to true, it draws more attention to the select button if no selection is made yet
 * - disabledVarIds is a list of var IDs which cannot be chosen anymore
 * - disabledVarsAreSelected: true means the disabled vars are shown as selected
 * - veeValidateRules: validation rules applied to selectedVarIds
 * - varModalTitle is the title of the var selection modal
 * - multiSelect specifies whether selection of multiple variables at once is selected
 * - selectLeaf specifies whether leaf node selection is allowed
 * - selectParent specifies whether parent node selection is allowed
 *
 * The component contains one slot, which can be used to provide an alternative way of rendering the currently
 * selected variables.
 */

export default {
  name: "select-var",
  components: {
    varListModal: varListModal
  },
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // List of selected ids
    rootVarDef: {
      type: Object,
      required: true
    },
    selectLeaf: {
      type: Boolean,
      default: true
    },
    selectParent: {
      type: Boolean,
      default: false
    },
    graphDef: {
      type: Object,
      default: function _default() {
        return null;
      }
    },
    stillNeedSelection: {
      type: Boolean,
      default: false
    },
    disabledVarIds: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    disabledVarsAreSelected: {
      type: Boolean,
      default: false
    },
    varModalTitle: {
      type: String,
      default: 'Select Variables'
    },
    multiSelect: {
      type: Boolean,
      default: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    createVarFunction: {
      type: Function,
      default: null
    },
    varNameMap: {
      type: Object,
      default: function _default() {
        return null;
      }
    }
  },
  data: function data() {
    return {
      api: api,
      dispatcher: dispatcher
    };
  },
  watch: {
    rootVarDef: function rootVarDef() {
      var modal = this.$refs.varList;
      if (modal && modal.opened) modal.rootVarDefUpdated();
    }
  },
  computed: {
    hasSelection: function hasSelection() {
      return this.value.length > 0;
    },
    selectedVars: function selectedVars() {
      return this._getVarNames(this.value);
    },
    varMap: function varMap() {
      if (this.selectLeaf && this.selectParent) {
        return getFlatVars(this.rootVarDef);
      } else if (this.selectLeaf) {
        return getLeafVars(this.rootVarDef);
      } else {
        return getParentVars(this.rootVarDef);
      }
    },
    hasEnough: function hasEnough() {
      return this.stillNeedSelection && this.hasSelection || !this.stillNeedSelection;
    },
    selectButtonClasses: function selectButtonClasses() {
      return {
        'btn-default': this.hasEnough,
        'btn-primary': !this.hasEnough
      };
    },
    canCreate: function canCreate() {
      return this.createVarFunction !== null;
    }
  },
  methods: {
    reset: function reset() {
      this.$emit('input', []);
      this.$emit('reset');
    },
    _getVarNames: function _getVarNames(varIds) {
      var names = getNonConflictingVarNames(varIds, this.varMap);
      var varNameMap = this.varNameMap;
      if (!varNameMap) return names;
      return _.map(names, function (name, idx) {
        var varId = varIds[idx];
        return varNameMap.hasOwnProperty(varId) ? varNameMap[varId] : name;
      });
    },
    _selectVars: function _selectVars() {
      var modal = this.$refs.varList;
      modal.resetModal();
      modal.setTitle(this.varModalTitle);
      modal.setSelectable(true, this.multiSelect, this.selectLeaf, this.selectParent);
      var selectedVarIds = this.disabledVarsAreSelected ? _.concat(this.value, this.disabledVarIds) : this.value;

      var _c = this;

      modal.open(function () {
        return _c.rootVarDef;
      }, selectedVarIds, this.disabledVarIds);
    },
    _varsSelected: function _varsSelected(varIds) {
      var _this = this;

      var selectedVarIds = _.filter(varIds, function (varId) {
        return !_.includes(_this.disabledVarIds, varId);
      });

      if (!this.multiSelect && selectedVarIds.length > 1) selectedVarIds = [selectedVarIds[0]];
      this.$emit('input', selectedVarIds);
    }
  }
};