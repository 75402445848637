export var functionTypes = {
  TOOL: 'TOOL',
  MATH: 'MATH',
  SUB_WF: 'SUB_WF',
  OPT: 'OPT',
  DOE: 'DOE',
  MDA: 'MDA'
};
export var functionTypeTitles = {
  TOOL: 'Disciplinary Analysis Tool',
  MATH: 'Mathematical Function',
  SUB_WF: 'Sub Workflow',
  OPT: 'Optimizer',
  DOE: 'DOE Driver',
  MDA: 'Converger'
};
export var functionTypeTitlesPlural = {
  TOOL: 'Disciplinary Analysis Tools',
  MATH: 'Mathematical Functions',
  SUB_WF: 'Sub Workflows',
  OPT: 'Optimizers',
  DOE: 'DOE Drivers',
  MDA: 'Convergers'
};
export var shortFunctionTypeTitles = {
  TOOL: 'Tool',
  MATH: 'Math Block',
  SUB_WF: 'Sub Workflow',
  OPT: 'Optimizer',
  DOE: 'DOE',
  MDA: 'Converger'
};
export var shortFunctionTypeTitlesLC = {
  TOOL: 'tool',
  MATH: 'math block',
  SUB_WF: 'sub workflow',
  OPT: 'optimizer',
  DOE: 'DOE',
  MDA: 'converger'
};
export function isMdaoType(type) {
  return _.includes([functionTypes.OPT, functionTypes.DOE, functionTypes.MDA], type);
}
export var newFunctionDefObj = {
  id: -1,
  type: functionTypes.TOOL,
  title: ''
};