var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      ref: "modal",
      attrs: {
        title: "Workflow Statistics",
        "sub-title": _vm.title,
        "confirm-close": false,
        "has-buttons": false,
        draggable: true
      }
    },
    [
      _vm.hasBranches ? _c("h4", [_vm._v(_vm._s(_vm.branchTitle))]) : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-horizontal stats-form" },
        _vm._l(_vm.stats, function(stat) {
          return _c("div", { staticClass: "form-group" }, [
            _c("label", {
              staticClass: "col-sm-3 control-label",
              domProps: { innerHTML: _vm._s(stat.label) }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-9" }, [
              _c("div", {
                staticClass: "form-control-static",
                domProps: { innerHTML: _vm._s(stat.value) }
              })
            ])
          ])
        })
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }