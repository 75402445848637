/**
 * This file contains a utility functions for dealing with variables. In principle, variables are communicated from the
 * backend in the form of one root variable object, that then contains the descendant variables.
 *
 * Format of the variable definition object (varDef):
 * {
 *     id: 1, // Variable ID
 *     name: "var_name",
 *     attrib: { // Object containing attributes
 *         key: "value",
 *         ...
 *     },
 *     svm: { // Object containing sub value matchers
 *         xpath: "value",
 *         ...
 *     },
 *     routing: [ // Optional routing (connection to functions) defintions
 *         {
 *             out: [], // List of function IDs outputting this variable
 *             in: [], // List of function IDs needing this variable as input
 *         },
 *         ...
 *     ],
 *     children: [], // List of child vars
 * }
 */
import _ from './lodash.custom.js'; // Same as mdax.core.elements.variables.variable.VariableElement.MULTI_FILE_ROOT

export var MULTI_FILE_ROOT = '__multi_file__';
export var MULTI_FILE_NEW_ROOT_ID = -4; // api.py::APIWebSocketController.MULTI_FILE_NEW_ROOT_ID

export function isMultiFileRoot(varDef) {
  return varDef.name === MULTI_FILE_ROOT || varDef.id === MULTI_FILE_NEW_ROOT_ID;
}
/**
 * @returns {varDefType}
 */

export function getNewMultiFileRootVarDef() {
  return {
    id: MULTI_FILE_NEW_ROOT_ID,
    name: MULTI_FILE_ROOT,
    attrib: {},
    xpath: '',
    children: [],
    col: false,
    route_col: false,
    routing: []
  };
}
/**
 * @typedef {varDefType} extendedVarDefType
 * @property {extendedVarDefType} [parent]
 */

/**
 * @typedef {object.<number, extendedVarDefType>} flatVarMap
 */

/**
 * @callback filterPredicateType
 * @param {varDefType} varDef
 */

/**
 * Function that takes a root var definition (see var-list.vue for format) and returns a dict of vars, transform ids
 * to varDef objects.
 *
 * @param {varDefType} rootVarDef
 * @returns {flatVarMap}
 */

export function getFlatVars(rootVarDef) {
  function getFlatVars(varDef, parentDef) {
    if (!varDef) return [];
    varDef.parent = parentDef;
    var varDefs = [varDef];
    if (varDef.children.length == 0) return varDefs;
    return _.concat(varDefs, _.flatten(_.map(varDef.children, function (child) {
      return getFlatVars(child, varDef);
    })));
  }

  return _.fromPairs(_.map(getFlatVars(_.cloneDeep(rootVarDef), null), function (varDef) {
    return [varDef.id, varDef];
  }));
}
/**
 * Function that takes a root var definition (see var-list.vue for format) and returns a dict of leaf vars (vars
 * without children), transform ids to varDef objects.
 *
 * @param {varDefType} rootVarDef
 * @returns {flatVarMap}
 */

export function getLeafVars(rootVarDef) {
  return _.pickBy(getFlatVars(rootVarDef), function (varDef) {
    return varDef.children.length == 0;
  });
}
/**
 * Function that takes a root var definition (see var-list.vue for format) and returns a dict of parent vars (vars
 * with children), transform ids to varDef objects.
 *
 * @param {varDefType} rootVarDef
 * @returns {flatVarMap}
 */

export function getParentVars(rootVarDef) {
  return _.pickBy(getFlatVars(rootVarDef), function (varDef) {
    return varDef.children.length > 0;
  });
}
/**
 * Function that takes a list of variable IDs and the list of leaf vars, and returns a list with non-conflicting
 * variable names for each of the provided variable IDs.
 *
 * @param {number[]} varIds
 * @param {flatVarMap} flatVars
 * @returns {string[]}
 */

export function getNonConflictingVarNames(varIds, flatVars) {
  var uniqVarIds = _.uniq(varIds);

  var varDefs = _.filter(_.map(uniqVarIds, function (varId) {
    return varId in flatVars ? flatVars[varId] : null;
  }));

  function getXPathPart(varDef) {
    var part = varDef.name;

    var partAttr = _.concat(_.map(varDef.attrib, function (value, key) {
      return '@' + key + '=' + value;
    }), _.map(varDef.svm, function (value, key) {
      return value ? key + '=' + value : key;
    }));

    if (partAttr.length > 0) {
      part += '[' + partAttr.join(',') + ']';
    }

    return part;
  }

  var varNames = _.map(varDefs, function (varDef) {
    return getXPathPart(varDef);
  });

  var backLevels = _.map(varNames, function () {
    return 0;
  }); // Functions to prepend an ancestor to the "XPath" of a variable


  function getAncestorVar(varDef, nLevels) {
    if (nLevels == 0) return varDef;
    if (varDef.parent === null) return false;
    return getAncestorVar(varDef.parent, nLevels - 1);
  }

  function addBackLevel(idx) {
    var ancestorVarDef = getAncestorVar(varDefs[idx], ++backLevels[idx]);
    if (!ancestorVarDef) return false;
    varNames[idx] = getXPathPart(ancestorVarDef) + '/' + varNames[idx];
  } // Extend variables names back (from the end of the XPath) until no two names are the same


  var uniqueNames;
  var iTry = 0;

  while ((uniqueNames = _.uniq(varNames)).length != varNames.length) {
    _.forEach(uniqueNames, function (name) {
      // Find all occurences of name in varNames
      // https://stackoverflow.com/a/20798567
      var idxName = [];
      var i = -1;

      while ((i = varNames.indexOf(name, i + 1)) != -1) {
        idxName.push(i);
      }

      if (idxName.length <= 1) return;

      _.forEach(idxName, function (idx) {
        return addBackLevel(idx);
      });
    });

    if (iTry > 100) break;
    iTry++;
  } // Return all names of all initually provided var IDs


  return _.map(varIds, function (varId) {
    var varName = null;

    _.forEach(varDefs, function (varDef, idx) {
      if (varName !== null) return;

      if (varDef.id == varId) {
        varName = varNames[idx];
      }
    });

    return varName;
  });
}
/**
 * Filters a variable tree by only selecting vars where predicate returns a truthy value. Predicate receives a varDef
 * object. Branches where no leaf vars remain are purged. If allVars is set to true, all vars are being checked by the
 * predicate. The original rootVarDef object is not modified. The function returns null if no matching vars are found.
 *
 * If the predicate returns null, its inclusion is based on whether any children are selected.
 *
 * @param {varDefType} rootVarDef
 * @param {filterPredicateType} predicate
 * @param {boolean} [allVars]
 * @param {boolean} [matchedIncludeChildren]
 * @param {boolean} [includeDescendants]
 * @returns {?varDefType}
 */

function filterTree(rootVarDef, predicate, allVars, matchedIncludeChildren, includeDescendants) {
  if (!rootVarDef) return null;

  function filterVarDef(varDef) {
    // If this is a leaf var, return the varDef object is predicate is truthy, otherwise null
    if (varDef.children.length == 0) return predicate(varDef) ? _.clone(varDef) : null; // If we have to check all vars, return null immediately if predicate is not truthy

    var include = null;

    if (allVars) {
      include = predicate(varDef);
      if (include === false) return null;
    } // Filter the child vars


    var children = [];

    if (include === true && includeDescendants) {
      children = varDef.children;
    } else if (!matchedIncludeChildren || include === null) {
      children = _.filter(_.map(varDef.children, function (childVarDef) {
        return filterVarDef(childVarDef);
      }));
    } // If no child vars remain, it means we also want to remove the parent, so return null


    if (include === null && children.length == 0) return null;
    varDef = _.clone(varDef);
    varDef.children = children;
    return varDef;
  }

  return filterVarDef(rootVarDef);
}
/**
 * Finds leaf vars that are input to and/or output from a specific function.
 *
 * If functionId is not specified, it will match all functions. functionId can also be a list.
 *
 * @param {varDefType} rootVarDef
 * @param {?(number|number[])} functionId
 * @param {?boolean} checkIn
 * @param {?boolean} checkOut
 * @param {?boolean} checkOriginalConn
 * @returns {?varDefType}
 */


export function filterTreeByFunctionIO(rootVarDef, functionId, checkIn, checkOut, checkOriginalConn) {
  var emptyFunctionId = !functionId;

  var isArray = _.isArray(functionId);

  function matchesFunctionIds(functionIds) {
    if (emptyFunctionId) {
      return functionIds.length > 0;
    } else if (isArray) {
      return _.findIndex(functionIds, function (fId) {
        return _.includes(functionId, fId);
      }) != -1;
    } else {
      return _.includes(functionIds, functionId);
    }
  }

  return filterTree(rootVarDef, function (varDef) {
    if (checkOriginalConn) {
      // Check original connections
      var funCon = varDef.fun_con;
      if (!funCon) return false;
      var matchesIn = checkIn !== null ? matchesFunctionIds(funCon.in) : null;
      var matchesOut = checkOut !== null ? matchesFunctionIds(funCon.out) : null;
      return matchesIn === checkIn && matchesOut === checkOut;
    } else {
      // Check current variable routing
      var varRouting = varDef.routing;
      if (!varRouting || varRouting.length == 0) return false; // Check all routes, if one of them links to this function, we match the variable

      return _.findIndex(varRouting, function (varRoute) {
        var matchesIn = checkIn !== null ? matchesFunctionIds(varRoute.in) : null;
        var matchesOut = checkOut !== null ? matchesFunctionIds(varRoute.out) : null;
        return matchesIn === checkIn && matchesOut === checkOut;
      }) != -1;
    }
  });
}
/**
 * Finds leaf vars that are coupling variables between the given functions.
 *
 * @param {varDefType} rootVarDef
 * @param {number[]} functionIds
 * @param {filterPredicateType} [additionalPredicate]
 * @returns {?varDefType}
 */

export function filterTreeByFunctionCoupling(rootVarDef, functionIds, additionalPredicate) {
  return filterTree(rootVarDef, function (varDef) {
    var varRouting = varDef.routing;
    if (!varRouting || varRouting.length == 0) return false;
    var isInput = _.findIndex(varRouting, function (varRoute) {
      return _.findIndex(functionIds, function (fId) {
        return _.includes(varRoute.in, fId);
      }) != -1;
    }) != -1;
    var isOutput = _.findIndex(varRouting, function (varRoute) {
      return _.findIndex(functionIds, function (fId) {
        return _.includes(varRoute.out, fId);
      }) != -1;
    }) != -1;
    var select = isInput && isOutput;

    if (select && additionalPredicate) {
      select = additionalPredicate(varDef);
    }

    return select;
  });
}
/**
 * Filters the tree to only keep variables that are internal to the provided functions. Internal means that the variable
 * can be passed between the functions, but is not output to any tool from these functions.
 *
 * @param {varDefType} rootVarDef
 * @param {number[]} functionIds
 * @returns {?varDefType}
 */

export function filterTreeInternalVars(rootVarDef, functionIds) {
  return filterTreeByFunctionCoupling(rootVarDef, functionIds, function (varDef) {
    var varRouting = varDef.routing;

    var routingFromFunctions = _.filter(varRouting, function (varRoute) {
      return _.findIndex(functionIds, function (fId) {
        return _.includes(varRoute.out, fId);
      }) != -1;
    });

    var targetFunctionIds = _.filter(_.flatten(_.map(routingFromFunctions, function (varRoute) {
      return varRoute.in;
    })), function (fId) {
      return !_.includes(functionIds, fId);
    }); // If there are any target functions other than the coupled functions, it is not an internal variable


    return targetFunctionIds.length == 0;
  });
}
/**
 * Filters the tree to only include the trees containing the given variable IDs.
 *
 * @param {varDefType} rootVarDef
 * @param varIds
 * @param {boolean} [includeDescendants]
 */

export function filterByVarIds(rootVarDef, varIds, includeDescendants) {
  return filterTree(rootVarDef, function (varDef) {
    return _.includes(varIds, varDef.id) ? true : null;
  }, true, true, includeDescendants);
}
/**
 * Get a list of variable IDs that are originally connected to a function.
 *
 * @param rootVarDef
 */

export function getConnectedVarIds(rootVarDef) {
  var unconnectedTree = filterTree(rootVarDef, function (varDef) {
    return varDef.fun_con.in.length > 0 || varDef.fun_con.out.length > 0;
  });
  return _.map(getFlatVars(unconnectedTree), function (varDef) {
    return varDef.id;
  });
}
/**
 * @callback walkTreeCallbackType
 * @param {varDefType} varDef
 */

/**
 * Call a function for every item in the tree.
 *
 * @param {varDefType} rootVarDef
 * @param {walkTreeCallbackType} callback
 */

export function walkVarTree(rootVarDef, callback) {
  function recursiveWalk(varDef) {
    callback(varDef);

    _.forEach(varDef.children, recursiveWalk);
  }

  recursiveWalk(rootVarDef);
}
/**
 * Walk over all leaf vars.
 *
 * @param {varDefType} rootVarDef
 * @param {walkTreeCallbackType} callback
 */

export function walkLeafVars(rootVarDef, callback) {
  if (!rootVarDef) return;
  walkVarTree(rootVarDef, function (varDef) {
    if (varDef.children.length == 0) callback(varDef);
  });
}
/**
 * Map the leaf vars.
 *
 * @param {varDefType} rootVarDef
 * @param {walkTreeCallbackType} predicate
 */

export function mapLeafVars(rootVarDef, predicate) {
  var mapped = [];
  walkLeafVars(rootVarDef, function (varDef) {
    return mapped.push(predicate(varDef));
  });
  return mapped;
}
/**
 * @callback walkVarTreeRoutingCallbackType
 * @param {varDefType} varDef
 * @param {?number} outFunctionId
 * @param {?number} inFunctionId
 */

/**
 * Walk over all routing connections for all variables.
 *
 * @param {varDefType} rootVarDef
 * @param {walkVarTreeRoutingCallbackType} callback
 * @param {boolean} includingUnconnected
 */

export function walkVarTreeRouting(rootVarDef, callback, includingUnconnected) {
  function getFunctionIds(idsList) {
    if (includingUnconnected && idsList.length == 0) return [null];
    return idsList;
  }

  walkLeafVars(rootVarDef, function (varDef) {
    // Loop over routes
    _.forEach(varDef.routing, function (varRoute) {
      // Loop over outputting functions
      _.forEach(getFunctionIds(varRoute.out), function (outFunctionId) {
        // Loop over inputting functions
        _.forEach(getFunctionIds(varRoute.in), function (inFunctionId) {
          callback(varDef, outFunctionId, inFunctionId);
        });
      });
    });
  });
}
/**
 * Find a varDef object by id.
 *
 * @param {varDefType} rootVarDef
 * @param {number} varId
 * @returns {?varDefType}
 */

export function findVarById(rootVarDef, varId) {
  function getMatchedVarDef(varDef) {
    if (varDef.id == varId) return varDef;

    for (var i = 0; i < varDef.children.length; i++) {
      var matchedVarDef = getMatchedVarDef(varDef.children[i]);
      if (matchedVarDef) return matchedVarDef;
    }

    return null;
  }

  return getMatchedVarDef(rootVarDef, varId) || null;
}
/**
 * Check if there is a collision in the function connection of this variable.
 *
 * @param {varDefType} varDef
 * @returns boolean
 */

export function hasCollision(varDef) {
  return varDef.col;
}
/**
 * Check if there is a collision in the routing of this variable.
 *
 * @param {varDefType} varDef
 * @returns boolean
 */

export function hasRoutingCollision(varDef) {
  return varDef.route_col;
}
/**
 * Check if this var or any of the descendant vars have a collision.
 *
 * @param {varDefType} varDef
 * @returns boolean
 */

export function recursiveHasCollision(varDef) {
  if (varDef.children.length == 0) return hasCollision(varDef);
  return _.findIndex(varDef.children, function (childVarDef) {
    return recursiveHasCollision(childVarDef);
  }) != -1;
}
/**
 * Check if this var or any of the descendant vars have a routing collision.
 *
 * @param {varDefType} varDef
 * @returns boolean
 */

export function recursiveHasRoutingCollision(varDef) {
  if (varDef.children.length == 0) return hasRoutingCollision(varDef);
  return _.findIndex(varDef.children, function (childVarDef) {
    return recursiveHasRoutingCollision(childVarDef);
  }) != -1;
}