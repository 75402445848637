import Vue from 'vue';
import { dispatcher } from "./index";

function Dispatcher() {
  var _this = this;

  // https://vuejs.org/v2/guide/migration.html#dispatch-and-broadcast-replaced
  var vueInstance = new Vue({
    data: {
      isAuth: true,
      hasScope: true
    },
    computed: {
      selectedScopeName: function selectedScopeName() {
        return this.hasScope !== null && this.hasScope !== true ? this.hasScope : null;
      }
    }
  });
  this.zIndex = 1000;

  this.getIncreaseZIndex = function () {
    var zIndex = _this.zIndex;
    _this.zIndex += 10;
    return zIndex;
  };

  this.loading = false;

  this.isLoading = function (isLoading) {
    _this.loading = isLoading;
    vueInstance.$emit('is-loading', isLoading);
  };

  this.onIsLoading = function (listener) {
    return vueInstance.$on('is-loading', listener);
  };

  this.offIsLoading = function (listener) {
    return vueInstance.$off('is-loading', listener);
  };

  this.notify = function (msg, type) {
    return vueInstance.$emit('notify', msg, type);
  };

  this.onNotify = function (listener) {
    return vueInstance.$on('notify', listener);
  };

  this.offNotify = function (listener) {
    return vueInstance.$off('nofity', listener);
  };

  this.error = function (msg) {
    return vueInstance.$emit('error', msg);
  };

  this.onError = function (listener) {
    return vueInstance.$on('error', listener);
  };

  this.offError = function (listener) {
    return vueInstance.$off('error', listener);
  };

  this.success = function (msg) {
    return vueInstance.$emit('success', msg);
  };

  this.onSuccess = function (listener) {
    return vueInstance.$on('success', listener);
  };

  this.offSuccess = function (listener) {
    return vueInstance.$off('success', listener);
  };

  this.openHelp = function () {
    return vueInstance.$emit('open-help');
  };

  this.onOpenHelp = function (listener) {
    return vueInstance.$on('open-help', listener);
  };

  this.offOpenHelp = function (listener) {
    return vueInstance.$off('open-help', listener);
  };

  this.addHelpConfig = function (config) {
    return vueInstance.$emit('add-help-config', config);
  };

  this.onAddHelpConfig = function (listener) {
    return vueInstance.$on('add-help-config', listener);
  };

  this.offAddHelpConfig = function (listener) {
    return vueInstance.$off('add-help-config', listener);
  };

  this.popHelpConfig = function () {
    return vueInstance.$emit('pop-help-config');
  };

  this.onPopHelpConfig = function (listener) {
    return vueInstance.$on('pop-help-config', listener);
  };

  this.offPopHelpConfig = function (listener) {
    return vueInstance.$off('pop-help-config', listener);
  };

  this.addEscapeHandler = function (escapeKeyHandler, handlerId) {
    return vueInstance.$emit('add-modal', escapeKeyHandler, handlerId);
  };

  this.onAddEscapeHandler = function (listener) {
    return vueInstance.$on('add-modal', listener);
  };

  this.offAddEscapeHandler = function (listener) {
    return vueInstance.$off('add-modal', listener);
  };

  this.popEscapeHandler = function (handlerId) {
    return vueInstance.$emit('pop-modal', handlerId);
  };

  this.onPopEscapeHander = function (listener) {
    return vueInstance.$on('pop-modal', listener);
  };

  this.offPopEscapeHandler = function (listener) {
    return vueInstance.$off('pop-modal', listener);
  };

  this.updateGraph = function (projectId, branchId) {
    return vueInstance.$emit('update-graph', projectId, branchId);
  };

  this.onUpdateGraph = function (listener) {
    return vueInstance.$on('update-graph', listener);
  };

  this.offUpdateGraph = function (listener) {
    return vueInstance.$off('update-graph', listener);
  };

  this.setInBatchGraphUpdate = function (inGraphUpdate) {
    return vueInstance.$emit('in-batch-graph-update', inGraphUpdate);
  };

  this.onSetInBatchGraphUpdate = function (listener) {
    return vueInstance.$on('in-batch-graph-update', listener);
  };

  this.offSetInBatchGraphUpdate = function (listener) {
    return vueInstance.$off('in-batch-graph-update', listener);
  };

  this.updatedGraph = function (projectId, branchId, graphDef) {
    return vueInstance.$emit('updated-graph', projectId, branchId, graphDef);
  };

  this.onUpdatedGraph = function (listener) {
    return vueInstance.$on('updated-graph', listener);
  };

  this.offUpdatedGraph = function (listener) {
    return vueInstance.$off('updated-graph', listener);
  };

  this.updatedRootVarDef = function (rootVarDef) {
    return vueInstance.$emit('updated-root-var-def', rootVarDef);
  };

  this.onUpdatedRootVarDef = function (listener) {
    return vueInstance.$on('updated-root-var-def', listener);
  };

  this.offUpdatedRootVarDef = function (listener) {
    return vueInstance.$off('updated-root-var-def', listener);
  };

  this.updatedProject = function (project) {
    return vueInstance.$emit('updated-project', project);
  };

  this.onUpdatedProject = function (listener) {
    return vueInstance.$on('updated-project', listener);
  };

  this.offUpdatedProject = function (listener) {
    return vueInstance.$off('updated-project', listener);
  };
  /**
   * @typedef {object} actionType
   * @property {number} id
   * @property {string} title
   */

  /**
   * @param {actionType} action
   */


  this.pushAction = function (action) {
    return vueInstance.$emit('push-action', action);
  };

  this.onPushAction = function (listener) {
    return vueInstance.$on('push-action', listener);
  };

  this.offPushAction = function (listener) {
    return vueInstance.$off('push-action', listener);
  };

  this.backendStateUpdated = function () {
    return vueInstance.$emit('backend-state-updated');
  };

  this.onBackendStateUpdated = function (listener) {
    return vueInstance.$on('backend-state-updated', listener);
  };

  this.offBackendStateUpdated = function (listener) {
    return vueInstance.$off('backend-state-updated', listener);
  };

  this.branchesEdited = function () {
    return vueInstance.$emit('branches-edited');
  };

  this.onBranchesEdited = function (listener) {
    return vueInstance.$on('branches-edited', listener);
  };

  this.offBranchesEdited = function (listener) {
    return vueInstance.$off('branches-edited', listener);
  };

  this.isAuth = vueInstance.isAuth;
  this.hasScope = vueInstance.hasScope;

  this.setIsAuth = function (isAuth) {
    return _this.isAuth = isAuth;
  };

  this.setHasScope = function (hasScope) {
    return _this.hasScope = hasScope;
  };
}

export default Dispatcher;