//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from './lodash.custom.js';
import uiModal from './ui-items/ui-modal.vue';
import uploader from './ui-items/uploader.vue';
import rceExportSettings from './ui-items/rce-export-settings.vue';
import { api, dispatcher, staticBaseUrl, ioMode, previewMode } from './index.js';
export default {
  name: "export-modal",
  components: {
    uiModal: uiModal,
    uploader: uploader,
    rceExportSettings: rceExportSettings
  },
  props: {
    hasBranches: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      api: api,
      dispatcher: dispatcher,
      staticBaseUrl: staticBaseUrl,
      ioMode: ioMode,
      previewMode: previewMode,
      project: null,
      branchId: null
    };
  },
  computed: {
    projectTitle: function projectTitle() {
      return this.project ? this.project.title : '';
    },
    branch: function branch() {
      if (!this.project) return null;
      var branchId = this.branchId;
      return _.find(this.project.branches, function (branchDef) {
        return branchDef.id == branchId;
      });
    },
    projectId: function projectId() {
      return this.project.id;
    },
    branchTitle: function branchTitle() {
      return this.branch ? this.branch.title : '';
    },
    title: function title() {
      return 'Export: ' + this.projectTitle;
    },
    subTitle: function subTitle() {
      return this.hasBranches ? this.branchTitle : null;
    }
  },
  methods: {
    open: function open(project, branchId) {
      this.project = project;
      this.branchId = branchId;
      this.$refs.modal.open();
    },
    close: function close() {
      this.$refs.modal.close();
    },
    _doExport: function _doExport(type) {
      if (type === 'rce') {
        this.$refs.rceSettings.open();
      } else {
        this._emitExport(type, {});
      }
    },
    _rceSelect: function _rceSelect(settings) {
      this._emitExport('rce', settings);
    },
    _emitExport: function _emitExport(type, settings) {
      this.$emit('export', this.project, this.branchId, type, settings);
    }
  },
  created: function created() {
    this.staticBaseUrl = staticBaseUrl;
  }
};