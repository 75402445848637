var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      ref: "modal",
      attrs: {
        title: "Variable Routing Settings",
        "action-title": "Apply",
        "confirm-close": false,
        action: _vm._submit,
        "action-disabled": _vm.previewMode,
        "has-buttons": _vm.editable
      },
      on: { opened: _vm._opened }
    },
    [
      _c("div", { staticClass: "form-horizontal settings-form" }, [
        _c(
          "div",
          { staticClass: "routing", class: { editable: _vm.editable } },
          [
            _c("div", { staticClass: "instructions" }, [
              _vm._v(
                "\n                Automated variable routing methods solve "
              ),
              _c("b", [_vm._v("collissions")]),
              _vm._v(
                ": two or more functions writing to the\n                same variable.\n            "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-options" }, [
              _c(
                "div",
                { staticClass: "options" },
                _vm._l(_vm.shownAutoRoutingMethods, function(method) {
                  return _c("div", [
                    _c(
                      "label",
                      {
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.routingMethod = method.key
                          }
                        }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.routingMethod,
                              expression: "routingMethod"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            name: "auto-routing-method",
                            disabled: !_vm.editable
                          },
                          domProps: {
                            value: method.key,
                            checked: _vm._q(_vm.routingMethod, method.key)
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                            },
                            change: function($event) {
                              _vm.routingMethod = method.key
                            }
                          }
                        }),
                        _vm._v(
                          "\n                            " +
                            _vm._s(method.title) +
                            "\n                            "
                        ),
                        method.description
                          ? _c("div", {
                              staticClass: "description",
                              domProps: {
                                innerHTML: _vm._s(method.description)
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  ])
                })
              ),
              _vm._v(" "),
              _c("div", { staticClass: "preview" }, [
                _c("h4", [_vm._v("Example Result")]),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: _vm.currentPreviewImgUrl,
                    alt: _vm.routingMethod
                  }
                })
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _vm.customCollRes.length > 0
        ? _c(
            "div",
            { staticClass: "custom-routing" },
            [
              _c("h3", { staticClass: "text-center" }, [
                _vm._v("Custom Variable Routing")
              ]),
              _vm._v(" "),
              _c("vue-good-table", {
                staticClass: "table-wide-modal",
                attrs: {
                  columns: _vm.customCollResColumns,
                  rows: _vm.customCollResData,
                  "search-options": _vm.searchOptions,
                  "sort-options": _vm.sortOptions,
                  "style-class": "table table-striped table-hover table-sm"
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-row",
                    fn: function(props) {
                      return [
                        props.column.field == "actions"
                          ? _c("span", { staticClass: "row-actions" }, [
                              _c("span", {
                                staticClass: "fas fa-eye link",
                                attrs: { title: "View variable info" },
                                on: {
                                  click: function($event) {
                                    _vm._viewVar(props.row.varId)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.editable
                                ? _c("span", {
                                    staticClass: "fas fa-edit link",
                                    attrs: { title: "Edit variable routing" },
                                    on: {
                                      click: function($event) {
                                        _vm._editRouting(props.row.varId)
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.editable
                                ? _c("span", {
                                    staticClass: "far fa-trash-alt link",
                                    attrs: {
                                      title: "Reset custom variable routing"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm._removeCollRes(props.row.idx)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    props.formattedRow[props.column.field]
                                  ) +
                                  "\n                "
                              )
                            ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }