//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../lodash.custom.js';
import uiModal from './ui-modal.vue';
import { api, projectFileMode, ioMode } from '../index.js';
export default {
  name: "workflow-settings",
  components: {
    uiModal: uiModal
  },
  props: {
    hasBranches: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      api: api,
      projectFileMode: projectFileMode,
      ioMode: ioMode,
      project: {},
      callbackFunction: null,
      editable: true,
      isValid: true
    };
  },
  computed: {
    branchId: function branchId() {
      return this.project ? this.project.branch_id : null;
    },
    branch: function branch() {
      var branchId = this.branchId;
      return _.find(this.project.branches, function (branchDef) {
        return branchDef.id == branchId;
      }) || null;
    },
    title: function title() {
      return this.projectFileMode ? 'Project Settings' : 'Workflow Settings';
    }
  },
  methods: {
    open: function open(project, branchEditable, callback) {
      this.project = _.cloneDeep(project);
      this.callbackFunction = callback || null;
      this.editable = branchEditable;
      this.isValid = true;
      this.$refs.modal.open();
    },
    close: function close() {
      this.$refs.modal.close();
    },
    _opened: function _opened() {
      this.$emit('opened');
    },
    _submit: function _submit() {
      var _c = this;

      this.$validator.validateAll('settings').then(function (valid) {
        if (!valid) {
          _c.isValid = false;
          return;
        }

        _c.isValid = true;
        var project = _c.project;

        _c.api.editProjectSettings(project.title, project.branch_title, project.auto_sort_func, function (project) {
          if (_c.callbackFunction) _c.callbackFunction(project);

          _c.close();
        });
      }); // Prevent closing of modal

      return false;
    }
  }
};