//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../lodash.custom.js';
import { api, dispatcher } from '../index';
import Dropzone from 'vue-clip/node_modules/dropzone';
var ERROR_TO_MANY_FILES = 'ERROR_TOO_MANY_FILES';
export default {
  name: "uploader",
  props: {
    nFiles: {
      type: Number,
      default: 1
    },
    // -1 for no limit
    nFilesMin: {
      type: Number,
      default: -1
    },
    externalIsValid: {
      type: Boolean,
      default: true
    },
    // One-way: just to keep the container aware on the uploaded files
    fileNames: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    dropZoneMessage: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      api: api,
      dispatcher: dispatcher,
      uploadValidated: false,
      clipUploader: null,
      dropzoneObj: null
    };
  },
  watch: {
    uploadedFiles: function uploadedFiles() {
      this._updateExternalFiles();
    }
  },
  computed: {
    uploadSettings: function uploadSettings() {
      return {
        url: this.api.fileUploadURL,
        uploadMultiple: false,
        paramName: 'files',
        maxFiles: this.nFiles == -1 ? null : this.nFiles,
        dictMaxFilesExceeded: ERROR_TO_MANY_FILES
      };
    },
    files: function files() {
      return this.clipUploader ? this.clipUploader.files : [];
    },
    uploadingFiles: function uploadingFiles() {
      return _.filter(this.files, function (file) {
        return _.includes([Dropzone.ADDED, Dropzone.QUEUED, Dropzone.UPLOADING], file.status);
      });
    },
    uploadedFiles: function uploadedFiles() {
      return _.filter(this.files, function (file) {
        return file.status == Dropzone.SUCCESS;
      });
    },
    uploadedFileNames: function uploadedFileNames() {
      return _.map(this.uploadedFiles, function (file) {
        return file.name;
      });
    },
    dropMessage: function dropMessage() {
      if (this.dropZoneMessage) return this.dropZoneMessage;
      var file = this.nFiles == 1 ? 'file' : 'files';
      return "Click or drag ".concat(file, " here to upload");
    },
    hasFile: function hasFile() {
      return this.uploadedFiles.length > 0;
    },
    minNumberFiles: function minNumberFiles() {
      return this.nFilesMin == -1 ? this.nFiles : this.nFilesMin;
    },
    hasEnoughFiles: function hasEnoughFiles() {
      return this.uploadedFiles.length >= this.minNumberFiles;
    },
    hasAllFiles: function hasAllFiles() {
      return this.nFiles == -1 ? false : this.uploadedFiles.length >= this.nFiles;
    },
    uploadValid: function uploadValid() {
      return this.hasEnoughFiles && this.externalIsValid || !this.uploadValidated;
    },
    isUploading: function isUploading() {
      return this.uploadingFiles.length != 0;
    }
  },
  methods: {
    reset: function reset() {
      this.api.resetUpload();
      this.clipUploader.removeAllFiles();
      this.uploadValidated = false;
    },
    validate: function validate() {
      this.uploadValidated = true;
      return this.uploadValid;
    },
    _removeFile: function _removeFile(file) {
      this.clipUploader.removeFile(file);
    },
    _fileRemoved: function _fileRemoved(file) {
      // Remove file from backend
      var dropzoneFile = file._file;

      if (dropzoneFile.accepted && dropzoneFile.status == Dropzone.SUCCESS) {
        this.api.removeUploadedFile(file.id);
      }
    },
    _fileAdded: function _fileAdded(file) {
      // Assign an ID for identification between frontend and backend
      file.id = Math.random().toString(36).slice(2);
    },
    _sending: function _sending(file, xhr) {
      // Add the socket.IO session ID (as this is not automatically added since files are uploaded using AJAX)
      xhr.setRequestHeader('X-Socket-IO-SID', this.api.sessionId()); // Add the file ID

      xhr.setRequestHeader('X-File-ID', file.id);
    },
    _fileUploadComplete: function _fileUploadComplete(file, status) {
      // Display error message, except if this was a "too many files" error
      if (status == 'error' && file.errorMessage != ERROR_TO_MANY_FILES) {
        this.dispatcher.error(file.errorMessage);
      }
    },
    _updateExternalFiles: function _updateExternalFiles() {
      this.$emit('update:fileNames', this.uploadedFileNames);
    }
  },
  mounted: function mounted() {
    this.clipUploader = this.$refs.clipUploader;
    this.dropzoneObj = this.clipUploader.uploader._uploader;

    this._updateExternalFiles();
  }
};