//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../lodash.custom.js';
export default {
  name: "issues-list",
  props: {
    /* [{
            text: "Issue text",
            status: "critical|neutral|completed",
            [clickable]: true|false,
            [tooltip]: "tooltip text",
       }, ...] */
    issues: {
      type: Array,
      required: true
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    issuesList: function issuesList() {
      var clickable = this.clickable;
      return _.map(this.issues, function (issue) {
        var issueObj = _.cloneDeep(issue);

        if (_.isString(issueObj)) {
          issueObj = {
            text: issueObj,
            status: 'neutral'
          };
        }

        if (issueObj.status == '') {
          issueObj.status = 'neutral';
        }

        if (!issueObj.hasOwnProperty('clickable')) {
          issueObj.clickable = clickable;
        }

        if (!issueObj.hasOwnProperty('tooltip')) {
          issueObj.tooltip = '';
        }

        return issueObj;
      });
    }
  },
  methods: {
    _issueItemClasses: function _issueItemClasses(issue) {
      var classes = [issue.status];

      if (issue.clickable) {
        classes.push('clickable');
      }

      return classes;
    },
    _click: function _click(idx) {
      if (this.issuesList[idx].clickable) {
        this.$emit('click', idx);
      }
    }
  }
};