var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      ref: "modal",
      attrs: {
        title: "Batch Add Disciplinary Analysis Tools",
        wide: true,
        action: _vm._submit,
        "action-title": "Add Tools",
        "action-disabled": _vm.actionDisabled,
        "action-error": !_vm.isValid
      },
      on: { opened: _vm._opened, closed: _vm._closed }
    },
    [
      _vm.useAvailableFiles && !_vm.hasAvailableFiles
        ? _c("div", { staticClass: "instructions" }, [
            _vm._v(
              "\n        No disciplines and I/O files available in " +
                _vm._s(_vm.remoteName) +
                ", batch import not available.\n    "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.useAvailableFiles
        ? _c("div", { staticClass: "instructions" }, [
            _vm._v(
              "\n        Upload the XML files specifying the input and output of the disciplinary analysis tools."
            ),
            _c("br"),
            _vm._v(
              '\n        The input files should contain "input" in its file name, the output files should contain "output".\n    '
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.useAvailableFiles
        ? _c("uploader", {
            ref: "uploader",
            staticClass: "uploader",
            attrs: {
              "n-files": -1,
              "n-files-min": 2,
              "external-is-valid": _vm.isValid,
              "drop-zone-message":
                "Upload required XML files: click or drag here"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.hasEnoughFiles
        ? _c("div", { staticClass: "instructions middle" }, [
            _vm._v(
              "\n        Use the table below to link the uploaded input/output files to tools to be created."
            ),
            _c("br"),
            _vm._v(
              "\n        It is possible to create tools wich only have either input or output.\n    "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasEnoughFiles
        ? _c("select-table", {
            staticClass: "tool-def-table",
            attrs: { data: _vm.toolDefs, columns: _vm.columns },
            on: { delete: _vm._deleteToolDef, add: _vm._addToolDef },
            scopedSlots: _vm._u([
              {
                key: "add-name",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        class: { "has-error": _vm.errors.has("tool.name") }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.toolName,
                              expression: "toolName"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "form-control",
                          staticStyle: { width: "400px" },
                          attrs: {
                            type: "text",
                            name: "name",
                            placeholder: "Tool name",
                            "data-vv-validate-on": "change|input|blur",
                            "data-vv-scope": "tool"
                          },
                          domProps: { value: _vm.toolName },
                          on: {
                            keyup: function($event) {
                              if (
                                !("button" in $event) &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm._addToolDef($event)
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.toolName = $event.target.value
                            }
                          }
                        })
                      ]
                    )
                  ]
                }
              },
              {
                key: "add-inputFileId",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        class: {
                          "has-error": _vm.errors.has("tool.inputFileId")
                        }
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.toolInputFileId,
                                expression: "toolInputFileId"
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: _vm.inputFileRules,
                                expression: "inputFileRules"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              name: "inputFileId",
                              "data-vv-validate-on": "change|input|blur",
                              "data-vv-scope": "tool"
                            },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.toolInputFileId = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          _vm._l(_vm.fileSelectOptions, function(f) {
                            return _c("option", { domProps: { value: f.id } }, [
                              _vm._v(_vm._s(f.name))
                            ])
                          })
                        )
                      ]
                    )
                  ]
                }
              },
              {
                key: "add-outputFileId",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        class: {
                          "has-error": _vm.errors.has("tool.outputFileId")
                        }
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.toolOutputFileId,
                                expression: "toolOutputFileId"
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: _vm.outputFileRules,
                                expression: "outputFileRules"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              name: "outputFileId",
                              "data-vv-validate-on": "change|input|blur",
                              "data-vv-scope": "tool"
                            },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.toolOutputFileId = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          _vm._l(_vm.fileSelectOptions, function(f) {
                            return _c("option", { domProps: { value: f.id } }, [
                              _vm._v(_vm._s(f.name))
                            ])
                          })
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }