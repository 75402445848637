import _ from './lodash.custom';
/**
 * Center fit a panzoom-enabled object in its container.
 * Code based on panzoom.autocenter()
 *
 * @param {HTMLElement} containerEl
 * @param {HTMLElement} objectEl
 * @param panzoomController
 * @param zoomAbsFunc
 * @param {boolean} leftAlign
 */

export function panzoomCenterFit(containerEl, objectEl, panzoomController, zoomAbsFunc, leftAlign) {
  // Get container dimensions
  var bbox = containerEl.getBoundingClientRect();
  if (!bbox) return;
  var cw = bbox.width;
  var ch = bbox.height; // Get zoom/pan object dimensions

  bbox = objectEl.getBoundingClientRect();
  if (!bbox) return;
  var tr = panzoomController.getTransform();
  var w = bbox.width / tr.scale;
  var h = bbox.height / tr.scale;
  if (w == 0 || h == 0) return; // Calculate pan position and scale

  var dh = ch / h;
  var dw = cw / w;
  var scale = Math.min(dw, dh);
  if (scale > 1) scale = 1;
  var x = leftAlign ? 0 : -(w / 2) * scale + cw / 2;
  var y = -(h / 2) * scale + ch / 2;

  if (zoomAbsFunc) {
    zoomAbsFunc(scale);
  } else {
    panzoomController.zoomAbs(0, 0, scale);
  }

  panzoomController.moveTo(x, y);
}
/**
 * Gets a graph definition from a dictionary of project and branch graphs, and optionally a list of function IDs for
 * sub-graphs.
 *
 * @param graphs
 * @param projectId
 * @param branchId
 * @param subWfFunctionIds
 * @returns graphDefType|null
 */

export function getGraphDef(graphs, projectId, branchId, subWfFunctionIds) {
  if (!graphs.hasOwnProperty(projectId)) return null;
  if (!graphs[projectId].hasOwnProperty(branchId)) return null;
  /** @type {graphDefType} branchGraph **/

  var branchGraph = graphs[projectId][branchId]; // Get graph of (recursive) sub-workflow functions if requested

  var subWfFunctions = getSubWorkflowFunctions(graphs, projectId, branchId, subWfFunctionIds);
  if (subWfFunctions.length > 0) return subWfFunctions[subWfFunctions.length - 1].graph;
  return branchGraph;
}
/**
 * Gets the recursive function definitions representing sub-workflows.
 *
 * @param graphs
 * @param projectId
 * @param branchId
 * @param subWfFunctionIds
 * @returns {functionDefType[]}
 */

export function getSubWorkflowFunctions(graphs, projectId, branchId, subWfFunctionIds) {
  if (!graphs.hasOwnProperty(projectId)) return [];
  if (!graphs[projectId].hasOwnProperty(branchId)) return [];
  /** @type {graphDefType} branchGraph **/

  var branchGraph = graphs[projectId][branchId];
  if (!subWfFunctionIds || subWfFunctionIds.length == 0) return [];
  /**
   * @param {graphDefType} graph
   * @param functionIds
   */

  function getNextFunctions(graph, functionIds) {
    if (functionIds.length == 0) return [];
    /** @type {functionDefType} functionDef **/

    var functionDef = _.find(graph.functions, function (funcDef) {
      return funcDef.id == functionIds[0];
    });

    if (!functionDef || !functionDef.graph) return [];
    return _.concat([functionDef], getNextFunctions(functionDef.graph, functionIds.slice(1)));
  }

  return getNextFunctions(branchGraph, subWfFunctionIds);
}