var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        action: _vm._apply,
        "action-title": "Apply",
        "action-disabled": !_vm.isValid,
        classes: "routing-select-modal",
        wide: "60%"
      },
      on: {
        opened: _vm._opened,
        closed: _vm._closed,
        change: _vm._modalChanged
      }
    },
    [
      _c("div", { staticClass: "xpath" }, [
        _vm._v(_vm._s(_vm._f("xpath")(_vm.xpath)))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "instructions" }, [
        _vm._v(
          "\n        Connect functions providing (left) to functions needing (right) this variable:\n        "
        ),
        _c("ul", [
          _c("li", [
            _vm._v(
              "Click on a providing, then on a needing function to create a connection"
            )
          ]),
          _vm._v(" "),
          _c("li", [_vm._v("Click on an arrow to remove the connection")])
        ]),
        _vm._v("\n\n        Connection rules:\n        "),
        _c("ul", [
          _c("li", [_vm._v("Functions are ordered by process order")]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "Outputs may not be provided to functions after another connection"
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v("Feedback connections may not cross a loop boundary")
          ]),
          _vm._v(" "),
          _c("li", [_vm._v("Self-loops are not allowed")]),
          _vm._v(" "),
          _c("li", [_vm._v("All needing functions must be connected")])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { ref: "arrowsContainer", staticClass: "functions no-select" },
        [
          _c("arrows", {
            staticClass: "arrows",
            attrs: {
              arrows: _vm.arrows,
              "marker-mid": true,
              clickable: true,
              "click-title": "Remove connection"
            },
            on: { click: _vm._arrowClick }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "indicator left" }, [
            _c(
              "div",
              { staticClass: "center-rotated" },
              [
                _c("h4", [_vm._v("Feedback")]),
                _vm._v(" "),
                _c("arrows", {
                  staticClass: "indicator-arrows",
                  attrs: { arrows: ["M0,15 L148,15"] }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "indicator right" }, [
            _c(
              "div",
              { staticClass: "center-rotated" },
              [
                _c("h4", [_vm._v("Feedforward")]),
                _vm._v(" "),
                _c("arrows", {
                  staticClass: "indicator-arrows",
                  attrs: { arrows: ["M0,15 L148,15"] }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-wrapper" }, [
            _c("table", { staticClass: "routing-table" }, [
              _c(
                "tbody",
                [
                  _c("tr", { staticClass: "head" }, [
                    _c("td", [_c("h3", [_vm._v("Provided By")])]),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c("td", { staticClass: "right" }, [
                      _c("h3", [_vm._v("Needed By")])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.functionSlots, function(functionSlot, idx) {
                    return _c("tr", [
                      _c(
                        "td",
                        {
                          ref: "outSlot",
                          refInFor: true,
                          staticClass: "function clickable",
                          class: { selected: _vm.outSelectedIdx === idx }
                        },
                        [
                          functionSlot.out
                            ? _c("xdsm-function", {
                                attrs: { "function-def": functionSlot.out },
                                nativeOn: {
                                  mousedown: function($event) {
                                    $event.stopPropagation()
                                    _vm._selectOut(idx)
                                  },
                                  click: function($event) {
                                    $event.stopPropagation()
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td"),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          ref: "inSlot",
                          refInFor: true,
                          staticClass: "function right",
                          class: {
                            "not-connected":
                              !_vm.isOutSelected &&
                              !_vm.inFunctionSelected[idx],
                            "clickable selectable":
                              _vm.inFunctionSelectable[idx]
                          }
                        },
                        [
                          functionSlot.in
                            ? _c("xdsm-function", {
                                attrs: { "function-def": functionSlot.in },
                                nativeOn: {
                                  mouseup: function($event) {
                                    $event.stopPropagation()
                                    _vm._clickIn(idx)
                                  },
                                  click: function($event) {
                                    $event.stopPropagation()
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  })
                ],
                2
              )
            ])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }