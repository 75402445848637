var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "xdsm-preview-modal",
    {
      ref: "modal",
      staticClass: "preview-modal",
      attrs: {
        "graph-def": _vm.graphDef,
        title: "Automatically Remove Unnecessary Functions",
        "settings-title": "Settings",
        action: _vm._apply,
        "action-title": "Apply",
        "action-disabled": _vm.actionDisabled,
        "function-classes": _vm.functionClasses
      },
      on: { closing: _vm.closing }
    },
    [
      _c("h3", [_vm._v("Output variables to keep")]),
      _vm._v(" "),
      _c("select-vars", {
        attrs: {
          "root-var-def": _vm.rootVarDefOutputVars,
          "graph-def": _vm.graphDef,
          "var-modal-title": "Select Output Variables to Keep"
        },
        model: {
          value: _vm.selectedVarIds,
          callback: function($$v) {
            _vm.selectedVarIds = $$v
          },
          expression: "selectedVarIds"
        }
      }),
      _vm._v(" "),
      _c("h3", [_vm._v("Functions to keep")]),
      _vm._v(" "),
      _c("select-functions", {
        attrs: { functions: _vm.functions },
        model: {
          value: _vm.selectedFunctionIds,
          callback: function($$v) {
            _vm.selectedFunctionIds = $$v
          },
          expression: "selectedFunctionIds"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }