var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      ref: "modal",
      staticClass: "import-modal",
      attrs: {
        title: "Import Projects",
        action: _vm._doImport,
        "action-title": "Import",
        "confirm-close": _vm.hasFiles,
        "action-error": !_vm.isValid
      },
      on: { opened: _vm._opened }
    },
    [
      _c("div", { staticClass: "instructions" }, [
        _vm._v(
          "\n            Select files to import. Supported formats are:\n            "
        ),
        _c("ul", [_c("li", [_vm._v("*.mdax: MDAx projects")])])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-horizontal" }, [
        _c(
          "div",
          {
            staticClass: "form-group uploader",
            class: { "has-error": !_vm.uploaderIsValid }
          },
          [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("uploader", {
                  ref: "uploader",
                  attrs: {
                    "n-files": -1,
                    "n-files-min": 1,
                    "file-names": _vm.fileNames
                  },
                  on: {
                    "update:fileNames": function($event) {
                      _vm.fileNames = $event
                    }
                  }
                })
              ],
              1
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }