/**
 * Component mixin to provide function definition syncing between the function-modal component and sub-forms.
 *
 * Sub-forms should perform all operations on the "def" data item.
 * Any custom behavior for preparing the function definition object for editing should happen in the
 * _customPrepareFunctionDef method.
 */
export default {
  props: {
    functionDef: {
      type: Object,
      required: true
    },
    isNew: {
      type: Boolean,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    },
    rootVarDef: {
      type: Object,
      default: function _default() {
        return null;
      }
    },
    graphDef: {
      type: Object,
      default: function _default() {
        return null;
      }
    }
  },
  data: function data() {
    return {
      def: {},
      watchDef: false
    };
  },
  watch: {
    functionDef: {
      handler: function handler(functionDef) {
        var _this = this;

        // Disable watching for changes in def (to prevent a infinite loop)
        this.watchDef = false;
        this.def = this._prepareFunctionDef(functionDef);
        setTimeout(function () {
          return _this.watchDef = true;
        }, 0);
      },
      deep: true
    },
    def: {
      handler: function handler(functionDef) {
        this._defUpdated(functionDef);

        if (!this.watchDef) return; // Send the update signal to the parent component

        this.$emit('update:functionDef', functionDef);
      },
      deep: true
    }
  },
  computed: {
    type: function type() {
      return this.def.type;
    }
  },
  methods: {
    validateAll: function validateAll(scope) {
      return this.$validator.validateAll(scope);
    },
    _prepareFunctionDef: function _prepareFunctionDef(externalFunctionDef) {
      var functionDef = _.cloneDeep(externalFunctionDef);

      this._customPrepareFunctionDef(functionDef);

      return functionDef;
    },
    // Override for custom behavior
    _customPrepareFunctionDef: function _customPrepareFunctionDef(functionDef) {},
    _defUpdated: function _defUpdated(functionDef) {},
    _forceUpdateDef: function _forceUpdateDef() {
      this.$emit('update:functionDef', this.def);
    }
  },
  created: function created() {
    this.def = this._prepareFunctionDef(this.functionDef);
  },
  mounted: function mounted() {
    this.watchDef = true;
  }
};