//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { functionTypes } from "../mdao/functions";
export default {
  name: "xdsm-function",
  props: {
    functionDef: {
      type: Object,
      required: true
    },
    // Format as defined in xdsm.vue
    dragHandle: {
      type: Boolean,
      default: false
    },
    deletable: {
      type: Boolean,
      default: false
    },
    deleteClass: {
      type: String,
      default: 'far fa-trash-alt'
    },
    deleteTitle: {
      type: String,
      default: null
    },
    collapsible: {
      type: Boolean,
      default: false
    },
    showIssues: {
      type: Boolean,
      default: false
    },
    showEnter: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes: function classes() {
      var classes = [];
      if (this.isRounded) classes.push('rounded');
      if (this.processTitle) classes.push('has-process-title');
      if (this.deletable) classes.push('is-deletable');
      if (this.hasIssues) classes.push('has-issues');
      return classes;
    },
    styles: function styles() {
      var f = this.functionDef;
      var styles = {};

      if (f.hasOwnProperty('bg') && f.bg) {
        styles['background'] = f.bg;
      }

      return styles;
    },
    isRounded: function isRounded() {
      return this.functionDef.hasOwnProperty('rounded') && this.functionDef.rounded;
    },
    processTitle: function processTitle() {
      return this.functionDef.hasOwnProperty('process_title') && this.functionDef.process_title ? this.functionDef.process_title : null;
    },
    title: function title() {
      return this.functionDef.title;
    },
    functionId: function functionId() {
      return this.functionDef.id;
    },
    hasCollapseButton: function hasCollapseButton() {
      return this.collapsible;
    },
    isCollapsed: function isCollapsed() {
      return this.functionDef.collapsed;
    },
    collapseClass: function collapseClass() {
      return this.isCollapsed ? 'fas fa-expand' : 'fas fa-compress';
    },
    collapseTitle: function collapseTitle() {
      return this.isCollapsed ? 'Expand nesting level' : 'Collapse nesting level';
    },
    enterable: function enterable() {
      return this.showEnter && this.functionDef.type == functionTypes.SUB_WF;
    },
    hasIssues: function hasIssues() {
      return this.showIssues && this.functionDef.issues;
    }
  },
  methods: {
    _doDelete: function _doDelete() {
      this.$emit('delete', this.functionDef);
    },
    _collapse: function _collapse() {
      this.$emit('collapse', this.functionId, !this.isCollapsed);
    },
    _enter: function _enter() {
      this.$emit('enter', this.functionId);
    }
  }
};