var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-popover",
    {
      attrs: {
        open: _vm.linkedIsOpen,
        placement: _vm.placement,
        "popover-base-class": _vm.baseClass,
        "popover-inner-class": "ui-tooltip-inner",
        "popover-arrow-class": "ui-tooltip-arrow",
        autoHide: true,
        offset: _vm.offset
      },
      on: { "update:open": _vm._setOpen }
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("template", { slot: "popover" }, [_vm._t("popover")], 2)
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }