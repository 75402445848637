//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../../lodash.custom.js';
import xdsmFunction from './xdsm-function.vue';
export default {
  name: "function-drag",
  components: {
    xdsmFunction: xdsmFunction
  },
  props: {
    // [{id: functionId, wraps: <sub-nesting-list>}, ...]
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // {id: {top: top, left: left}, ...}
    parentFunctionId: {
      default: null
    },
    functionPos: {
      type: Object,
      require: true
    },
    functionClasses: {
      type: Object,
      default: function _default() {}
    },
    dropDisabled: {
      type: Boolean,
      default: false
    },
    functionClickable: {
      type: Boolean,
      default: true
    },
    functionDeletable: {
      type: Boolean,
      default: false
    },
    sortableIds: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    sortTargetIds: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    containerEl: {
      type: HTMLElement
    },
    moveEl: {
      type: HTMLElement
    },
    scale: {
      required: true
    },
    collapsibleMap: {
      default: function _default() {
        return {};
      }
    },
    collapsedMap: {
      default: function _default() {
        return {};
      }
    },
    showIssues: {
      type: Boolean,
      default: false
    },
    showEnter: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      options: {
        handle: '.drag-handle',
        scroll: false,
        ghostClass: 'ghost'
      },
      draggingId: null,
      localDroppingDisabled: false,
      subDropDisabled: false,
      handleClicked: false,
      sortAngle: 45 * Math.PI / 180
    };
  },
  watch: {
    enableSortHandle: function enableSortHandle() {
      this._setDraggable();
    }
  },
  computed: {
    isDragging: function isDragging() {
      return this.draggingId !== null;
    },
    droppingDisabled: function droppingDisabled() {
      var generallyDisabled = this.dropDisabled || this.localDroppingDisabled;
      var sortTargetIds = this.sortTargetIds;
      var isDragging = this.isDragging;
      return _.zipObject(_.map(this.value, function (v) {
        return v.id;
      }), _.map(this.value, function (nestingSpec) {
        // Check if dropping is generally disabled
        if (generallyDisabled) return true; // Check if we are currently dragging

        if (!isDragging) return false; // Check if dropping is disabled if the drop target IDs have been specified

        if (sortTargetIds.length > 0) {
          if (!_.includes(sortTargetIds, nestingSpec.id)) return true;
        }

        return false;
      }));
    },
    enableSort: function enableSort() {
      return this.value && this.value.length > 1;
    },
    enableSortHandle: function enableSortHandle() {
      return this.enableSort && (this.handleClicked || !this.functionClickable);
    },
    enableFunctionSort: function enableFunctionSort() {
      var enableSorting = this.enableSort;
      var sortableIds = this.sortableIds;
      var value = this.value || [];
      return _.zipObject(_.map(value, function (v) {
        return v.id;
      }), _.map(value, function (nestingSpec) {
        // Check if sorting is generally enabled
        if (!enableSorting) return false; // Check if we can sort if the sortable function IDs have been specified

        if (sortableIds.length > 0) {
          if (!_.includes(sortableIds, nestingSpec.id)) return false;
        }

        return true;
      }));
    },
    flatSortedIds: function flatSortedIds() {
      // Flatten the nesting array (which determines the function order)
      if (!this.value) return [];

      function getFlatIds(nestedIds) {
        var flatIds = [];

        _.forEach(nestedIds, function (nestingData) {
          flatIds.push(nestingData.id);

          _.forEach(getFlatIds(nestingData.wraps), function (id) {
            return flatIds.push(id);
          });
        });

        return flatIds;
      }

      return getFlatIds(this.value);
    },
    hasPos: function hasPos() {
      var functionPos = this.functionPos;
      return _.map(this.value, function (nestingSpec) {
        return functionPos && functionPos.hasOwnProperty(nestingSpec.id) && functionPos[nestingSpec.id];
      });
    },
    blockWrapperStyle: function blockWrapperStyle() {
      var functionPos = this.functionPos;
      var parentId = this.parentFunctionId;
      return _.fromPairs(_.map(this.value, function (nestingSpec) {
        var id = nestingSpec.id;
        var pos = functionPos[id];
        if (!pos) return {};
        var left = pos.left;
        var top = pos.top;

        if (parentId) {
          var relPos = functionPos[parentId];

          if (relPos) {
            left -= relPos.left;
            top -= relPos.top;
          }
        }

        return [id, {
          transform: 'translate(' + left + 'px, ' + top + 'px)'
        }];
      }));
    },
    dropTargetStyle: function dropTargetStyle() {
      var nesting = this.value;
      var functionPos = this.functionPos;
      return _.fromPairs(_.map(nesting, function (nestingSpec) {
        var id = nestingSpec.id;
        if (!functionPos[id]) return [id, null];
        var _functionPos$id = functionPos[id],
            width = _functionPos$id.width,
            height = _functionPos$id.height;
        return [id, {
          height: height + 'px',
          width: width + 'px'
        }];
      }));
    },
    sortCoord: function sortCoord() {
      var sortAngle = this.sortAngle;
      var leftAmount = Math.sin(sortAngle);
      var topAmount = Math.cos(sortAngle);
      return function (left, top) {
        return left * leftAmount + top * topAmount;
      };
    },
    functionBlockPos: function functionBlockPos() {
      var sortCoord = this.sortCoord;

      function getLastWrappedNesting(nestingSpec) {
        if (nestingSpec.wraps.length == 0 || nestingSpec.f.collapsed) return nestingSpec;
        return getLastWrappedNesting(_.last(nestingSpec.wraps));
      }

      var functionPos = this.functionPos;
      return _.filter(_.map(this.value, function (nestingSpec) {
        var id = nestingSpec.id;
        if (!functionPos[id]) return;
        var _functionPos$id2 = functionPos[id],
            left = _functionPos$id2.left,
            top = _functionPos$id2.top; // If this function wraps other functions, find the last wrapped block

        var lastNesting = getLastWrappedNesting(nestingSpec);

        if (lastNesting.id != id) {
          if (!functionPos[lastNesting.id]) return;
          var _functionPos$lastNest = functionPos[lastNesting.id],
              lastLeft = _functionPos$lastNest.left,
              lastTop = _functionPos$lastNest.top;
          left = .5 * (left + lastLeft);
          top = .5 * (top + lastTop);
        }

        var dragCoord = sortCoord(left, top);
        return {
          id: id,
          left: left,
          top: top,
          dragCoord: dragCoord
        };
      }));
    }
  },
  methods: {
    pausePanZoom: function pausePanZoom() {
      this.$emit('pausePanZoom');
    },
    _subInput: function _subInput(idx, value) {
      this.value[idx].wraps = value;
      this.$emit('input', this.value);
    },
    _sendInput: function _sendInput(movedId, targetIdx) {
      var _this = this;

      var movedIdx = _.findIndex(this.value, function (nestingSpec) {
        return nestingSpec.id == movedId;
      });

      if (movedIdx == -1) return;
      var movedItem = this.value[movedIdx];
      if (targetIdx == -1) targetIdx = this.value.length;
      if (targetIdx > movedIdx) targetIdx--;
      if (targetIdx == movedIdx) return;

      var newNesting = _.filter(this.value, function (nestingSpec) {
        return nestingSpec.id != movedId;
      });

      newNesting.splice(targetIdx, 0, movedItem);
      this.$emit('input', newNesting);
      setTimeout(function () {
        return _this.$emit('sort');
      }, 0);
    },
    _setDropDisabled: function _setDropDisabled(disabled) {
      this.localDroppingDisabled = disabled;
      this.$emit('setDropDisabled', disabled);
    },
    _handleMouseDown: function _handleMouseDown() {
      this.handleClicked = true;
      if (!this.enableSortHandle) return;

      this._setDraggable();
    },
    _onDragStart: function _onDragStart(e, id) {
      this.draggingId = id;
      this.$emit('dragStart');
      this.subDropDisabled = true; // Need to set data, otherwise Firefox won't allow dragging

      e.dataTransfer.setData('text/plain', id); // Set the transfer "image" to the element and set the anchor position to match the drag handle

      var imgX = 0,
          imgY = 0;

      if (this.functionPos.hasOwnProperty(id)) {
        var pos = this.functionPos[id];
        imgX = pos.width - 10;
        imgY = 10; // Additional offset for rounded corners

        var nestingSpec = _.find(this.value, function (nestingSpec) {
          return nestingSpec.id == id;
        });

        if (nestingSpec && nestingSpec.f && nestingSpec.f.hasOwnProperty('rounded') && nestingSpec.f.rounded) {
          imgX -= 4;
          imgY += 4;
        }
      }

      e.dataTransfer.setDragImage(e.target.parentNode, imgX, imgY); // Register event listeners

      this.containerEl.addEventListener('dragover', this._onDragOver);
      this.containerEl.addEventListener('drop', this._onDrop);
    },
    _onDragEnd: function _onDragEnd() {
      this.draggingId = null;
      this.$emit('dragStop');
      this.subDropDisabled = false;
      this.handleClicked = false; // Disable dragging on the draggable elements

      this._setDraggable(); // Remove event listeners


      this.containerEl.removeEventListener('dragover', this._onDragOver);
      this.containerEl.removeEventListener('drop', this._onDrop);
    },
    _onDragOver: function _onDragOver(e) {
      if (!this.isDragging) return; // Allow dropping
      // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#droptargets

      e.preventDefault();
    },
    _onDrop: function _onDrop(e) {
      if (!this.isDragging) return;
      e.stopPropagation();
      e.preventDefault(); // Get drop location relative to XDSM

      var scale = this.scale;
      var bbox = this.moveEl.getBoundingClientRect();
      var x = (e.clientX - bbox.left) / scale;
      var y = (e.clientY - bbox.top) / scale;
      var sortCoord = this.sortCoord(x, y); // Find the target index

      var targetIdx = _.findIndex(_.sortBy(this.functionBlockPos, function (pos) {
        return pos.dragCoord;
      }), function (pos) {
        return pos.dragCoord > sortCoord;
      });

      this._sendInput(this.draggingId, targetIdx);
    },
    _setDraggable: function _setDraggable() {
      var draggable = this.$refs.draggable;
      if (!draggable) return;
      var enableSort = this.enableSortHandle;

      if (enableSort) {
        _.forEach(draggable, function (el) {
          el.setAttribute('draggable', 'true');
        });
      } else {
        _.forEach(draggable, function (el) {
          el.setAttribute('draggable', 'false');
        });
      }
    },
    _collapse: function _collapse(functionId, collapsed) {
      this.$emit('collapse', functionId, collapsed);
    },
    _enter: function _enter(functionId) {
      this.$emit('enter', functionId);
    }
  },
  mounted: function mounted() {
    this._setDraggable();
  }
};