var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.xdsmId, staticClass: "xdsm-positioner no-select" },
    [
      _c(
        "div",
        {
          staticClass: "xdsm-container",
          class: { "pan-zoom": _vm.enablePanZoom }
        },
        [
          _c(
            "div",
            {
              ref: "moveObject",
              staticClass: "move-container",
              style: { visibility: _vm.visibility }
            },
            [
              _vm.isSortable && _vm.functions && _vm.containerEl
                ? _c("function-drag", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showDragFunctions,
                        expression: "showDragFunctions"
                      }
                    ],
                    attrs: {
                      "function-pos": _vm.functionPos,
                      "function-classes": _vm.xdsmFunctionClasses,
                      "function-clickable": _vm.clickable,
                      "function-deletable": _vm.deletable,
                      "sortable-ids": _vm.sortableIds,
                      "sort-target-ids": _vm.sortTargetIds,
                      "container-el": _vm.containerEl,
                      "move-el": _vm.moveEl,
                      scale: _vm.localZoom,
                      "collapsible-map": _vm.collapsibleMap,
                      "collapsed-map": _vm.collapsedMap,
                      "show-issues": _vm.showIssues,
                      "show-enter": _vm.showEnter
                    },
                    on: {
                      sort: _vm._sorted,
                      collapse: _vm._collapse,
                      enter: _vm._enter,
                      functionClick: function($event) {
                        _vm.$emit("functionClick", $event)
                      },
                      pausePanZoom: _vm.pausePanZoom,
                      delete: function($event) {
                        _vm.$emit("functionDelete", $event)
                      }
                    },
                    model: {
                      value: _vm.localNesting,
                      callback: function($$v) {
                        _vm.localNesting = $$v
                      },
                      expression: "localNesting"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isWrapping
                ? _c("wrapping-select", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showDragFunctions,
                        expression: "showDragFunctions"
                      }
                    ],
                    staticClass: "wrapping-select",
                    attrs: {
                      value: _vm.lastWrappedFunctionId,
                      "wrapping-function-id": _vm.wrappingFunctionId,
                      "function-pos": _vm.functionPos,
                      "orig-function-wrapping": _vm.consistentWrappingNesting,
                      scale: _vm.localZoom,
                      "wrapped-function-ids": _vm.wrappedFunctionIds
                    },
                    on: {
                      input: function($event) {
                        _vm.$emit("update:lastWrappedFunctionId", $event)
                      },
                      "update:wrappedFunctionIds": function($event) {
                        _vm.$emit("update:wrappedFunctionIds", $event)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.sortedFunctionsRender
                ? _c("table", { staticClass: "xdsm-table" }, [
                    _c(
                      "tbody",
                      _vm._l(_vm.sortedFunctionsRender, function(f, i) {
                        return _c(
                          "tr",
                          { class: { "row-hidden": f.rowHidden } },
                          _vm._l(f.outArray, function(data, j) {
                            return _c(
                              "td",
                              {
                                class: { "col-hidden": data.colHidden },
                                style: data.cellStyle
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "cell-wrapper" },
                                  [
                                    _vm._l(data.divs, function(div) {
                                      return _c("div", {
                                        class: div.class,
                                        on: {
                                          click: function($event) {
                                            div.data
                                              ? _vm._dataLineClick(
                                                  $event,
                                                  div.i,
                                                  div.j
                                                )
                                              : null
                                          }
                                        }
                                      })
                                    }),
                                    _vm._v(" "),
                                    !f.isCoordinator || j != 0
                                      ? _c(
                                          "div",
                                          { staticClass: "content-wrapper" },
                                          [
                                            i == j && !f.isAddButton
                                              ? _c("xdsm-function", {
                                                  directives: [
                                                    {
                                                      name: "no-pan",
                                                      rawName: "v-no-pan",
                                                      value: _vm.clickable,
                                                      expression: "clickable"
                                                    }
                                                  ],
                                                  key: f.id,
                                                  ref: "f" + f.id,
                                                  refInFor: true,
                                                  class: f.class,
                                                  style: {
                                                    visibility: _vm.hideOriginalFunctionBlocks
                                                      ? "hidden"
                                                      : ""
                                                  },
                                                  attrs: {
                                                    "function-def": f,
                                                    deletable: _vm.deletable,
                                                    collapsible:
                                                      _vm.collapsibleMap[f.id],
                                                    "show-issues":
                                                      _vm.showIssues,
                                                    "show-enter": _vm.showEnter
                                                  },
                                                  on: {
                                                    delete: function($event) {
                                                      _vm.$emit(
                                                        "functionDelete",
                                                        $event
                                                      )
                                                    },
                                                    collapse: _vm._collapse,
                                                    enter: _vm._enter
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      _vm.$emit(
                                                        "functionClick",
                                                        f.id
                                                      )
                                                    }
                                                  }
                                                })
                                              : i == j && f.isAddButton
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "add-button",
                                                      attrs: {
                                                        title:
                                                          "Add new analysis function"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.$emit("addClick")
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "fas fa-plus"
                                                      })
                                                    ]
                                                  )
                                                : data.hasDataConn
                                                  ? _c("xdsm-data", {
                                                      directives: [
                                                        {
                                                          name: "no-pan",
                                                          rawName: "v-no-pan",
                                                          value: _vm.clickable,
                                                          expression:
                                                            "clickable"
                                                        }
                                                      ],
                                                      key: f.id + "_" + j,
                                                      class: data.class,
                                                      attrs: {
                                                        label: data.label,
                                                        external:
                                                          i == 0 || j == 0
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm._dataBlockClick(
                                                            f.id,
                                                            data.fInId
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ]
                            )
                          })
                        )
                      })
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }