function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../../lodash.custom.js';
import xdsmPreviewModal from './xdsm-preview-modal.vue';
import { api, dispatcher, previewMode } from '../../index.js';
var autoSortMethods = null;
var ORIGINAL_ORDER_KEY = 'ORIGINAL_FUNCTION_ORDER';
export default {
  name: "auto-sort",
  components: {
    xdsmPreviewModal: xdsmPreviewModal
  },
  data: function data() {
    return {
      api: api,
      dispatcher: dispatcher,
      previewMode: previewMode,
      graphDef: null,

      /** @type {sortMethodType[]} */
      autoSortMethods: null,
      selectedMethod: null,
      previewCache: {},
      lastPreviewFunctionOrder: null
    };
  },
  computed: {
    previewFunctionOrder: function previewFunctionOrder() {
      return this.previewCache.hasOwnProperty(this.selectedMethod) ? this.previewCache[this.selectedMethod] : this.lastPreviewFunctionOrder;
    },
    shownAutoSortMethods: function shownAutoSortMethods() {
      return _.concat([{
        key: ORIGINAL_ORDER_KEY,
        title: 'Current function order'
      }], this.autoSortMethods || []);
    },
    actionDisabled: function actionDisabled() {
      return this.selectedMethod == ORIGINAL_ORDER_KEY || this.previewMode;
    }
  },
  watch: {
    selectedMethod: function selectedMethod(_selectedMethod) {
      if (this.graphDef !== null) this._makeSureHasPreviewOrder(_selectedMethod);
    },
    previewFunctionOrder: function previewFunctionOrder(sortedFunctionIds) {
      this.lastPreviewFunctionOrder = sortedFunctionIds;
      if (this.graphDef !== null) this.graphDef.nesting = sortedFunctionIds;
    }
  },
  methods: {
    open: function open(graphDef) {
      this.lastPreviewFunctionOrder = null;
      this.previewCache = _defineProperty({}, ORIGINAL_ORDER_KEY, null);
      this.selectedMethod = ORIGINAL_ORDER_KEY;
      this.graphDef = _.cloneDeep(graphDef);
      this.$refs.modal.open();
      this.api.editResetAutoSortPreview();
    },
    close: function close() {
      this.$refs.modal.close();
    },
    closing: function closing() {
      this.graphDef = null;
    },
    _makeSureHasPreviewOrder: function _makeSureHasPreviewOrder(methodKey) {
      // Check cache
      if (this.previewCache.hasOwnProperty(methodKey)) return;

      var _c = this;

      this.api.editAutoSortPreview(methodKey, function (sortedFunctionIds) {
        _c.$set(_c.previewCache, methodKey, sortedFunctionIds);
      });
    },
    _apply: function _apply() {
      this.$refs.modal.setLoading(true);

      var _c = this;

      this.api.editAutoSort(this.selectedMethod, function (graphDef) {
        _c.$emit('update', graphDef);

        _c.close();
      });
      return false;
    }
  },
  created: function created() {
    if (autoSortMethods) {
      this.autoSortMethods = autoSortMethods;
    } else {
      var _c = this;

      this.api.getAutoSortMethods(function (methods) {
        autoSortMethods = methods;
        _c.autoSortMethods = methods;
      });
    }
  }
};