var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-good-table", {
    ref: "table",
    staticClass: "select-table-comp",
    attrs: {
      columns: _vm.vgtColumns,
      rows: _vm.vgtData,
      "search-options": _vm.searchOptions,
      "sort-options": _vm.sortOptions,
      "style-class": "table table-striped table-hover table-sm select-table"
    },
    scopedSlots: _vm._u([
      {
        key: "table-row",
        fn: function(props) {
          return [
            (!_vm.editing && props.row.actions == "add-form") ||
            (_vm.editing && props.row.originalIndex == _vm.editIdx)
              ? _c("div", [
                  props.column.field == "actions"
                    ? _c("span", { ref: "addRowActions" }, [
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editing,
                                expression: "editing"
                              }
                            ],
                            staticClass: "btn btn-primary icon",
                            attrs: { title: "Apply" },
                            on: {
                              click: function($event) {
                                _vm.$emit("update")
                              }
                            }
                          },
                          [_c("span", { staticClass: "fas fa-check" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editing,
                                expression: "editing"
                              }
                            ],
                            staticClass: "btn btn-default icon",
                            attrs: { title: "Cancel" },
                            on: { click: _vm._cancelEdit }
                          },
                          [_c("span", { staticClass: "fas fa-ban" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.editing,
                                expression: "!editing"
                              }
                            ],
                            staticClass: "btn icon",
                            class: {
                              "btn-default": !_vm.addActive,
                              "btn-primary": _vm.addActive
                            },
                            attrs: { title: "Add" },
                            on: {
                              click: function($event) {
                                _vm.$emit("add")
                              }
                            }
                          },
                          [_c("span", { staticClass: "fa fa-plus" })]
                        )
                      ])
                    : _c(
                        "span",
                        { key: "add-" + props.column.field },
                        [
                          _vm._t("add-" + props.column.field, null, {
                            props: props
                          })
                        ],
                        2
                      )
                ])
              : props.row.actions == "add-form"
                ? _c("div", { style: { height: _vm.addRowHeight + "px" } }, [
                    _vm._v("\n             \n        ")
                  ])
                : props.column.field == "actions"
                  ? _c("span", [
                      _vm.rowsAreEditable
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-default icon",
                              attrs: { title: "Edit" },
                              on: {
                                click: function($event) {
                                  _vm._setEdit(props.row.originalIndex)
                                }
                              }
                            },
                            [_c("span", { staticClass: "fas fa-edit" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.allowDuplicateRow
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-default icon",
                              attrs: { title: "Duplicate" },
                              on: {
                                click: function($event) {
                                  _vm.$emit(
                                    "duplicate",
                                    props.row.originalIndex
                                  )
                                }
                              }
                            },
                            [_c("span", { staticClass: "far fa-copy" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default icon",
                          attrs: { title: "Remove" },
                          on: {
                            click: function($event) {
                              _vm.$emit("delete", props.row.originalIndex)
                            }
                          }
                        },
                        [_c("span", { staticClass: "far fa-trash-alt" })]
                      )
                    ])
                  : _c(
                      "div",
                      {
                        staticClass: "data-content",
                        attrs: { title: "Click to edit" },
                        on: {
                          click: function($event) {
                            _vm._contentClick(props.row.originalIndex)
                          }
                        }
                      },
                      [
                        props.column.renderFn
                          ? _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  props.column.renderFn(props.row)
                                )
                              }
                            })
                          : _c("div", [
                              _vm._v(
                                _vm._s(
                                  props.formattedRow[props.column.field] || " "
                                )
                              )
                            ])
                      ]
                    )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }