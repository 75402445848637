//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * A selector element used for selecting functions from list of functions.
 *
 * Usage:
 * <select-functions v-model="selectedFunctionIds" :functions="functions"></select-functions>
 * where:
 * - functions is a list of objects describing the available functions: [{id: 1, title: "Function name"}, ...]
 * - selectedFunctionIds is a two-way-bound list of selected function IDs
 */
import _ from '../../lodash.custom.js';
import selectTable from './select-table.vue';
export default {
  name: "select-functions",
  components: {
    selectTable: selectTable
  },
  props: {
    functions: {
      type: Array,
      required: true
    },
    // [{id: 6, title: "Function name"}, ...]
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    } // [6, ...]

  },
  data: function data() {
    return {
      columns: [['title', 'Function']],
      selectedFunctionId: null
    };
  },
  watch: {
    functions: function functions() {
      this._initForm();
    },
    value: function value() {
      this._initForm();
    }
  },
  computed: {
    tableData: function tableData() {
      var functionsById = _.keyBy(this.functions, 'id');

      return _.map(this.value, function (id) {
        return functionsById[id];
      });
    },
    selectableFunctions: function selectableFunctions() {
      var _this = this;

      return _.filter(this.functions, function (f) {
        return !_.includes(_this.value, f.id);
      });
    },
    canSelect: function canSelect() {
      return this.selectableFunctions.length > 0;
    }
  },
  methods: {
    _initForm: function _initForm() {
      var selectable = this.selectableFunctions;

      if (selectable.length == 0) {
        this.selectedFunctionId = null;
      } else {
        this.selectedFunctionId = _.head(selectable).id;
      }
    },
    // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
    _deleteHandler: function _deleteHandler(idx) {
      this.$emit('input', _.filter(this.value, function (v, i) {
        return i != idx;
      }));
    },
    _addHandler: function _addHandler() {
      this.$emit('input', _.concat(this.value, this.selectedFunctionId));
    }
  },
  mounted: function mounted() {
    this._initForm();
  }
};