var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        action: _vm.action,
        "action-title": _vm.actionTitle,
        "action-disabled": _vm.actionDisabled,
        wide: true
      },
      on: {
        close: function($event) {
          _vm.$emit("closing")
        }
      }
    },
    [
      _c("div", { staticClass: "flex-wrapper" }, [
        _c("div", { staticClass: "settings" }, [
          _c("h3", [_vm._v(_vm._s(_vm.settingsTitle))]),
          _vm._v(" "),
          _c("div", { staticClass: "settings-content" }, [_vm._t("default")], 2)
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "preview" },
          [
            _c("h3", [_vm._v("Preview")]),
            _vm._v(" "),
            _vm.graphDef
              ? _c("xdsm", {
                  staticClass: "preview-xdsm",
                  class: { loading: _vm.loading },
                  attrs: {
                    "graph-def": _vm.graphDef,
                    "function-classes": _vm.functionClasses
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }