var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("select-table", {
    ref: "table",
    attrs: {
      columns: _vm.columns,
      data: _vm.data,
      editable: _vm.editable,
      "editable-rows": _vm.rowsEditable,
      "allow-duplicate-row": _vm.allowDuplicateRow
    },
    on: {
      delete: _vm._deleteHandler,
      edit: _vm._editHandler,
      update: _vm._updateHandler,
      add: _vm._addHandler,
      reset: _vm._resetHandler,
      duplicate: _vm._duplicateHandler
    },
    scopedSlots: _vm._u([
      {
        key: "add-name",
        fn: function(scope) {
          return [
            _c("select-var", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              ref: "selectVar",
              class: { "select-var-has-error": _vm.errors.has("var.ids") },
              attrs: {
                "root-var-def": _vm.rootVarDef,
                "select-leaf": _vm.selectLeaf,
                "select-parent": _vm.selectParent,
                "graph-def": _vm.graphDef,
                "still-need-selection": _vm.needsSelection,
                "disabled-var-ids": _vm.selectDisabledVarIds,
                "var-modal-title": _vm.varListModalTitle,
                editable: _vm.varEditable,
                "create-var-function": _vm.createVarFunction,
                "var-name-map": _vm.varNameMap,
                name: "ids",
                "data-vv-scope": "var"
              },
              on: { input: _vm._varsSelected },
              model: {
                value: _vm.selectedVarIds,
                callback: function($$v) {
                  _vm.selectedVarIds = $$v
                },
                expression: "selectedVarIds"
              }
            })
          ]
        }
      },
      {
        key: "add-constraint_type",
        fn: function(scope) {
          return [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.constraintType,
                      expression: "constraintType"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    keyup: function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm._addOrUpdate($event)
                    },
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.constraintType = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.constraintTypes, function(type) {
                  return _c("option", { domProps: { value: type } }, [
                    _vm._v(_vm._s(type))
                  ])
                })
              )
            ])
          ]
        }
      },
      {
        key: "add-constraint_ref",
        fn: function(scope) {
          return [
            _c(
              "div",
              {
                staticClass: "form-group",
                class: { "has-error": _vm._showError("g", "ref") }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.constraintRef,
                      expression: "constraintRef",
                      modifiers: { number: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: _vm.constraintRefRules,
                      expression: "constraintRefRules"
                    }
                  ],
                  ref: "refField",
                  staticClass: "form-control",
                  staticStyle: { width: "80px" },
                  attrs: {
                    type: "number",
                    step: "any",
                    name: "ref",
                    placeholder: "Ref",
                    "data-vv-scope": "g"
                  },
                  domProps: { value: _vm.constraintRef },
                  on: {
                    keyup: function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm._addOrUpdate($event)
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.constraintRef = _vm._n($event.target.value)
                    },
                    blur: function($event) {
                      _vm.$forceUpdate()
                    }
                  }
                })
              ]
            )
          ]
        }
      },
      {
        key: "add-lower_bound",
        fn: function(scope) {
          return [
            _c(
              "div",
              {
                staticClass: "form-group",
                class: { "has-error": _vm._showError("x", "lower") }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.lowerBound,
                      expression: "lowerBound",
                      modifiers: { number: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: _vm.lowerBoundRules,
                      expression: "lowerBoundRules"
                    }
                  ],
                  ref: "lower",
                  staticClass: "form-control",
                  staticStyle: { width: "80px" },
                  attrs: {
                    type: "number",
                    step: "any",
                    name: "lower",
                    placeholder: "Lower",
                    "data-vv-validate-on": _vm.validateEvents,
                    "data-vv-scope": "x"
                  },
                  domProps: { value: _vm.lowerBound },
                  on: {
                    keyup: function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm._addOrUpdate($event)
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.lowerBound = _vm._n($event.target.value)
                    },
                    blur: function($event) {
                      _vm.$forceUpdate()
                    }
                  }
                })
              ]
            )
          ]
        }
      },
      {
        key: "add-nominal",
        fn: function(scope) {
          return [
            _c(
              "div",
              {
                staticClass: "form-group",
                class: { "has-error": _vm._showError("x", "nom") }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.nominal,
                      expression: "nominal",
                      modifiers: { number: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: _vm.nominalRules,
                      expression: "nominalRules"
                    }
                  ],
                  ref: "nom",
                  staticClass: "form-control",
                  staticStyle: { width: "80px" },
                  attrs: {
                    type: "number",
                    step: "any",
                    name: "nom",
                    placeholder: "Nom",
                    "data-vv-validate-on": _vm.validateEvents,
                    "data-vv-scope": "x"
                  },
                  domProps: { value: _vm.nominal },
                  on: {
                    keyup: function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm._addOrUpdate($event)
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.nominal = _vm._n($event.target.value)
                    },
                    blur: function($event) {
                      _vm.$forceUpdate()
                    }
                  }
                })
              ]
            )
          ]
        }
      },
      {
        key: "add-upper_bound",
        fn: function(scope) {
          return [
            _c(
              "div",
              {
                staticClass: "form-group",
                class: { "has-error": _vm._showError("x", "upper") }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.upperBound,
                      expression: "upperBound",
                      modifiers: { number: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: _vm.upperBoundRules,
                      expression: "upperBoundRules"
                    }
                  ],
                  ref: "upper",
                  staticClass: "form-control",
                  staticStyle: { width: "80px" },
                  attrs: {
                    type: "number",
                    step: "any",
                    name: "upper",
                    placeholder: "Upper",
                    "data-vv-validate-on": _vm.validateEvents,
                    "data-vv-scope": "x"
                  },
                  domProps: { value: _vm.upperBound },
                  on: {
                    keyup: function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm._addOrUpdate($event)
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.upperBound = _vm._n($event.target.value)
                    },
                    blur: function($event) {
                      _vm.$forceUpdate()
                    }
                  }
                })
              ]
            )
          ]
        }
      },
      {
        key: "add-alias",
        fn: function(scope) {
          return [
            _c(
              "div",
              {
                staticClass: "form-group",
                class: { "has-error": _vm._showError("alias", "alias") }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.alias,
                      expression: "alias"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: _vm.aliasRules,
                      expression: "aliasRules"
                    }
                  ],
                  ref: "aliasField",
                  staticClass: "form-control",
                  staticStyle: { width: "200px" },
                  attrs: {
                    type: "text",
                    name: "alias",
                    placeholder: "Alias",
                    "data-vv-validate-on": _vm.validateEvents,
                    "data-vv-scope": "alias"
                  },
                  domProps: { value: _vm.alias },
                  on: {
                    keyup: function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm._addOrUpdate($event)
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.alias = $event.target.value
                    }
                  }
                })
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }