var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "logos", style: { "z-index": _vm.zIndex } },
    [
      _c("img", {
        class: { "large-logo": _vm.previewMode },
        attrs: {
          src: _vm.previewMode
            ? _vm._getImgSrc("MDAxLightLogo.png")
            : _vm._getImgSrc("mdax.png"),
          alt: "MDAx Logo"
        }
      }),
      _vm._v(" "),
      _c("img", { attrs: { src: _vm._getImgSrc("dlr.png"), alt: "DLR Logo" } }),
      _vm._v(" "),
      _vm._l(_vm.logoFiles, function(file) {
        return _c("img", {
          key: file,
          attrs: {
            src: _vm.routePrefix + "/additional_logo/" + file,
            alt: file
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }