//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import uiModal from '../ui-modal.vue';
import xdsm from '../xdsm/xdsm.vue';
import { dispatcher } from '../../index.js';
export default {
  name: "xdsm-preview-modal",
  components: {
    uiModal: uiModal,
    xdsm: xdsm
  },
  props: {
    graphDef: {
      required: true
    },
    title: {
      type: String,
      required: true
    },
    settingsTitle: {
      type: String,
      required: true
    },
    action: {
      type: Function,
      default: null
    },
    actionTitle: {
      type: String,
      default: 'Apply'
    },
    actionDisabled: {
      type: Boolean,
      default: false
    },
    functionClasses: {
      type: Array,
      default: function _default() {
        return [];
      }
    } // [[i, 'class list'], ...]

  },
  data: function data() {
    return {
      dispatcher: dispatcher,
      loading: false
    };
  },
  methods: {
    open: function open() {
      this.loading = this.dispatcher.loading;
      this.$refs.modal.open();
    },
    close: function close() {
      this.$refs.modal.close();
    },
    setLoading: function setLoading(loading) {
      this.loading = loading;
    }
  },
  created: function created() {
    this.dispatcher.onIsLoading(this.setLoading);
  },
  beforeDestroy: function beforeDestroy() {
    this.dispatcher.offIsLoading(this.setLoading);
  }
};