//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import xdsm from './ui-items/xdsm/xdsm.vue';
import progressBar from './ui-items/progress-bar.vue';
import popover from './ui-items/popover.vue';
import issuesList from './ui-items/issues-list.vue';
import varListModal from './ui-items/var-list/var-list-modal.vue';
import workflowSettings from './ui-items/workflow-settings.vue';
import functionModal from './ui-items/mdao/function-modal.vue';
import { functionTypes, isMdaoType, newFunctionDefObj } from './ui-items/mdao/functions.js';
import uiDialog from './ui-items/ui-dialog.vue';
import xdsmFunction from './ui-items/xdsm/xdsm-function.vue';
import autoSort from './ui-items/mdao/auto-sort.vue';
import autoRemoveFunctions from './ui-items/mdao/auto-remove-functions.vue';
import toolBatchAddModal from './ui-items/mdao/tool-batch-add-modal.vue';
import statistics from './ui-items/statistics.vue';
import varInfo from './ui-items/var-list/var-info.vue';
import routingSelect from './ui-items/var-list/routing-select.vue';
import baseSchemaModal from './ui-items/base-schema-modal.vue';
import autoRoutingModal from './ui-items/auto-routing-modal.vue';
import createVar from './ui-items/var-list/create-var.vue';
import functionIoEdit from './ui-items/mdao/function-io-edit.vue';
import logos from './ui-items/logos.vue';
import { filterTreeByFunctionIO, hasRoutingCollision, walkVarTreeRouting, filterByVarIds, filterTreeInternalVars, mapLeafVars, filterTreeByFunctionCoupling, getConnectedVarIds } from './var-utils.js';
import { getGraphDef, getSubWorkflowFunctions } from "./utils";
import vueSlider from 'vue-slider-component';
import escape from 'escape-html';
import { api, dispatcher, staticBaseUrl, projectFileMode, ioMode, uiManaged, hasAuth, allowOpen, allowNew, displayScopeName, previewMode } from './index';
import { standaloneMode } from "./api";
export default {
  name: "editor",
  components: {
    xdsm: xdsm,
    progressBar: progressBar,
    popover: popover,
    varListModal: varListModal,
    issuesList: issuesList,
    workflowSettings: workflowSettings,
    functionModal: functionModal,
    uiDialog: uiDialog,
    xdsmFunction: xdsmFunction,
    autoSort: autoSort,
    autoRemoveFunctions: autoRemoveFunctions,
    vueSlider: vueSlider,
    toolBatchAddModal: toolBatchAddModal,
    statistics: statistics,
    varInfo: varInfo,
    routingSelect: routingSelect,
    baseSchemaModal: baseSchemaModal,
    autoRoutingModal: autoRoutingModal,
    createVar: createVar,
    functionIoEdit: functionIoEdit,
    logos: logos
  },
  props: {
    version: {
      type: String,
      required: true
    },
    project: {
      type: Object,
      required: true
    },
    graphs: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    hasBranches: {
      type: Boolean,
      default: true
    },
    projectUnsaved: {
      type: Boolean,
      default: true
    },
    hasLegalText: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      loading: false,
      api: api,
      dispatcher: dispatcher,
      projectFileMode: projectFileMode,
      ioMode: ioMode,
      previewMode: previewMode,
      uiManaged: uiManaged,
      hasAuth: hasAuth,
      standaloneMode: standaloneMode,
      allowOpen: allowOpen,
      allowNew: allowNew,
      displayScopeName: displayScopeName,
      progress: 50,
      progressStatus: '',
      zoomPopoverOpen: false,
      delayedZoomPopoverOpen: false,
      mapZoomFactor: 1000,
      xdsmZoom: 1,
      rootVarDef: null,
      zoomPopoverTimeout: null,
      confirmDeleteFunctionDef: null,
      staticBaseUrl: null,
      pastActions: [],
      futureActions: [],
      previousFunctions: [],
      highlightedFunctionIds: null,
      focusSettings: null,
      processPlaying: false,
      processPaused: false,
      processIdx: null,
      dataConVars: {},
      dataConLineVars: {},
      editVarId: null,
      screenWidth: 1200,
      editFunctionId: null,
      hasAddedNewFunction: false,
      inBatchGraphUpdate: false
    };
  },
  watch: {
    graphDef: function graphDef() {
      var _this = this;

      if (this.$refs.xdsm) this.resetZoom();
      if (!this.inBatchGraphUpdate) this._getRootVarDef();

      this._highlightNewFunctions();

      if (this.ioMode && this.toolFunctions) {
        if (this.hasAddedNewFunction) {
          this.hasAddedNewFunction = false;
          this.editFunctionId = this.toolFunctions[this.toolFunctions.length - 1].id;
        } else {
          // Check existing function editing ID
          if (this.editFunctionId !== null) {
            if (_.findIndex(this.toolFunctions, function (functionDef) {
              return functionDef.id == _this.editFunctionId;
            }) == -1) {
              this.editFunctionId = null;
            }
          } // Set default function editing ID


          if (this.editFunctionId === null && this.toolFunctions.length > 0) {
            this.editFunctionId = this.toolFunctions[0].id;
          }
        }
      }
    },
    zoomPopoverOpen: function zoomPopoverOpen(open) {
      var _c = this;

      if (this.zoomPopoverTimeout) {
        this.delayedZoomPopoverOpen = !open;
        clearTimeout(this.zoomPopoverTimeout);
      }

      this.zoomPopoverTimeout = setTimeout(function () {
        _c.delayedZoomPopoverOpen = open;
      }, open ? 20 : 500);
    }
  },
  computed: {
    isAuth: function isAuth() {
      return this.dispatcher.isAuth;
    },
    logoutTitle: function logoutTitle() {
      var title = 'Logout';
      var isAuth = this.dispatcher.isAuth;
      if (isAuth !== true) title += ' (logged in as: ' + isAuth + ')';
      return title;
    },
    selectedScopeName: function selectedScopeName() {
      var hasScope = this.dispatcher.hasScope;
      return hasScope !== null && hasScope !== true ? hasScope : null;
    },
    title: function title() {
      var title = this.project.title;
      var selectedScopeName = this.selectedScopeName;
      return selectedScopeName && this.displayScopeName ? selectedScopeName + ': ' + title : title;
    },
    branchTitle: function branchTitle() {
      return this.project.branch_title;
    },
    subtitle: function subtitle() {
      var subtitle = this.hasBranches ? this.branchTitle : 'Main Workflow';
      return this.standaloneMode ? this.title + ' - ' + subtitle : subtitle;
    },
    projectId: function projectId() {
      return this.project.id;
    },
    graphUnsaved: function graphUnsaved() {
      return this.graphDef ? this.graphDef.touched : false;
    },
    isUnsaved: function isUnsaved() {
      return this.projectUnsaved || this.graphUnsaved;
    },
    branchId: function branchId() {
      return this.project.branch_id;
    },
    subWfFunctionIds: function subWfFunctionIds() {
      return this.project.sub_wf_function_ids || [];
    },
    isInSubWorkflow: function isInSubWorkflow() {
      return this.subWfFunctionIds.length > 0;
    },
    subWfFunctions: function subWfFunctions() {
      return getSubWorkflowFunctions(this.graphs || {}, this.projectId, this.branchId, this.subWfFunctionIds);
    },
    graphDef: function graphDef() {
      return getGraphDef(this.graphs || {}, this.projectId, this.branchId, this.subWfFunctionIds);
    },
    editedFunctionDef: function editedFunctionDef() {
      var functions = this.toolFunctions;
      var editFunctionId = this.editFunctionId;

      var functionIdx = _.findIndex(functions, function (functionDef) {
        return functionDef.id == editFunctionId;
      });

      if (functionIdx == -1) return null;
      return functions[functionIdx];
    },
    showAddButton: function showAddButton() {
      return this.canEdit; // return this.canEdit && !this.hasMdaoBlocks;
    },
    showXdsmAddButton: function showXdsmAddButton() {
      return this.showAddButton && this.functions.length <= 1;
    },
    sortable: function sortable() {
      return this.canEdit;
    },
    deletable: function deletable() {
      return this.canEdit;
    },
    collapsible: function collapsible() {
      return this.canEdit || this.standaloneMode;
    },
    functions: function functions() {
      return this.graphDef ? this.graphDef.functions : [];
    },
    toolFunctions: function toolFunctions() {
      return _.filter(this.functions, function (functionDef) {
        return functionDef.type == functionTypes.TOOL;
      });
    },
    minMapZoom: function minMapZoom() {
      return this.mapZoomFactor / (Math.log(.1) + 1);
    },
    maxMapZoom: function maxMapZoom() {
      return this.mapZoomFactor * (Math.log(5) + 1);
    },
    _debouncedOnResize: function _debouncedOnResize() {
      return _.debounce(this._onResize, 10);
    },
    hasMdaoBlocks: function hasMdaoBlocks() {
      return _.findIndex(this.functions, function (functionDef) {
        return isMdaoType(functionDef.type);
      }) != -1;
    },
    hasUndo: function hasUndo() {
      return this.pastActions.length > 0;
    },
    lastAction: function lastAction() {
      return this.hasUndo ? _.last(this.pastActions) : null;
    },
    undoTitle: function undoTitle() {
      var lastAction = this.lastAction;
      return lastAction ? 'Undo last action (Ctrl+Z): ' + escape(lastAction.title) : null;
    },
    hasRedo: function hasRedo() {
      return this.futureActions.length > 0;
    },
    nextAction: function nextAction() {
      return this.hasRedo ? _.last(this.futureActions) : null;
    },
    redoTitle: function redoTitle() {
      var nextAction = this.nextAction;
      return nextAction ? 'Redo action (Ctrl+Y): ' + escape(nextAction.title) : null;
    },
    functionsById: function functionsById() {
      return _.fromPairs(_.map(this.functions, function (functionDef) {
        return [functionDef.id, functionDef];
      }));
    },
    coordinatorId: function coordinatorId() {
      var functions = this.functions;
      return functions.length > 0 ? functions[0].id : 0;
    },
    dataConVarIds: function dataConVarIds() {
      var rootVarDef = this.rootVarDef;
      if (!rootVarDef) return {};
      var coordId = this.coordinatorId;
      var conVarIds = {};
      walkVarTreeRouting(rootVarDef, function (varDef, outFunctionId, inFunctionId) {
        if (outFunctionId === null) outFunctionId = coordId;
        if (inFunctionId === null) inFunctionId = coordId;
        if (!(outFunctionId in conVarIds)) conVarIds[outFunctionId] = {};
        if (!(inFunctionId in conVarIds[outFunctionId])) conVarIds[outFunctionId][inFunctionId] = [];
        conVarIds[outFunctionId][inFunctionId].push(varDef.id);
      }, true);
      return conVarIds;
    },
    dataConCollision: function dataConCollision() {
      var rootVarDef = this.rootVarDef;
      if (!rootVarDef) return {};
      var coordId = this.coordinatorId;
      var conCollisions = {};
      walkVarTreeRouting(rootVarDef, function (varDef, outFunctionId, inFunctionId) {
        if (outFunctionId === null) outFunctionId = coordId;
        if (inFunctionId === null) inFunctionId = coordId;
        if (!(outFunctionId in conCollisions)) conCollisions[outFunctionId] = {};

        if (!(inFunctionId in conCollisions[outFunctionId])) {
          conCollisions[outFunctionId][inFunctionId] = false;
        }

        if (!conCollisions[outFunctionId][inFunctionId]) {
          if (hasRoutingCollision(varDef)) conCollisions[outFunctionId][inFunctionId] = true;
        }
      }, true);
      return conCollisions;
    },
    dataClasses: function dataClasses() {
      if (!this.canEdit) return [];
      var classes = [];

      _.forEach(this.dataConCollision, function (obj, outId) {
        _.forEach(obj, function (hasCollision, inId) {
          if (hasCollision) classes.push([outId, inId, 'collision']);
        });
      });

      return classes;
    },
    functionClasses: function functionClasses() {
      var classes = _.concat(_.map(this.highlightedFunctionIds || [], function (functionId) {
        return [functionId, 'highlight'];
      }), _.map(this.currentProcessFunctionIds, function (functionId) {
        return [functionId, 'highlight-process'];
      }));

      if (this.processIdx !== null) {
        classes = _.concat(classes, _.map(this.functions, function (functionDef) {
          return [functionDef.id, 'playing'];
        }));
      }

      return classes;
    },
    hasIssues: function hasIssues() {
      return this.graphDef ? this.graphDef.issues.length > 0 : true;
    },
    hasCriticalIssues: function hasCriticalIssues() {
      if (!this.hasIssues || !this.graphDef) return false;
      var nonCritical = ['NO_ANALYSIS'];
      return _.filter(this.graphDef.issues, function (issueKey) {
        return !_.includes(nonCritical, issueKey);
      }).length > 0;
    },
    issues: function issues() {
      var emptyIssue = {
        status: 'neutral',
        text: 'Empty workflow'
      };
      var graphDef = this.graphDef;
      if (!graphDef) return [emptyIssue];
      var issues = graphDef.issues;
      var issuesList = [];

      if (issues.length == 0) {
        issuesList = [{
          status: 'completed',
          text: 'Workflow is executable.'
        }];
      } else {
        issuesList = _.filter(_.map(issues, function (issueKey) {
          switch (issueKey) {
            case 'NO_ANALYSIS':
              return emptyIssue;

            case 'HAS_COLLISIONS':
              return {
                status: 'critical',
                text: 'Workflow has <b>collisions</b>. ' + 'This happens when one variable is <i>written to by multiple functions</i> ' + 'at a time. Solve this by <i>rerouting</i> the affected variables, or by ' + 'selecting a suitable automated routing method.'
              };

            case 'HAS_UNCONVERGED_FEEDBACK':
              return {
                status: 'critical',
                text: 'Workflow has <b>unconverged feedback</b> connections. ' + 'Solve this by adding <i>convergers</i>, or by <i>breaking</i> feedback ' + 'connections.'
              };

            case 'SUB_WORKFLOW_ISSUES':
              return {
                status: 'critical',
                text: 'One or more <b>sub-workflows</b> have issues. Edit the sub-workflow(s) to ' + 'solve this.'
              };
          }
        }));
      }

      if (!this.canEdit && !this.standaloneMode) {
        issuesList.push({
          status: 'neutral',
          text: 'Workflow is <i>not editable</i>, because there are workflow branches that depend on ' + 'this workflow. Click on <i>start editing</i>, or manually create a new branch to make ' + 'this workflow editable.'
        });
      }

      if (this.previewMode) {
        issuesList.push({
          status: 'neutral',
          text: 'MDAx is running in <b>light mode</b>: some features have been disabled. ' + 'Contact us to get access to the full experience.'
        });
      }

      return issuesList;
    },
    hasProcess: function hasProcess() {
      return this.graphDef ? this.graphDef.process.length > 0 : null;
    },
    processGraph: function processGraph() {
      return this.graphDef && this.functions.length > 1 ? this.graphDef.process : null;
    },
    processSteps: function processSteps() {
      // [[fId1, fId2], ...]
      var processGraph = this.processGraph;
      if (!processGraph) return [];

      var processNrs = _.fromPairs(_.map(this.functions, // {id: '1', id2: '2.0', ...}
      function (functionDef) {
        return [functionDef.id, functionDef.process_title.split(':')[0].split(',')[0]];
      }));

      var funcCollapsed = _.fromPairs(_.map(this.functions, function (f) {
        return [f.id, f.collapsed];
      }));

      function isSameNr(nr1, nr2) {
        var nrs1 = nr1.split('.');
        var nrs2 = nr2.split('.');

        for (var i = 0; i < nrs1.length || i < nrs2.length; i++) {
          if (i >= nrs1.length || i >= nrs2.length) return true;
          if (nrs1[i] != nrs2[i]) return false;
        }

        return true;
      }

      function mergeSteps(stepData1, stepData2) {
        var mergedIds = _.concat(stepData1.ids, stepData2.ids);

        var mergedNested = null;

        if (stepData1.nested && stepData2.nested) {
          // Merge the nested steps
          mergedNested = [];

          for (var i = 0; i < stepData1.nested.length || i < stepData2.nested.length; i++) {
            mergedNested.push(_.concat(stepData1.nested[i] || [], stepData2.nested[i] || []));
          }
        } else if (stepData1.nested) {
          mergedNested = stepData1.nested;
        } else if (stepData2.nested) {
          mergedNested = stepData2.nested;
        }

        return _.merge(stepData1, {
          ids: mergedIds,
          nested: mergedNested
        });
      }

      var nesting = this.graphDef.nesting;

      function getNestingLevelProcess(nestingSpec) {
        var functionData = []; // Get linear steps

        _.forEach(nestingSpec, function (nestingItem) {
          var nestedProcess = null,
              functionId = null;

          if (_.isArray(nestingItem)) {
            // Nested process
            functionId = nestingItem[0];
            var isCollapsed = funcCollapsed[functionId];

            if (!isCollapsed) {
              nestedProcess = _.concat(getNestingLevelProcess(nestingItem[1]), [[functionId]]);
            }
          } else {
            functionId = nestingItem;
          }

          functionData.push({
            ids: [functionId],
            nr: processNrs[functionId],
            nested: nestedProcess
          });
        }); // Merge parallel steps


        var mergedFunctionData = [];

        _.forEach(functionData, function (stepData) {
          var sameIdx = _.findIndex(mergedFunctionData, function (mergedStepData) {
            return isSameNr(stepData.nr, mergedStepData.nr);
          });

          if (sameIdx == -1) {
            mergedFunctionData.push(stepData);
          } else {
            mergedFunctionData[sameIdx] = mergeSteps(stepData, mergedFunctionData[sameIdx]);
          }
        }); // Flatten the array (integrate nested processes)


        var flattenedIds = [];

        _.forEach(mergedFunctionData, function (stepData) {
          flattenedIds.push(stepData.ids);

          if (stepData.nested) {
            _.forEach(stepData.nested, function (nestedIds) {
              return flattenedIds.push(nestedIds);
            });
          }
        });

        return flattenedIds;
      }

      return getNestingLevelProcess(nesting);
    },
    currentProcessFunctionIds: function currentProcessFunctionIds() {
      if (!this.processPlaying) return [];
      return this.processSteps[this.processIdx] || [];
    },
    connectedVarIds: function connectedVarIds() {
      if (!this.rootVarDef) return [];
      return getConnectedVarIds(this.rootVarDef);
    },
    isSmallScreen: function isSmallScreen() {
      return this.screenWidth < 1500;
    }
  },
  methods: {
    benchmark: function benchmark() {
      var _c = this;

      this.api.benchmark(100, function (t) {
        _c.dispatcher.success('Average roundtrip time: ' + Math.round(t * 100) / 100 + 'ms');
      });
    },
    undo: function undo() {
      /** @type {actionType} */
      var lastAction = this.lastAction;
      if (!lastAction) return;

      var _c = this;

      this.api.undo(lastAction.id, function () {
        _c.pastActions.pop();

        _c.futureActions.push(lastAction); // Tell the program to refresh its state


        _c.dispatcher.backendStateUpdated();
      });
    },
    redo: function redo() {
      /** @type {actionType} */
      var nextAction = this.nextAction;
      if (!nextAction) return;

      var _c = this;

      this.api.redo(nextAction.id, function () {
        _c.futureActions.pop();

        _c.pastActions.push(nextAction); // Tell the program to refresh its state


        _c.dispatcher.backendStateUpdated();
      });
    },
    resetUndoRedo: function resetUndoRedo() {
      this.pastActions = [];
      this.futureActions = [];
    },
    _onLoading: function _onLoading(isLoading) {
      this.loading = isLoading;
    },
    _pushAction: function _pushAction(action) {
      this.pastActions.push(action);
      this.futureActions = [];
    },
    _showSettings: function _showSettings(focusEl) {
      this.focusSettings = focusEl || null;

      var _c = this;

      this.$refs.settings.open(this.project, this.canEdit, _c._projectUpdated);
    },
    _settingsOpened: function _settingsOpened() {
      if (this.focusSettings) {
        this.$refs.settings.$refs[this.focusSettings].focus();
      }
    },
    _showAutoRouting: function _showAutoRouting() {
      this.$refs.autoRouting.open();
    },
    _todoClick: function _todoClick(idx) {
      console.log('TODO CLICK', idx);
    },
    _functionClick: function _functionClick(id) {
      var idx = _.findIndex(this.functions, function (functionDef) {
        return functionDef.id == id;
      });

      if (idx == -1) return;
      var functionDef = this.functions[idx];

      if (this.canEdit) {
        this.$refs.functionModal.openEdit(functionDef, this._functionEdited);
      } else {
        this.$refs.functionModal.openView(functionDef);
      }
    },
    _addTool: function _addTool() {
      this._openNewFunction(functionTypes.TOOL);
    },
    _addMath: function _addMath() {
      this._openNewFunction(functionTypes.MATH);
    },
    _addSubWorkflow: function _addSubWorkflow() {
      this._openNewFunction(functionTypes.SUB_WF);
    },
    _addOptimizer: function _addOptimizer() {
      this._openNewFunction(functionTypes.OPT);
    },
    _addConverger: function _addConverger() {
      this._openNewFunction(functionTypes.MDA);
    },
    _addDOE: function _addDOE() {
      this._openNewFunction(functionTypes.DOE);
    },
    _openNewFunction: function _openNewFunction(type) {
      this.$refs.functionModal.openNew(type, this._functionEdited);
    },
    _functionEdited: function _functionEdited(functionDef, callback) {
      var _c = this;

      this.api.editFunction(functionDef, function (graphDef) {
        if (callback) callback();

        _c.dispatcher.updatedGraph(_c.projectId, _c.branchId, graphDef);
      });
    },
    _addToolsBatch: function _addToolsBatch() {
      this.$refs.toolBatchAddModal.open();
    },
    _addedBatchTools: function _addedBatchTools(graphDef) {
      this.dispatcher.updatedGraph(this.projectId, this.branchId, graphDef);
    },
    _saveEditedFunction: function _saveEditedFunction(functionDef) {
      var _c = this;

      this.api.editFunction(functionDef, function (graphDef, functionId) {
        _c.dispatcher.updatedGraph(_c.projectId, _c.branchId, graphDef);

        _c.editFunctionId = functionId;
      });
    },
    _addEmptyTool: function _addEmptyTool() {
      var emptyFunctionDef = _.cloneDeep(newFunctionDefObj);

      emptyFunctionDef.title = 'New Discipline';

      var _c = this;

      this.api.editFunction(emptyFunctionDef, function (graphDef) {
        _c.hasAddedNewFunction = true;

        _c.dispatcher.updatedGraph(_c.projectId, _c.branchId, graphDef);
      });
    },
    _editFunctionDelete: function _editFunctionDelete() {
      if (!this.editedFunctionDef) return;

      this._functionDelete(this.editedFunctionDef);
    },
    _functionDelete: function _functionDelete(functionDef) {
      this.confirmDeleteFunctionDef = functionDef;

      var _c = this;

      this.$refs.confirmDeleteFunctionDialog.open(function () {
        _c.api.editDeleteFunction(functionDef.id, function (graphDef) {
          _c.dispatcher.updatedGraph(_c.projectId, _c.branchId, graphDef);
        });
      });
    },
    _dataClick: function _dataClick(outIds, inIds) {
      var _this2 = this;

      var conVarTree = this._getDataConVars(outIds, inIds);

      if (!conVarTree) return;
      var functionsById = this.functionsById;
      var coordId = this.coordinatorId;
      var outTitle = outIds[0] != coordId ? functionsById[outIds[0]].title : 'External input';
      var inTitle = inIds[0] != coordId ? functionsById[inIds[0]].title : 'external output';
      var subTitle = outTitle + ' to ' + inTitle;
      var modal = this.$refs.varListModal;
      modal.resetModal();
      modal.setTitle('Coupling Variables', subTitle);
      modal.open(function () {
        return _this2._getDataConVars(outIds, inIds);
      });
    },
    _editVarFunction: function _editVarFunction(parentVarId, varData) {
      var _c = this;

      this.api.editEditVar(this.editVarId, varData, function (rootVarDef, newVarDef) {
        _c.$refs.varListModal.highlight([newVarDef.id]);

        _c.dispatcher.updatedRootVarDef(rootVarDef);

        _c._updateGraph();
      });
    },
    _getDataConVars: function _getDataConVars(outIds, inIds) {
      // Check cache
      if (outIds in this.dataConVars && inIds in this.dataConVars[outIds]) return this.dataConVars[outIds][inIds]; // Get connection vars

      if (!(outIds in this.dataConVars)) {
        this.dataConVars[outIds] = {};
      }

      var varTree = null;
      var rootVarDef = this.rootVarDef;

      if (rootVarDef) {
        var dataConVarIds = this.dataConVarIds;

        var varIds = _.flatten(_.map(outIds, function (outId) {
          if (!(outId in dataConVarIds)) return [];
          return _.flatten(_.map(inIds, function (inId) {
            return dataConVarIds[outId][inId] ? dataConVarIds[outId][inId] : [];
          }));
        })); // Also add "internal" variables if we are dealing with a connection from multiple functions to the
        // global output


        var coordId = this.coordinatorId;

        if (outIds.length > 1 && inIds.length == 1 && inIds[0] == coordId) {
          var internalVarIds = mapLeafVars(filterTreeInternalVars(rootVarDef, outIds), function (varDef) {
            return varDef.id;
          });
          varIds = _.uniq(_.concat(varIds, internalVarIds));
        }

        if (varIds) {
          varTree = filterByVarIds(rootVarDef, varIds);
        }
      }

      this.dataConVars[outIds][inIds] = varTree;
      return varTree;
    },
    _dataLineClick: function _dataLineClick(functionIds, isInput) {
      var _this3 = this;

      var dataConLineVars = this._getDataConLineVars(functionIds, isInput);

      if (!dataConLineVars) return;

      var functionDef = _.find(this.functions, function (functionDef) {
        return functionDef.id == functionIds[0];
      });

      var title = isInput ? 'Input Variables' : 'Output Variables';
      var modal = this.$refs.varListModal;
      modal.resetModal();
      modal.setTitle(title, null, functionDef);
      modal.open(function () {
        return _this3._getDataConLineVars(functionIds, isInput);
      });
    },
    _getDataConLineVars: function _getDataConLineVars(functionIds, isInput) {
      // Check cache
      if (functionIds in this.dataConLineVars && isInput in this.dataConLineVars[functionIds]) {
        return this.dataConLineVars[functionIds][isInput];
      } // Get line connection vars


      if (!(functionIds in this.dataConLineVars)) {
        this.dataConLineVars[functionIds] = {};
      }

      var varTree = null;
      var rootVarDef = this.rootVarDef;

      if (rootVarDef) {
        varTree = filterTreeByFunctionIO(rootVarDef, functionIds, isInput, !isInput); // Add coupling variables if we are dealing with output from multiple functions

        if (!isInput && functionIds.length > 1) {
          var varIds = mapLeafVars(varTree, function (varDef) {
            return varDef.id;
          });
          var couplingVarIds = mapLeafVars(filterTreeByFunctionCoupling(rootVarDef, functionIds), function (varDef) {
            return varDef.id;
          });
          varIds = _.uniq(_.concat(varIds, couplingVarIds));
          varTree = filterByVarIds(rootVarDef, varIds);
        }
      }

      this.dataConLineVars[functionIds][isInput] = varTree;
      return varTree;
    },
    _sort: function _sort(nesting) {
      var _c = this;

      this.api.editApplyNesting(nesting, function (graphDef) {
        _c.dispatcher.updatedGraph(_c.projectId, _c.branchId, graphDef);
      });
    },
    _collapse: function _collapse(functionId, collapsed) {
      var _c = this;

      this.api.editSetFunctionCollapsed(functionId, collapsed, function (graphDef) {
        _c.dispatcher.updatedGraph(_c.projectId, _c.branchId, graphDef);
      });
    },
    _autoSort: function _autoSort() {
      this.$refs.autoSort.open(this.graphDef);
    },
    _autoRemoveFunctions: function _autoRemoveFunctions() {
      this.$refs.autoRemove.open(this.graphDef);
    },
    _getRootVarDef: function _getRootVarDef() {
      var _c = this;

      this.api.editRootVarDef(function (rootVarDef) {
        _c.dispatcher.updatedRootVarDef(rootVarDef);
      });
    },
    _updatedRootVarDef: function _updatedRootVarDef(rootVarDef) {
      // https://vuedose.tips/tips/improve-performance-on-large-lists-in-vue-js/
      this.rootVarDef = Object.freeze(rootVarDef);
      this.dataConVars = {};
      this.dataConLineVars = {};
    },
    _viewAllVars: function _viewAllVars() {
      var _c = this;

      var modal = this.$refs.varListModal;
      modal.resetModal();
      modal.open(function () {
        return _c.rootVarDef;
      }, null, null, function () {
        return _c.connectedVarIds;
      });
    },
    _viewSystemInputVars: function _viewSystemInputVars() {
      var _c = this;

      var modal = this.$refs.varListModal;
      modal.resetModal();
      modal.setTitle('Workflow Input Variables');
      var systemInputVarTree = this.rootVarDef;

      function setSystemInputVarTree(varIds) {
        systemInputVarTree = filterByVarIds(_c.rootVarDef, varIds);
      }

      this.api.getSystemInputVarIds(function (varIds) {
        setSystemInputVarTree(varIds);
        modal.open(function () {
          return systemInputVarTree;
        });
      });
    },
    _graphUpdated: function _graphUpdated(graphDef) {
      this.dispatcher.updatedGraph(this.projectId, this.branchId, graphDef);
    },
    resetZoom: function resetZoom() {
      if (this.$refs.xdsm) this.$refs.xdsm.resetZoom();
    },
    _mapZoom: function _mapZoom(zoom) {
      var mappedZoom = (Math.log(zoom) + 1) * this.mapZoomFactor;
      return mappedZoom < this.minMapZoom ? this.minMapZoom : mappedZoom > this.maxMapZoom ? this.maxMapZoom : mappedZoom;
    },
    _getZoom: function _getZoom(mappedZoom) {
      var zoom = mappedZoom / this.mapZoomFactor;
      return Math.exp(zoom - 1);
    },
    _projectUpdated: function _projectUpdated(project) {
      this.$emit('update:project', project);
    },
    _onResize: function _onResize() {
      this.resetZoom();
      this.screenWidth = screen.width;
    },
    _highlightNewFunctions: function _highlightNewFunctions() {
      if (this.standaloneMode) return;
      var functions = this.functions.slice(1);

      if (this.previousFunctions.length == 0) {
        this.previousFunctions = functions;
        this.highlightedFunctionIds = [];
        return;
      } // Get function IDs not previously included


      var highlightedFunctions = _.differenceBy(functions, this.previousFunctions, function (functionDef) {
        return functionDef.title;
      });

      this.highlightedFunctionIds = _.map(highlightedFunctions, function (functionDef) {
        return functionDef.id;
      });

      if (this.highlightedFunctionIds.length == functions.length) {
        this.highlightedFunctionIds = [];
      }

      this.previousFunctions = functions;
    },
    _openStats: function _openStats() {
      this.$refs.stats.open();
    },
    _quickDuplicate: function _quickDuplicate() {
      var newTitle = this.branchTitle + ' - edited';
      this.$emit('quickDuplicate', newTitle);
    },
    _editTopLevelWorkflow: function _editTopLevelWorkflow() {
      this._editSub([]);
    },
    _editParentWorkflowId: function _editParentWorkflowId(functionId) {
      var subWfFunctionIds = this.subWfFunctionIds;
      var idx = subWfFunctionIds.indexOf(functionId);

      if (idx != -1) {
        this._editSub(subWfFunctionIds.slice(0, idx + 1));
      }
    },
    _editParentWorkflow: function _editParentWorkflow() {
      var subWfFunctionIds = this.subWfFunctionIds;

      this._editSub(subWfFunctionIds.slice(0, subWfFunctionIds.length - 1));
    },
    _editSubWorkflow: function _editSubWorkflow(functionId) {
      this._editSub(_.concat(this.subWfFunctionIds, [functionId]));
    },
    _mergeSubWorkflow: function _mergeSubWorkflow(functionId) {
      var _this4 = this;

      this.api.editMergeSubWorkflow(functionId, function (graphDef) {
        return _this4._graphUpdated(graphDef);
      });
    },
    _editSub: function _editSub(subWfFunctionIds) {
      this.$emit('editSub', subWfFunctionIds);
    },
    togglePlayProcess: function togglePlayProcess() {
      if (!this.processPlaying || this.processPaused) {
        this._playProcess();
      } else {
        this._pauseProcess();
      }
    },
    _playProcess: function _playProcess() {
      if (!this.processGraph) return;
      this.processPaused = false;

      if (!this.processPlaying) {
        this.processPlaying = true;
        this.processIdx = -1;
      }

      this._nextProcessFrame();
    },
    _pauseProcess: function _pauseProcess() {
      this.processPaused = true;
    },
    _stopProcess: function _stopProcess() {
      this.processPlaying = false;
    },
    previousProcess: function previousProcess() {
      if (!this.processPlaying) return;

      this._pauseProcess();

      this.processIdx--;
      if (this.processIdx < 0) this.processIdx = this.processSteps.length - 1;
    },
    nextProcess: function nextProcess() {
      if (!this.processPlaying) return;

      this._pauseProcess();

      this.processIdx++;
      if (this.processIdx >= this.processSteps.length) this.processIdx = 0;
    },
    _nextProcessFrame: function _nextProcessFrame() {
      // Externally stopped/paused
      if (this.processPaused) return;

      if (!this.processPlaying) {
        this.processIdx = null;
        return;
      }

      this.processIdx += 1; // Check end reached

      if (this.processIdx >= this.processSteps.length) {
        this.processPlaying = false;
        this.processIdx = null;
        return;
      } // Next frame


      setTimeout(this._nextProcessFrame, 1000);
    },
    _showInfo: function _showInfo(varDef) {
      this.$refs.varInfo.open(varDef);
    },
    _showRouting: function _showRouting(varDef) {
      if (!this.canEdit) return;
      this.$refs.routingSelect.open(varDef);
    },
    _showEdit: function _showEdit(varDef) {
      if (!this.canEdit) return;
      this.editVarId = varDef.id;
      this.$refs.createVar.setData(varDef.name, varDef.attrib, varDef.svm);
      this.$refs.createVar.start(varDef, 'Edit Variable', 'Apply');
    },
    _selectBaseSchema: function _selectBaseSchema() {
      if (!this.canEdit) return;
      this.$refs.baseSchemaModal.open();
    },
    _updateGraph: function _updateGraph(graphDef) {
      if (!graphDef) {
        this.dispatcher.updateGraph(this.projectId, this.branchId);
      } else {
        this.dispatcher.updatedGraph(this.projectId, this.branchId, graphDef);
      }
    },
    _logout: function _logout() {
      var _this5 = this;

      this.$refs.confirmLogoutDialog.open(function () {
        _this5.api.logout(function () {
          return _this5.dispatcher.backendStateUpdated();
        });
      });
    },
    _displayLegal: function _displayLegal() {
      this.$emit('displayLegal');
    },
    _displayAbout: function _displayAbout() {
      this.$emit('displayAbout');
    },
    _setInBatchGraphUpdate: function _setInBatchGraphUpdate(inBatchUpdate) {
      this.inBatchGraphUpdate = inBatchUpdate;
    }
  },
  created: function created() {
    this.staticBaseUrl = staticBaseUrl;
    this.dispatcher.onIsLoading(this._onLoading);
    this.dispatcher.onUpdatedRootVarDef(this._updatedRootVarDef);
    this.dispatcher.onPushAction(this._pushAction);
    this.dispatcher.onSetInBatchGraphUpdate(this._setInBatchGraphUpdate);
    window.addEventListener('resize', this._debouncedOnResize);
  },
  mounted: function mounted() {
    this._onResize();
  },
  beforeDestroy: function beforeDestroy() {
    this.dispatcher.offIsLoading(this._onLoading);
    this.dispatcher.offUpdatedRootVarDef(this._updatedRootVarDef);
    this.dispatcher.offPushAction(this._pushAction);
    this.dispatcher.offSetInBatchGraphUpdate(this._setInBatchGraphUpdate);
    window.removeEventListener('resize', this._debouncedOnResize);
  }
};