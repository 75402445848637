var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      ref: "modal",
      staticClass: "branches-modal",
      attrs: {
        title: "Project Branch Tree",
        "sub-title": _vm.projectTitle,
        "confirm-close": false,
        "has-buttons": false,
        wide: true
      },
      on: { opened: _vm._opened, close: _vm._closing }
    },
    [
      _c(
        "div",
        { ref: "canvas", staticClass: "canvas", style: _vm.canvasStyle },
        [
          _c("span", {
            staticClass: "fas fa-arrows-alt link center-button",
            attrs: { title: "Reset zoom" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm._centerFit($event)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { ref: "moveObject", staticClass: "branches-wrapper" },
            [
              _c("branch-tree", {
                ref: "tree",
                attrs: {
                  branches: _vm.branches,
                  graphs: _vm.graphs,
                  "project-id": _vm.projectId,
                  "current-project-id": _vm.projectId,
                  "current-branch-id": _vm.activeBranchId,
                  scale: _vm.scale
                },
                on: {
                  edit: _vm._editBranch,
                  export: _vm._exportBranch,
                  delete: _vm._deleteBranch,
                  duplicate: _vm._duplicateBranch
                }
              })
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }