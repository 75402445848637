//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { logoFiles, previewMode, getRouteNamespace, getStaticImageSrc } from "../index";
export default {
  name: "logos",
  props: {
    zIndex: {
      default: -100
    }
  },
  data: function data() {
    return {
      logoFiles: [],
      routeNamespace: '',
      previewMode: previewMode
    };
  },
  computed: {
    routePrefix: function routePrefix() {
      return this.routeNamespace ? '/' + this.routeNamespace : '';
    }
  },
  methods: {
    _getImgSrc: function _getImgSrc(path) {
      return getStaticImageSrc(path);
    }
  },
  created: function created() {
    this.logoFiles = logoFiles;
    this.routeNamespace = getRouteNamespace();
  }
};