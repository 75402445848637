//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from './lodash.custom.js';
import uiModal from './ui-items/ui-modal.vue';
import { api, dispatcher, hasAuth, scopeName, loginFields, remoteName, reloadOnLogout } from "./index";
export default {
  name: "auth",
  components: {
    uiModal: uiModal
  },
  props: {
    hasLegalText: {
      default: false
    },
    showing: {
      default: false
    }
  },
  data: function data() {
    return {
      hasAuth: hasAuth,
      scopeName: scopeName,
      loginFields: loginFields,
      remoteName: remoteName,
      reloadOnLogout: reloadOnLogout,
      scopes: [],
      selectedScope: null,
      loginRef: null,
      scopeRef: null,
      loginData: {}
    };
  },
  watch: {
    isAuth: function isAuth() {
      this._checkAuth();
    },
    hasScope: function hasScope() {
      this._checkAuth();
    },
    showingModal: function showingModal(_showingModal) {
      this.$emit('update:showing', _showingModal);
    }
  },
  computed: {
    isAuth: function isAuth() {
      return !!dispatcher.isAuth;
    },
    hasScope: function hasScope() {
      return dispatcher.hasScope !== null;
    },
    scopeSelectTitle: function scopeSelectTitle() {
      return 'Select ' + this.scopeName;
    },
    scopeSelectActionTitle: function scopeSelectActionTitle() {
      return this.scopeSelectTitle;
    },
    loginTitle: function loginTitle() {
      var title = 'Login';
      if (this.remoteName) title += ' to ' + this.remoteName;
      return title;
    },
    showingModal: function showingModal() {
      if (!this.loginRef || !this.scopeRef) return false;
      return this.loginRef.opened || this.scopeRef.opened;
    },
    actionLinkTexts: function actionLinkTexts() {
      var actionLinks = {};
      if (this.hasLegalText) actionLinks['legal'] = 'Legal - impressum';
      actionLinks['about'] = 'About MDAx';
      return actionLinks;
    }
  },
  methods: {
    _showLogin: function _showLogin() {
      this._resetLoginForm();

      this.$refs.loginModal.open();
    },
    _hasLoginField: function _hasLoginField(field) {
      return _.includes(this.loginFields, field);
    },
    _resetLoginForm: function _resetLoginForm() {
      this.loginData = {
        username: '',
        password: '',
        remember: false
      };
    },
    _doLogin: function _doLogin() {
      if (this.reloadOnLogout) return;

      var _c = this;

      this.$validator.validateAll('auth').then(function (valid) {
        if (!valid) {
          _c._resetLoginForm();

          return;
        }

        api.login(_c.loginData, function () {
          _c._processLogin();
        }, function () {
          _c._resetLoginForm();
        });
      }); // Prevent closing of modal

      return false;
    },
    _hideLogin: function _hideLogin() {
      this.$refs.loginModal.close();

      this._resetLoginForm();
    },
    _processLogin: function _processLogin() {
      if (this.hasScope) {
        this._hideLogin();

        this._refresh();
      }
    },
    _showScopeSelect: function _showScopeSelect() {
      var _this = this;

      this.scopes = [];
      this.selectedScope = null;
      api.listScopes(function (scopes) {
        _this.scopes = scopes;

        _this.$refs.scopeModal.open();

        _this._hideLogin();
      });
    },
    _doScopeSelect: function _doScopeSelect() {
      var _c = this;

      this.$validator.validateAll('scope').then(function (valid) {
        if (!valid) {
          return;
        }

        api.selectScope(_c.selectedScope, function () {
          if (_c.hasScope) {
            _c._hideScopeSelect();

            _c._refresh();
          }
        });
      });
      return false;
    },
    _hideScopeSelect: function _hideScopeSelect() {
      this.$refs.scopeModal.close();
    },
    _refresh: function _refresh() {
      dispatcher.backendStateUpdated();
    },
    _checkAuth: function _checkAuth() {
      if (!this.isAuth) {
        this._showLogin();

        if (this.reloadOnLogout) window.location.reload();
      } else if (!this.hasScope) {
        this._showScopeSelect();
      }
    },
    _actionLink: function _actionLink(key) {
      if (key === 'legal') this._displayLegal();
      if (key === 'about') this._displayAbout();
    },
    _displayLegal: function _displayLegal() {
      this.$emit('displayLegal');
    },
    _displayAbout: function _displayAbout() {
      this.$emit('displayAbout');
    }
  },
  mounted: function mounted() {
    var _this2 = this;

    this.loginRef = this.$refs.loginModal;
    this.scopeRef = this.$refs.scopeModal;
    setTimeout(function () {
      return _this2._checkAuth();
    }, 100);
  }
};