//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../../lodash.custom.js';
import uiModal from '../ui-modal.vue';
import varRouting from './var-routing.vue';
import connectVar from './connect-var.vue';
import { findVarById, hasCollision as _hasCollision, hasRoutingCollision as _hasRoutingCollision } from '../../var-utils.js';
import { functionTypes } from "../mdao/functions.js";
import { api, dispatcher } from '../../index.js';
import Vue from 'vue'; // Insert zero-width-spaces when displaying xpaths: https://stackoverflow.com/a/24489931

Vue.filter('xpath', function (xpath) {
  return xpath.replace(/\//g, "\u200B/");
});
export default {
  name: "var-info",
  components: {
    uiModal: uiModal,
    varRouting: varRouting,
    connectVar: connectVar
  },
  props: {
    functions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // Same format as graphDefObj.functions (see xdsm.vue)
    routingEditable: {
      type: Boolean,
      default: false
    },
    varEditable: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      api: api,
      dispatcher: dispatcher,
      isLargeAttr: [],
      varDef: null
    };
  },
  watch: {
    varDef: function varDef() {
      var nAttr = Object.keys(this.attr).length;
      this.isLargeAttr = _.map(_.range(nAttr), function () {
        return false;
      });
    }
  },
  computed: {
    name: function name() {
      return this.varDef ? this.varDef.name : '';
    },
    xpath: function xpath() {
      return this.varDef ? this.varDef.xpath : '';
    },
    modalTitle: function modalTitle() {
      return 'Variable Information: ' + this.name;
    },
    varId: function varId() {
      return this.varDef ? this.varDef.id : null;
    },
    attr: function attr() {
      return this.varDef ? this.varDef.attrib : {};
    },
    svm: function svm() {
      return this.varDef ? this.varDef.svm || {} : {};
    },
    hasAttr: function hasAttr() {
      return Object.keys(this.attr).length > 0;
    },
    hasSubValueMatch: function hasSubValueMatch() {
      return Object.keys(this.svm).length > 0;
    },
    isVector: function isVector() {
      return 'mapType' in this.attr && this.attr['mapType'] == 'vector';
    },
    hasCollision: function hasCollision() {
      return this.routingEditable && this.varDef ? _hasCollision(this.varDef) : false;
    },
    hasRoutingCollision: function hasRoutingCollision() {
      return this.routingEditable && this.varDef ? _hasRoutingCollision(this.varDef) : false;
    },
    hasEditRouteButton: function hasEditRouteButton() {
      return this.routingEditable; // return this.routingEditable && this.hasCollision;
    },
    routing: function routing() {
      if (!this.varDef) return [];
      var functions = this.functions;

      function getFunctionDefs(functionIds) {
        return _.filter(_.map(functions, function (functionDef) {
          return functionDef.id != 0 && _.includes(functionIds, functionDef.id) ? functionDef : null;
        }));
      }

      var routingData = [];

      _.forEach(this.varDef.routing, function (routingFunctionIds) {
        var inFunctionIds = routingFunctionIds.in;
        var outFunctionIds = routingFunctionIds.out;
        routingData.push({
          in: getFunctionDefs(inFunctionIds),
          out: getFunctionDefs(outFunctionIds)
        });
      }); // If no routing is specified (variable is part of base schema), add a placeholder


      if (routingData.length == 0) {
        return [{
          in: [],
          out: []
        }];
      } // Order by outputting function order


      var functionIdxMap = _.fromPairs(_.map(functions, function (functionDef, idx) {
        return [functionDef.id, idx];
      }));

      return _.sortBy(routingData, function (routeDef) {
        return routeDef.out.length > 0 ? functionIdxMap[routeDef.out[0].id] : -1;
      });
    },
    connectedOutFunctionIds: function connectedOutFunctionIds() {
      return this.varDef ? this.varDef.fun_con.out : [];
    },
    connectedInFunctionIds: function connectedInFunctionIds() {
      return this.varDef ? this.varDef.fun_con.in : [];
    },
    toolFunctionIds: function toolFunctionIds() {
      return _.filter(_.map(this.functions.slice(1), function (functionDef) {
        return functionDef.type == functionTypes.TOOL ? functionDef.id : null;
      }));
    },
    availableOutToolFunctionIds: function availableOutToolFunctionIds() {
      var connectedFunctionIds = this.connectedOutFunctionIds;
      return _.filter(this.toolFunctionIds, function (functionId) {
        return !_.includes(connectedFunctionIds, functionId);
      });
    },
    availableInToolFunctionIds: function availableInToolFunctionIds() {
      var connectedFunctionIds = this.connectedInFunctionIds;
      return _.filter(this.toolFunctionIds, function (functionId) {
        return !_.includes(connectedFunctionIds, functionId);
      });
    },
    hasUnusedToolFunctionsOut: function hasUnusedToolFunctionsOut() {
      return this.availableOutToolFunctionIds.length > 0;
    },
    hasUnusedToolFunctionsIn: function hasUnusedToolFunctionsIn() {
      return this.availableInToolFunctionIds.length > 0;
    }
  },
  methods: {
    open: function open(varDef) {
      this.varDef = _.cloneDeep(varDef);
      this.$refs.modal.open();
    },
    close: function close() {
      this.$refs.modal.close();
    },
    _opened: function _opened() {
      setTimeout(this._resizeAttr, 0);
      this.dispatcher.onUpdatedRootVarDef(this.rootVarDefUpdated);
    },
    _closed: function _closed() {
      this.dispatcher.offUpdatedRootVarDef(this.rootVarDefUpdated);
    },
    rootVarDefUpdated: function rootVarDefUpdated(rootVarDef) {
      if (!rootVarDef) {
        this.close();
        return;
      }

      var varId = this.varId;
      if (!varId) return;
      var varDef = findVarById(rootVarDef, varId);

      if (varDef) {
        this.varDef = varDef;
      } else {
        this.close();
      }
    },
    _deleteToolVar: function _deleteToolVar(isInput, functionId) {
      var _c = this;

      this.api.editDeleteVar(functionId, isInput, this.varId, function () {
        _c.dispatcher.backendStateUpdated();
      });
    },
    _connectVar: function _connectVar(isInput) {
      var functionIds = isInput ? this.availableInToolFunctionIds : this.availableOutToolFunctionIds;
      this.$refs.connectVar.open(this.varDef, isInput, functionIds);
    },
    _doConnect: function _doConnect(isInput, functionId) {
      var _c = this;

      this.api.editConnectVar(functionId, isInput, this.varDef.id, function () {
        _c.dispatcher.backendStateUpdated();
      });
    },
    _resizeAttr: function _resizeAttr() {
      var attr = this.attr;
      var keys = Object.keys(attr);

      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];

        if (attr.hasOwnProperty(key)) {
          var refEl = this.$refs.attr[0];
          var elWidth = refEl.offsetWidth;
          var elParentWidth = refEl.parentElement.offsetWidth;

          if (elWidth > elParentWidth) {
            this.$set(this.isLargeAttr, i, true);
          }
        }
      }
    },
    _resetRouting: function _resetRouting() {
      var _c = this;

      this.api.editResetVarRouting(this.varId, function (wasReset) {
        if (wasReset) {
          _c.dispatcher.backendStateUpdated();
        }
      });
    },
    _XPathCopy: function _XPathCopy(e) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/copy_event
      var xpathSelected = document.getSelection().toString();
      var xpathCopy = xpathSelected.replace(/\u200B/g, '');
      e.clipboardData.setData('text/plain', xpathCopy);
      e.preventDefault();
    }
  }
};