//
//
//
//
//
//
//
//
//
import _ from '../lodash.custom.js';
export default {
  name: "progress-bar",
  props: {
    progress: {
      type: Number,
      required: true
    },
    // 0 to 1
    status: {
      default: '',
      validator: function validator(value) {
        return _.includes(['', 'success', 'error'], value);
      }
    }
  },
  data: function data() {
    return {
      wrapperEl: null,
      progressTextEl: null,
      wrapperWidth: 100,
      progressTextWidth: 0
    };
  },
  watch: {
    progress: function progress() {
      this._domUpdate();
    }
  },
  computed: {
    safeProgress: function safeProgress() {
      return this.progress < 0 ? 0 : this.progress > 1 ? 1 : this.progress;
    },
    posStyle: function posStyle() {
      return this.safeProgress * 100 + '%';
    },
    barClass: function barClass() {
      return this.status;
    },
    progressTextLeft: function progressTextLeft() {
      return this.wrapperWidth * (1 - this.safeProgress) < this.progressTextWidth;
    },
    progressClass: function progressClass() {
      return this.progressTextLeft ? 'left' : '';
    },
    progressText: function progressText() {
      return Math.round(this.safeProgress * 100) + '%';
    }
  },
  methods: {
    _domUpdate: function _domUpdate() {
      this.wrapperWidth = this.wrapperEl ? this.wrapperEl.getBoundingClientRect().width : 100;
      this.progressTextWidth = this.progressTextEl ? this.progressTextEl.getBoundingClientRect().width : 0;
    }
  },
  mounted: function mounted() {
    this.wrapperEl = this.$refs.wrapper;
    this.progressTextEl = this.$refs.progressText;

    this._domUpdate();
  }
};