var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isRadio
      ? _c(
          "div",
          _vm._l(_vm.options, function(opt) {
            return _c("div", { staticClass: "radio" }, [
              _c(
                "label",
                {
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.modelValue = opt[0]
                    }
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.modelValue,
                        expression: "modelValue"
                      }
                    ],
                    attrs: { type: "radio", name: _vm.inputName },
                    domProps: {
                      value: opt[0],
                      checked: _vm._q(_vm.modelValue, opt[0])
                    },
                    on: {
                      input: _vm._input,
                      click: function($event) {
                        $event.stopPropagation()
                      },
                      change: function($event) {
                        _vm.modelValue = opt[0]
                      }
                    }
                  }),
                  _vm._v(
                    "\n                " + _vm._s(opt[1]) + "\n            "
                  )
                ]
              )
            ])
          })
        )
      : _c("label", [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.modelValue,
                  expression: "modelValue"
                }
              ],
              staticClass: "form-control",
              on: {
                input: _vm._input,
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.modelValue = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            _vm._l(_vm.options, function(opt) {
              return _c("option", { domProps: { value: opt[0] } }, [
                _vm._v(_vm._s(opt[1]))
              ])
            })
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }