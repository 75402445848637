//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../../lodash.custom.js';
import uiModal from '../ui-modal.vue';
import xdsmData from '../xdsm/xdsm-data.vue';
import xdsmFunction from '../xdsm/xdsm-function.vue';
import arrows from '../arrows.vue';
import { api, dispatcher } from '../../index.js';
export default {
  name: "connect-var",
  components: {
    uiModal: uiModal,
    xdsmData: xdsmData,
    xdsmFunction: xdsmFunction,
    arrows: arrows
  },
  props: {
    functions: {
      type: Array,
      default: function _default() {
        return [];
      }
    } // Same format as graphDefObj.functions (see xdsm.vue)

  },
  data: function data() {
    return {
      api: api,
      dispatcher: dispatcher,
      varDef: null,
      isInput: null,
      availableFunctionIds: null,
      selectedFunctionId: null,
      pos: null,
      functionHoverIdx: null,
      arrowMargin: 20
    };
  },
  computed: {
    name: function name() {
      return this.varDef ? this.varDef.name : '';
    },
    modalTitle: function modalTitle() {
      return 'Connect Variable: ' + this.name;
    },
    modalSubTitle: function modalSubTitle() {
      return this.isInput ? 'As input to tool' : 'As output from tool';
    },
    hasSelect: function hasSelect() {
      return this.availableFunctionIds ? this.availableFunctionIds.length > 8 : true;
    },
    availableFunctions: function availableFunctions() {
      if (!this.availableFunctionIds) return [];
      var functions = this.functions;
      return _.filter(_.map(this.availableFunctionIds, function (functionId) {
        return _.find(functions, function (functionDef) {
          return functionDef.id == functionId;
        });
      }));
    },
    options: function options() {
      return _.map(this.availableFunctions, function (functionDef) {
        return [functionDef.id, functionDef.title];
      });
    },
    arrows: function arrows() {
      if (!this.pos) return [];
      var varPos = this.pos.var;
      var m = this.arrowMargin;
      var isInput = this.isInput;
      var fromPos, toPos;

      if (this.hasSelect) {
        var selPos = this.pos.sel;
        fromPos = isInput ? varPos : selPos;
        toPos = !isInput ? varPos : selPos;
      } else {
        var funcIdx = this.functionHoverIdx;
        if (funcIdx === null) return [];
        var funcPos = this.pos.func[funcIdx];
        fromPos = isInput ? varPos : funcPos;
        toPos = !isInput ? varPos : funcPos;
      }

      var _fromPos = fromPos,
          x1 = _fromPos.x,
          y1 = _fromPos.y,
          w1 = _fromPos.width;
      var _toPos = toPos,
          x2 = _toPos.x,
          y2 = _toPos.y,
          w2 = _toPos.width;
      x1 += .5 * w1 + m;
      x2 -= .5 * w2 + m;
      var xMid = .5 * (x1 + x2);
      var yMid = .5 * (y1 + y2);
      return ["M".concat(x1, ",").concat(y1, " L").concat(xMid, ",").concat(yMid, " L").concat(x2, ",").concat(y2)];
    }
  },
  methods: {
    open: function open(varDef, isInput, availableFunctionIds) {
      if (!availableFunctionIds) return;
      this.availableFunctionIds = availableFunctionIds;
      this.isInput = isInput;
      this.varDef = varDef;
      this.selectedFunctionId = availableFunctionIds[0];
      this.functionHoverIdx = null;
      this.$refs.modal.open();
    },
    close: function close() {
      this.$refs.modal.close();
    },
    _opened: function _opened() {
      this._setPos();
    },
    _setPos: function _setPos() {
      var rootBbox = this.$refs.wrapper.getBoundingClientRect();

      function getPos(el) {
        var bbox = el.getBoundingClientRect();
        var x = bbox.left - rootBbox.left + 0.5 * bbox.width;
        var y = bbox.top - rootBbox.top + 0.5 * bbox.height;
        return {
          x: x,
          y: y,
          width: bbox.width,
          height: bbox.height
        };
      }

      var pos = {
        var: getPos(this.$refs.var)
      };

      if (this.hasSelect) {
        pos.sel = getPos(this.$refs.select);
      } else {
        pos.func = _.map(this.$refs.functionBlock, function (el) {
          return getPos(el);
        });
      }

      this.pos = pos;
    },
    _submit: function _submit() {
      this.$emit('connect', this.isInput, this.selectedFunctionId);
    },
    _selectFunction: function _selectFunction(functionId) {
      this.$emit('connect', this.isInput, functionId);
      this.close();
    }
  }
};