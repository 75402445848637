//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../lodash.custom.js';
import uiModal from './ui-modal.vue';
import { functionTypeTitles, functionTypeTitlesPlural } from './mdao/functions.js';
import { walkLeafVars } from '../var-utils.js';

function formatNr(nr) {
  // The French locale gives use spaces as thousands-separator: 1000000 --> 1 000 000
  return new Intl.NumberFormat('fr').format(nr);
}

export default {
  name: "statistics",
  components: {
    uiModal: uiModal
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    graphDef: {
      type: Object,
      default: null
    },
    rootVarDef: {
      type: Object,
      default: null
    },
    hasBranches: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      stats: []
    };
  },
  computed: {
    title: function title() {
      return this.project ? this.project.title : '';
    },
    branchTitle: function branchTitle() {
      return this.project ? this.project.branch_title : '';
    },
    functions: function functions() {
      return this.graphDef ? this.graphDef.functions.slice(1) : [];
    },
    functionStats: function functionStats() {
      var functions = this.functions; // Number of functions

      var nFuncs = formatNr(functions.length); // Number of functions by type

      var nFuncsByType = _.filter(_.map(functionTypeTitlesPlural, function (title, key) {
        var nr = _.filter(functions, function (functionDef) {
          return functionDef.type == key;
        }).length;

        if (!nr) return null;
        var functionTitle = nr == 1 ? functionTypeTitles[key] : title;
        return formatNr(nr) + ' ' + functionTitle;
      })); // Statistics


      var functionStats = [{
        label: 'Functions',
        value: nFuncs
      }];

      if (nFuncsByType.length > 0) {
        functionStats.push({
          label: '<span class="light">by type</span>',
          value: nFuncsByType.join('<br />')
        });
      }

      return functionStats;
    },
    varStats: function varStats() {
      if (!this.rootVarDef) return [];
      var functions = this.functions; // Variables by type

      var varTypes = {
        input: 0,
        output: 0,
        coupling: 0,
        fb: 0,
        x: 0,
        f: 0,
        g: 0,
        y: 0
      };
      var varTypeTitles = {
        input: ['system input', 'system inputs'],
        output: ['system output', 'system outputs'],
        coupling: ['coupling variable', 'coupling variables'],
        fb: ['feedback variable', 'feedback variables'],
        x: ['design variable', 'design variables'],
        f: ['objective', 'objectives'],
        g: ['constraint', 'constraints'],
        y: ['state variable', 'state variables']
      };
      var nVars = 0;

      var fIdxMap = _.fromPairs(_.map(functions, function (functionDef, idx) {
        return [functionDef.id, idx];
      }));

      walkLeafVars(this.rootVarDef, function (varDef) {
        nVars += 1; // Determine function connections

        var outFuncs = _.flatten(_.map(varDef.routing, function (routingDef) {
          return routingDef.out;
        }));

        var inFuncs = _.flatten(_.map(varDef.routing, function (routingDef) {
          return routingDef.in;
        })); // System input/output


        if (outFuncs.length == 0) varTypes.input++;
        if (outFuncs.length > 0) varTypes.output++; // Coupling

        if (outFuncs.length > 0 && inFuncs.length > 0) varTypes.coupling++; // Feedback

        if (_.findIndex(varDef.routing, function (routingDef) {
          for (var i = 0; i < routingDef.out.length; i++) {
            var outIdx = fIdxMap[routingDef.out[i]];

            for (var j = 0; j < routingDef.in.length; j++) {
              var inIdx = fIdxMap[routingDef.in[j]];
              if (inIdx < outIdx) return true;
            }
          }

          return false;
        }) != -1) varTypes.fb++;
      });
      if (nVars == 0) return [];
      nVars = formatNr(nVars);

      _.forEach(functions, function (functionDef) {
        // Number of MDAO variables (x, f, g, y)
        var mdaoData = functionDef.mdao;
        if (!mdaoData) return;
        var keys = ['x', 'f', 'g', 'y'];

        _.forEach(keys, function (key) {
          if (!mdaoData[key]) return;
          varTypes[key] += mdaoData[key].length;
        });
      });

      var varTypesData = _.filter(_.map(varTypes, function (nr, key) {
        if (!nr) return null;
        var title = nr == 1 ? varTypeTitles[key][0] : varTypeTitles[key][1];
        return formatNr(nr) + ' ' + title;
      })); // Statistics


      var varStats = [{
        label: 'Variables',
        value: nVars
      }];

      if (varTypesData.length > 0) {
        varStats.push({
          label: '<span class="light">by type</span>',
          value: varTypesData.join('<br />')
        });
      }

      return varStats;
    }
  },
  methods: {
    open: function open() {
      this.stats = this._getStats();
      this.$refs.modal.open();
    },
    close: function close() {
      this.$refs.modal.close();
    },
    _getStats: function _getStats() {
      return _.concat(this.functionStats, this.varStats);
    }
  }
};