var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("select-table", {
    attrs: {
      columns: _vm.columns,
      data: _vm.tableData,
      "has-add-row": _vm.canSelect
    },
    on: { delete: _vm._deleteHandler, add: _vm._addHandler },
    scopedSlots: _vm._u([
      {
        key: "add-title",
        fn: function(scope) {
          return [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedFunctionId,
                      expression: "selectedFunctionId"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedFunctionId = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.selectableFunctions, function(f) {
                  return _c("option", { domProps: { value: f.id } }, [
                    _vm._v(_vm._s(f.title))
                  ])
                })
              )
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }