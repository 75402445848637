//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../lodash.custom.js';
import uiModal from './ui-modal.vue';
import uiDialog from './ui-dialog.vue';
import { VueGoodTable } from 'vue-good-table';
import { getFlatVars, getNonConflictingVarNames } from "../var-utils";
import { api, dispatcher, staticBaseUrl, previewMode } from '../index.js';
import { standaloneMode } from "../api";
var autoRoutingMethods = null,
    noAutoPreviewImg = 'no_auto.png';

if (standaloneMode && typeof window.standaloneAutoCollResData != 'undefined') {
  autoRoutingMethods = window.standaloneAutoCollResData[0];
  noAutoPreviewImg = window.standaloneAutoCollResData[1];
}

var NO_ROUTING_KEY = 'NO_AUTO_ROUTING';
export default {
  name: "auto-routing-modal",
  components: {
    uiModal: uiModal,
    uiDialog: uiDialog,
    VueGoodTable: VueGoodTable
  },
  props: {
    graphDef: {
      type: Object
    },
    rootVarDef: {
      type: Object
    },
    project: {
      type: Object
    },
    editable: {
      type: Boolean
    }
  },
  data: function data() {
    return {
      standaloneMode: standaloneMode,
      previewMode: previewMode,
      autoRoutingMethods: null,
      routingMethod: null,
      staticBaseUrl: null,
      customCollResColumns: [{
        field: 'nr',
        label: '#'
      }, {
        field: 'varName',
        label: 'Variable'
      }, {
        field: 'actions',
        label: 'Actions',
        globalSearchDisabled: true,
        html: true,
        sortable: false
      }],
      searchOptions: {
        enabled: true,
        placeholder: 'Search'
      },
      sortOptions: {
        enabled: true,
        initialSortBy: {
          field: 'nr',
          type: 'asc'
        }
      }
    };
  },
  computed: {
    shownAutoRoutingMethods: function shownAutoRoutingMethods() {
      return _.concat([{
        key: NO_ROUTING_KEY,
        title: 'No automated variable routing',
        preview_img: noAutoPreviewImg
      }], this.autoRoutingMethods || []);
    },
    projectId: function projectId() {
      return this.project ? this.project.id : null;
    },
    branchId: function branchId() {
      return this.project ? this.project.branch_id : null;
    },
    currentAutoRoutingMethodKey: function currentAutoRoutingMethodKey() {
      return this.graphDef && this.graphDef.auto_routing_method ? this.graphDef.auto_routing_method : NO_ROUTING_KEY;
    },
    currentAutoRoutingMethod: function currentAutoRoutingMethod() {
      var key = this.currentAutoRoutingMethodKey;
      return _.find(this.shownAutoRoutingMethods, function (method) {
        return method.key == key;
      });
    },
    selectedRoutingMethod: function selectedRoutingMethod() {
      var key = this.routingMethod;
      return _.find(this.shownAutoRoutingMethods, function (method) {
        return method.key == key;
      });
    },
    currentPreviewImgUrl: function currentPreviewImgUrl() {
      var method = this.selectedRoutingMethod;
      if (!method) return null;
      if (this.standaloneMode) return method.preview_img;
      return this.staticBaseUrl + 'img/' + method.preview_img;
    },
    flatVars: function flatVars() {
      return this.rootVarDef ? getFlatVars(this.rootVarDef) : {};
    },
    collRes: function collRes() {
      return this.graphDef ? this.graphDef.coll_res : [];
    },
    customCollRes: function customCollRes() {
      return _.filter(this.collRes, function (collResDef) {
        return !collResDef.auto;
      });
    },
    customCollResData: function customCollResData() {
      var varIds = _.map(this.customCollRes, function (collResData) {
        return collResData.var_id;
      });

      var varNames = getNonConflictingVarNames(varIds, this.flatVars);
      return _.map(this.customCollRes, function (collResData, i) {
        return {
          nr: (i + 1).toString(),
          varName: varNames[i],
          idx: collResData.idx,
          varId: collResData.var_id
        };
      });
    }
  },
  methods: {
    open: function open() {
      this.routingMethod = this.currentAutoRoutingMethodKey;
      this.$refs.modal.open();
    },
    close: function close() {
      this.$refs.modal.close();
    },
    _opened: function _opened() {
      this.$emit('opened');
    },
    _submit: function _submit() {
      var _this = this;

      if (!this.editable) return true;

      var _c = this;

      var routingMethod = this.routingMethod != NO_ROUTING_KEY ? this.routingMethod : null;
      api.editProjectAutoRouting(routingMethod, function (graphDef) {
        dispatcher.updatedGraph(_this.projectId, _this.branchId, graphDef);

        _c.close();
      }); // Prevent closing of modal

      return false;
    },
    _viewVar: function _viewVar(varId) {
      var varDef = this.flatVars[varId];
      if (varDef) this.$emit('info', varDef);
    },
    _editRouting: function _editRouting(varId) {
      var varDef = this.flatVars[varId];
      if (varDef) this.$emit('routing', varDef);
    },
    _removeCollRes: function _removeCollRes(idx) {
      api.removeCollRes(idx, function () {
        dispatcher.backendStateUpdated();
      });
    }
  },
  created: function created() {
    this.staticBaseUrl = staticBaseUrl;

    if (autoRoutingMethods) {
      this.autoRoutingMethods = autoRoutingMethods;
    } else {
      var _c = this;

      api.getAutoRoutingMethods(function (methods) {
        autoRoutingMethods = methods;
        _c.autoRoutingMethods = methods;
      });
    }
  }
};