import { render, staticRenderFns } from "./base-schema-modal.vue?vue&type=template&id=0974d44e&scoped=true&"
import script from "./base-schema-modal.vue?vue&type=script&lang=js&"
export * from "./base-schema-modal.vue?vue&type=script&lang=js&"
import style0 from "./base-schema-modal.vue?vue&type=style&index=0&id=0974d44e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0974d44e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\garg_sp\\Desktop\\Sparsh\\Sparsh\\MDAx\\06_Tool_Installation\\Cloned_20240827\\mdax\\mdax\\ui\\web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('0974d44e', component.options)
    } else {
      api.reload('0974d44e', component.options)
    }
    module.hot.accept("./base-schema-modal.vue?vue&type=template&id=0974d44e&scoped=true&", function () {
      api.rerender('0974d44e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui-items/base-schema-modal.vue"
export default component.exports