var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      ref: "modal",
      attrs: {
        title: _vm.modalTitle,
        "sub-title": _vm.subTitle || "",
        "has-buttons": _vm.selectable,
        action: _vm._doSelect,
        "action-title": "Select",
        "confirm-close": false,
        classes: ["var-list-modal"],
        "pivot-y": 0.00001,
        draggable: !_vm.modal,
        wide: "50%"
      },
      on: { opened: _vm._opened, closed: _vm._closed, change: _vm._modalChange }
    },
    [
      _vm.subTitleFunctionDef
        ? _c(
            "div",
            { staticClass: "subtitle-function" },
            [
              _c("xdsm-function", {
                attrs: { "function-def": _vm.subTitleFunctionDef }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.scrollContainer && _vm.rootVarDef
        ? _c("var-list", {
            ref: "varList",
            staticClass: "var-list-container",
            attrs: {
              "root-var-def": _vm.rootVarDef,
              "scroll-container": _vm.scrollContainer,
              "sticky-to-window": true,
              "graph-def": _vm.graphDef,
              editable: _vm.editable,
              deletable: _vm.deletable,
              "search-ids": _vm.searchIds,
              "highlight-ids": _vm.highlightIds,
              "disabled-ids": _vm.disabledIds,
              "main-ids": _vm.mainIds,
              padding: 15,
              "multi-select": _vm.multiSelect,
              "select-leaf": _vm.selectLeaf,
              "select-parent": _vm.selectParent,
              "selected-ids": _vm.listSelectedIds,
              "search-placeholder": _vm.searchPlaceholder,
              "routing-editable": _vm.routingEditable,
              "var-editable": _vm.varEditable,
              modal: _vm.modal,
              "sticky-close-button": true
            },
            on: {
              create: _vm._showCreate,
              search: _vm._search,
              connect: _vm._connect,
              delete: _vm._delete,
              edit: _vm._showEdit,
              info: _vm._showInfo,
              routing: _vm._showRouting,
              close: _vm.close,
              "update:selectedIds": _vm._updateSelectedIds
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ui-dialog",
        { ref: "confirmDeleteDialog", attrs: { "action-title": "Delete" } },
        [
          _vm._v(
            "\n        Are you sure you want to delete the following variable?"
          ),
          _c("br"),
          _vm._v(" "),
          _c("h3", { staticStyle: { "text-align": "center" } }, [
            _vm._v(_vm._s(_vm.confirmDeleteVarDef.name))
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { "margin-top": "25px" } }, [
            _vm._v("\n            XPath:"),
            _c("br"),
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("xpath")(_vm.confirmDeleteVarDef.xpath || "")) +
                "\n        "
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("var-info", {
        ref: "varInfo",
        attrs: {
          functions: _vm.functions,
          "routing-editable": _vm.routingEditable,
          "var-editable": _vm.varEditable,
          modal: _vm.modal
        },
        on: { routing: _vm._showRouting, edit: _vm._showEdit }
      }),
      _vm._v(" "),
      _c("create-var", { ref: "createVar", on: { create: _vm._create } }),
      _vm._v(" "),
      _c("routing-select", {
        ref: "routingSelect",
        attrs: { "graph-def": _vm.graphDef }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }