var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        "action-title": "Update",
        "confirm-close": false,
        action: _vm._submit,
        "action-error": !_vm.isValid
      },
      on: { opened: _vm._opened }
    },
    [
      _c("div", { staticClass: "form-horizontal settings-form" }, [
        _c(
          "div",
          {
            staticClass: "form-group",
            class: { "has-error": _vm.errors.has("settings.title") }
          },
          [
            _c("label", { staticClass: "col-sm-3 control-label" }, [
              _vm._v("Project Name\n                "),
              _c("span", { staticClass: "error-note" }, [_vm._v("required")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-9" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.project.title,
                    expression: "project.title"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                ref: "title",
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  name: "title",
                  placeholder: "Project name",
                  "data-vv-validate-on": "input|change|blur",
                  "data-vv-scope": "settings"
                },
                domProps: { value: _vm.project.title },
                on: {
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm._submit($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.project, "title", $event.target.value)
                  }
                }
              })
            ])
          ]
        ),
        _vm._v(" "),
        _vm.hasBranches
          ? _c(
              "div",
              {
                staticClass: "form-group",
                class: { "has-error": _vm.errors.has("settings.branch_title") }
              },
              [
                _c("label", { staticClass: "col-sm-3 control-label" }, [
                  _vm._v("Branch Name\n                "),
                  _c("span", { staticClass: "error-note" }, [
                    _vm._v("required")
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.project.branch_title,
                        expression: "project.branch_title"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    ref: "branchTitle",
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "branch_title",
                      placeholder: "Branch name",
                      "data-vv-validate-on": "input|change|blur",
                      "data-vv-scope": "settings"
                    },
                    domProps: { value: _vm.project.branch_title },
                    on: {
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm._submit($event)
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.project,
                          "branch_title",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.ioMode
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-3 control-label" }, [
                _vm._v("Auto Sort")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-9" }, [
                _c("div", { staticClass: "checkbox" }, [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.project.auto_sort_func,
                          expression: "project.auto_sort_func"
                        }
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.project.auto_sort_func)
                          ? _vm._i(_vm.project.auto_sort_func, null) > -1
                          : _vm.project.auto_sort_func
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.project.auto_sort_func,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.project,
                                  "auto_sort_func",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.project,
                                  "auto_sort_func",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.project, "auto_sort_func", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(
                      "\n                        Automatically sort blocks when adding a new tool\n                    "
                    )
                  ])
                ])
              ])
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }