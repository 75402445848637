//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../../lodash.custom.js';
export default {
  name: "wrapping-select",
  props: {
    value: {
      default: null
    },
    // lastWrappedFunctionId
    functionPos: {
      type: Object,
      required: true
    },
    wrappingFunctionId: {
      required: true
    },
    // // [{id: functionId, wraps: <sub-nesting-list>}, ...]
    origFunctionWrapping: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    scale: {
      required: true
    },
    wrappedFunctionIds: {
      default: null
    }
  },
  data: function data() {
    return {
      isDragging: false,
      draggingAngle: null,
      svgPos: null,
      relMousePos: null,
      hasCheckedValue: false,
      relStrokeWidth: 10
    };
  },
  watch: {
    functionPos: function functionPos() {
      this._checkValue();
    },
    theWrappedFunctionIds: function theWrappedFunctionIds() {
      this._debouncedUpdateWrappedFunctionIds();
    }
  },
  computed: {
    wrapperFunctionPos: function wrapperFunctionPos() {
      return this.functionPos.hasOwnProperty(this.wrappingFunctionId) ? this.functionPos[this.wrappingFunctionId] : null;
    },
    safeScale: function safeScale() {
      return this.scale ? this.scale : 1;
    },
    absFunctionPosWrap: function absFunctionPosWrap() {
      var scale = this.safeScale;
      return _.mapValues(this.functionPos, function (functionPos) {
        var left = functionPos.left,
            top = functionPos.top;
        left *= scale;
        top *= scale;
        return {
          left: left,
          top: top
        };
      });
    },
    wrappingFunctionPos: function wrappingFunctionPos() {
      var renderWrapFunctionId = this.dragSelectFunctionRenderId || this.renderValue;
      return this.functionPos.hasOwnProperty(renderWrapFunctionId) ? this.functionPos[renderWrapFunctionId] : null;
    },
    rectProps: function rectProps() {
      var wrappingFunctionPos = this.wrapperFunctionPos;
      var lastWrappedFunctionPos = this.wrappingFunctionPos;
      if (wrappingFunctionPos === null || lastWrappedFunctionPos === null) return null;
      var x1 = wrappingFunctionPos.left,
          y1 = wrappingFunctionPos.top,
          w1 = wrappingFunctionPos.width,
          h1 = wrappingFunctionPos.height;
      var x2 = lastWrappedFunctionPos.left,
          y2 = lastWrappedFunctionPos.top,
          w2 = lastWrappedFunctionPos.width,
          h2 = lastWrappedFunctionPos.height;
      return {
        x1: x1,
        y1: y1,
        w1: w1,
        h1: h1,
        x2: x2,
        y2: y2,
        w2: w2,
        h2: h2
      };
    },
    strokeWidth: function strokeWidth() {
      return this.relStrokeWidth / this.safeScale;
    },
    leftPath: function leftPath() {
      var _this$rectProps = this.rectProps,
          x1 = _this$rectProps.x1,
          y1 = _this$rectProps.y1,
          h1 = _this$rectProps.h1,
          y2 = _this$rectProps.y2;
      var w = this.strokeWidth;
      return "M".concat(x1, ",").concat(y1 + .5 * h1, " L").concat(x1, ",").concat(y2 + .5 * w);
    },
    topPath: function topPath() {
      var _this$rectProps2 = this.rectProps,
          x1 = _this$rectProps2.x1,
          y1 = _this$rectProps2.y1,
          w1 = _this$rectProps2.w1,
          x2 = _this$rectProps2.x2;
      var w = this.strokeWidth;
      return "M".concat(x1 + .5 * w1, ",").concat(y1, " L").concat(x2 + .5 * w, ",").concat(y1);
    },
    rightPath: function rightPath() {
      var _this$rectProps3 = this.rectProps,
          y1 = _this$rectProps3.y1,
          x2 = _this$rectProps3.x2,
          y2 = _this$rectProps3.y2,
          h2 = _this$rectProps3.h2;
      return "M".concat(x2, ",").concat(y1, " L").concat(x2, ",").concat(y2 - .5 * h2);
    },
    bottomPath: function bottomPath() {
      var _this$rectProps4 = this.rectProps,
          x1 = _this$rectProps4.x1,
          x2 = _this$rectProps4.x2,
          y2 = _this$rectProps4.y2,
          w2 = _this$rectProps4.w2;
      return "M".concat(x1, ",").concat(y2, " L").concat(x2 - .5 * w2, ",").concat(y2);
    },
    bottomRightRect: function bottomRightRect() {
      var _this$rectProps5 = this.rectProps,
          x2 = _this$rectProps5.x2,
          y2 = _this$rectProps5.y2,
          w2 = _this$rectProps5.w2,
          h2 = _this$rectProps5.h2;
      return {
        x: x2 - .5 * w2,
        y: y2 - .5 * h2,
        width: w2,
        height: h2
      };
    },
    dragHandlePaths: function dragHandlePaths() {
      var strokeWidth = this.strokeWidth;
      var offset = strokeWidth / 6;
      var offsetLength = 10;
      return ["M".concat(-offsetLength, ",").concat(-offset, " L").concat(offsetLength, ",").concat(-offset), "M".concat(-offsetLength, ",").concat(offset, " L").concat(offsetLength, ",").concat(offset)];
    },
    dragHandleLocs: function dragHandleLocs() {
      var _this$rectProps6 = this.rectProps,
          x1 = _this$rectProps6.x1,
          y1 = _this$rectProps6.y1,
          x2 = _this$rectProps6.x2,
          y2 = _this$rectProps6.y2;
      var xr = x2,
          yr = .5 * (y1 + y2);
      return [{
        x: xr,
        y: yr,
        transform: "rotate(90 ".concat(xr, " ").concat(yr, ")")
      }, // Right line
      {
        x: .5 * (x1 + x2),
        y: y2
      }];
    },
    currentNestingLevelData: function currentNestingLevelData() {
      var wrapperPos = this.wrapperFunctionPos;
      if (!wrapperPos) return null;
      var functionPos = this.functionPos;
      var matchedNesting = null;

      var _c = this;

      function getNestingLevel(nesting, parentPos) {
        // Get index in this nesting level
        var idx = _c._getIdxInNestingLevel(nesting, wrapperPos, parentPos);

        if (idx == -1) return -1; // Check if it is contained in a child nesting level

        if (idx > 0 || idx == -2) {
          // -2 means after the last element
          var parentNesting = idx == -2 ? nesting[nesting.length - 1] : nesting[idx - 1];

          if (parentNesting.wraps.length > 0) {
            var nestedIdx = getNestingLevel(parentNesting.wraps, functionPos[parentNesting.id]);
            if (nestedIdx != -1) return nestedIdx;
          }
        } // If we are after the element, but not part of a child loop, something went wrong


        if (idx == -2) return -1; // Update match

        matchedNesting = nesting;
        return idx;
      }

      var nestingIdx = getNestingLevel(this.origFunctionWrapping, {
        left: 0
      });
      if (nestingIdx == -1 || matchedNesting === null) throw "Unable to determine nesting position!";
      return {
        currentNesting: matchedNesting,
        nestingIdx: nestingIdx
      };
    },
    possibleNestingLevelData: function possibleNestingLevelData() {
      var nestingLevelData = this.currentNestingLevelData;
      if (!nestingLevelData) return [];
      var currentNesting = nestingLevelData.currentNesting,
          nestingIdx = nestingLevelData.nestingIdx;
      return currentNesting.slice(nestingIdx);
    },
    possibleWrappingFunctionIds: function possibleWrappingFunctionIds() {
      function getLastLeafFunction(nestingSpec) {
        return nestingSpec.wraps.length == 0 ? nestingSpec : getLastLeafFunction(_.last(nestingSpec.wraps));
      }

      return _.map(this.possibleNestingLevelData, function (nestingSpec) {
        return {
          id: nestingSpec.id,
          lastWrapId: getLastLeafFunction(nestingSpec).id
        };
      });
    },
    possibleWrappingFunctionPos: function possibleWrappingFunctionPos() {
      var functionPosWrap = this.absFunctionPosWrap;
      return _.sortBy(_.map(this.possibleWrappingFunctionIds, function (_ref) {
        var id = _ref.id,
            lastWrapId = _ref.lastWrapId;
        return _.merge({
          id: id,
          endId: lastWrapId
        }, functionPosWrap[id], {
          endLeft: functionPosWrap[lastWrapId].left,
          endTop: functionPosWrap[lastWrapId].top
        });
      }), function (obj) {
        return obj.left;
      });
    },
    dragSelectedFunctionId: function dragSelectedFunctionId() {
      if (!this.isDragging) return null;
      var possibleWrappingFunctionPos = this.possibleWrappingFunctionPos;
      var draggingAngle = this.draggingAngle;
      var leftAmount = Math.sin(draggingAngle);
      var topAmount = Math.cos(draggingAngle);
      var _this$relMousePos = this.relMousePos,
          coordLeft = _this$relMousePos.left,
          coordTop = _this$relMousePos.top;
      var coord = coordLeft * leftAmount + coordTop * topAmount; // Find closest coordinate

      var idx = null,
          n = possibleWrappingFunctionPos.length;
      var functionPos = 0,
          lastFunctionPos = 0,
          endFunctionPos = 0;

      for (var i = 0; i < n; i++) {
        var _possibleWrappingFunc = possibleWrappingFunctionPos[i],
            left = _possibleWrappingFunc.left,
            top = _possibleWrappingFunc.top,
            endLeft = _possibleWrappingFunc.endLeft,
            endTop = _possibleWrappingFunc.endTop;
        functionPos = left * leftAmount + top * topAmount;
        endFunctionPos = endLeft * leftAmount + endTop * topAmount; // For the first element, we only know we select it for sure if we are to the top/left of it

        if (i == 0) {
          if (coord < functionPos) {
            idx = i;
            break;
          }

          lastFunctionPos = endFunctionPos;
          continue;
        } // Calculate midway coordinate


        var midCoord = .5 * (functionPos + lastFunctionPos);

        if (coord < midCoord) {
          idx = i - 1;
          break;
        }

        lastFunctionPos = endFunctionPos;
      } // If not found, we are at the end


      if (idx === null) idx = n - 1;
      return {
        id: possibleWrappingFunctionPos[idx].id,
        renderWrapId: possibleWrappingFunctionPos[idx].endId
      };
    },
    dragSelectFunctionRenderId: function dragSelectFunctionRenderId() {
      var id = this.dragSelectedFunctionId;
      return id ? id.renderWrapId : null;
    },
    renderValue: function renderValue() {
      var value = this.value;

      var posData = _.find(this.possibleWrappingFunctionPos, function (pos) {
        return pos.id == value;
      });

      return posData ? posData.endId : null;
    },
    canDrag: function canDrag() {
      return this.possibleWrappingFunctionIds.length > 1;
    },
    wrappedNestingLevelData: function wrappedNestingLevelData() {
      var possibleNestingLevelData = this.possibleNestingLevelData;
      var value = this.value;
      if (!this.value) return [];
      return possibleNestingLevelData.slice(0, _.findIndex(possibleNestingLevelData, function (nestingSpec) {
        return nestingSpec.id == value;
      }) + 1);
    },
    theWrappedFunctionIds: function theWrappedFunctionIds() {
      function getFlatFunctionIds(nestingLevelData) {
        var ids = [];

        _.forEach(nestingLevelData, function (nestingSpec) {
          ids.push(nestingSpec.id);

          _.forEach(getFlatFunctionIds(nestingSpec.wraps), function (id) {
            return ids.push(id);
          });
        });

        return ids;
      }

      var wrappedNestingLevelData = this.wrappedNestingLevelData;
      if (!wrappedNestingLevelData) return null;
      return getFlatFunctionIds(wrappedNestingLevelData);
    },
    _debouncedUpdateWrappedFunctionIds: function _debouncedUpdateWrappedFunctionIds() {
      return _.debounce(this._updateWrappedFunctionIds, 50);
    }
  },
  methods: {
    _getIdxInNestingLevel: function _getIdxInNestingLevel(nesting, elementPos, parentPos) {
      var functionPos = this.functionPos;
      var x = elementPos.left;
      var startX = parentPos.left;
      if (x < startX) return -1; // Not in nesting level

      var idx = _.findIndex(nesting, function (nestingSpec) {
        return functionPos[nestingSpec.id].left > x;
      });

      return idx == -1 ? -2 : idx;
    },
    _mouseDown: function _mouseDown(e, angle) {
      this.draggingAngle = angle * (Math.PI / 180);
      this.svgPos = this.$refs.svg.getBoundingClientRect();

      this._setSelMousePos(e);

      this.isDragging = true;
      document.body.classList.add('no-select'); // Prevent selection of text while dragging
    },
    _mouseMove: function _mouseMove(e) {
      if (!this.isDragging) return; // Fallback for detecting mouse release

      if (e.buttons == 0) {
        return this._mouseUp(e);
      }

      this._setSelMousePos(e);
    },
    _mouseUp: function _mouseUp(e) {
      if (!this.isDragging) return;

      this._setSelMousePos(e);

      var selectedId = this.dragSelectedFunctionId.id;
      this.isDragging = false;
      document.body.classList.remove('no-select');

      this._updateValue(selectedId);
    },
    _setSelMousePos: function _setSelMousePos(e) {
      var _this$svgPos = this.svgPos,
          svgLeft = _this$svgPos.left,
          svgTop = _this$svgPos.top;
      this.relMousePos = {
        left: e.clientX - svgLeft,
        top: e.clientY - svgTop
      };
    },
    _checkValue: function _checkValue() {
      this.hasCheckedValue = true;
      var value = this.value;
      var possibleWrappingFunctionPos = this.possibleWrappingFunctionPos; // If value is not possible, just wrap the outer loop

      if (_.findIndex(possibleWrappingFunctionPos, function (pos) {
        return pos.id == value;
      }) == -1) {
        this._updateValue(_.last(possibleWrappingFunctionPos).id);
      }
    },
    _updateValue: function _updateValue(functionId) {
      this.$emit('input', functionId);
    },
    _updateWrappedFunctionIds: function _updateWrappedFunctionIds() {
      this.$emit('update:wrappedFunctionIds', this.theWrappedFunctionIds);
    }
  },
  created: function created() {
    window.addEventListener('mousemove', this._mouseMove);
    window.addEventListener('mouseup', this._mouseUp);
  },
  mounted: function mounted() {
    this._debouncedUpdateWrappedFunctionIds();
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('mousemove', this._mouseMove);
    window.removeEventListener('mouseup', this._mouseUp);
  }
};