var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "root", staticClass: "wrapper" }, [
    _c(
      "div",
      {
        staticClass: "data",
        class: { external: _vm.external },
        on: {
          click: function($event) {
            _vm.$emit("click")
          }
        }
      },
      [_c("div", { staticClass: "data-label" }, [_vm._v(_vm._s(_vm.label))])]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }