function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Component rendering the function routing. Three columns are drawn: on the left the functions providing the
 * variable as output (outFunctions) are shown, in the middle the variable data block is displayed, and on the
 * right the functions which need this variable as input (inFunctions) are shown.
 */
import xdsmData from '../xdsm/xdsm-data.vue';
import xdsmFunction from '../xdsm/xdsm-function.vue';
import arrows from '../arrows.vue';
import { functionTypes } from "../mdao/functions.js";
export default {
  name: "var-routing",
  components: {
    xdsmData: xdsmData,
    xdsmFunction: xdsmFunction,
    arrows: arrows
  },
  props: {
    name: {
      type: String,
      required: true
    },
    // Function format is the same as in graphDefObj.functions (see xdsm.vue)
    inFunctions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // Functions to which this variable is an input
    outFunctions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // Functions that provided this variable as an output
    addInFunctionButton: {
      type: Boolean,
      default: false
    },
    addOutFunctionButton: {
      type: Boolean,
      default: false
    },
    routingEditable: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      functionTypes: functionTypes,
      inFunctionPos: [],
      outFunctionPos: [],
      dataPos: null,
      arrowMargin: 20,
      arrowFunctionAnchor: 0.25,
      arrowDrawPos: 0.4
    };
  },
  watch: {
    name: function name() {
      setTimeout(this._updatePositions, 0);
    },
    inFunctions: function inFunctions() {
      setTimeout(this._updatePositions, 0);
    },
    outFunctions: function outFunctions() {
      setTimeout(this._updatePositions, 0);
    }
  },
  computed: {
    isExternal: function isExternal() {
      return this.inFunctions.length == 0 || this.outFunctions == 0;
    },
    arrowFunctionMap: function arrowFunctionMap() {
      return _.concat(_.map(this.inFunctions, function (functionDef) {
        return [true, functionDef.id, functionDef];
      }), _.map(this.outFunctions, function (functionDef) {
        return [false, functionDef.id, functionDef];
      }));
    },
    clickableArrows: function clickableArrows() {
      var routingEditable = this.routingEditable;
      return _.map(this.arrowFunctionMap, function (_ref) {
        var _ref2 = _slicedToArray(_ref, 3),
            isInput = _ref2[0],
            functionid = _ref2[1],
            functionDef = _ref2[2];

        return routingEditable && functionDef.type == functionTypes.TOOL;
      });
    },
    arrows: function arrows() {
      if (!this.dataPos) return [];
      var dataPos = this.dataPos;
      var anchor = this.arrowFunctionAnchor;
      var drawPos = this.arrowDrawPos;

      function getArrowCoords(dataIsLeft, functionPos) {
        var dataY = dataPos.y;
        var dataX = dataPos.x;
        var dataW = 0.4 * dataPos.width;
        return _.map(functionPos, function (pos) {
          var fY = pos.y;
          var fX = pos.x;
          var fW = pos.width;
          var xMid = dataX * drawPos + fX * (1 - drawPos); // [x1, y1, w_half_1, x2, y2, w_half_2, x_mid]

          if (dataIsLeft) {
            // Function to data
            return [fX + anchor * fW, fY, 0.5 * anchor * fW, dataX - dataW, dataY, 0, xMid];
          } else {
            // Data to function
            return [dataX + dataW, dataY, 0, fX - anchor * fW, fY, 0.5 * anchor * fW, xMid];
          }
        });
      }

      return _.concat(getArrowCoords(false, this.inFunctionPos), getArrowCoords(true, this.outFunctionPos));
    },
    clippedArrows: function clippedArrows() {
      var m = this.arrowMargin;
      return _.map(this.arrows, function (arrow) {
        var x1, y1, w1, x2, y2, w2, xMid;

        var _arrow = _slicedToArray(arrow, 7);

        x1 = _arrow[0];
        y1 = _arrow[1];
        w1 = _arrow[2];
        x2 = _arrow[3];
        y2 = _arrow[4];
        w2 = _arrow[5];
        xMid = _arrow[6];
        var w = x2 - x1;
        var h = y2 - y1;
        var m1 = w1 + m;
        x1 += m1;
        y1 += h / w * m1;
        var m2 = w2 + m;
        x2 -= m2;
        y2 -= h / w * m2;
        var xMidPos = (xMid - x1) / (x2 - x1);
        var yMid = xMidPos * (y2 - y1) + y1;
        return 'M' + x1 + ',' + y1 + ' L' + xMid + ',' + yMid + ' L' + x2 + ',' + y2; // SVG path
      });
    }
  },
  methods: {
    _updatePositions: function _updatePositions() {
      var cbox = this.$refs.container.getBoundingClientRect();

      function getElPos(el) {
        var bbox = el.getBoundingClientRect();
        return {
          x: bbox.left - cbox.left + 0.5 * bbox.width,
          y: bbox.top - cbox.top + 0.5 * bbox.height,
          width: bbox.width
        };
      }

      this.dataPos = getElPos(this.$refs.dataBlockEl.$refs.root);

      var _c = this;

      this.inFunctionPos = _.map(this.inFunctions, function (f, idx) {
        return getElPos(_c.$refs.in[idx].$refs.root);
      });
      this.outFunctionPos = _.map(this.outFunctions, function (f, idx) {
        return getElPos(_c.$refs.out[idx].$refs.root);
      });
    },
    _emitDelete: function _emitDelete(isInput, functionId, functionDef) {
      if (functionDef.type != functionTypes.TOOL) return;
      this.$emit('delete', isInput, functionId);
    },
    _arrowClick: function _arrowClick(idx) {
      var _this$arrowFunctionMa = _slicedToArray(this.arrowFunctionMap[idx], 3),
          isInput = _this$arrowFunctionMa[0],
          functionId = _this$arrowFunctionMa[1],
          functionDef = _this$arrowFunctionMa[2];

      this._emitDelete(isInput, functionId, functionDef);
    }
  },
  mounted: function mounted() {
    this._updatePositions();
  }
};