var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      ref: "modal",
      class: { draggable: _vm.draggable, opaque: _vm.opaqueBackground },
      style: { "z-index": _vm.zIndex },
      attrs: {
        name: _vm.modalId,
        height: "auto",
        scrollable: true,
        width: _vm.modalWidth,
        adaptive: true,
        "pivot-y": _vm.pivotY,
        classes: _vm.modalClasses,
        "overlay-transition": "",
        "click-to-close": false,
        reset: true,
        draggable: _vm.draggableParam
      },
      on: { closed: _vm._closed }
    },
    [
      _c(
        "div",
        {
          ref: "wrapper",
          on: { keyup: _vm._keyPress, keydown: _vm._keyPress }
        },
        [
          _c(
            "header",
            {
              ref: "header",
              class: { draggable: _vm.draggable },
              on: { mousedown: _vm._setZIndex }
            },
            [
              _c("h2", [_vm._v(_vm._s(_vm.title))]),
              _vm._v(" "),
              _vm.subTitle
                ? _c("h3", [_vm._v(_vm._s(_vm.subTitle))])
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "header-buttons" }, [
                _c("span", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticClass: "fa fa-spinner fa-spin"
                }),
                _vm._v(" "),
                _vm.helpConfig
                  ? _c("span", {
                      staticClass: "fas fa-question link",
                      attrs: { title: "Open help (F1)" },
                      on: { click: _vm.dispatcher.openHelp }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.closeable
                  ? _c("span", {
                      staticClass: "fas fa-times link",
                      attrs: { title: "Close popup (Esc)" },
                      on: { click: _vm._closeButton }
                    })
                  : _vm._e()
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [_vm._t("default")], 2),
          _vm._v(" "),
          _vm.hasButtons
            ? _c(
                "div",
                {
                  ref: "actionButtons",
                  staticClass: "vue-dialog-buttons",
                  class: { sticky: _vm.sticky },
                  style: _vm.actionButtonsStyle
                },
                [
                  _vm.actionLinkTexts
                    ? _c(
                        "span",
                        { staticClass: "action-link" },
                        _vm._l(_vm.actionLinkTexts, function(
                          actionLinkText,
                          key
                        ) {
                          return _c(
                            "span",
                            {
                              key: key,
                              staticClass: "link",
                              on: {
                                click: function($event) {
                                  _vm.$emit("actionLink", key)
                                }
                              }
                            },
                            [_vm._v(_vm._s(actionLinkText))]
                          )
                        })
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasCancel
                    ? _c(
                        "button",
                        {
                          staticClass: "vue-dialog-button",
                          on: { click: _vm.close }
                        },
                        [_vm._v("Cancel")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "vue-dialog-button primary",
                      class: { danger: _vm.actionError },
                      attrs: { disabled: _vm.loading || _vm.actionDisabled },
                      on: { click: _vm.doAction }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.actionTitle) +
                          "\n                "
                      ),
                      _c("span", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loading,
                            expression: "loading"
                          }
                        ],
                        staticClass: "fa fa-spinner fa-spin",
                        staticStyle: { "margin-left": "5px" }
                      })
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.sticky,
                expression: "sticky"
              }
            ],
            style: { height: _vm.actionButtonsHeight + "px" }
          })
        ]
      ),
      _vm._v(" "),
      _c("ui-dialog", { ref: "dialog", attrs: { "action-title": "Close" } }, [
        _vm._v("\n        Are you sure you want to close the popup?\n    ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }