//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Var list modal component, that can be called to show the variable list on demand, and can be used as a selection
 * dialog for selecting variables.
 *
 * Usage:
 * <var-list-modal
 *     :functions="functions"
 *     @select="selectFunction"
 *     ref="varListModal"
 * />
 *
 * where:
 * - functions is the list of available functions (see xdsm.vue)
 * - selectFunction is the function that gets passed the selected varIds
 *
 * The main interaction with the modal is through the component API, which contains the following methods:
 * - setTitle(title, subTitle)
 *       Sets the title and sub-title of the modal.
 * - setEditable(editable, deletable, createFunction, deleteFunction)
 *       Sets the editable and deletable flags and the create/delete functions.
 *       The create function gets passed the following:
 *       - parentVarId: varId of the parent element that should contain the newly created variable node
 *       - varDef: varDef for the new variable
 *       - callback: callback function to call with the updated newVarDef
 *       The delete function gets passed the following:
 *       - varDef: varDef for the variable to be deleted
 * - setSelectable(selectable, multiSelect, selectLeaf, selectParent)
 *       Opens the list as a variable-selection list, optionally enabling multi-select or disabling the constraint
 *       that only leaf vars can be selected.
 * - setSearchFunction(searchFunction)
 *       Sets a custom search function that gets passed:
 *       - term: the search term
 *       - callback: callback function to call with the updated searchIds list (see var-list.vue)
 * - open(getRootVarDefFunction, selectedIds, disabledIds)
 *       Opens the modal for the given root variable function, pre-selected IDs, and disabled IDs.
 *       The root variable function should returned the latest available (properly filtered) root var def.
 *
 * The best idea is to use the function in the sequence as explained above, e.g.:
 * const varListModal = this.$refs.varListModal;
 * varListModal.resetModal();
 * varListModal.setTitle(title, subTitle);
 * varListModal.setSearchFunction(this.searchFunction);
 * varListModal.open(() => this.rootVarDef, selectedIds, disabledIds);
 */
import _ from '../../lodash.custom.js';
import uiModal from '../ui-modal.vue';
import varList from './var-list.vue';
import uiDialog from '../ui-dialog.vue';
import xdsmFunction from '../xdsm/xdsm-function.vue';
import varInfo from './var-info.vue';
import routingSelect from './routing-select.vue';
import createVar from './create-var.vue';
import { getFlatVars } from "../../var-utils";
import { api, dispatcher } from '../../index';
export default {
  name: "var-list-modal",
  components: {
    varList: varList,
    uiModal: uiModal,
    uiDialog: uiDialog,
    xdsmFunction: xdsmFunction,
    varInfo: varInfo,
    routingSelect: routingSelect,
    createVar: createVar
  },
  props: {
    graphDef: {
      type: Object,
      default: null
    },
    routingEditable: {
      type: Boolean,
      default: false
    },
    varEditable: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    // Adding variables
    deletable: {
      type: Boolean,
      default: false
    },
    createFunction: {
      default: null
    },
    // function(parentVarId, varData, callback), callback = function(newVarData)
    deleteFunction: {
      default: null
    },
    // function(varDef, callback), callback = function()
    connectFunction: {
      default: null
    },
    // function(varDef, callback)
    editFunction: {
      default: null
    } // function(varId, varData, callback), callback = function(newVarData)

  },
  data: function data() {
    return {
      api: api,
      dispatcher: dispatcher,
      title: null,
      subTitle: null,
      subTitleFunctionDef: null,
      searchFunction: null,
      // function(term, callback), callback = function(searchIds)
      rootVarDefFunction: null,
      rootVarDef: null,
      searchIds: null,
      highlightIds: null,
      scrollContainer: null,
      selectable: false,
      selectLeaf: true,
      selectParent: false,
      multiSelect: false,
      selectedIds: [],
      disabledIds: [],
      confirmDeleteVarDef: {},
      mainIdsFunction: null,
      mainIds: null,
      createdVarParentId: null,
      editVarId: null
    };
  },
  watch: {
    rootVarDef: function rootVarDef() {
      if (this.createdVarParentId !== null) {
        this.$refs.varList.expandId(this.createdVarParentId);
        this.createdVarParentId = null;
      }
    }
  },
  computed: {
    modalTitle: function modalTitle() {
      return this.title || 'Workflow Variable Tree';
    },
    listSelectedIds: function listSelectedIds() {
      return this.selectable ? this.selectedIds : null;
    },
    searchPlaceholder: function searchPlaceholder() {
      return this.selectable ? 'xpath or !selected' : 'xpath';
    },
    nVarItems: function nVarItems() {
      var rootVarDef = this.rootVarDef;
      return rootVarDef ? _.map(getFlatVars(rootVarDef), function (val) {
        return val;
      }).length : 0;
    },
    opened: function opened() {
      var modal = this.$refs.modal;
      return modal && modal.opened;
    },
    functions: function functions() {
      return this.graphDef ? this.graphDef.functions : [];
    }
  },
  methods: {
    setTitle: function setTitle(title, subTitle, subTitleFunctionDef) {
      this.title = title || null;
      this.subTitle = subTitle || null;
      this.subTitleFunctionDef = subTitleFunctionDef || null;
    },
    setSelectable: function setSelectable(selectable, multiSelect, selectLeaf, selectParent) {
      this.selectable = selectable;
      this.multiSelect = multiSelect;
      this.selectLeaf = _.isUndefined(selectLeaf) ? true : selectLeaf;
      this.selectParent = _.isUndefined(selectParent) ? false : selectParent;
    },
    setSearchFunction: function setSearchFunction(searchFunction) {
      this.searchFunction = searchFunction;
    },
    open: function open(getRootVarDefFunction, selectedIds, disabledIds, mainIdsFunction) {
      this.searchIds = null;
      this.rootVarDefFunction = getRootVarDefFunction;
      this.rootVarDef = getRootVarDefFunction();
      if (!this.rootVarDef) return;
      this.selectedIds = selectedIds || [];
      this.disabledIds = disabledIds || [];
      this.mainIdsFunction = mainIdsFunction || null;
      this.mainIds = mainIdsFunction ? mainIdsFunction() : null;
      this.searchFunction = this.searchFunction || this._fallbackSearch;

      if (this.$refs.modal.opened) {
        this._openList();
      } else {
        this.$refs.modal.open();
      }
    },
    close: function close() {
      this.$refs.modal.close();
    },
    rootVarDefUpdated: function rootVarDefUpdated() {
      if (!this.rootVarDefFunction) throw 'No root var def request function given';

      var _c = this;

      setTimeout(function () {
        var rootVarDef = _c.rootVarDefFunction();

        if (rootVarDef) {
          _c.mainIds = _c.mainIdsFunction ? _c.mainIdsFunction() : null;
          _c.rootVarDef = rootVarDef;
        } else {
          _c.close();
        }
      }, 0);
    },
    _opened: function _opened() {
      this.scrollContainer = this.$refs.modal.modalContentEl.parentElement;
      this.dispatcher.onUpdatedRootVarDef(this.rootVarDefUpdated);

      this._openList();
    },
    _openList: function _openList() {
      // Open the list if it is not too big
      if (this.nVarItems < 30) {
        var _c = this;

        setTimeout(function () {
          _c.$refs.varList.expandAll();
        }, 0);
      }
    },
    _closed: function _closed() {
      this.resetModal();
      this.scrollContainer = null;
      this.dispatcher.offUpdatedRootVarDef(this.rootVarDefUpdated);
    },
    _modalChange: function _modalChange() {
      if ('varList' in this.$refs) {
        var _varList = this.$refs.varList;
        if (_varList) _varList.positionUpdated();
      }
    },
    resetModal: function resetModal() {
      this.title = null;
      this.subTitle = null;
      this.selectable = false;
      this.multiSelect = false;
      this.selectedIds = [];
      this.selectLeaf = true;
      this.selectParent = false;
      this.rootVarDef = null;
      this.rootVarDefFunction = null;
      this.searchFunction = null;
    },
    highlight: function highlight(varIds) {
      this.highlightIds = varIds;
    },
    _create: function _create(parentVarId, varData) {
      if (this.editVarId !== null) {
        this._edit(this.editVarId, varData);

        return;
      }

      if (!this.createFunction) return;

      var _c = this;

      this.createFunction(parentVarId, varData, function (newVarDef) {
        // Highlight the newly created variable
        _c.highlightIds = [newVarDef.id];
        _c.createdVarParentId = parentVarId;

        _c.rootVarDefUpdated();
      });
    },
    _edit: function _edit(varId, varData) {
      if (!this.editFunction) return;

      var _c = this;

      this.editFunction(varId, varData, function (newVarDef) {
        _c.highlightIds = [newVarDef.id];

        _c.rootVarDefUpdated();
      });
    },
    _connect: function _connect(varDef) {
      if (!this.connectFunction) return;

      var _c = this;

      this.connectFunction(varDef, function () {});
    },
    _delete: function _delete(varDef) {
      if (!this.deleteFunction) return;

      var _c = this;

      this.confirmDeleteVarDef = varDef;
      this.$refs.confirmDeleteDialog.open(function () {
        _c.deleteFunction(varDef, function () {
          _c.rootVarDefUpdated();
        });
      });
    },
    _search: function _search(term) {
      if (!term) {
        this.searchIds = [];
      } else if (this.selectable && term == '!selected') {
        this.searchIds = _.cloneDeep(this.selectedIds);
      } else if (this.searchFunction) {
        var _c = this;

        this.searchFunction(term, function (searchIds) {
          _c.searchIds = searchIds;
        });
      }
    },
    _fallbackSearch: function _fallbackSearch(term, callback) {
      this.api.searchMainRootVar(term, function (searchIds) {
        callback(searchIds);
      });
    },
    _updateSelectedIds: function _updateSelectedIds(selectedIds) {
      this.selectedIds = selectedIds;
    },
    _doSelect: function _doSelect() {
      var selectedIds = this.selectedIds;

      if (selectedIds.length == 0) {
        this.dispatcher.error('Select at least one variable');
        return false;
      } // Fallback


      if (!this.multiSelect && selectedIds.length > 1) {
        selectedIds = [selectedIds[0]];
      }

      this.$emit('select', selectedIds);
    },
    _showCreate: function _showCreate(parentVarDef) {
      this.editVarId = null;
      this.$refs.createVar.start(parentVarDef);
    },
    _showEdit: function _showEdit(varDef) {
      if (!this.varEditable) return;

      if (!this.modal) {
        this.$emit('edit', varDef);
      } else {
        this.editVarId = varDef.id;
        this.$refs.createVar.setData(varDef.name, varDef.attrib, varDef.svm);
        this.$refs.createVar.start(varDef, 'Edit Variable', 'Apply');
      }
    },
    _showInfo: function _showInfo(varDef) {
      if (!this.modal) {
        // Use external var-info component, because it possible this modal is closed before var-info modal
        this.$emit('info', varDef);
      } else {
        this.$refs.varInfo.open(varDef);
      }
    },
    _showRouting: function _showRouting(varDef) {
      if (!this.routingEditable) return;

      if (!this.modal) {
        this.$emit('routing', varDef);
      } else {
        this.$refs.routingSelect.open(varDef);
      }
    }
  },
  mounted: function mounted() {
    this.resetModal();
  }
};