var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "wrapper", staticClass: "wrapper" }, [
    _c("div", {
      staticClass: "bar",
      class: _vm.barClass,
      style: { width: _vm.posStyle }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "text-hook", style: { left: _vm.posStyle } }, [
      _c(
        "div",
        {
          ref: "progressText",
          staticClass: "progress-text",
          class: _vm.progressClass
        },
        [_vm._v(_vm._s(_vm.progressText))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }