var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "list-item",
      class: {
        editable: _vm.editable,
        "search-match": _vm.searchMatches,
        selected: _vm.selected,
        "descendant-selected": _vm.descendantSelected
      }
    },
    [
      !_vm.isMultiFileRoot
        ? _c(
            "div",
            {
              staticClass: "var-item",
              class: {
                normal: !_vm.itemSelectable,
                collision: _vm.hasRoutingCollision,
                secondary: !_vm.isMain
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm._itemClick($event)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "var-actions", style: _vm.actionsStyle },
                [
                  _vm.hasGrandChildren
                    ? _c("span", [
                        _c("span", {
                          staticClass: "fa fa-angle-double-up link",
                          attrs: { title: "Collapse all" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.collapseAll($event)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "fa fa-angle-double-down link",
                          attrs: { title: "Expand all" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.expandAll($event)
                            }
                          }
                        })
                      ])
                    : _vm._e(),
                  _c(
                    "span",
                    { ref: "extraButtons", staticClass: "extra-var-buttons" },
                    [
                      _vm.hasShowInfo
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-default icon linked",
                              attrs: { title: "Show info" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm._showInfo($event)
                                }
                              }
                            },
                            [_c("span", { staticClass: "fa fa-info" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasShowRouting
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-default icon",
                              attrs: { title: "Edit routing" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm._showRouting($event)
                                }
                              }
                            },
                            [_c("span", { staticClass: "fas fa-route" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasCreateVar
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-default icon",
                              attrs: { title: "Add child variable" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm._createVar($event)
                                }
                              }
                            },
                            [_c("span", { staticClass: "fa fa-plus" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasEditVar
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-default icon",
                              attrs: { title: "Edit variable" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm._editVar($event)
                                }
                              }
                            },
                            [_c("span", { staticClass: "fa fa-edit" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasConnect
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-default icon",
                              attrs: { title: "Connect variable" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm._connectVar($event)
                                }
                              }
                            },
                            [_c("span", { staticClass: "fas fa-link" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasDeleteVar
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-default icon",
                              style: {
                                visibility: _vm.showDeleteVar
                                  ? "visible"
                                  : "hidden"
                              },
                              attrs: { title: "Remove variable" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm._deleteVar($event)
                                }
                              }
                            },
                            [_c("span", { staticClass: "far fa-trash-alt" })]
                          )
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _vm.hasChildren
                ? _c(
                    "span",
                    {
                      staticClass: "collapse-toggle",
                      style: _vm.leftStyle,
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm._expandCollapseClick($event)
                        }
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "fa link",
                        class: {
                          "fa-caret-right": _vm.collapsed,
                          "fa-caret-down": !_vm.collapsed
                        }
                      })
                    ]
                  )
                : _c("span", {
                    staticClass: "no-children-spacer",
                    style: _vm.leftStyle
                  }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "var-title",
                  class: { selectable: _vm.itemSelectable }
                },
                [
                  _vm.itemSelectable
                    ? _c("label", { staticClass: "select-check" }, [
                        _c("input", {
                          attrs: {
                            type: "checkbox",
                            disabled: _vm.selectionDisabled
                          },
                          domProps: { checked: _vm.selected },
                          on: {
                            input: function($event) {
                              _vm._setSelectVar($event.target.checked)
                            },
                            click: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "var-name" }, [
                    _vm._v(_vm._s(_vm.varDef.name))
                  ]),
                  _vm._v(
                    _vm._s(_vm.isVectorStr) +
                      "\n            " +
                      _vm._s(_vm.attrStr) +
                      "\n            "
                  ),
                  _vm.isBlackBox
                    ? _c("span", { staticClass: "var-status" }, [
                        _vm._v("Black-box file")
                      ])
                    : _vm._e()
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasChildren && (_vm.hasExpanded || _vm.isMultiFileRoot)
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.collapsed || _vm.isMultiFileRoot,
                  expression: "!collapsed || isMultiFileRoot"
                }
              ],
              staticClass: "var-children-container",
              class: { indented: !_vm.isMultiFileRoot },
              style: _vm.leftStyle
            },
            _vm._l(_vm.children, function(varDef) {
              return _c("var-item", {
                key: varDef.id,
                ref: "childItem",
                refInFor: true,
                attrs: {
                  "var-def": varDef,
                  "search-ids": _vm.searchIds,
                  editable: _vm.editable,
                  deletable: _vm.deletable,
                  "padding-left": _vm.isMultiFileRoot ? _vm.paddingLeft : 0,
                  "padding-right": _vm.paddingRight,
                  selectable: _vm.selectable,
                  "multi-select": _vm.multiSelect,
                  "select-leaf": _vm.selectLeaf,
                  "select-parent": _vm.selectParent,
                  "selected-ids": _vm.selectedIds,
                  "disabled-ids": _vm.disabledIds,
                  "main-ids": _vm.mainIds,
                  "routing-editable": _vm.routingEditable,
                  "var-editable": _vm.varEditable,
                  "is-root": _vm.isMultiFileRoot,
                  "black-box-can-add-child-var": _vm.bbChildCanAddChildVar,
                  "parent-id": _vm.varId
                },
                on: {
                  info: _vm._showInfo,
                  routing: _vm._showRouting,
                  create: _vm._createVar,
                  edit: _vm._editVar,
                  select: _vm._selectVar,
                  connect: _vm._connectVar,
                  delete: _vm._deleteVar
                }
              })
            })
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }