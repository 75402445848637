//
//
//
//
//
//
//
//
import _ from '../lodash.custom.js';
import { dispatcher } from "../index";
export default {
  name: "ui-dialog",
  props: {
    action: Function,
    cancelTitle: String,
    actionTitle: String
  },
  data: function data() {
    return {
      dispatcher: dispatcher,
      actionHandler: null,
      overlays: []
    };
  },
  computed: {
    cancelButtonTitle: function cancelButtonTitle() {
      return this.cancelTitle ? this.cancelTitle : 'Cancel';
    },
    actionButtonTitle: function actionButtonTitle() {
      return this.actionTitle ? this.actionTitle : 'Ok';
    }
  },
  methods: {
    open: function open(actionHandler) {
      this.actionHandler = actionHandler;
      this.overlays = this._getOverlayBackgroundElements(); // Allow the dialog HTML to render

      setTimeout(this._doOpen, 0);
    },
    close: function close() {
      this.$modal.hide('dialog');
    },
    _doOpen: function _doOpen() {
      this.$modal.show('dialog', {
        text: this.$refs.slot.innerHTML,
        buttons: [{
          title: this.cancelButtonTitle
        }, {
          title: this.actionButtonTitle,
          default: true,
          class: 'vue-dialog-button primary',
          handler: this._handleAction
        }],
        class: 'ui-dialog'
      });
      setTimeout(this._setZIndex, 0);
      setTimeout(this._opened, 100);
    },
    _setZIndex: function _setZIndex() {
      var dialogEl = document.querySelector('.v--modal-overlay[data-modal=dialog]');
      if (dialogEl) dialogEl.style.zIndex = this.dispatcher.getIncreaseZIndex();
    },
    _opened: function _opened() {
      // Get the latest created modal overlay element, since we can have multiple modals stacked
      var newOverlays = _.difference(this._getOverlayBackgroundElements(), this.overlays);

      this._addOverlayEvent(newOverlays[0]);
    },
    _handleAction: function _handleAction() {
      if (this.action) this.action();
      if (this.actionHandler) this.actionHandler();
      this.close();
    },
    _getOverlayBackgroundElements: function _getOverlayBackgroundElements() {
      return Array.from(document.getElementsByClassName('v--modal-background-click'));
    },
    _addOverlayEvent: function _addOverlayEvent(el) {
      this.overlayClickElement = el;
      el.addEventListener('mousedown', this._overlayClick);
      el.addEventListener('touchstart', this._overlayClick);
    },
    _overlayClick: function _overlayClick(e) {
      if (e.target != this.overlayClickElement) return;
      this.close();
    }
  }
};