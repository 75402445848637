//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../../lodash.custom.js';
import xdsmPreviewModal from './xdsm-preview-modal.vue';
import selectVars from '../select/select-vars.vue';
import selectFunctions from '../select/select-functions.vue';
import { filterTreeByFunctionIO } from '../../var-utils.js';
import { api, dispatcher } from '../../index.js';
export default {
  name: "auto-remove-functions",
  components: {
    xdsmPreviewModal: xdsmPreviewModal,
    selectVars: selectVars,
    selectFunctions: selectFunctions
  },
  props: {
    rootVarDef: {
      type: Object,
      default: function _default() {
        return null;
      }
    }
  },
  data: function data() {
    return {
      api: api,
      dispatcher: dispatcher,
      graphDef: null,
      modalComp: null,
      selectedVarIds: [],
      selectedFunctionIds: [],
      removedFunctionIds: [],
      rootVarDefOutputVars: null
    };
  },
  watch: {
    selectedVarIds: function selectedVarIds() {
      if (this.graphDef !== null) this._debouncedUpdatePreview();
    },
    selectedFunctionIds: function selectedFunctionIds() {
      if (this.graphDef !== null) this._debouncedUpdatePreview();
    }
  },
  computed: {
    functionClasses: function functionClasses() {
      if (this.isLoading) return [];
      return _.map(this.removedFunctionIds, function (functionId) {
        return [functionId, 'removed'];
      });
    },
    varIdList: function varIdList() {
      return _.map(this.selectedVarIds, function (varSel) {
        return varSel.id;
      });
    },
    isLoading: function isLoading() {
      return this.modalComp ? this.modalComp.loading : false;
    },
    functions: function functions() {
      return this.graphDef ? this.graphDef.functions.slice(1) : [];
    },
    actionDisabled: function actionDisabled() {
      return this.removedFunctionIds.length == 0;
    },
    _debouncedUpdatePreview: function _debouncedUpdatePreview() {
      return _.debounce(this._updatePreview, 500);
    }
  },
  methods: {
    open: function open(graphDef) {
      this.graphDef = _.cloneDeep(graphDef);
      this.rootVarDefOutputVars = this._getRootVarDefOutputVars();
      this.$refs.modal.open();

      this._updatePreview(true);

      this.api.editResetRemovedFunctions();
    },
    close: function close() {
      this.$refs.modal.close();
    },
    closing: function closing() {
      this.graphDef = null;
      this.rootVarDefOutputVars = null;
      this.selectedVarIds = [];
      this.selectedFunctionIds = [];
      this.removedFunctionIds = [];
    },
    _getRootVarDefOutputVars: function _getRootVarDefOutputVars() {
      if (!this.rootVarDef) return null;
      return filterTreeByFunctionIO(this.rootVarDef, this.wrappedFunctionIds, null, true, false);
    },
    _updatePreview: function _updatePreview(isInitial) {
      var _c = this;

      this.api.editGetRemovedFunctions(this.varIdList, this.selectedFunctionIds, function (removedFunctionIds) {
        _c.removedFunctionIds = removedFunctionIds;

        if (!isInitial && removedFunctionIds.length == 0) {
          _c.dispatcher.error('No functions to remove.');
        }
      });
    },
    _apply: function _apply() {
      this.$refs.modal.setLoading(true);

      var _c = this;

      this.api.editRemoveFunctions(this.varIdList, this.selectedFunctionIds, function (graphDef) {
        _c.close();

        _c.$emit('update', graphDef);
      });
      return false;
    }
  },
  mounted: function mounted() {
    this.modalComp = this.$refs.modal;
  }
};