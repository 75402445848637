var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "var-list-comp-container",
      class: { "flex-scroll": _vm.fillHeightAutoScroll }
    },
    [
      _c(
        "div",
        {
          ref: "listHeader",
          staticClass: "var-list-header",
          class: {
            sticky: _vm.headerSticky,
            "window-scroll": _vm.scrollIsWindow,
            "flex-scroll": _vm.fillHeightAutoScroll
          },
          style: _vm.headerStyles
        },
        [
          _c(
            "div",
            {
              staticClass: "form-group search-field",
              style: _vm.searchFieldContainerStyle
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchValue,
                    expression: "searchValue"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", placeholder: _vm.placeholder },
                domProps: { value: _vm.searchValue },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchValue = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("span", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.searchValue,
                    expression: "searchValue"
                  }
                ],
                staticClass: "clear-search fas fa-ban",
                attrs: { title: "Clear search" },
                on: {
                  click: function($event) {
                    _vm.searchValue = ""
                  }
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "headerButtons",
              staticClass: "var-list-buttons",
              style: _vm.headerButtonStyles
            },
            [
              _c("span", {
                staticClass: "fa fa-angle-double-up link",
                attrs: { title: "Collapse all" },
                on: { click: _vm.collapseAll }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "fa fa-angle-double-down link",
                attrs: { title: "Expand all" },
                on: { click: _vm.expandAll }
              }),
              _vm._v(" "),
              _vm.editable
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-default icon var-list-extra-button",
                      attrs: { title: "Create new root" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm._createMultiFileRootVar($event)
                        }
                      }
                    },
                    [_c("span", { staticClass: "fa fa-plus" })]
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _vm.stickyCloseButton && _vm.headerSticky
            ? _c("div", { staticClass: "close-button" }, [
                _c("span", {
                  staticClass: "fas fa-times link",
                  on: {
                    click: function($event) {
                      _vm.$emit("close")
                    }
                  }
                })
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.headerSticky,
            expression: "headerSticky"
          }
        ],
        ref: "proxyListHeader",
        style: { height: _vm.headerHeight + "px" }
      }),
      _vm._v(" "),
      _vm.rootVarDef !== null
        ? _c(
            "div",
            {
              staticClass: "var-list",
              class: { "flex-scroll": _vm.fillHeightAutoScroll }
            },
            [
              _c("var-item", {
                ref: "rootVarItem",
                style: { "padding-bottom": _vm.paddingBottom + "px" },
                attrs: {
                  "var-def": _vm.displayedRootVarDef,
                  "search-ids": _vm.showSearchIds,
                  editable: _vm.editable,
                  deletable: _vm.deletable,
                  selectable: _vm.selectable,
                  "multi-select": _vm.multiSelect,
                  "select-leaf": _vm.selectLeaf,
                  "select-parent": _vm.selectParent,
                  "selected-ids": _vm.selectedIds,
                  "disabled-ids": _vm.disabledIds,
                  "main-ids": _vm.mainIds,
                  "padding-left": _vm.padding,
                  "padding-right": _vm.padding,
                  "routing-editable": _vm.routingEditable,
                  "var-editable": _vm.varEditable,
                  "is-root": true,
                  "is-real-root": true
                },
                on: {
                  info: _vm._showInfo,
                  routing: _vm._showRouting,
                  create: _vm._createVar,
                  select: _vm._selectVar,
                  connect: _vm._connectVar,
                  delete: _vm._deleteVar,
                  edit: _vm._editVar
                }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }