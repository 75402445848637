var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table", { staticClass: "add-var-table" }, [
        _c("tbody", [
          _c("tr", [
            _c(
              "td",
              [
                _vm._t(
                  "default",
                  _vm._l(_vm.selectedVars, function(selectedVar) {
                    return _c("div", { staticClass: "selected-var" }, [
                      _vm._v(_vm._s(selectedVar))
                    ])
                  })
                )
              ],
              2
            ),
            _vm._v(" "),
            _vm.editable
              ? _c("td", { staticClass: "var-select-button" }, [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hasSelection,
                          expression: "hasSelection"
                        }
                      ],
                      staticClass: "btn btn-default icon",
                      attrs: { title: "Clear selection" },
                      on: { click: _vm.reset }
                    },
                    [_c("span", { staticClass: "fas fa-times" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn icon",
                      class: _vm.selectButtonClasses,
                      attrs: { title: "Select variables" },
                      on: { click: _vm._selectVars }
                    },
                    [_c("span", { staticClass: "fas fa-check-double" })]
                  )
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.editable
        ? _c("var-list-modal", {
            ref: "varList",
            attrs: {
              "graph-def": _vm.graphDef,
              editable: _vm.canCreate,
              "create-function": _vm.createVarFunction
            },
            on: { select: _vm._varsSelected }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }