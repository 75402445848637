//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../lodash.custom.js';
import arrows from './arrows.vue';
import xdsm from './xdsm/xdsm.vue';
import { api } from './../index';
import { standaloneMode } from "../api";
import { getGraphDef } from "../utils";
export default {
  name: "branch-tree",
  components: {
    arrows: arrows,
    xdsm: xdsm
  },
  props: {
    branches: {
      type: Array,
      required: true
    },
    graphs: {
      type: Object,
      required: true
    },
    projectId: {
      required: true
    },
    currentProjectId: {
      default: null
    },
    currentBranchId: {
      default: null
    },
    scale: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {
      api: api,
      standaloneMode: standaloneMode,
      pos: null,
      margin: 20
    };
  },
  watch: {
    graphDefs: function graphDefs() {
      setTimeout(this._updatePositions, 20);
    }
  },
  computed: {
    arrows: function arrows() {
      var margin = this.margin;
      return _.flatten(_.map(this.pos, function (pos) {
        if (!pos) return [];
        var x = pos.x,
            y = pos.y,
            h = pos.height,
            children = pos.children;
        return _.map(children, function (childPos) {
          var targetX = childPos.x,
              targetY = childPos.y,
              th = childPos.height;
          var dx = targetX - x;
          var dy = targetY - y; // Move starting point

          var startX = x,
              startY = y;
          var adjustY = h / 2 + margin;
          startY += adjustY;
          startX += adjustY * (dx / dy); // Move target point

          adjustY = -th / 2 - margin;
          targetY += adjustY;
          targetX += adjustY * (dx / dy);
          return 'M' + startX + ',' + startY + ' L' + targetX + ',' + targetY;
        });
      }));
    },
    canDelete: function canDelete() {
      if (this.standaloneMode) return false;
      return this.branches.length > 1 || this.branches[0].parent_id !== null;
    },
    graphDefs: function graphDefs() {
      var graphs = this.graphs || {};
      var projectId = this.projectId;
      return _.map(this.branches, function (branch) {
        return getGraphDef(graphs, projectId, branch.id);
      });
    },
    isCurrent: function isCurrent() {
      var projectId = this.projectId;
      var currentProjectId = this.currentProjectId;
      var currentBranchId = this.currentBranchId;
      return _.map(this.branches, function (branch) {
        return currentProjectId && projectId == currentProjectId && currentBranchId == branch.id;
      });
    }
  },
  methods: {
    updated: function updated() {
      setTimeout(this._updatePositions, 0);
      var refs = this.$refs;

      _.forEach(this.branches, function (branch, idx) {
        var childBranches = refs['child_' + idx];
        if (!childBranches || childBranches.length == 0) return;
        childBranches[0].updated();
      });
    },
    _edit: function _edit(branchId) {
      this.$emit('edit', branchId);
    },
    _updatePositions: function _updatePositions() {
      var scale = this.scale;
      var refs = this.$refs;
      if (!refs.root) return;
      var rootBbox = refs.root.getBoundingClientRect();

      function getPos(el) {
        var bbox = el.getBoundingClientRect();
        var width = bbox.width / scale;
        var height = bbox.height / scale;
        var x = (bbox.left - rootBbox.left + 0.5 * bbox.width) / scale;
        var y = (bbox.top - rootBbox.top + 0.5 * bbox.height) / scale;
        return {
          x: x,
          y: y,
          width: width,
          height: height
        };
      }

      this.pos = _.map(this.branches, function (branch, idx) {
        var mainEl = refs.branch[idx];
        var childBranches = refs['child_' + idx];
        if (!childBranches || childBranches.length == 0) return null;
        var childEls = childBranches[0].$refs.branch;
        return _.merge(getPos(mainEl), {
          children: _.map(childEls, function (childEl) {
            return getPos(childEl);
          })
        });
      });
    }
  },
  mounted: function mounted() {
    this._updatePositions();
  }
};