//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import xdsmFunction from '../xdsm/xdsm-function.vue';
import createVar from '../var-list/create-var.vue';
import varList from '../var-list/var-list.vue';
import varInfo from '../var-list/var-info.vue';
import uiModal from '../ui-modal.vue';
import uploader from '../uploader.vue';
import rceExportSettings from '../rce-export-settings.vue';
import _ from '../../lodash.custom';
import { api, dispatcher } from "../../index";
import { filterTreeByFunctionIO, getFlatVars } from "../../var-utils";
import { saveFile } from "../../files";
export default {
  name: "function-io-edit",
  components: {
    xdsmFunction: xdsmFunction,
    createVar: createVar,
    varList: varList,
    varInfo: varInfo,
    uiModal: uiModal,
    uploader: uploader,
    rceExportSettings: rceExportSettings
  },
  props: {
    projectId: {
      required: true
    },
    branchId: {
      required: true
    },
    graphDef: {
      type: Object,
      required: true
    },
    rootVarDef: {
      type: Object,
      default: function _default() {
        return null;
      }
    },
    functionDef: {
      type: Object,
      required: true
    } // Format as defined in xdsm.vue

  },
  data: function data() {
    return {
      api: api,
      dispatcher: dispatcher,
      editedFunctionDef: null,
      editingTitle: false,
      editedTitle: null,
      editable: true,
      editingInput: true,
      windowWidth: 0,
      widthLimit: 1000,
      editVarId: null,
      editVarInput: true,
      inputSearchIds: null,
      outputSearchIds: null,
      highlightIds: null,
      uploadingInput: true,
      exportType: 'rce',
      exportTypes: [['rce', 'RCE'], ['cmdows', 'CMDOWS'], ['io', 'I/O files']]
    };
  },
  watch: {
    functionDef: function functionDef(_functionDef) {
      this._setEditedFunctionDef(_functionDef);
    },
    isWide: function isWide() {
      var _this = this;

      this.$nextTick(function () {
        return _this._displayChanged();
      });
    },
    editingInput: function editingInput() {
      var _this2 = this;

      this.$nextTick(function () {
        return _this2._displayChanged();
      });
    }
  },
  computed: {
    title: function title() {
      return this.functionDef.title;
    },
    functionId: function functionId() {
      return this.functionDef.id;
    },
    isWide: function isWide() {
      return this.windowWidth >= this.widthLimit;
    },
    functions: function functions() {
      return this.graphDef.functions;
    },
    inputRootVarDef: function inputRootVarDef() {
      var rootVarDef = this.rootVarDef;
      if (!this.rootVarDef) return null;
      return filterTreeByFunctionIO(rootVarDef, this.functionId, true, null, true);
    },
    outputRootVarDef: function outputRootVarDef() {
      var rootVarDef = this.rootVarDef;
      if (!this.rootVarDef) return null;
      return filterTreeByFunctionIO(rootVarDef, this.functionId, null, true, true);
    },
    inputMainIds: function inputMainIds() {
      if (!this.inputRootVarDef) return [];
      return _.map(getFlatVars(this.inputRootVarDef), function (varDef) {
        return varDef.id;
      });
    },
    outputMainIds: function outputMainIds() {
      if (!this.outputRootVarDef) return [];
      return _.map(getFlatVars(this.outputRootVarDef), function (varDef) {
        return varDef.id;
      });
    },
    uploaderTitle: function uploaderTitle() {
      return this.uploadingInput ? 'Upload Input File' : 'Upload Output File';
    },
    uploaderIO: function uploaderIO() {
      return this.uploadingInput ? 'input' : 'output';
    },
    _debouncedSave: function _debouncedSave() {
      return _.debounce(this._save, 500);
    }
  },
  methods: {
    _setEditedFunctionDef: function _setEditedFunctionDef(functionDef) {
      this.editedFunctionDef = _.cloneDeep(functionDef);

      this._stopEditingTitle();
    },
    _editTitle: function _editTitle() {
      var _this3 = this;

      this.editedTitle = this.editedFunctionDef.title;
      this.editingTitle = true;
      this.$nextTick(function () {
        return _this3.$refs.titleField.focus();
      });
    },
    _finishEditingTitle: function _finishEditingTitle() {
      this.editedFunctionDef.title = this.editedTitle;

      this._save();

      this._stopEditingTitle();
    },
    _stopEditingTitle: function _stopEditingTitle() {
      this.editedTitle = this.editedFunctionDef.title;
      this.editingTitle = false;
    },
    _save: function _save() {
      this.$emit('save', this.editedFunctionDef);
    },
    _addRootNode: function _addRootNode() {
      this.$refs.createVar.start({
        id: null,
        xpath: null
      }, 'Create Root Variable', 'Create', false);
    },
    _showCreate: function _showCreate(isInput, parentVarDef) {
      this.editVarId = null;
      this.editVarInput = isInput;
      this.$refs.createVar.start(parentVarDef);
    },
    _showInfo: function _showInfo(isInput, varDef) {
      this.editVarInput = isInput;
      this.$refs.varInfo.open(varDef);
    },
    _showEdit: function _showEdit(isInput, varDef) {
      this.editVarId = varDef.id;
      if (isInput !== null) this.editVarInput = isInput;
      this.$refs.createVar.setData(varDef.name, varDef.attrib, varDef.svm);
      this.$refs.createVar.start(varDef, 'Edit Variable', 'Apply');
    },
    _doCreateVar: function _doCreateVar(parentVarId, varDefData) {
      var _c = this;

      if (this.editVarId !== null) {
        var _c2 = this;

        this.api.editEditVar(this.editVarId, varDefData, function (rootVarDef, newVarDef) {
          _c2.highlightIds = [newVarDef.id];

          _c2.dispatcher.updatedRootVarDef(rootVarDef);
        });
        return;
      }

      this.api.editCreateVar(this.functionId, this.editVarInput, parentVarId, varDefData, function (rootVarDef, newVarDef) {
        _c.highlightIds = [newVarDef.id];

        _c.dispatcher.updatedRootVarDef(rootVarDef);

        _c.dispatcher.success('Created schema root: ' + newVarDef.name);
      });
    },
    _connect: function _connect(isInput, varDef) {
      var _c = this;

      this.api.editConnectVar(this.functionId, isInput, varDef.id, function (rootVarDef) {
        _c.dispatcher.updatedRootVarDef(rootVarDef);
      });
    },
    _delete: function _delete(isInput, varDef) {
      var _c = this;

      this.api.editDeleteVar(this.functionId, isInput, varDef.id, function (rootVarDef) {
        _c.dispatcher.updatedRootVarDef(rootVarDef);
      });
    },
    _search: function _search(isInput, term) {
      if (!term) {
        if (isInput) {
          this.inputSearchIds = [];
        } else {
          this.outputSearchIds = [];
        }
      } else {
        var _c = this;

        this.api.searchMainRootVar(term, function (searchIds) {
          if (isInput) {
            _c.inputSearchIds = searchIds;
          } else {
            _c.outputSearchIds = searchIds;
          }
        });
      }
    },
    _showUpload: function _showUpload(isInput) {
      this.uploadingInput = isInput;
      this.$refs.uploadModal.open();
    },
    _uploadOpened: function _uploadOpened() {
      var uploader = this.$refs.uploader;
      if (uploader) uploader.reset();
    },
    _applyUpload: function _applyUpload() {
      var uploader = this.$refs.uploader;
      if (!uploader.uploadValid) return; // Set the tool_io_type attribute to process uploaded I/O in the backend

      this.editedFunctionDef.tool_io_type = 'xml';
      this.editedFunctionDef.tool_io_is_input = this.uploadingInput;

      this._save();

      return true;
    },
    _export: function _export() {
      if (this.exportType === 'rce') {
        this.$refs.rceSettings.open();
      } else {
        this._doExport({});
      }
    },
    _rceSelect: function _rceSelect(settings) {
      this._doExport(settings);
    },
    _doExport: function _doExport(settings) {
      var _c = this;

      this.api.ioExport(this.projectId, this.branchId, this.exportType, this.functionId, settings, function (file) {
        if (file.msg) {
          _c.dispatcher.success(file.msg);
        }

        if (file.filename) {
          if (file.saved) {
            _c.dispatcher.success('Saved file as: ' + file.filename);
          } else {
            saveFile(file.filename, file.data, file.mime_type);
          }
        }
      });
    },
    _onResize: function _onResize() {
      this.windowWidth = window.innerWidth;
    },
    _keyPress: function _keyPress(e) {
      // Stop propagation of the space key: the app is also listening for this key (used for playing/pausing
      // the workflow process), and there e.preventDefault() is called, which prevents the space to be typed
      // in any input element
      if (_.includes([32, 37, 39], e.keyCode)) {
        // Space, left arrow, right arrow
        e.stopPropagation();
      }
    },
    _displayChanged: function _displayChanged() {
      if (this.$refs.inputVarList) this.$refs.inputVarList.displayChanged();
      if (this.$refs.outputVarList) this.$refs.outputVarList.displayChanged();
    }
  },
  created: function created() {
    this._setEditedFunctionDef(this.functionDef);
  },
  mounted: function mounted() {
    this._onResize();

    window.addEventListener('resize', this._onResize);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this._onResize);
  }
};