var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "root", staticClass: "branches" },
    [
      _c("arrows", { staticClass: "arrows", attrs: { arrows: _vm.arrows } }),
      _vm._v(" "),
      _c("table", [
        _c(
          "tr",
          _vm._l(_vm.branches, function(branch, idx) {
            return _c("td", { staticClass: "branch-tree" }, [
              _c(
                "div",
                {
                  ref: "branch",
                  refInFor: true,
                  staticClass: "branch",
                  class: { current: _vm.isCurrent[idx] }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm._edit(branch.id)
                        }
                      }
                    },
                    [_vm._v(_vm._s(branch.title))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "branch-content" }, [
                    _c("div", { staticClass: "xdsm-flex" }, [
                      _c(
                        "div",
                        {
                          staticClass: "xdsm-wrapper",
                          style: { opacity: _vm.graphDefs[idx] ? 1 : 0 },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm._edit(branch.id)
                            }
                          }
                        },
                        [
                          _vm.graphDefs[idx]
                            ? _c("xdsm", {
                                attrs: { "graph-def": _vm.graphDefs[idx] }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "controls" }, [
                      branch.children.length == 0 && !_vm.standaloneMode
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-default icon",
                              attrs: { title: "Edit workflow" },
                              on: {
                                click: function($event) {
                                  _vm._edit(branch.id)
                                }
                              }
                            },
                            [_c("span", { staticClass: "fas fa-edit" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      branch.children.length || _vm.standaloneMode
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-default icon",
                              attrs: { title: "View workflow" },
                              on: {
                                click: function($event) {
                                  _vm._edit(branch.id)
                                }
                              }
                            },
                            [_c("span", { staticClass: "fas fa-eye" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.standaloneMode
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-default icon",
                              attrs: { title: "New branch from this branch" },
                              on: {
                                click: function($event) {
                                  _vm.$emit("duplicate", branch.id)
                                }
                              }
                            },
                            [_c("span", { staticClass: "fas fa-code-branch" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.standaloneMode
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-default icon",
                              attrs: { title: "Export workflow" },
                              on: {
                                click: function($event) {
                                  _vm.$emit("export", branch.id)
                                }
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "fas fa-external-link-alt"
                              })
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canDelete
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-default icon",
                              attrs: { title: "Delete branch tree from here" },
                              on: {
                                click: function($event) {
                                  _vm.$emit("delete", branch)
                                }
                              }
                            },
                            [_c("span", { staticClass: "far fa-trash-alt" })]
                          )
                        : _vm._e()
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              branch.children.length
                ? _c(
                    "div",
                    { staticClass: "child-branches" },
                    [
                      _c("branch-tree", {
                        ref: "child_" + idx,
                        refInFor: true,
                        attrs: {
                          graphs: _vm.graphs,
                          branches: branch.children,
                          "project-id": _vm.projectId,
                          "current-project-id": _vm.currentProjectId,
                          "current-branch-id": _vm.currentBranchId,
                          scale: _vm.scale
                        },
                        on: {
                          edit: function($event) {
                            _vm.$emit("edit", $event)
                          },
                          export: function($event) {
                            _vm.$emit("export", $event)
                          },
                          delete: function($event) {
                            _vm.$emit("delete", $event)
                          },
                          duplicate: function($event) {
                            _vm.$emit("duplicate", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          })
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }