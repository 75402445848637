//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../../lodash.custom.js';
import escape from 'escape-html';
import uiModal from '../ui-modal.vue';
import uiDialog from '../ui-dialog.vue';
import analysisForm from './analysis-form.vue';
import mdaoForm from './mdao-form.vue';
import { filterTreeByFunctionIO, getFlatVars } from '../../var-utils.js';
import varListModal from '../var-list/var-list-modal.vue';
import xdsmFunction from '../xdsm/xdsm-function.vue';
import createVar from '../var-list/create-var.vue';
import { functionTypes, functionTypeTitles, shortFunctionTypeTitles, shortFunctionTypeTitlesLC, isMdaoType, newFunctionDefObj } from './functions.js';
import { api, dispatcher } from '../../index';
export default {
  name: "function-modal",
  components: {
    uiModal: uiModal,
    uiDialog: uiDialog,
    analysisForm: analysisForm,
    mdaoForm: mdaoForm,
    varListModal: varListModal,
    xdsmFunction: xdsmFunction,
    createVar: createVar
  },
  props: {
    rootVarDef: {
      type: Object,
      default: function _default() {
        return null;
      }
    },
    graphDef: {
      type: Object,
      default: function _default() {
        return null;
      }
    }
  },
  data: function data() {
    return {
      api: api,
      dispatcher: dispatcher,
      newFunctionDef: newFunctionDefObj,
      functionDef: _.cloneDeep(newFunctionDefObj),
      callbackFunction: null,
      editable: true,
      titleRules: {
        required: true,
        excluded: []
      },
      showingInput: false,
      submitted: false,
      varsEdited: false,
      editedInputRootVarDef: null,
      editedOutputRootVarDef: null,
      isValid: true,
      createVarCallback: null,
      availableToolIO: null
    };
  },
  computed: {
    functionId: function functionId() {
      return this.functionDef.id;
    },
    isNew: function isNew() {
      return this.functionId == -1;
    },
    confirmClose: function confirmClose() {
      return this.isNew;
    },
    type: function type() {
      return this.functionDef.type;
    },
    typeTitle: function typeTitle() {
      if (!functionTypeTitles.hasOwnProperty(this.type)) throw "Unknown type: " + this.type;
      return functionTypeTitles[this.type];
    },
    modalTitle: function modalTitle() {
      var verb = !this.editable ? 'View' : this.isNew ? 'Add' : 'Edit';
      return verb + ' ' + this.typeTitle;
    },
    shortTypeTitle: function shortTypeTitle() {
      if (!shortFunctionTypeTitles.hasOwnProperty(this.type)) throw "Unknown (short) type: " + this.type;
      return shortFunctionTypeTitles[this.type];
    },
    shortTypeTitleLC: function shortTypeTitleLC() {
      if (!shortFunctionTypeTitlesLC.hasOwnProperty(this.type)) throw "Unknown (short LC) type: " + this.type;
      return shortFunctionTypeTitlesLC[this.type];
    },
    hasButtons: function hasButtons() {
      return this.editable;
    },
    actionTitle: function actionTitle() {
      return this.isNew ? 'Add' : 'Update';
    },
    existingFunctions: function existingFunctions() {
      return this.graphDef ? this.graphDef.functions : [];
    },
    usedOtherFunctionNames: function usedOtherFunctionNames() {
      var id = this.functionId;
      return _.map(_.filter(this.existingFunctions, function (functionDef) {
        return functionDef.id != id;
      }), function (functionDef) {
        return functionDef.title;
      });
    },
    isTool: function isTool() {
      return this.type == functionTypes.TOOL;
    },
    isSubWorkflow: function isSubWorkflow() {
      return this.type == functionTypes.SUB_WF;
    },
    hasSubWorkflowIssues: function hasSubWorkflowIssues() {
      return this.isSubWorkflow && this.functionDef.issues;
    },
    isAnalysis: function isAnalysis() {
      return !this.isMdao;
    },
    isMdao: function isMdao() {
      return isMdaoType(this.type);
    },
    inputRootVarDef: function inputRootVarDef() {
      var editedVarDef = this.editedInputRootVarDef;
      var rootVarDef = editedVarDef !== null ? editedVarDef : this.rootVarDef;
      if (!this.rootVarDef) return null;
      return filterTreeByFunctionIO(rootVarDef, this.functionId, true, null, true);
    },
    outputRootVarDef: function outputRootVarDef() {
      var editedVarDef = this.editedOutputRootVarDef;
      var rootVarDef = editedVarDef !== null ? editedVarDef : this.rootVarDef;
      if (!this.rootVarDef) return null;
      return filterTreeByFunctionIO(rootVarDef, this.functionId, null, true, true);
    },
    inputTreeVarIds: function inputTreeVarIds() {
      if (!this.inputRootVarDef) return [];
      return _.map(getFlatVars(this.inputRootVarDef), function (varDef) {
        return varDef.id;
      });
    },
    outputTreeVarIds: function outputTreeVarIds() {
      if (!this.outputRootVarDef) return [];
      return _.map(getFlatVars(this.outputRootVarDef), function (varDef) {
        return varDef.id;
      });
    },
    hasIoDef: function hasIoDef() {
      return this.inputRootVarDef || this.outputRootVarDef;
    },
    ioEditable: function ioEditable() {
      return this.editable && this.isTool;
    },
    ioButtonVerb: function ioButtonVerb() {
      return this.ioEditable ? 'View / edit' : 'View';
    },
    notes: function notes() {
      return this.functionDef.notes || '';
    },
    escapedHtmlNotes: function escapedHtmlNotes() {
      return escape(this.notes).replace(/\n/g, '<br />');
    }
  },
  methods: {
    openNew: function openNew(type, callback) {
      var _this = this;

      if (!_.includes(functionTypes, type)) throw "Unknown function type: " + type;

      var functionDef = _.cloneDeep(this.newFunctionDef);

      functionDef.type = type;
      this.functionDef = functionDef;
      this.callbackFunction = callback || null;
      this.editable = true;
      this.api.getToolIOFiles(function (toolIO) {
        _this.availableToolIO = toolIO;

        _this._open();
      });
    },
    openEdit: function openEdit(functionDef, callback) {
      var _this2 = this;

      this.functionDef = _.cloneDeep(functionDef);
      this.callbackFunction = callback || null;
      this.editable = true;
      this.api.getToolIOFiles(function (toolIO) {
        _this2.availableToolIO = toolIO;

        _this2._open();
      });
    },
    openView: function openView(functionDef) {
      this.functionDef = functionDef;
      this.callbackFunction = null;
      this.editable = false;

      this._open();
    },
    close: function close() {
      this.$refs.modal.close();
    },
    _open: function _open() {
      var _this3 = this;

      this.isValid = true;
      this.varsEdited = false;
      this.submitted = false;
      this.editedInputRootVarDef = null;
      this.editedOutputRootVarDef = null; //Prevent duplicate tool names in MDAx

      this.titleRules.excluded = Array.from(this.usedOtherFunctionNames); // Timeout to make sure the new width has been propagated

      setTimeout(function () {
        return _this3.$refs.modal.open();
      }, 0);
    },
    _opened: function _opened() {
      if (this.editable && this.isNew) {
        this.$refs.nameField.focus();
      }
    },
    _submit: function _submit() {
      var _c = this;

      _c.errors.clear();

      Promise.all(this._getValidationPromises('function')).then(function (valid) {
        _.forEach(_c.errors.items, function (item) {
          if (item.field != 'ids') _c.dispatcher.error(item.msg);
        }); // Check if all promises resolved truthy


        if (!valid.every(function (val) {
          return val;
        })) {
          _c.isValid = false;
          return;
        }

        _c.isValid = true; // Call the function modal callback function and close the modal

        _c.submitted = true;
        if (_c.callbackFunction) _c.callbackFunction(_c.functionDef, function () {
          _c.close();
        });
      }); // Prevent modal close

      return false;
    },
    _closed: function _closed() {
      // If we have not submitted the edited function, but we have edited the I/O variables, we must reload
      if (!this.submitted && this.varsEdited) {
        this.dispatcher.backendStateUpdated();
      }
    },
    _getValidationPromises: function _getValidationPromises(scope) {
      var promises = [this.$validator.validateAll(scope)];

      _.forEach(this.$refs, function (ref) {
        if (ref && ref.hasOwnProperty('validateAll')) promises.push(ref.validateAll(scope));
      });

      return promises;
    },
    _showInput: function _showInput() {
      if (!this.rootVarDef) {
        this._createRootVarDef(this._showInput);

        return;
      }

      var _c = this;

      this._showInputOutput(function () {
        return _c.inputRootVarDef;
      }, function () {
        return _c.inputTreeVarIds;
      }, true, 'Input Variables');
    },
    _showOutput: function _showOutput() {
      if (!this.rootVarDef) {
        this._createRootVarDef(this._showOutput);

        return;
      }

      var _c = this;

      this._showInputOutput(function () {
        return _c.outputRootVarDef;
      }, function () {
        return _c.outputTreeVarIds;
      }, false, 'Output Variables');
    },
    _showInputOutput: function _showInputOutput(getRootVarDefFunction, getTreeMainIdsFunction, isInput, title) {
      if (!this.rootVarDef) return;
      if (!this.$refs.varListModal) return;
      this.showingInput = isInput;
      var modal = this.$refs.varListModal;
      modal.resetModal();
      modal.setTitle(title, null, this.functionDef);

      var _c = this; // If I/O is editable, open the modal showing the complete diagram root, where nodes not included in the
      // tool I/O are shown in a light color. Otherwise, just only show the tool I/O nodes.


      if (this.ioEditable) {
        modal.open(function () {
          return _c.rootVarDef;
        }, null, null, getTreeMainIdsFunction);
      } else {
        modal.open(getRootVarDefFunction);
      }
    },
    _createRootVarDef: function _createRootVarDef(finishCallback) {
      if (!this.editable) return;
      this.createVarCallback = finishCallback;
      this.$refs.createVar.start({
        id: null,
        xpath: null
      }, 'Create Root Variable', 'Create', false);
    },
    _doCreateVar: function _doCreateVar(parentVarId, varDefData) {
      var _c = this;

      this._createVarFunction(parentVarId, varDefData, function () {
        _c.dispatcher.success('Created schema root: ' + varDefData.name);

        if (_c.createVarCallback) {
          _c.$nextTick(function () {
            _c.createVarCallback();

            _c.createVarCallback = null;
          });
        }
      });
    },
    _createVarFunction: function _createVarFunction(parentVarId, varData, callback) {
      var _c = this;

      var isInput = this.showingInput;
      this.api.editCreateVar(this.functionId, isInput, parentVarId, varData, function (rootVarDef, newVarDef) {
        _c.varsEdited = true;

        _c.dispatcher.updatedRootVarDef(rootVarDef);

        callback(newVarDef);
      });
    },
    _editVarFunction: function _editVarFunction(varId, varData, callback) {
      var _c = this;

      this.api.editEditVar(varId, varData, function (rootVarDef, newVarDef) {
        callback(newVarDef);
        _c.varsEdited = true;

        _c.dispatcher.updatedRootVarDef(rootVarDef);
      });
    },
    _connectVarFunction: function _connectVarFunction(varDef, callback) {
      var _c = this;

      var isInput = this.showingInput;
      this.api.editConnectVar(this.functionId, isInput, varDef.id, function (rootVarDef) {
        _c.varsEdited = true;

        _c.dispatcher.updatedRootVarDef(rootVarDef);

        callback();
      });
    },
    _deleteVarFunction: function _deleteVarFunction(varDef, callback) {
      var _c = this;

      var isInput = this.showingInput;
      this.api.editDeleteVar(this.functionId, isInput, varDef.id, function (rootVarDef) {
        _c.varsEdited = true;

        _c.dispatcher.updatedRootVarDef(rootVarDef);

        callback();
      });
    },
    _addToolsBatch: function _addToolsBatch() {
      this.close();
      this.$emit('addBatch');
    },
    _editSubWorkflow: function _editSubWorkflow() {
      this.close();
      this.$emit('editSubWorkflow', this.functionId);
    },
    _mergeSubWorkflow: function _mergeSubWorkflow() {
      var _this4 = this;

      var _c = this;

      this.$refs.confirmMergeSubWorkflowDialog.open(function () {
        _c.close();

        _c.$emit('mergeSubWorkflow', _this4.functionId);
      });
    }
  }
};