var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vue-clip",
    {
      ref: "clipUploader",
      staticClass: "no-select",
      attrs: {
        options: _vm.uploadSettings,
        "on-added-file": _vm._fileAdded,
        "on-sending": _vm._sending,
        "on-removed-file": _vm._fileRemoved,
        "on-complete": _vm._fileUploadComplete
      },
      scopedSlots: _vm._u([
        {
          key: "clip-uploader-body",
          fn: function(props) {
            return [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.hasFile && !_vm.isUploading,
                      expression: "hasFile && !isUploading"
                    }
                  ],
                  staticClass: "uploader-body",
                  class: { error: !_vm.uploadValid || !_vm.externalIsValid }
                },
                _vm._l(props.files, function(file) {
                  return file.status == "success"
                    ? _c("div", [
                        _vm._v("\n                Selected file: "),
                        _c("b", [_vm._v(_vm._s(file.name))]),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "far fa-trash-alt link",
                          attrs: { title: "Remove uploaded file" },
                          on: {
                            click: function($event) {
                              _vm._removeFile(file)
                            }
                          }
                        })
                      ])
                    : _vm._e()
                })
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "clip-uploader-action" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.hasAllFiles || _vm.isUploading,
                expression: "!hasAllFiles || isUploading"
              }
            ]
          },
          [
            _c(
              "div",
              {
                staticClass: "dz-message drop-zone",
                class: { error: !_vm.uploadValid, disabled: _vm.isUploading }
              },
              [
                _c(
                  "h2",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isUploading,
                        expression: "!isUploading"
                      }
                    ]
                  },
                  [_vm._v(_vm._s(_vm.dropMessage))]
                ),
                _vm._v(" "),
                _c(
                  "h2",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isUploading,
                        expression: "isUploading"
                      }
                    ]
                  },
                  [_vm._v("Uploading...")]
                )
              ]
            )
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }