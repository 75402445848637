var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      ref: "modal",
      attrs: {
        title: _vm.modalTitle,
        "sub-title": _vm.modalSubTitle,
        "has-buttons": _vm.hasSelect,
        action: _vm._submit,
        "action-title": "Connect",
        "confirm-close": false
      },
      on: { opened: _vm._opened }
    },
    [
      _c("div", { staticClass: "instructions" }, [
        !_vm.hasSelect
          ? _c("div", { staticStyle: { "margin-bottom": "15px" } }, [
              _vm._v(
                "\n            Click on a function to connect the variable.\n        "
              )
            ])
          : _vm._e(),
        _vm._v(
          "\n\n        To connect to other types of functions (e.g. optimizers or math functions),\n        edit the corresponding function from the main workflow diagram.\n    "
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { ref: "wrapper", staticClass: "select-wrapper no-select" },
        [
          _c("arrows", {
            staticClass: "canvas",
            attrs: { arrows: _vm.arrows, "marker-mid": true }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "select",
              class: { left: !_vm.isInput, right: _vm.isInput }
            },
            [
              _vm.hasSelect
                ? _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedFunctionId,
                          expression: "selectedFunctionId"
                        }
                      ],
                      ref: "select",
                      staticClass: "form-control",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedFunctionId = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.options, function(opt, idx) {
                      return _c(
                        "option",
                        { key: idx, domProps: { value: opt[0] } },
                        [_vm._v(_vm._s(opt[1]))]
                      )
                    })
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.availableFunctions, function(functionDef, idx) {
                return !_vm.hasSelect
                  ? _c(
                      "div",
                      {
                        ref: "functionBlock",
                        refInFor: true,
                        staticClass: "function-block",
                        on: {
                          mouseup: function($event) {
                            _vm._selectFunction(functionDef.id)
                          },
                          mouseenter: function($event) {
                            _vm.functionHoverIdx = idx
                          },
                          mouseleave: function($event) {
                            _vm.functionHoverIdx = null
                          }
                        }
                      },
                      [
                        _c("xdsm-function", {
                          attrs: { "function-def": functionDef }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "var",
              staticClass: "var",
              class: { left: _vm.isInput, right: !_vm.isInput }
            },
            [_c("xdsm-data", { attrs: { label: _vm.name } })],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }