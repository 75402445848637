var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "root",
      staticClass: "function",
      class: _vm.classes,
      style: _vm.styles
    },
    [
      _c("div", { staticClass: "function-content" }, [
        _vm.processTitle
          ? _c("div", { staticClass: "process-title" }, [
              _vm._v(_vm._s(_vm.processTitle) + ":")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "function-title" }, [
          _vm._v(_vm._s(_vm.title))
        ])
      ]),
      _vm._v(" "),
      _vm.deletable || _vm.hasCollapseButton || _vm.enterable
        ? _c("div", { staticClass: "actions actions-left-top" }, [
            _vm.deletable
              ? _c(
                  "div",
                  {
                    attrs: {
                      title: _vm.deleteTitle
                        ? _vm.deleteTitle
                        : "Delete function"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm._doDelete($event)
                      }
                    }
                  },
                  [_c("span", { class: _vm.deleteClass })]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasCollapseButton
              ? _c(
                  "div",
                  {
                    attrs: { title: _vm.collapseTitle },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm._collapse($event)
                      }
                    }
                  },
                  [_c("span", { class: _vm.collapseClass })]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.enterable
              ? _c(
                  "div",
                  {
                    attrs: { title: "Enter sub-workflow" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm._enter($event)
                      }
                    }
                  },
                  [_c("span", { staticClass: "fas fa-level-down-alt" })]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.dragHandle
        ? _c(
            "div",
            {
              staticClass: "actions actions-right-top drag-handle",
              attrs: { title: "Drag to sort functions" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                },
                mousedown: function($event) {
                  _vm.$emit("handleMouseDown")
                },
                mouseup: function($event) {
                  _vm.$emit("handleMouseUp")
                }
              }
            },
            [_c("span", { staticClass: "fas fa-arrows-alt" })]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }