var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "issue-list" }, [
    _c("table", [
      _c(
        "tbody",
        _vm._l(_vm.issuesList, function(issue, idx) {
          return _c(
            "tr",
            {
              key: idx,
              staticClass: "issue-item",
              class: _vm._issueItemClasses(issue),
              attrs: { title: issue.tooltip },
              on: {
                click: function($event) {
                  _vm._click(idx)
                }
              }
            },
            [
              _c("td", { staticClass: "issue-part issue-icon" }, [
                issue.status == "completed"
                  ? _c("span", { staticClass: "fas fa-check" })
                  : issue.status == "neutral"
                    ? _c("span", { staticClass: "fas fa-times" })
                    : issue.status == "critical"
                      ? _c("span", { staticClass: "fas fa-times" })
                      : _vm._e()
              ]),
              _vm._v(" "),
              _c("td", {
                staticClass: "issue-part issue-text",
                domProps: { innerHTML: _vm._s(issue.text) }
              })
            ]
          )
        })
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }