//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../../lodash.custom.js';
import uiModal from '../ui-modal.vue';
import selectTable from '../select/select-table.vue';
import { api, dispatcher } from '../../index';
import { isMultiFileRoot } from "../../var-utils";
import { Validator } from 'vee-validate';
Validator.extend('var_name', function (value) {
  return /^[0-9A-Z_.-]*$/i.test(value);
}); // Alpha-numeric and _.-

export default {
  name: "create-var",
  components: {
    uiModal: uiModal,
    selectTable: selectTable
  },
  data: function data() {
    return {
      api: api,
      dispatcher: dispatcher,
      parentVarId: null,
      isValid: true,
      xpath: null,
      name: '',
      attr: [],
      attrTableColumns: [['key', 'Key'], ['value', 'Value']],
      newAttrKey: '',
      newAttrValue: '',
      svm: [],
      svmTableColumns: [['xpath', 'XPath'], ['value', 'value']],
      newSvmXPath: '',
      newSvmValue: '',
      inCreateVarEvent: false,
      modalTitle: null,
      actionTitle: null,
      attrAllowed: true,
      hasCustomData: false
    };
  },
  computed: {
    varData: function varData() {
      var attrObj = _.fromPairs(_.map(this.attr, function (attr) {
        return [attr.key, attr.value];
      }));

      var svmObj = _.fromPairs(_.map(this.svm, function (svm) {
        return [svm.xpath, svm.value || null];
      }));

      return {
        'name': this.name,
        'attrib': attrObj,
        'svm': svmObj
      };
    },
    xpathBase: function xpathBase() {
      var xpath = this.xpath;

      if (_.endsWith(xpath, ']')) {
        xpath = _.split(xpath, '[');
        xpath[xpath.length - 1] = '';
        xpath = _.join(xpath, '[');
      }

      var xpathParts = _.split(xpath, '/');

      xpathParts[xpathParts.length - 1] = '';
      return _.join(xpathParts, '/');
    },
    xpathVar: function xpathVar() {
      var name = this.name;
      var attrs = '';

      if (this.attr.length > 0 || this.svm.length > 0) {
        var attrItems = _.concat(_.map(this.attr, function (attr) {
          return '@' + attr.key + '=' + attr.value;
        }), _.map(this.svm, function (svm) {
          return svm.value ? svm.xpath + '="' + svm.value + '"' : svm.xpath;
        }));

        attrs = '[' + _.join(attrItems, ' and ') + ']';
      }

      return name + attrs;
    },
    xpathShow: function xpathShow() {
      if (!this.hasCustomData) return this.xpath;
      return this.xpathBase + this.xpathVar;
    },
    xpathLabel: function xpathLabel() {
      return this.hasCustomData ? 'XPath' : 'Parent XPath';
    },
    svmAllowed: function svmAllowed() {
      return this.attrAllowed;
    }
  },
  methods: {
    setData: function setData(name, attrib, svm) {
      this.hasCustomData = true;
      this.name = name;
      this.attr = attrib ? _.map(attrib, function (value, key) {
        return {
          key: key,
          value: value
        };
      }) : [];
      this.svm = svm ? _.map(svm, function (value, xpath) {
        return {
          xpath: xpath,
          value: value
        };
      }) : [];
    },
    start: function start(parentVarDef, modalTitle, actionTitle, attrAllowed) {
      var _this = this;

      this.parentVarId = parentVarDef.id;

      if (isMultiFileRoot(parentVarDef)) {
        this.xpath = null;
        attrAllowed = false;
        modalTitle = 'Create Root Variable';
      } else {
        this.xpath = parentVarDef.xpath;
      }

      this.modalTitle = modalTitle || null;
      this.actionTitle = actionTitle || null;
      this.attrAllowed = _.isUndefined(attrAllowed) ? true : attrAllowed;

      if (!this.hasCustomData) {
        this.name = '';
        this.attr = [];
        this.svm = [];
      }

      this.value = '';
      this.newAttrKey = '';
      this.newAttrValue = '';
      this.newSvmXPath = '';
      this.newSvmValue = '';
      this.inCreateVarEvent = false;
      this.isValid = true;
      this.$refs.modal.open();
      setTimeout(function () {
        return _this.$refs.name.focus();
      }, 100);
    },
    close: function close() {
      this.$refs.modal.close();
    },
    _closing: function _closing() {
      this.hasCustomData = false;
    },
    _create: function _create() {
      var _c = this;

      this.$validator.validateAll('main').then(function (valid) {
        if (valid) {
          _c.inCreateVarEvent = true;

          _c.$refs.modal.setLoading(true);

          _c.$emit('create', _c.parentVarId, _c.varData);
        }
      });
      return false;
    },
    _addAttr: function _addAttr() {
      var _c = this;

      this.$validator.validateAll('attr').then(function (valid) {
        if (!valid) {
          _c.isValid = false;
          return;
        }

        _c.isValid = true;

        _c.attr.push({
          key: _c.newAttrKey,
          value: _c.newAttrValue
        });

        _c.newAttrKey = '';
        _c.newAttrValue = '';
        setTimeout(function () {
          return _c.errors.clear('attr');
        }, 0);
      });
    },
    _removeAttr: function _removeAttr(i) {
      this.attr.splice(i, 1);
    },
    _addSvm: function _addSvm() {
      var _c = this;

      this.$validator.validateAll('svm').then(function (valid) {
        if (!valid) {
          _c.isValid = false;
          return;
        }

        _c.isValid = true;

        _c.svm.push({
          xpath: _c.newSvmXPath,
          value: _c.newSvmValue || null
        });

        _c.newSvmXPath = '';
        _c.newSvmValue = '';
        setTimeout(function () {
          return _c.errors.clear('svm');
        }, 0);
      });
    },
    _removeSvm: function _removeSvm(i) {
      this.svm.splice(i, 1);
    },
    _isLoading: function _isLoading(isLoading) {
      if (this.inCreateVarEvent && !isLoading) {
        this.inCreateVarEvent = false;
        this.close();
      }
    }
  },
  created: function created() {
    this.dispatcher.onIsLoading(this._isLoading);
  },
  beforeDestroy: function beforeDestroy() {
    this.dispatcher.offIsLoading(this._isLoading);
  }
};