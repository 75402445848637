var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isTool && (_vm.editable || (!_vm.editable && _vm.escapedHtmlVersion))
        ? _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "col-sm-2 control-label" }, [
              _vm._v("Tool Version")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-2" }, [
              _vm.editable
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.functionDef.version,
                        expression: "functionDef.version"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "version",
                      placeholder: "Tool version"
                    },
                    domProps: { value: _vm.functionDef.version },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.functionDef,
                          "version",
                          $event.target.value
                        )
                      }
                    }
                  })
                : _c("div", {
                    staticClass: "form-control-static",
                    domProps: { innerHTML: _vm._s(_vm.escapedHtmlVersion) }
                  })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isTool && (_vm.editable || (!_vm.editable && _vm.escapedHtmlVersion))
        ? _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "col-sm-2 control-label" }, [
              _vm._v("Execution Name")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-4" }, [
              _vm.editable
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.functionDef.exe_name,
                        expression: "functionDef.exe_name"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "exeName",
                      placeholder: "Default: Tool name"
                    },
                    domProps: { value: _vm.functionDef.exe_name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.functionDef,
                          "exe_name",
                          $event.target.value
                        )
                      }
                    }
                  })
                : _c("div", {
                    staticClass: "form-control-static",
                    domProps: { innerHTML: _vm._s(_vm.escapedHtmlVersion) }
                  })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isTool && _vm.hasIoTypeChoice
        ? _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "col-sm-2 control-label" }, [
              _vm._v("I/O Definition Format")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("options", {
                  attrs: { options: _vm.ioTypes },
                  model: {
                    value: _vm.def.tool_io_type,
                    callback: function($$v) {
                      _vm.$set(_vm.def, "tool_io_type", $$v)
                    },
                    expression: "def.tool_io_type"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasUploader
        ? _c("div", { staticClass: "form-group instructions" }, [
            _c("div", { staticClass: "col-sm-offset-2 col-sm-10" }, [
              _vm.isTool
                ? _c("div", { staticClass: "form-control-static" }, [
                    _vm._v(
                      "\n                Upload the XML files specifying the input and output of the disciplinary analysis tool."
                    ),
                    _c("br"),
                    _vm._v(
                      '\n                The input file should contain "input" in its file name, the output file should contain "output".\n                '
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                It is possible to only upload one of the two, or not upload any at all.\n            "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isSubWorkflow
                ? _c("div", { staticClass: "form-control-static" }, [
                    _vm._v(
                      "\n                Upload an MDAx project (*.mdax) and select a workflow from that project to use.\n            "
                    )
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasUploader
        ? _c(
            "div",
            {
              staticClass: "form-group",
              class: { "has-error": !_vm.uploaderIsValid }
            },
            [
              _c("label", { staticClass: "col-sm-2 control-label" }, [
                _vm.isTool ? _c("span", [_vm._v("I/O Definition")]) : _vm._e(),
                _vm._v(" "),
                _vm.isSubWorkflow
                  ? _c("span", [_vm._v("MDAx File")])
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticClass: "error-note" }, [_vm._v("required")])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-10" },
                [
                  _c("uploader", {
                    ref: "uploader",
                    attrs: {
                      "n-files": _vm.uploaderMaxFiles,
                      "n-files-min": _vm.uploaderMinFiles,
                      "file-names": _vm.uploadedFiles,
                      "external-is-valid": _vm.uploaderFileNamesValid,
                      "drop-zone-message": _vm.isTool
                        ? "Upload I/O XML files: click or drag here"
                        : "Upload MDAx file (*.mdax): click or drag here"
                    },
                    on: {
                      "update:fileNames": function($event) {
                        _vm.uploadedFiles = $event
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAvailableToolIOSelect && !_vm.hasAvailableToolIO
        ? _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "col-sm-2 control-label" }, [
              _vm._v("I/O Definition Format")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-10" }, [
              _c("div", { staticClass: "form-control-static" }, [
                _vm._v(
                  "\n                No tool I/O available in " +
                    _vm._s(_vm.remoteName) +
                    ".\n            "
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAvailableToolIOSelect && _vm.hasAvailableToolIO
        ? _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "col-sm-2 control-label" }, [
              _vm._v("Input Definition")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-4" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.functionDef.input_file,
                      expression: "functionDef.input_file"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { name: "inputFile" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.functionDef,
                        "input_file",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { domProps: { value: null } }),
                  _vm._v(" "),
                  _vm._l(_vm.availableToolIO, function(toolIO) {
                    return toolIO.input_file !== null
                      ? _c(
                          "option",
                          {
                            key: toolIO.input_file,
                            domProps: { value: toolIO.input_file }
                          },
                          [_vm._v(_vm._s(toolIO.input_file))]
                        )
                      : _vm._e()
                  })
                ],
                2
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAvailableToolIOSelect && _vm.hasAvailableToolIO
        ? _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "col-sm-2 control-label" }, [
              _vm._v("Output Definition")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-4" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.functionDef.output_file,
                      expression: "functionDef.output_file"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { name: "outputFile" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.functionDef,
                        "output_file",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { domProps: { value: null } }),
                  _vm._v(" "),
                  _vm._l(_vm.availableToolIO, function(toolIO) {
                    return toolIO.output_file !== null
                      ? _c(
                          "option",
                          {
                            key: toolIO.output_file,
                            domProps: { value: toolIO.output_file }
                          },
                          [_vm._v(_vm._s(toolIO.output_file))]
                        )
                      : _vm._e()
                  })
                ],
                2
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isSubWorkflow &&
      _vm.hasUploader &&
      _vm.uploaderIsValid &&
      _vm.uploadedDiagramData.length > 0
        ? _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "col-sm-2 control-label" }, [
              _vm._v("Select workflow")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-4" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedDiagram,
                      expression: "selectedDiagram"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { name: "selectedDiagram" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedDiagram = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.uploadedDiagramData, function(diagramData, idx) {
                  return _c("option", { key: idx, domProps: { value: idx } }, [
                    _vm._v(_vm._s(diagramData.title.replace(/ /g, " ")))
                  ])
                })
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isMath && _vm.editable
        ? _c("div", { staticClass: "form-group instructions" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _vm.isMath
        ? _c("div", { staticClass: "form-group" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 control-label",
                class: {
                  "has-error": _vm.errors.has("function_math.math_params")
                }
              },
              [_vm._v("Equation Parameters")]
            ),
            _vm._v(" "),
            _vm.hasRootVarDef
              ? _c(
                  "div",
                  { staticClass: "col-sm-10" },
                  [
                    _c("select-vars", {
                      attrs: {
                        mode: "alias",
                        "root-var-def": _vm.usedRootVarDef,
                        "graph-def": _vm.graphDef,
                        editable: _vm.editable,
                        "create-var-function": _vm._createVarFunction,
                        "need-one": true,
                        "disabled-var-ids": _vm.selectedOutputVarIds,
                        "var-modal-title": "Select Math Expression Parameters",
                        name: "math_params"
                      },
                      model: {
                        value: _vm.def.math.params,
                        callback: function($$v) {
                          _vm.$set(_vm.def.math, "params", $$v)
                        },
                        expression: "def.math.params"
                      }
                    })
                  ],
                  1
                )
              : _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default btn-root-var",
                      on: { click: _vm._createRoot }
                    },
                    [
                      _vm._v("Create root variable\n                "),
                      _c("span", { staticClass: "fas fa-edit" })
                    ]
                  )
                ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isMath && _vm.editable
        ? _c("div", { staticClass: "form-group instructions" }, [_vm._m(1)])
        : _vm._e(),
      _vm._v(" "),
      _vm.isMath
        ? _c("div", { staticClass: "form-group" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 control-label",
                class: {
                  "has-error": _vm.errors.has("function_math.output_var_id")
                }
              },
              [
                _vm._v("Output Parent Variable\n            "),
                _c("span", { staticClass: "error-note" }, [_vm._v("required")])
              ]
            ),
            _vm._v(" "),
            _vm.hasRootVarDef
              ? _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-control-static",
                      staticStyle: { "padding-top": "3px" }
                    },
                    [
                      _c(
                        "select-var",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          ref: "selectOutputVar",
                          class: {
                            "select-var-has-error": _vm.errors.has(
                              "function_math.output_var_id"
                            )
                          },
                          attrs: {
                            "root-var-def": _vm.usedRootVarDef,
                            "graph-def": _vm.graphDef,
                            "disabled-var-ids": _vm.mathParamVarIds,
                            "var-modal-title": "Select Output Node",
                            "multi-select": false,
                            "select-leaf": false,
                            "select-parent": true,
                            editable: _vm.editable,
                            "create-var-function": _vm._createVarFunction,
                            "still-need-selection":
                              _vm.selectedOutputVarIds.length == 0,
                            name: "output_var_id",
                            "data-vv-scope": "function_math",
                            "data-vv-as": "output node"
                          },
                          model: {
                            value: _vm.selectedOutputVarIds,
                            callback: function($$v) {
                              _vm.selectedOutputVarIds = $$v
                            },
                            expression: "selectedOutputVarIds"
                          }
                        },
                        [
                          _vm.selectedMathOutputVarId
                            ? _c("div", { staticClass: "selected-var" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("xpath")(
                                        _vm.selectedMathOutputXPath
                                      )
                                    ) +
                                    "\n                    "
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default btn-root-var",
                      on: { click: _vm._createRoot }
                    },
                    [
                      _vm._v("Create root variable\n                "),
                      _c("span", { staticClass: "fas fa-edit" })
                    ]
                  )
                ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isMath
        ? _c("div", { staticClass: "form-group" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 control-label",
                class: {
                  "has-error": _vm.errors.has("function_math.equations")
                }
              },
              [
                _vm._v("Equations\n            "),
                _c("span", { staticClass: "error-note" }, [_vm._v("required")])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("select-equations", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  attrs: {
                    editable: _vm.editable,
                    "expression-available-vars": _vm.expressionAvailableVars,
                    name: "equations",
                    "data-vv-validate-on": "input",
                    "data-vv-scope": "function_math"
                  },
                  model: {
                    value: _vm.def.math.equations,
                    callback: function($$v) {
                      _vm.$set(_vm.def.math, "equations", $$v)
                    },
                    expression: "def.math.equations"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("create-var", { ref: "createVar", on: { create: _vm._doCreateRoot } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-offset-2 col-sm-10" }, [
      _c("div", { staticClass: "form-control-static" }, [
        _c("ol", { staticStyle: { "padding-left": "20px" } }, [
          _c("li", [
            _vm._v(
              "Select variables to use as equation parameters, optionally giving them an alias"
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "Select the output parent: all expression outputs will be children of this node"
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "Enter the equations: use Python syntax,\n                        and use the previously entered aliases as variables"
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-offset-2 col-sm-10" }, [
      _c("div", { staticClass: "form-control-static" }, [
        _vm._v(
          "\n                Equation output will be written to variables in the "
        ),
        _c("i", [_vm._v("output parent")]),
        _vm._v("."),
        _c("br"),
        _vm._v(
          '\n                It is possible to specify nested child variables using a / delimiter: "child/var" writes the value\n                to '
        ),
        _c("i", [_vm._v("var")]),
        _vm._v(" in "),
        _c("i", [_vm._v("child")]),
        _vm._v(", which is located in the "),
        _c("i", [_vm._v("output parent")]),
        _vm._v(".\n            ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }