//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Select table component: a table where items can be added to using a simple form, and items can easily be remove
 * from the list.
 *
 * Usage:
 * <select-table
 *     :data="dataListOfObjects"
 *     :columns="columnDef"
 *     :has-add-row="hasAddRow"
 *     :editable="editable"
 *     :editable-rows="editableRows"
 *     @delete="deleteHandler"
 *     @edit="editHandler"
 *     @update="updateHandler"
 *     @add="addHandler"
 *     @reset="resetHandler"
 * >
 *     <template slot="add-<col>" slot-scope="scope">
 *         HTML to render for the add form cell for column <col>
 *     </template>
 * </select-table>
 *
 * where:
 * - dataListOfObjects is a list of objects containing the data; the fields of the objects are rendered in the table
 * - columnDef is a list describing the available columns:
 *   [[data_key, label, <formatFunction>], ...]
 *   where:
 *   - data_key is the key of the object in the dataList to render in this column
 *   - label is the name of the column as shown in the table header
 *   - formatFunction (optional) is a function that takes the data object and returns the rendered value (HTML)
 * - hasAddRow is a boolean specifying whether or not the add-row is rendered
 * - editable specifies whether the table is editable globally
 * - editableRows specifies whether individual rows are editable or not
 * - deleteHandler is a function handling the delete event, it accepts one parameter: the index (in
 *   dataListOfObjects) of the object to be deleted
 * - editHandler is a function handler the edit event, it accepts one parameter: the index of the object to be
 *   edited. It should populate the add row form to represent the state of the edited object.
 * - updateHandler is the function that handles the application of the edited form to the underlying data object
 *   that was edited previously by the editHandler; this function should handle input validation and form-resetting
 *   itself; once editing is completed, the select-table component instance should be notified using the
 *   editingFinished method
 * - addHandler is the function handling the adding of a new item to the list, it should simply add a new object to
 *   the data list; it should handle all input validation and form-resetting logic itself
 * - resetHandler is the function that reset the add row form (only relevant if row-editing is enabled)
 *
 * The HTML for the cells of the add form row is provided using the template slots. E.g. if there is a column called
 * name, the cell for the name column in the add form row is filled using:
 * <template slot="add-name" slot-scope="scope">
 *     HTML needed to display the form element goes here
 * </template>
 * */
import _ from '../../lodash.custom.js';
import { VueGoodTable } from 'vue-good-table';
import { dispatcher } from "../../index.js";
export default {
  name: "select-table",
  components: {
    VueGoodTable: VueGoodTable
  },
  props: {
    searchEnabled: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      required: true
    },
    // [[data_key, label, <formatFunction>], ...]
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    hasAddRow: {
      type: Boolean,
      default: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    editableRows: {
      type: Boolean,
      default: false
    },
    allowDuplicateRow: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      dispatcher: dispatcher,
      sortOptions: {
        enabled: false
      },
      addActive: false,
      editIdx: null,
      addRowHeight: 0
    };
  },
  watch: {
    data: function data() {
      this.addActive = false;
    }
  },
  computed: {
    vgtColumns: function vgtColumns() {
      var vgtColumns = [];
      var columns = this.columns;

      for (var i = 0; i < columns.length; i++) {
        var columnData = columns[i];
        var columnSettings = {
          field: columnData[0],
          label: columnData[1]
        };

        if (columnData.length >= 3 && _.isFunction(columnData[2])) {
          // We do not use formatFn here, as we want to offer the user to render the HTML based on the
          // data object, rather than only the value of the field (which is how formatFn operates)
          columnSettings.renderFn = columnData[2];
        }

        vgtColumns.push(columnSettings);
      } // Actions column


      if (this.editable) {
        vgtColumns.push({
          field: 'actions',
          label: 'Actions'
        });
      }

      return vgtColumns;
    },
    showAddRow: function showAddRow() {
      return this.hasAddRow && this.editable;
    },
    vgtData: function vgtData() {
      var vgtData = [];
      var tableData = this.data;

      for (var i = 0; i < tableData.length; i++) {
        vgtData.push(_.merge(_.cloneDeep(tableData[i]), {
          actions: null
        }));
      } // Add form row


      if (this.showAddRow) {
        vgtData.push({
          actions: 'add-form'
        });
      }

      return vgtData;
    },
    searchOptions: function searchOptions() {
      return {
        enabled: this.searchEnabled,
        placeholder: 'Search'
      };
    },
    rowsAreEditable: function rowsAreEditable() {
      return this.editable && this.editableRows;
    },
    editing: function editing() {
      return this.rowsAreEditable && this.editIdx !== null;
    }
  },
  methods: {
    hasInput: function hasInput() {
      this.addActive = true;
    },
    editingFinished: function editingFinished() {
      this.editIdx = null;
      this.dispatcher.popEscapeHandler();
    },
    _setEdit: function _setEdit(idx) {
      this.$emit('edit', idx);
      this.editIdx = idx;
      this.dispatcher.addEscapeHandler(this._cancelEdit);
    },
    _cancelEdit: function _cancelEdit() {
      this.$emit('reset');
      this.editingFinished();
    },
    _setAddRowHeight: function _setAddRowHeight() {
      if (this.hasAddRow && this.$refs.addRowActions) {
        var actionsEl = this.$refs.addRowActions;
        this.addRowHeight = actionsEl.parentElement.clientHeight;
      }
    },
    _contentClick: function _contentClick(idx) {
      this._setEdit(idx);
    }
  },
  mounted: function mounted() {
    setTimeout(this._setAddRowHeight, 50);
  }
};