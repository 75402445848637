var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      ref: "modal",
      attrs: {
        title: _vm.modalTitle || "Add Child Variable",
        "action-title": _vm.actionTitle || "Add",
        action: _vm._create,
        "action-error": !_vm.isValid
      },
      on: { close: _vm._closing }
    },
    [
      _c("div", { staticClass: "form-horizontal" }, [
        _vm.xpath
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-3 control-label" }, [
                _vm._v(_vm._s(_vm.xpathLabel))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-9" }, [
                _c("p", { staticClass: "form-control-static" }, [
                  _vm._v(_vm._s(_vm._f("xpath")(_vm.xpathShow)))
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "form-group",
            class: { "has-error": _vm.errors.has("main.name") }
          },
          [
            _c("label", { staticClass: "col-sm-3 control-label" }, [
              _vm._v("Name\n                "),
              _c("span", { staticClass: "error-note" }, [_vm._v("required")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-9" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.name,
                    expression: "name"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|var_name",
                    expression: "'required|var_name'"
                  }
                ],
                ref: "name",
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  name: "name",
                  placeholder: "Variable name",
                  "data-vv-validate-on": "input|change|blur",
                  "data-vv-scope": "main"
                },
                domProps: { value: _vm.name },
                on: {
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm._create($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.name = $event.target.value
                  }
                }
              })
            ])
          ]
        ),
        _vm._v(" "),
        _vm.attrAllowed
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-3 control-label" }, [
                _vm._v("Attributes")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-9" },
                [
                  _c("select-table", {
                    attrs: { data: _vm.attr, columns: _vm.attrTableColumns },
                    on: { delete: _vm._removeAttr, add: _vm._addAttr },
                    scopedSlots: _vm._u([
                      {
                        key: "add-key",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                class: {
                                  "has-error": _vm.errors.has("attr.newAttrKey")
                                }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.newAttrKey,
                                      expression: "newAttrKey"
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required|alpha_dash",
                                      expression: "'required|alpha_dash'"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "newAttrKey",
                                    placeholder: "Attribute key",
                                    "data-vv-validate-on": "input|change|blur",
                                    "data-vv-scope": "attr"
                                  },
                                  domProps: { value: _vm.newAttrKey },
                                  on: {
                                    keyup: function($event) {
                                      if (
                                        !("button" in $event) &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm._addAttr($event)
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.newAttrKey = $event.target.value
                                    }
                                  }
                                })
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "add-value",
                        fn: function(scope) {
                          return [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.newAttrValue,
                                  expression: "newAttrValue"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                name: "newAttrValue",
                                placeholder: "Attribute value"
                              },
                              domProps: { value: _vm.newAttrValue },
                              on: {
                                keyup: function($event) {
                                  if (
                                    !("button" in $event) &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm._addAttr($event)
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.newAttrValue = $event.target.value
                                }
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.svmAllowed
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-3 control-label" }, [
                _vm._v("Sub Value Matchers")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-9" },
                [
                  _c("select-table", {
                    attrs: { data: _vm.svm, columns: _vm.svmTableColumns },
                    on: { delete: _vm._removeSvm, add: _vm._addSvm },
                    scopedSlots: _vm._u([
                      {
                        key: "add-xpath",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                class: {
                                  "has-error": _vm.errors.has("svm.newSvmXPath")
                                }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.newSvmXPath,
                                      expression: "newSvmXPath"
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required|alpha_dash_slash",
                                      expression: "'required|alpha_dash_slash'"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "newSvmXPath",
                                    placeholder: "Sub XPath",
                                    "data-vv-validate-on": "input|change|blur",
                                    "data-vv-scope": "svm"
                                  },
                                  domProps: { value: _vm.newSvmXPath },
                                  on: {
                                    keyup: function($event) {
                                      if (
                                        !("button" in $event) &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm._addSvm($event)
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.newSvmXPath = $event.target.value
                                    }
                                  }
                                })
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "add-value",
                        fn: function(scope) {
                          return [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.newSvmValue,
                                  expression: "newSvmValue"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                name: "newSvmValue",
                                placeholder: "Value"
                              },
                              domProps: { value: _vm.newSvmValue },
                              on: {
                                keyup: function($event) {
                                  if (
                                    !("button" in $event) &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm._addSvm($event)
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.newSvmValue = $event.target.value
                                }
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }