function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from './lodash.custom.js';
import escape from 'escape-html';
import editor from './editor.vue';
import projectList from './project-list.vue';
import branches from './branches.vue';
import uiDialog from './ui-items/ui-dialog.vue';
import uiModal from './ui-items/ui-modal.vue';
import importModal from './import-modal.vue';
import exportModal from './export-modal.vue';
import auth from './auth.vue';
import logos from './ui-items/logos.vue';
import MarkdownIt from 'markdown-it';
import mila from 'markdown-it-link-attributes';
import { api, dispatcher, projectFileMode, branchesEnabled, ioMode, uiManaged, hasAuth, routeNamespace, previewMode } from './index';
import { standaloneMode } from "./api";
import { projectOpenBackend, projectSaveBackend, saveFile, openFile } from "./files";
export default {
  name: "app",
  components: {
    editor: editor,
    projectList: projectList,
    branches: branches,
    uiDialog: uiDialog,
    uiModal: uiModal,
    importModal: importModal,
    exportModal: exportModal,
    auth: auth,
    logos: logos
  },
  props: {
    version: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      api: api,
      dispatcher: dispatcher,
      projectFileMode: projectFileMode,
      branchesEnabled: branchesEnabled,
      ioMode: ioMode,
      previewMode: previewMode,
      uiManaged: uiManaged,
      hasAuth: hasAuth,
      standaloneMode: standaloneMode,
      helpConfigStack: [],
      escapeHandlerStack: [],
      editedProject: null,
      projectsEdited: false,
      graphs: {},
      confirmDeleteProject: null,
      confirmDeleteBranch: null,
      askNewProjectTitle: false,
      newProjectTitle: null,
      newBranchTitle: null,
      directBranchEdit: false,
      branchIsValid: true,
      dblClickTimeoutId: null,
      loading: false,
      legalText: null,
      showingAuth: false
    };
  },
  watch: {
    editedProject: function editedProject(project) {
      this.dispatcher.setInBatchGraphUpdate(true);

      var _c = this;

      setTimeout(function () {
        return _c._getGraph(_c.editedProjectId, _c.editedBranchId, true);
      }, 0);
      this.dispatcher.updatedProject(project);
    }
  },
  computed: {
    // https://github.com/vuejs/vue/issues/2870#issuecomment-219096773
    setIsLoading: function setIsLoading() {
      return _.debounce(function () {
        this.dispatcher.isLoading(true);
      }, 500);
    },
    hasProjectList: function hasProjectList() {
      return !this.projectFileMode;
    },
    hasBranches: function hasBranches() {
      return this.branchesEnabled;
    },
    storeStateUrl: function storeStateUrl() {
      return !this.projectFileMode;
    },
    editedProjectId: function editedProjectId() {
      return this.editedProject ? this.editedProject.id : null;
    },
    editedBranchId: function editedBranchId() {
      return this.editedProject ? this.editedProject.branch_id : null;
    },
    editedSubWfFunctionIds: function editedSubWfFunctionIds() {
      return this.editedProject ? this.editedProject.sub_wf_function_ids || null : null;
    },
    canEdit: function canEdit() {
      if (this.standaloneMode) return false;
      var project = this.editedProject;
      if (!project) return false;
      var branchId = this.editedBranchId;

      var nChildren = _.filter(project.branches, function (branch) {
        return branch.parent_id == branchId;
      }).length;

      return nChildren == 0;
    },
    editedUnsaved: function editedUnsaved() {
      return this.editedProject ? this.editedProject.touched : false;
    },
    hasLegalText: function hasLegalText() {
      return this.legalText != null;
    }
  },
  methods: {
    _onLoading: function _onLoading(isLoading) {
      if (isLoading) {
        this.setIsLoading();
      } else {
        this.dispatcher.isLoading(false);
        this.setIsLoading.cancel();
      }
    },
    _receivedIsLoadingStatus: function _receivedIsLoadingStatus(isLoading) {
      this.loading = isLoading;
    },
    _errorHandler: function _errorHandler(msg) {
      this._notification(msg, 'error');
    },
    _successHandler: function _successHandler(msg) {
      this._notification(msg, 'success');
    },
    _notification: function _notification(msg, type) {
      this.$toasted.show(escape(msg), {
        theme: 'primary',
        position: 'bottom-right',
        duration: 5000,
        type: type
      });
    },
    _handleKeyDown: function _handleKeyDown(e) {
      this._handleKeyPresses(e, true);
    },
    _handleKeyUp: function _handleKeyUp(e) {
      this._handleKeyPresses(e, false);
    },
    _handleKeyPresses: function _handleKeyPresses(e, doAction) {
      var editor = this.$refs.editor;
      if (this.loading) doAction = false; // https://www.cambiaresearch.com/articles/15/javascript-char-codes-key-codes

      switch (e.keyCode) {
        case 112:
          // F1
          if (doAction) this._openHelp();
          e.preventDefault();
          break;

        case 83:
          // S
          if (e.ctrlKey) {
            if (doAction) {
              var saveAs = e.shiftKey;

              if (saveAs) {// this._duplicate();
              } else {
                this._save();
              }
            }

            e.preventDefault();
          }

          break;

        case 78:
          // N
          // This does not work in Chrome, as Chrome catches it outside of JS to open a new window
          if (e.ctrlKey) {
            if (doAction) this._doNew();
            e.preventDefault();
          }

          break;

        case 90:
          // Z
          if (e.ctrlKey) {
            if (doAction) editor.undo();
            e.preventDefault();
          }

          break;

        case 89:
          // Y
          if (e.ctrlKey) {
            if (doAction) editor.redo();
            e.preventDefault();
          }

          break;

        case 27:
          // Esc
          // Do not prevent if there were no more escape events to be handled on keyup, since then this
          // event fill be handled by the browser window and used to exit from fullscreen mode
          // (see browser_window.py:BrowserEventHandler.OnKeyEvent)
          if (!doAction) {
            if (this._handleEscape()) {
              e.preventDefault();
            }
          } else {
            e.preventDefault();
          }

          break;

        case 48: // 0

        case 96:
          // numpad 0
          if (e.ctrlKey) {
            if (doAction) editor.resetZoom();
            e.preventDefault();
          }

          break;

        case 32:
          // space
          if (doAction) editor.togglePlayProcess();
          e.preventDefault();
          break;

        case 37:
          // left arrow
          if (doAction) editor.previousProcess();
          e.preventDefault();
          break;

        case 39:
          // right arrow
          if (doAction) editor.nextProcess();
          e.preventDefault();
          break;
      }
    },
    _showExport: function _showExport(project, branchId) {
      if (!project) project = this.editedProject;
      if (!branchId) branchId = project.branch_id;
      this.$refs.exportModal.open(project, branchId);
    },
    _doExport: function _doExport(project, branchId, type, settings) {
      if (!project) project = this.editedProject;
      if (!branchId) branchId = project.branch_id;

      var _c = this;

      this.api.ioExport(project.id, branchId, type, null, settings, function (file) {
        if (file.msg) {
          _c.dispatcher.success(file.msg);
        }

        if (file.filename) {
          if (file.saved) {
            _c.dispatcher.success('Saved file as: ' + file.filename);
          } else {
            saveFile(file.filename, file.data, file.mime_type);
          }
        }
      });
    },
    _doImport: function _doImport(callback) {
      var _c = this;

      this.$refs.importModal.open(function (projects) {
        // Edit the project if only one project was selected
        if (projects.length == 1) {
          _c._setEdit(projects[0].id, projects[0].branch_id);
        } else {
          _c._showProjectList();
        }

        if (callback) callback(projects);
      });
    },
    _showBranches: function _showBranches(project, selectCallback) {
      if (this.previewMode) return;
      if (!project) project = this.editedProject;
      this.$refs.branches.open(project, selectCallback);
    },
    _doNew: function _doNew(callback) {
      var _this = this;

      var _c = this;

      this.newProjectTitle = null;
      this.newBranchTitle = null;
      this.askNewProjectTitle = true;
      this.branchIsValid = true;
      this.$refs.newBranchModal.open(function () {
        // Validate input
        _this.$validator.validateAll('branch').then(function (valid) {
          if (!valid) {
            _c.branchIsValid = false;
            return;
          }

          _c.branchIsValid = true; // Create new project

          _c.api.newEdit(_c.newProjectTitle, _c.newBranchTitle, function (project) {
            _c.editedProject = project;

            _c._resetUndoRedo();

            if (callback) callback();
          });

          _c.$refs.newBranchModal.close();
        }); // Cancel closing of modal


        return false;
      });
      setTimeout(function () {
        _c.$refs.projectTitle.focus();
      }, 100);
    },
    _save: function _save() {
      if (this.projectFileMode && !this.uiManaged) {
        this._saveAs();

        return;
      }

      var _c = this;

      this.api.saveEdit(function (project) {
        if (project == 'ASK_SAVE_PATH') {
          _c._saveAs();

          return;
        }

        _c.editedProject = project;
      });
    },
    _saveAs: function _saveAs() {
      var _this2 = this;

      var _c = this;

      var fileName = this.editedProject.title;

      if (this.uiManaged) {
        // If the UI is managed, we ask the backend to present the save-as dialog and remember the new
        // save location
        projectSaveBackend(fileName, function (projectFilePath) {
          if (projectFilePath) {
            _c.api.saveAsEdit(projectFilePath, function (project) {
              _c.editedProject = project;
            });
          }
        });
      } else {
        // If the UI is not managed, we download the saved file and present a save-as dialogue locally
        this.api.downloadProjectFile(function (file) {
          saveFile(file.filename, file.data, file.mime_type);

          _this2.dispatcher.backendStateUpdated();
        });
      }
    },
    _open: function _open() {
      var _c = this;

      var projectOpenCallback = function projectOpenCallback(project) {
        _c.editedProject = project;

        _c._resetUndoRedo();

        _c._pushAppState();
      };

      if (this.uiManaged) {
        // If the UI is managed, we ask the backend to present the open dialog
        projectOpenBackend(function (projectFilePath) {
          if (projectFilePath) {
            _c.api.openProject(projectFilePath, projectOpenCallback);
          }
        });
      } else {
        // If the UI is not managed, we choose and upload a file
        openFile(function (data) {
          _c.api.uploadProject(data, projectOpenCallback);
        });
      }
    },
    _duplicate: function _duplicate(project, branchId, callback) {
      var _this3 = this;

      this.directBranchEdit = !project && !branchId;

      var _c = this;

      this.newBranchTitle = null;
      this.askNewProjectTitle = false;
      this.branchIsValid = true;
      var activate = this.directBranchEdit;
      this.$refs.newBranchModal.open(function () {
        // Validate input
        _this3.$validator.validateAll('branch').then(function (valid) {
          if (!valid) {
            _c.branchIsValid = false;
            return;
          }

          _c.branchIsValid = true; // Duplicate the branch

          _c._doDuplicate(project, branchId, _c.newBranchTitle, activate, callback);

          _c.$refs.newBranchModal.close();
        }); // Cancel closing of modal


        return false;
      });
      setTimeout(function () {
        _c.$refs.branchTitle.focus();
      }, 100);
    },
    _quickDuplicate: function _quickDuplicate(newBranchTitle) {
      this._doDuplicate(null, null, newBranchTitle, true);
    },
    _doDuplicate: function _doDuplicate(project, branchId, newBranchTitle, activate, callback) {
      if (!project) project = this.editedProject;
      if (!branchId) branchId = project.branch_id;

      var _c = this;

      _c.api.duplicateBranch(project.id, branchId, newBranchTitle, activate, function (project) {
        if (callback) callback(project);

        _c.dispatcher.branchesEdited();
      });
    },
    _onBranchesEdited: function _onBranchesEdited() {
      this.dispatcher.backendStateUpdated();
    },
    _edit: function _edit(project, branchId) {
      if (!branchId) branchId = project.branch_id;
      var subWfFunctionIds = branchId == project.branch_id ? project.sub_wf_function_ids : null;

      this._setEdit(project.id, branchId, subWfFunctionIds);
    },
    _editSub: function _editSub(subWfFunctionIds) {
      this._setEdit(this.editedProjectId, this.editedBranchId, subWfFunctionIds);
    },
    _setEdit: function _setEdit(projectId, branchId, subWfFunctionIds, fromPopState) {
      if (subWfFunctionIds === undefined) subWfFunctionIds = null;

      var _c = this;

      this.api.setEdit(projectId, branchId, subWfFunctionIds, function (project) {
        // Success
        _c.editedProject = project;

        _c._resetUndoRedo();

        if (!fromPopState) _c._pushAppState();
      }, function () {
        // Error
        _c._refreshState();
      });
    },
    _resetUndoRedo: function _resetUndoRedo() {
      if (this.projectFileMode) {
        if (this.$refs.editor) this.$refs.editor.resetUndoRedo();
      }
    },
    _refreshState: function _refreshState() {
      // Refresh the program state if the backend state has been updated
      var _c = this;

      this.api.getEdit(function (project) {
        // This also triggers the refresh of the XDSM and variable tree
        _c.editedProject = project;

        _c._pushAppState();
      });
    },
    _doDelete: function _doDelete(project, callback) {
      this.confirmDeleteBranch = null;
      this.confirmDeleteProject = project.title;

      var _c = this;

      this.$refs.confirmDeleteDialog.open(function () {
        _c.api.deleteProject(project.id, function () {
          _c.projectsEdited = true;
          callback();
        });
      });
    },
    _deleteBranch: function _deleteBranch(project, branch, callback) {
      this.confirmDeleteBranch = branch.title;
      this.confirmDeleteProject = project.title;

      var _c = this;

      this.$refs.confirmDeleteDialog.open(function () {
        _c.api.deleteBranch(project.id, branch.id, function (project) {
          _c.dispatcher.branchesEdited();

          callback(project);
        });
      });
    },
    _showProjectList: function _showProjectList() {
      if (this.hasProjectList) {
        this.projectsEdited = false;
        this.$refs.projectList.open();
      }
    },
    _finishProjectList: function _finishProjectList() {
      if (!this.projectsEdited) return;
      this.dispatcher.backendStateUpdated();
    },
    _getGraph: function _getGraph(projectId, branchId, inBatch) {
      var _c = this;

      this.api.loadGraph(projectId, branchId, function (graphDef) {
        if (inBatch) _c.dispatcher.setInBatchGraphUpdate(false);

        _c._graphUpdated(projectId, branchId, graphDef);
      });
    },
    _graphUpdated: function _graphUpdated(projectId, branchId, graphDef) {
      if (!(projectId in this.graphs)) {
        this.$set(this.graphs, projectId, _defineProperty({}, branchId, graphDef));
      } else {
        this.$set(this.graphs[projectId], branchId, graphDef);
      }
    },
    _openHelp: function _openHelp() {
      // const helpConfig = (this.helpConfigStack.length > 0) ? _.last(this.helpConfigStack): null;
      if (this.standaloneMode) return;
      var url = '/static/';

      if (this.ioMode) {
        url += 'MDAx_IO_QRM.pdf';
      } else {
        url += 'MDAx_QRM.pdf';
      }

      window.open(url, "_blank");
    },
    _addHelpConfig: function _addHelpConfig(config) {
      this.helpConfigStack.push(config);
    },
    _popHelpConfig: function _popHelpConfig() {
      this.helpConfigStack.pop();
    },
    _handleEscape: function _handleEscape() {
      if (this.escapeHandlerStack.length > 0) {
        var handler = _.last(this.escapeHandlerStack)[0];

        if (handler) handler();
        return true;
      }

      return false;
    },
    _addEscapeHandler: function _addEscapeHandler(escapeKeyHandler, handlerId) {
      this.escapeHandlerStack.push([escapeKeyHandler, handlerId]);
    },
    _popEscapeHandler: function _popEscapeHandler(handlerId) {
      if (handlerId) {
        var handlerIdx = _.findIndex(this.escapeHandlerStack, function (item) {
          return item[1] == handlerId;
        });

        if (handlerIdx != -1) {
          this.escapeHandlerStack.splice(handlerIdx, 1);
          return;
        }
      }

      this.escapeHandlerStack.pop();
    },
    _dialogOpened: function _dialogOpened() {
      var _this4 = this;

      this._addEscapeHandler(function () {
        return _this4.$modal.hide('dialog');
      });
    },
    _dialogBeforeClosed: function _dialogBeforeClosed() {
      this._popEscapeHandler();
    },
    // https://developer.mozilla.org/en-US/docs/Web/API/History_API
    _pushAppState: function _pushAppState(replace) {
      if (this.standaloneMode) return;
      var state = {
        projectId: this.editedProjectId,
        branchId: this.editedBranchId,
        subWfFunctionIds: this.editedSubWfFunctionIds || []
      };
      var namespace = routeNamespace ? routeNamespace + '/' : '';
      var url = '/' + namespace;

      if (this.storeStateUrl) {
        var subWfFunctionIdsHash = _.map(state.subWfFunctionIds, function (funcId) {
          return funcId.toString();
        }).join('|');

        url += '#' + state.projectId + '/' + state.branchId + '/' + subWfFunctionIdsHash;
      }

      if (replace) {
        history.replaceState(state, "", url);
      } else {
        history.pushState(state, "", url);
      }
    },
    _setAppState: function _setAppState() {
      if (this.standaloneMode) {
        this._refreshState();

        return;
      }

      var state = history.state;
      var projectId = null;
      var branchId = null;
      var subWfFunctionIds = null;

      if (state) {
        // Get state from state object
        projectId = state.projectId;
        branchId = state.branchId;
        subWfFunctionIds = state.subWfFunctionIds;
      } else {
        // Try to get state from URL
        var hash = window.location.hash.substr(1).split('/');

        if (hash.length >= 3) {
          projectId = parseInt(hash[hash.length - 3]);
          branchId = parseInt(hash[hash.length - 2]);
          subWfFunctionIds = hash[hash.length - 1];
          subWfFunctionIds = subWfFunctionIds == '' ? [] : _.map(subWfFunctionIds.split('|'), function (fId) {
            return parseInt(fId);
          });
        }
      } // if (branchId && branchId !== -1)


      if (branchId) {
        this._setEdit(projectId, branchId, subWfFunctionIds, true);
      } else {
        this._refreshState();
      }
    },
    _cancelEvent: function _cancelEvent(e) {
      // Only prevent a dragover event if we are dragging files (otherwise, it will also look like it is
      // possible to drop other elements, like XDSM blocks, here)
      if (e.type == 'dragover' && !_.includes(e.dataTransfer.types, 'Files')) return;
      e.preventDefault();
    },
    _catchDoubleClick: function _catchDoubleClick(e) {
      // Do not handle anything for labels (because if you click a label that wraps an input (e.g. checkbox),
      // a second click event on the input is fired right away)
      if (_.findIndex(e.path, function (el) {
        return el.tagName && el.tagName.toLowerCase() == 'label';
      }) != -1) {
        return;
      }

      if (this.dblClickTimeoutId === null) {
        var _c = this;

        this.dblClickTimeoutId = setTimeout(function () {
          return _c.dblClickTimeoutId = null;
        }, 200);
      } else {
        e.preventDefault();
      }
    },
    _displayLegal: function _displayLegal() {
      this.$refs.legalModal.open();
    },
    _displayAbout: function _displayAbout() {
      this.$refs.aboutModal.open();
    }
  },
  created: function created() {
    var _this5 = this;

    this.api.onLoading(this._onLoading);
    window.addEventListener('keydown', this._handleKeyDown);
    window.addEventListener('keyup', this._handleKeyUp);
    this.dispatcher.onNotify(this._notification);
    this.dispatcher.onError(this._errorHandler);
    this.dispatcher.onSuccess(this._successHandler);
    this.dispatcher.onIsLoading(this._receivedIsLoadingStatus);
    this.dispatcher.onOpenHelp(this._openHelp);
    this.dispatcher.onAddHelpConfig(this._addHelpConfig);
    this.dispatcher.onPopHelpConfig(this._popHelpConfig);
    this.dispatcher.onAddEscapeHandler(this._addEscapeHandler);
    this.dispatcher.onPopEscapeHander(this._popEscapeHandler);
    this.dispatcher.onUpdateGraph(this._getGraph);
    this.dispatcher.onUpdatedGraph(this._graphUpdated);
    this.dispatcher.onBackendStateUpdated(this._refreshState);
    this.dispatcher.onBranchesEdited(this._onBranchesEdited); // Prevent drop anywhere: https://stackoverflow.com/a/6756680

    window.addEventListener('dragover', this._cancelEvent, false);
    window.addEventListener('drop', this._cancelEvent, false);
    window.addEventListener('click', this._catchDoubleClick); // Load initial project

    window.addEventListener('popstate', this._setAppState);

    this._setAppState();

    this.api.getLegalText(function (legalText) {
      if (!legalText) {
        _this5.legalText = null;
        return;
      }

      var md = new MarkdownIt();
      md.use(mila, {
        attrs: {
          target: '_blank'
        }
      });
      _this5.legalText = md.render(legalText);
    });
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('keydown', this._handleKeyDown);
    window.removeEventListener('keyup', this._handleKeyUp);
    this.dispatcher.offNotify(this._notification);
    this.dispatcher.offError(this._errorHandler);
    this.dispatcher.offSuccess(this._successHandler);
    this.dispatcher.offIsLoading(this._receivedIsLoadingStatus);
    this.dispatcher.offOpenHelp(this._openHelp);
    this.dispatcher.offAddHelpConfig(this._addHelpConfig);
    this.dispatcher.offPopHelpConfig(this._popHelpConfig);
    this.dispatcher.offAddEscapeHandler(this._addEscapeHandler);
    this.dispatcher.offPopEscapeHandler(this._popEscapeHandler);
    this.dispatcher.offUpdateGraph(this._getGraph);
    this.dispatcher.offUpdatedGraph(this._graphUpdated);
    this.dispatcher.offBackendStateUpdated(this._refreshState);
    this.dispatcher.offBranchesEdited(this._onBranchesEdited);
    window.removeEventListener('dragover', this._cancelEvent, false);
    window.removeEventListener('drop', this._cancelEvent, false);
    window.removeEventListener('click', this._catchDoubleClick);
    window.removeEventListener('popstate', this._setAppState);
  }
};