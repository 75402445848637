function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../../lodash.custom.js';
import { functionTypes } from './functions.js';
import functionSubFormMixin from './sub-form-mixin.js';
import selectVars from '../select/select-vars.vue';
import options from '../options.vue';
import uiModal from '../ui-modal.vue';
import { filterTreeByFunctionIO } from '../../var-utils.js';
import xdsm from '../xdsm/xdsm.vue';
import { staticBaseUrl } from '../../index.js';
export default {
  name: "mdao-form",
  mixins: [functionSubFormMixin],
  components: {
    selectVars: selectVars,
    options: options,
    xdsm: xdsm,
    uiModal: uiModal
  },
  props: {
    graphDef: {
      type: Object,
      required: true
    },
    usedNames: {
      type: Array,
      required: true
    },
    typeTitle: {
      type: String,
      required: true
    }
  },
  data: function data() {
    var _defaultNames;

    return {
      defaultConvergerType: 'gauss-seidel',
      convergerTypes: [['gauss-seidel', 'Gauss-Seidel'], ['jacobi', 'Jacobi']],
      defaultDoeType: 'lhs',
      doeTypes: [['lhs', 'Latin Hypercube'], ['full', 'Full Factorial'], ['table', 'Custom Table']],
      wrapGraphDef: null,
      wrapFunctionDef: null,
      initialFunctionIndex: 1,
      endFunctionId: null,
      lastWrappedFunctionId: null,
      wrappingNestingSpec: null,
      wrappedFunctionIds: null,
      firstDefUpdate: true,
      xdsmRef: null,
      currentFunctionIndex: 1,
      xdsmShown: false,
      optFunctionDef: {
        rounded: true,
        bg: '#ccccff'
      },
      doeFunctionDef: {
        rounded: true,
        bg: '#fffcd5'
      },
      convergerFunctionDef: {
        rounded: true,
        bg: '#ffe5cc'
      },
      staticBaseUrl: null,
      defaultNames: (_defaultNames = {}, _defineProperty(_defaultNames, functionTypes.OPT, 'Optimizer'), _defineProperty(_defaultNames, functionTypes.DOE, 'DOE'), _defineProperty(_defaultNames, functionTypes.MDA, 'MDA'), _defaultNames)
    };
  },
  watch: {
    graphDef: function graphDef() {
      this.endFunctionId = null;
      this.lastWrappedFunctionId = null;

      this._setWrapGraphDef();
    },
    // Timeouts needed to make sure the update is done in a non-blocking cycle
    // (see watch def handler in sub-form-mixin.js)
    currentFunctionIndex: function currentFunctionIndex(idx) {
      var _c = this;

      setTimeout(function () {
        return _c.def.index = idx;
      }, 0);
    },
    lastWrappedFunctionId: function lastWrappedFunctionId(fId) {
      var _c = this;

      setTimeout(function () {
        return _c.def.last_wrapped_id = fId;
      }, 0);
    }
  },
  computed: {
    hasDesVars: function hasDesVars() {
      return _.includes([functionTypes.OPT, functionTypes.DOE], this.type);
    },
    hasObj: function hasObj() {
      return this.type == functionTypes.OPT;
    },
    hasCon: function hasCon() {
      return this.type == functionTypes.OPT;
    },
    hasStateVars: function hasStateVars() {
      return this.type == functionTypes.DOE;
    },
    isDOE: function isDOE() {
      return this.type == functionTypes.DOE;
    },
    isConverger: function isConverger() {
      return this.type == functionTypes.MDA;
    },
    isOptimizer: function isOptimizer() {
      return this.type == functionTypes.OPT;
    },
    hasWrappingPos: function hasWrappingPos() {
      return true;
    },
    hasCouplingVars: function hasCouplingVars() {
      return this.type == functionTypes.MDA;
    },
    convergerType: function convergerType() {
      return this.def.mdao && this.def.mdao.converger_type ? this.def.mdao.converger_type : null;
    },
    convergerTypeTitle: function convergerTypeTitle() {
      var convergerType = this.convergerType;

      var matchedConvergerType = _.find(this.convergerTypes, function (opt) {
        return opt[0] == convergerType;
      });

      return matchedConvergerType ? matchedConvergerType[1] : convergerType;
    },

    /*doeType() {
        return (this.def.mdao && this.def.mdao.doe_type) ? this.def.mdao.doe_type: null;
    },
    doeTypeTitle() {
        const doeType = this.doeType;
        const matchedDoeType = _.find(this.doeTypes, (opt) => opt[0] == doeType);
        return (matchedDoeType) ? matchedDoeType[1]: doeType;
    },
    hasDoeTable() {
        return this.isDOE && this.doeType == 'table';
    },*/
    rootVarDefInputVars: function rootVarDefInputVars() {
      if (!this.editable) return this.rootVarDef;
      if (!this.rootVarDef) return null; // Select vars that are input but not output

      return filterTreeByFunctionIO(this.rootVarDef, this.wrappedFunctionIds, true, false, false) || this.rootVarDef;
    },
    rootVarDefOutputVars: function rootVarDefOutputVars() {
      if (!this.editable) return this.rootVarDef;
      if (!this.rootVarDef) return null; // Select vars that are output (don't care about input)

      return filterTreeByFunctionIO(this.rootVarDef, this.wrappedFunctionIds, null, true, true) || this.rootVarDef;
    },
    rootVarDefCouplingVars: function rootVarDefCouplingVars() {
      // Simply select all output since any output variable can be used for convergence check
      return this.rootVarDefOutputVars; // if (!this.editable) return this.rootVarDef;
      // if (!this.rootVarDef) return null;
      // return filterTreeByFunctionCoupling(this.rootVarDef, this.wrappedFunctionIds);
    },
    baseFunctionDef: function baseFunctionDef() {
      return this.isConverger ? this.convergerFunctionDef : this.isDOE ? this.doeFunctionDef : this.optFunctionDef;
    },
    sortable: function sortable() {
      return this.graphDef ? this.graphDef.functions.length > 2 : false;
    },
    _debouncedDefUpdated: function _debouncedDefUpdated() {
      var _this = this;

      return _.debounce(function () {
        return _this._doDefUpdated();
      }, 500);
    },
    wrapFunctionId: function wrapFunctionId() {
      return this.wrapFunctionDef ? this.wrapFunctionDef.id : null;
    },
    sortableIds: function sortableIds() {
      return this.wrapFunctionId ? [this.wrapFunctionId] : [];
    },
    sortingFunctionIds: function sortingFunctionIds() {
      return this._sortingFunctionIds(this.wrapGraphDef);
    },
    sortTargetIds: function sortTargetIds() {
      var endFunctionId = this.endFunctionId;
      return _.filter(this.sortingFunctionIds, function (id) {
        return id != endFunctionId;
      });
    },
    functionId: function functionId() {
      return this.def ? this.def.id : null;
    },
    functionClasses: function functionClasses() {
      return _.map(this.wrappedFunctionIds, function (functionId) {
        return [functionId, 'wrapped'];
      });
    }
  },
  methods: {
    _customPrepareFunctionDef: function _customPrepareFunctionDef(functionDef) {
      functionDef.mdao = _.merge({
        doe_type: this.defaultDoeType,
        doe_table: [],
        converger_type: this.defaultConvergerType,
        coupling_vars: [],
        x: [],
        f: [],
        g: [],
        y: []
      }, functionDef.mdao || {});
      if (!('index' in functionDef)) functionDef.index = null;
      if (!('last_wrapped_id' in functionDef)) functionDef.last_wrapped_id = null;

      if (!functionDef.title) {
        var defaultNames = this.defaultNames;
        var usedNames = this.usedNames;

        if (defaultNames.hasOwnProperty(functionDef.type)) {
          var title = defaultNames[functionDef.type];
          var baseTitle = title;
          var i = 2;

          while (_.includes(usedNames, title)) {
            title = baseTitle + ' ' + i;
            i++;
          }

          functionDef.title = title;
        }
      }
    },
    _defUpdated: function _defUpdated() {
      if (this.firstDefUpdate) {
        this.firstDefUpdate = false;

        this._doDefUpdated();
      } else {
        this._debouncedDefUpdated();
      }
    },
    _doDefUpdated: function _doDefUpdated() {
      var wrapFunctionDef = _.merge({
        process_title: '?'
      }, this.baseFunctionDef, _.cloneDeep(this.def));

      if (!wrapFunctionDef.title) wrapFunctionDef.title = 'New';
      this.wrapFunctionDef = wrapFunctionDef;

      if (this.wrapGraphDef) {
        this.$set(this.wrapGraphDef.functions, this.initialFunctionIndex, wrapFunctionDef);
      }
    },
    _sorted: function _sorted() {
      this.$set(this.wrapGraphDef, 'nesting', this._getGraphDefNesting());

      this._setCurrentFunctionIndex();
    },
    _setCurrentFunctionIndex: function _setCurrentFunctionIndex() {
      var functionId = this.functionId;
      this.currentFunctionIndex = _.findIndex(this.sortingFunctionIds, function (id) {
        return id == functionId;
      });
    },
    _setWrapGraphDef: function _setWrapGraphDef() {
      var graphDef = _.cloneDeep(this.graphDef);

      var wrapFunctionDef = this.wrapFunctionDef; // Get the wrapping nesting spec assuming that the currently edited loop driver does not actually wrap
      // the functions it does, so that we can freely editing its wrapping behavior

      var _this$_flattenNesting = this._flattenNestingSpec(graphDef.nesting, wrapFunctionDef.id),
          flattenedNesting = _this$_flattenNesting.nesting,
          lastWrappedId = _this$_flattenNesting.lastWrappedId;

      this.wrappingNestingSpec = flattenedNesting;
      this.lastWrappedFunctionId = lastWrappedId; // Inject the function block to be added into the graph

      this.initialFunctionIndex = this.isNew ? 1 : _.findIndex(graphDef.functions, function (functionDef) {
        return functionDef.id == wrapFunctionDef.id;
      });
      graphDef.functions.splice(this.initialFunctionIndex, this.isNew ? 0 : 1, wrapFunctionDef);
      graphDef.nesting = this._getGraphDefNesting(graphDef);
      this.wrapGraphDef = graphDef;

      this._setCurrentFunctionIndex();
    },
    _flattenNestingSpec: function _flattenNestingSpec(nestingSpec, flattenedLoopDriverFunctionId) {
      var lastWrappedFunctionId = null;

      function getFlattenedNestingSpec(nestingLevel) {
        var flattenedNestingLevel = [];

        _.forEach(nestingLevel, function (nestingIds) {
          if (!_.isArray(nestingIds)) {
            flattenedNestingLevel.push(nestingIds);
            return;
          } // Check if we match the loop driver we want to flatten


          var loopDriverId = nestingIds[0];
          var wrappedNestingSpec = nestingIds[1];
          var flattenedNextLevel = getFlattenedNestingSpec(wrappedNestingSpec);

          if (loopDriverId == flattenedLoopDriverFunctionId) {
            flattenedNestingLevel.push(loopDriverId); // Add next-level nesting spec to current level

            _.forEach(flattenedNextLevel, function (nextNestingIds) {
              flattenedNestingLevel.push(nextNestingIds); // Track last wrapped function

              lastWrappedFunctionId = _.isArray(nextNestingIds) ? nextNestingIds[0] : nextNestingIds;
            });
          } else {
            // Otherwise just add the loop driver and its wrapping behavior
            flattenedNestingLevel.push([loopDriverId, flattenedNextLevel]);
          }
        });

        return flattenedNestingLevel;
      }

      return {
        nesting: getFlattenedNestingSpec(nestingSpec),
        lastWrappedId: lastWrappedFunctionId
      };
    },
    _sortingFunctions: function _sortingFunctions(graphDef) {
      if (!graphDef) graphDef = this.wrapGraphDef;
      return this.xdsmRef ? this.xdsmRef.sortedFunctions : graphDef.functions;
    },
    _sortingFunctionIds: function _sortingFunctionIds(graphDef) {
      return _.map(this._sortingFunctions(graphDef), function (functionDef) {
        return functionDef.id;
      }).slice(1); // Exclude coordinator
    },
    _getGraphDefNesting: function _getGraphDefNesting(graphDef) {
      // Get the currently sorted function Ids
      var ids = this._sortingFunctionIds(graphDef); // Determine the function that will always be at the end


      var lastId = _.last(ids);

      if (this.endFunctionId === null) {
        this.endFunctionId = lastId;
      } else {
        lastId = this.endFunctionId;
      } // Make sure that this function indeed is at the end


      ids.splice(ids.indexOf(lastId), 1);
      ids.push(lastId);
      return ids;
    },
    _selectConvergerType: function _selectConvergerType(type) {
      this.$set(this.def.mdao, 'converger_type', type);
      this.$refs.convInfoModal.close();
    },
    _resetZoom: function _resetZoom() {
      this.$refs.xdsm.resetZoom();
    }
  },
  created: function created() {
    var _this2 = this;

    this._doDefUpdated();

    this._setWrapGraphDef();

    this.staticBaseUrl = staticBaseUrl; // Make sure the last_wrap_id has been updated

    setTimeout(function () {
      return _this2._forceUpdateDef();
    }, 200);
  },
  mounted: function mounted() {
    this.xdsmRef = this.$refs.xdsm;
    this.xdsmShown = false;

    var _c = this;

    setTimeout(function () {
      return _c.xdsmShown = true;
    }, 500);
  }
};