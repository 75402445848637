//
//
//
//
//
//
//
//
export default {
  name: "xdsm-data",
  props: {
    label: {
      type: String,
      required: true
    },
    // Whether it connects two functions (internal) or connects to/from outside the problem (external)
    external: {
      type: Boolean,
      default: false
    }
  }
};