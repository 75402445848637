// IE11 polyfill
import 'core-js/features/array/from';
import devtools from '@vue/devtools';
import Vue from 'vue'; // Enable Vue performance profiler
// Vue.config.devtools = true;
// Vue.config.performance = true;

import Toasted from 'vue-toasted';
import VModal from 'vue-js-modal';
import VeeValidate from 'vee-validate';
import VueClip from 'vue-clip/dist/vue-clip.min.js';
Vue.use(Toasted);
Vue.use(VModal, {
  dialog: true
});
Vue.use(VeeValidate, {
  fastExit: false
});
Vue.use(VueClip);
import 'vue-good-table/dist/vue-good-table.css';
import 'vue-toasted/dist/vue-toasted.min.css';
import { MdaxApi } from './api';
export var api = null;
import Dispatcher from './dispatcher';
export var dispatcher = new Dispatcher();
export var staticBaseUrl = null;
export var routeNamespace = '';
export var projectFileMode = false;
export var allowOpen = true;
export var allowNew = true;
export var branchesEnabled = true;
export var ioMode = false;
export var previewMode = false;
export var uiManaged = false;
export var hasAuth = false;
export var loginFields = null;
export var scopeName = 'Scope';
export var remoteName = null;
export var reloadOnLogout = false;
export var displayScopeName = true;
export var logoFiles = [];
export var getStaticImageSrc = function getStaticImageSrc(path) {
  return staticBaseUrl + 'img/' + path;
};
export function setGetStaticImageSrc(func) {
  getStaticImageSrc = func;
}
export var getRouteNamespace = function getRouteNamespace() {
  return routeNamespace;
};
export function startApi() {
  api = new MdaxApi();
}
import app from './app.vue';
export function start(settings) {
  // Try to connect to Vue dev tools
  if (settings.devMode) {
    devtools.connect(); // Filter out [flush] messages

    var originalConsoleLog = console.log;

    console.log = function () {
      try {
        if (arguments[0].substring(0, 7) == '[flush]') return;
      } catch (_unused) {}

      originalConsoleLog.apply(null, arguments);
    };
  }

  staticBaseUrl = settings.staticBase;
  routeNamespace = settings.routeNamespace;
  projectFileMode = settings.projectFileMode;
  allowOpen = settings.allowOpen;
  allowNew = settings.allowNew;
  branchesEnabled = settings.branchesEnabled;
  ioMode = settings.ioMode;
  previewMode = settings.previewMode;
  uiManaged = settings.uiManaged;
  hasAuth = settings.hasAuth;
  loginFields = settings.loginFields;
  scopeName = settings.scopeName;
  remoteName = settings.remoteName;
  reloadOnLogout = settings.reloadOnLogout;
  displayScopeName = settings.displayScopeName;
  logoFiles = settings.logoFiles;
  if (api === null) startApi();
  new Vue({
    el: '#app',
    name: 'MDAx',
    render: function render(h) {
      return h(app, {
        props: {
          version: settings.version
        }
      });
    }
  });
}