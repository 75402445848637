function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../../lodash.custom.js';
import uiModal from '../ui-modal.vue';
import uploader from '../uploader.vue';
import selectTable from '../select/select-table.vue';
import { api, dispatcher, remoteName } from '../../index';
export function filterName(name) {
  var filtered = name.toLowerCase().split('input')[0].split('output')[0];

  while ([' ', '-', '_'].some(function (c) {
    return _.endsWith(filtered, c);
  })) {
    filtered = filtered.substring(0, filtered.length - 1);
  }

  return filtered;
}
export default {
  name: "tool-batch-add-modal",
  components: {
    uiModal: uiModal,
    uploader: uploader,
    selectTable: selectTable
  },
  data: function data() {
    return {
      api: api,
      dispatcher: dispatcher,
      remoteName: remoteName,
      validChecked: false,
      toolDefs: [],
      // [{name, inputFileId, outputFileId}, ...]
      uploaderRef: null,
      columns: [['name', 'Tool Name'], ['inputFileId', 'Input File', this._formatFileId('inputFileId')], ['outputFileId', 'Output File', this._formatFileId('outputFileId')]],
      toolName: null,
      toolInputFileId: null,
      toolOutputFileId: null,
      availableToolIO: null
    };
  },
  watch: {
    uploadedFiles: function uploadedFiles(_uploadedFiles) {
      this._removeUnavailableFiles();

      if (_uploadedFiles.length > 0 && !this.isUploading) this._autoAddTools();
    }
  },
  computed: {
    hasEnough: function hasEnough() {
      return this.toolDefs.length > 0;
    },
    actionDisabled: function actionDisabled() {
      if (this.useAvailableFiles && !this.hasAvailableFiles) return true;
      return !this.hasEnough;
    },
    isValid: function isValid() {
      return !this.validChecked || this.hasEnough;
    },
    useAvailableFiles: function useAvailableFiles() {
      return this.availableToolIO !== null;
    },
    hasAvailableFiles: function hasAvailableFiles() {
      return this.availableToolIO !== null && this.availableToolIO.length > 0;
    },
    allAvailableFiles: function allAvailableFiles() {
      return _.concat(_.map(this.availableToolIO, function (io) {
        return io.input_file;
      }), _.map(this.availableToolIO, function (io) {
        return io.output_file;
      }));
    },
    uploadedFiles: function uploadedFiles() {
      if (this.useAvailableFiles) return _.map(this.allAvailableFiles, function (name) {
        return {
          id: name,
          name: name
        };
      });
      var uploader = this.uploaderRef;
      if (!uploader) return [];
      return uploader.uploadedFiles;
    },
    isUploading: function isUploading() {
      if (this.useAvailableFiles) return false;
      var uploader = this.uploaderRef;
      if (!uploader) return false;
      return uploader.isUploading;
    },
    hasEnoughFiles: function hasEnoughFiles() {
      if (this.useAvailableFiles) return this.hasAvailableFiles;
      return this.uploadedFiles.length >= 2;
    },
    usedFileIds: function usedFileIds() {
      var fileIds = [];

      _.forEach(this.toolDefs, function (toolDef) {
        if (toolDef.inputFileId) fileIds.push(toolDef.inputFileId);
        if (toolDef.outputFileId) fileIds.push(toolDef.outputFileId);
      });

      return _.uniq(fileIds);
    },
    unusedUploadedFiles: function unusedUploadedFiles() {
      var usedFileIds = this.usedFileIds;
      return _.filter(this.uploadedFiles, function (uploadedFile) {
        return !_.includes(usedFileIds, uploadedFile.id);
      });
    },
    fileIdNameMap: function fileIdNameMap() {
      return _.fromPairs(_.map(this.uploadedFiles, function (uploadedFile) {
        return [uploadedFile.id, uploadedFile.name];
      }));
    },
    fileSelectOptions: function fileSelectOptions() {
      return _.concat([{
        id: null,
        name: ''
      }], _.sortBy(this.uploadedFiles, function (file) {
        return file.name;
      }));
    },
    inputFileRules: function inputFileRules() {
      return {
        required: this.toolOutputFileId === null
      };
    },
    outputFileRules: function outputFileRules() {
      return {
        required: this.toolInputFileId === null,
        is_not: this.toolInputFileId
      };
    }
  },
  methods: {
    open: function open() {
      var _this = this;

      this._initForm();

      this.toolDefs = [];
      this.validChecked = false;
      this.api.resetUpload();
      this.api.getToolIOFiles(function (toolIO) {
        _this.availableToolIO = toolIO;

        _this.$refs.modal.open();
      });
    },
    close: function close() {
      this.$refs.modal.close();
    },
    _opened: function _opened() {
      this.uploaderRef = this.$refs.uploader;
    },
    _closed: function _closed() {
      this.uploaderRef = null;
    },
    _formatFileId: function _formatFileId(key) {
      var _c = this;

      return function (row) {
        return _c.fileIdNameMap[row[key]];
      };
    },
    _deleteToolDef: function _deleteToolDef(idx) {
      this.toolDefs = _.filter(this.toolDefs, function (v, i) {
        return i != idx;
      });
    },
    _initForm: function _initForm() {
      this.toolName = null;
      this.toolInputFileId = null;
      this.toolOutputFileId = null;
    },
    _addToolDef: function _addToolDef() {
      var _c = this;

      this.$validator.validateAll('tool').then(function (valid) {
        if (!valid) return;

        _c.toolDefs.push({
          name: _c.toolName,
          inputFileId: _c.toolInputFileId,
          outputFileId: _c.toolOutputFileId
        });

        _c._initForm();
      });
    },
    _removeUnavailableFiles: function _removeUnavailableFiles() {
      var fileIdMap = this.fileIdNameMap;
      var idxRemove = [];

      _.forEach(this.toolDefs, function (toolDef, idx) {
        if (toolDef.inputFileId !== null && !(toolDef.inputFileId in fileIdMap) || toolDef.outputFileId !== null && !(toolDef.outputFileId in fileIdMap)) {
          idxRemove.push(idx);
        }
      });

      if (idxRemove.length > 0) {
        this.toolDefs = _.filter(this.toolDefs, function (v, i) {
          return !_.includes(idxRemove, i);
        });
      }
    },
    _autoAddTools: function _autoAddTools() {
      var _this2 = this;

      var possibleToolDefs = {};

      if (this.useAvailableFiles) {
        var availableToolIO = this.availableToolIO;

        for (var i = 0; i < availableToolIO.length; i++) {
          var io = availableToolIO[i];
          if (io.input_file === null && io.output_file === null) continue;
          possibleToolDefs[io.tool_name] = {
            name: io.tool_name,
            inputFileId: io.input_file,
            outputFileId: io.output_file
          };
        }
      } else {
        var unusedFiles = this.unusedUploadedFiles; // Filter the tool names to do matching later

        var filteredNames = _.map(unusedFiles, function (file) {
          var data = {
            id: file.id,
            name: filterName(file.name),
            lcName: file.name.toLowerCase()
          };
          data.formattedName = file.name.substring(0, data.name.length);
          return data;
        }); // Group I/O files by possible tool names


        for (var _i = 0; _i < filteredNames.length; _i++) {
          var _filteredNames$_i = filteredNames[_i],
              id = _filteredNames$_i.id,
              lcName = _filteredNames$_i.lcName,
              formattedName = _filteredNames$_i.formattedName;

          var isInput = _.includes(lcName, 'input');

          var fileIdKey = isInput ? 'inputFileId' : 'outputFileId';
          var otherKey = !isInput ? 'inputFileId' : 'outputFileId';

          if (formattedName in possibleToolDefs) {
            possibleToolDefs[formattedName][fileIdKey] = id;
          } else {
            var _possibleToolDefs$for;

            possibleToolDefs[formattedName] = (_possibleToolDefs$for = {
              name: formattedName
            }, _defineProperty(_possibleToolDefs$for, fileIdKey, id), _defineProperty(_possibleToolDefs$for, otherKey, null), _possibleToolDefs$for);
          }
        }
      } // Add tools


      _.forEach(possibleToolDefs, function (toolDef) {
        return _this2.toolDefs.push(toolDef);
      });
    },
    _submit: function _submit() {
      this.validChecked = true;
      if (!this.isValid) return false;

      var _c = this;

      this.api.editAddToolsBatch(_.map(this.toolDefs, function (t) {
        return {
          name: t.name,
          input_file_id: t.inputFileId,
          output_file_id: t.outputFileId
        };
      }), function (graphDef) {
        _c.$emit('added', graphDef);

        _c.close();
      });
      return false; // Prevent modal from closing
    }
  }
};