var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.editedProject
        ? _c("editor", {
            ref: "editor",
            attrs: {
              version: _vm.version,
              project: _vm.editedProject,
              graphs: _vm.graphs,
              "can-edit": _vm.canEdit,
              "project-unsaved": _vm.editedUnsaved,
              "has-branches": _vm.hasBranches,
              "has-legal-text": _vm.hasLegalText
            },
            on: {
              "update:project": function($event) {
                _vm.editedProject = $event
              },
              simload: _vm._onLoading,
              new: _vm._doNew,
              open: _vm._open,
              save: _vm._save,
              saveas: _vm._saveAs,
              export: _vm._showExport,
              import: _vm._doImport,
              branches: _vm._showBranches,
              list: _vm._showProjectList,
              duplicate: _vm._duplicate,
              quickDuplicate: _vm._quickDuplicate,
              editSub: _vm._editSub,
              displayLegal: _vm._displayLegal,
              displayAbout: _vm._displayAbout
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.hasProjectList
        ? _c("project-list", {
            ref: "projectList",
            on: {
              new: _vm._doNew,
              edit: _vm._edit,
              import: _vm._doImport,
              duplicate: _vm._duplicate,
              delete: _vm._doDelete,
              branches: _vm._showBranches,
              close: _vm._finishProjectList
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("branches", {
        ref: "branches",
        attrs: { graphs: _vm.graphs },
        on: {
          edit: _vm._edit,
          export: _vm._showExport,
          delete: _vm._deleteBranch,
          duplicate: _vm._duplicate
        }
      }),
      _vm._v(" "),
      _c(
        "ui-dialog",
        { ref: "confirmDeleteDialog", attrs: { "action-title": "Delete" } },
        [
          _vm._v(
            "\n        Are you sure you want to delete the following " +
              _vm._s(_vm.confirmDeleteBranch ? "branch" : "project") +
              "?"
          ),
          _c("br"),
          _vm._v(" "),
          _c("h3", [
            _vm._v(_vm._s(_vm.confirmDeleteBranch || _vm.confirmDeleteProject))
          ]),
          _vm._v(" "),
          _vm.confirmDeleteBranch
            ? _c("div", { staticStyle: { "margin-top": "25px" } }, [
                _vm._v("\n            of the project:\n            "),
                _c("h4", { staticStyle: { "margin-top": "5px" } }, [
                  _vm._v(_vm._s(_vm.confirmDeleteProject))
                ])
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "ui-modal",
        {
          ref: "newBranchModal",
          attrs: {
            title: _vm.askNewProjectTitle
              ? "New Project"
              : "New Workflow Branch",
            "action-title": _vm.askNewProjectTitle
              ? "Create Project"
              : "Create Branch",
            "confirm-close": false,
            "action-error": !_vm.branchIsValid
          }
        },
        [
          _c("div", { staticClass: "form-horizontal branch-form" }, [
            _vm.askNewProjectTitle
              ? _c(
                  "div",
                  {
                    staticClass: "form-group",
                    class: {
                      "has-error": _vm.errors.has("branch.project_title")
                    }
                  },
                  [
                    _c("label", { staticClass: "col-sm-3 control-label" }, [
                      _vm._v("Project Name\n                    "),
                      _c("span", { staticClass: "error-note" }, [
                        _vm._v("required")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newProjectTitle,
                            expression: "newProjectTitle"
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        ref: "projectTitle",
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "project_title",
                          placeholder: "Project name",
                          "data-vv-validate-on": "input|change|blur",
                          "data-vv-scope": "branch"
                        },
                        domProps: { value: _vm.newProjectTitle },
                        on: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.$refs.newBranchModal.doAction($event)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.newProjectTitle = $event.target.value
                          }
                        }
                      })
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasBranches
              ? _c(
                  "div",
                  {
                    staticClass: "form-group",
                    class: { "has-error": _vm.errors.has("branch.title") }
                  },
                  [
                    _c("label", { staticClass: "col-sm-3 control-label" }, [
                      _vm._v("Branch Name\n                    "),
                      _c("span", { staticClass: "error-note" }, [
                        _vm._v("required")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newBranchTitle,
                            expression: "newBranchTitle"
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.askNewProjectTitle ? "" : "required",
                            expression: "(askNewProjectTitle) ? '': 'required'"
                          }
                        ],
                        ref: "branchTitle",
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "title",
                          placeholder: "Branch name",
                          "data-vv-validate-on": "input|change|blur",
                          "data-vv-scope": "branch"
                        },
                        domProps: { value: _vm.newBranchTitle },
                        on: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.$refs.newBranchModal.doAction($event)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.newBranchTitle = $event.target.value
                          }
                        }
                      })
                    ])
                  ]
                )
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      _c("import-modal", { ref: "importModal" }),
      _vm._v(" "),
      _c("export-modal", {
        ref: "exportModal",
        attrs: { "has-branches": _vm.hasBranches },
        on: { export: _vm._doExport }
      }),
      _vm._v(" "),
      _c("v-dialog", {
        attrs: { "click-to-close": false },
        on: {
          opened: _vm._dialogOpened,
          "before-closed": _vm._dialogBeforeClosed
        }
      }),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "disable-overlay"
      }),
      _vm._v(" "),
      _vm.hasAuth
        ? _c("auth", {
            attrs: {
              showing: _vm.showingAuth,
              "has-legal-text": _vm.hasLegalText
            },
            on: {
              "update:showing": function($event) {
                _vm.showingAuth = $event
              },
              displayLegal: _vm._displayLegal,
              displayAbout: _vm._displayAbout
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("logos", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showingAuth,
            expression: "showingAuth"
          }
        ],
        attrs: { "z-index": "5000" }
      }),
      _vm._v(" "),
      _c(
        "ui-modal",
        {
          ref: "legalModal",
          attrs: {
            title: "Legal - impressum",
            "has-buttons": false,
            "confirm-close": false
          }
        },
        [_c("div", { domProps: { innerHTML: _vm._s(_vm.legalText) } })]
      ),
      _vm._v(" "),
      _c(
        "ui-modal",
        {
          ref: "aboutModal",
          attrs: {
            title: "About MDAx",
            "has-buttons": false,
            "confirm-close": false
          }
        },
        [
          _vm._v(
            "\n        MDAx (the MDAO Workflow Design Accelerator) is a platform supporting the modeling and the inspection of\n        MDAO (Multidisciplinary Design Analysis and Optimization) workflows.\n        It can automatically identify connections and couplings between disciplinary modules whose input and output\n        are specified in terms of a common data language. It supports the validation of MDAO workflows, by solving\n        parameters collisions and feedback connections. Valid workflows can then be exported to be executed in a\n        PIDO (Process Integration and Design Optimization) environment.\n\n        "
          ),
          _c("h3", [_vm._v("More Information and Citing")]),
          _vm._v(
            "\n\n        If you want to obtain more information about the rationale and theory behind developing MDAx, please refer\n        to our "
          ),
          _c("b", [_vm._v("paper")]),
          _vm._v(":\n        "),
          _c(
            "a",
            {
              attrs: {
                href: "https://dx.doi.org/10.2514/6.2020-3133",
                target: "_blank"
              }
            },
            [
              _vm._v(
                "MDAx:\n            Agile Generation of Collaborative MDAO Workflows for Complex Systems"
              )
            ]
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "\n\n        Please cite this paper if you are using MDAx in your work.\n\n        "
          ),
          _c("h3", [_vm._v("Contact")]),
          _vm._v("\n\n        MDAx is developed at the "),
          _c("b", [_vm._v("DLR (German Aerospace Center)")]),
          _vm._v(
            ",\n        Institute of System Architectures in Aeronautics, Digital Development Process group, in Hamburg, Germany.\n        Contact us at "
          ),
          _c("a", { attrs: { href: "mailto:mdax@dlr.de" } }, [
            _vm._v("mdax@dlr.de")
          ]),
          _vm._v(" for any questions or suggestions.\n\n        "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("em", [
            _c("b", [
              _vm._v("You are running MDAx v" + _vm._s(_vm.version)),
              _vm.previewMode
                ? _c("span", [_vm._v(" in 'light' mode")])
                : _vm._e(),
              _vm._v(".")
            ])
          ]),
          _vm._v(" "),
          _vm.previewMode
            ? _c("div", [
                _c("h3", [_vm._v("MDAx Light")]),
                _vm._v(
                  "\n              MDAx is running in light mode and can "
                ),
                _c("b", [_vm._v("ONLY")]),
                _vm._v(
                  " be used to familiarize yourself with MDAx' functionality.\n          It is "
                ),
                _c("b", [_vm._v("explicitly forbidden")]),
                _vm._v(
                  " to use MDAx running in light mode for commercial purposes or\n              in research projects!"
                ),
                _c("br"),
                _c("br"),
                _vm._v("\n          Certain "),
                _c("b", [_vm._v("restrictions")]),
                _vm._v(
                  " have been placed on the tool functionality in the light mode:"
                ),
                _c("br"),
                _vm._v(
                  "\n            - Maximum 5 block can be added, including converger driver"
                ),
                _c("br"),
                _vm._v(
                  "\n            - Optimizer and DOE drivers are disabled"
                ),
                _c("br"),
                _vm._v(
                  "\n            - SVG, HTML, RCE, CMDOWS and IO exports are disabled"
                ),
                _c("br"),
                _vm._v(
                  "\n            - Sub-workflow nesting is limited to 1 level"
                ),
                _c("br")
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }