var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ui-modal",
        {
          ref: "loginModal",
          attrs: {
            title: _vm.loginTitle,
            "has-cancel": false,
            closeable: false,
            action: _vm._doLogin,
            "opaque-background": true,
            "action-title": "Login",
            "action-link-texts": _vm.actionLinkTexts,
            "action-disabled": _vm.reloadOnLogout
          },
          on: { actionLink: _vm._actionLink }
        },
        [
          _vm.reloadOnLogout
            ? _c("div", [
                _vm._v(
                  "\n            You have been logged out. Redirecting...\n        "
                )
              ])
            : _c("div", { staticClass: "form-horizontal" }, [
                _vm._hasLoginField("username")
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group",
                        class: { "has-error": _vm.errors.has("auth.username") }
                      },
                      [
                        _c("label", { staticClass: "col-sm-3 control-label" }, [
                          _vm._v("Username\n                    "),
                          _c("span", { staticClass: "error-note" }, [
                            _vm._v("required")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-9" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.loginData.username,
                                expression: "loginData.username"
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "username",
                              placeholder: "Username",
                              "data-vv-validate-on": "input|change|blur",
                              "data-vv-scope": "auth"
                            },
                            domProps: { value: _vm.loginData.username },
                            on: {
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm._doLogin($event)
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.loginData,
                                  "username",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._hasLoginField("password")
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group",
                        class: { "has-error": _vm.errors.has("auth.password") }
                      },
                      [
                        _c("label", { staticClass: "col-sm-3 control-label" }, [
                          _vm._v("Password\n                    "),
                          _c("span", { staticClass: "error-note" }, [
                            _vm._v("required")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-9" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.loginData.password,
                                expression: "loginData.password"
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "password",
                              name: "password",
                              placeholder: "Password",
                              "data-vv-validate-on": "input|change|blur",
                              "data-vv-scope": "auth"
                            },
                            domProps: { value: _vm.loginData.password },
                            on: {
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm._doLogin($event)
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.loginData,
                                  "password",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._hasLoginField("remember")
                  ? _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "col-sm-3 control-label" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-9" }, [
                        _c("div", { staticClass: "checkbox" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.loginData.remember,
                                  expression: "loginData.remember"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.loginData.remember)
                                  ? _vm._i(_vm.loginData.remember, null) > -1
                                  : _vm.loginData.remember
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.loginData.remember,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.loginData,
                                          "remember",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.loginData,
                                          "remember",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.loginData, "remember", $$c)
                                  }
                                }
                              }
                            }),
                            _vm._v(
                              "\n                            Remember Me\n                        "
                            )
                          ])
                        ])
                      ])
                    ])
                  : _vm._e()
              ])
        ]
      ),
      _vm._v(" "),
      _c(
        "ui-modal",
        {
          ref: "scopeModal",
          attrs: {
            title: _vm.scopeSelectTitle,
            "has-cancel": false,
            closeable: false,
            "opaque-background": true,
            action: _vm._doScopeSelect,
            "action-title": _vm.scopeSelectActionTitle
          }
        },
        [
          _c("div", { staticClass: "form-horizontal" }, [
            _c(
              "div",
              {
                staticClass: "form-group",
                class: { "has-error": _vm.errors.has("scope.selectedScope") }
              },
              [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedScope,
                          expression: "selectedScope"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        name: "selectedScope",
                        "data-vv-validate-on": "input|change|blur",
                        "data-vv-scope": "scope"
                      },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedScope = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c("option", { domProps: { value: null } }, [
                        _vm._v("Choose a " + _vm._s(_vm.scopeName) + "...")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.scopes, function(scope) {
                        return _c(
                          "option",
                          { key: scope[0], domProps: { value: scope[0] } },
                          [_vm._v(_vm._s(scope[1]))]
                        )
                      })
                    ],
                    2
                  )
                ])
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }