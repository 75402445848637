var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.rectProps
    ? _c(
        "svg",
        { ref: "svg", staticClass: "wrapping-select" },
        [
          _c("title", [_vm._v("Drag to select wrapped blocks")]),
          _vm._v(" "),
          _c("path", {
            attrs: { d: _vm.leftPath, "stroke-width": _vm.strokeWidth }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: { d: _vm.topPath, "stroke-width": _vm.strokeWidth }
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "pointer-events",
            attrs: {
              d: _vm.rightPath,
              "stroke-width": _vm.strokeWidth,
              cursor: _vm.canDrag ? "ew-resize" : ""
            },
            on: {
              mousedown: function($event) {
                $event.stopPropagation()
                _vm._mouseDown($event, 90)
              }
            }
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "pointer-events",
            attrs: {
              d: _vm.bottomPath,
              "stroke-width": _vm.strokeWidth,
              cursor: _vm.canDrag ? "ns-resize" : ""
            },
            on: {
              mousedown: function($event) {
                $event.stopPropagation()
                _vm._mouseDown($event, 0)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "rect",
            _vm._b(
              {
                staticClass: "pointer-events",
                attrs: {
                  cursor: _vm.canDrag ? "nwse-resize" : "",
                  "fill-opacity": "0"
                },
                on: {
                  mousedown: function($event) {
                    $event.stopPropagation()
                    _vm._mouseDown($event, 45)
                  }
                }
              },
              "rect",
              _vm.bottomRightRect,
              false
            )
          ),
          _vm._v(" "),
          _c("defs", [
            _c(
              "g",
              { attrs: { id: "drag-handle" } },
              _vm._l(_vm.dragHandlePaths, function(path) {
                return _c("path", {
                  staticClass: "drag-handle",
                  attrs: { d: path }
                })
              })
            )
          ]),
          _vm._v(" "),
          _vm._l(_vm.dragHandleLocs, function(attr) {
            return _c(
              "use",
              _vm._b(
                { attrs: { "xlink:href": "#drag-handle" } },
                "use",
                attr,
                false
              )
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }