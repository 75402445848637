var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      ref: "modal",
      staticClass: "projects-modal",
      attrs: {
        title: "MDAx Projects",
        "confirm-close": false,
        wide: true,
        "has-buttons": false,
        "help-config": _vm.helpConfig
      },
      on: { opened: _vm._opened, closed: _vm._closed }
    },
    [
      _c(
        "vue-good-table",
        {
          staticClass: "table-wide-modal",
          attrs: {
            columns: _vm.columns,
            rows: _vm.data,
            "search-options": _vm.searchOptions,
            "sort-options": _vm.sortOptions,
            "style-class": "table table-striped table-hover table-sm"
          },
          on: { "on-sort-change": _vm._sorted },
          scopedSlots: _vm._u([
            {
              key: "table-column",
              fn: function(props) {
                return [
                  _c("span", { ref: "col_" + props.column.field }, [
                    _vm._v(_vm._s(props.column.label))
                  ])
                ]
              }
            },
            {
              key: "table-row",
              fn: function(props) {
                return [
                  props.column.field == "actions"
                    ? _c("span", { staticClass: "row-actions" }, [
                        _c("span", {
                          staticClass: "fas fa-edit link",
                          attrs: { title: "Show/edit current branch" },
                          on: {
                            click: function($event) {
                              _vm._edit(props.row)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "fas fa-code-branch link",
                          attrs: { title: "Show project branch tree" },
                          on: {
                            click: function($event) {
                              _vm._showBranches(props.row)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "far fa-trash-alt link",
                          attrs: { title: "Delete project" },
                          on: {
                            click: function($event) {
                              _vm._doDelete(props.row)
                            }
                          }
                        })
                      ])
                    : _c("span", [
                        _vm._v(
                          "\n                " +
                            _vm._s(props.formattedRow[props.column.field]) +
                            "\n            "
                        )
                      ])
                ]
              }
            }
          ])
        },
        [
          _vm.allowNew
            ? _c("template", { slot: "table-actions" }, [
                _c("div", { staticClass: "actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default icon",
                      attrs: { title: "New project" },
                      on: { click: _vm._createNew }
                    },
                    [_c("span", { staticClass: "far fa-file" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default icon",
                      attrs: { title: "Import project" },
                      on: { click: _vm._doImport }
                    },
                    [_c("span", { staticClass: "fas fa-file-import" })]
                  )
                ])
              ])
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }