//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from './lodash.custom.js';
import uiModal from './ui-items/ui-modal.vue';
import { VueGoodTable } from 'vue-good-table';
import format from 'date-fns/format';
import { api, dispatcher, staticBaseUrl, allowNew } from './index';
export default {
  name: "project-list",
  components: {
    uiModal: uiModal,
    VueGoodTable: VueGoodTable
  },
  data: function data() {
    return {
      api: api,
      dispatcher: dispatcher,
      allowNew: allowNew,
      columns: [{
        field: 'title',
        label: 'Project'
      }, {
        field: 'branch_title',
        label: 'Last Edited Branch'
      }, {
        field: 'created',
        label: 'Created',
        type: 'date',
        formatFn: this._formatDate
      }, {
        field: 'actions',
        label: 'Actions',
        globalSearchDisabled: true,
        html: true,
        sortable: false
      }],
      searchOptions: {
        enabled: true,
        placeholder: 'Search'
      },
      sortOptions: {
        enabled: true,
        initialSortBy: {
          field: 'created',
          type: 'asc'
        }
      },
      data: [],
      helpConfig: {},
      staticBaseUrl: null
    };
  },
  methods: {
    open: function open() {
      // If already opened, just update the list
      if (this.$refs.modal.opened) {
        this._list();

        return;
      }

      this.data = [];

      var _c = this;

      this._list(function () {
        _c.$refs.modal.open();
      });
    },
    close: function close() {
      this.$refs.modal.close();
    },
    _opened: function _opened() {
      setTimeout(this._setColumnClasses, 0);
      this.dispatcher.onBackendStateUpdated(this._refreshState);
      this.dispatcher.onBranchesEdited(this._refreshState);
    },
    _closed: function _closed() {
      this.dispatcher.offBackendStateUpdated(this._refreshState);
      this.dispatcher.offBranchesEdited(this._refreshState);
      this.$emit('close');
    },
    _list: function _list(callback) {
      var _c = this;

      this.api.listProjects(function (projects) {
        _c.data = projects;
        if (callback) callback();

        _.forEach(projects, function (project) {
          return _c.dispatcher.updatedProject(project);
        });
      });
    },
    _refreshState: function _refreshState() {
      this._list();
    },
    _formatDate: function _formatDate(val) {
      return format(val, 'yyyy-MM-dd HH:mm');
    },
    _doImport: function _doImport() {
      var _c = this;

      this.$emit('import', function (projects) {
        // If imported only one project, we want to edit it right away
        if (projects.length == 1) _c.close();
      });
    },
    _createNew: function _createNew() {
      this.$emit('new', this.close);
    },
    _edit: function _edit(project) {
      this.$emit('edit', project);
      this.close();
    },
    _doDelete: function _doDelete(project) {
      this.$emit('delete', project, this._list);
    },
    _duplicate: function _duplicate(project) {
      this.$emit('duplicate', project, null, this.close);
    },
    _showBranches: function _showBranches(project) {
      this.$emit('branches', project, this.close);
    },
    _sorted: function _sorted() {
      setTimeout(this._setColumnClasses, 0);
    },
    _setColumnClasses: function _setColumnClasses() {
      // Fallback function for giving the "sorting" class to sortable columns
      // Unfortunately this functionality was removed from VueGoodTable
      var _c = this;

      var defaultSortable = this.sortOptions.enabled;

      _.forEach(this.columns, function (col) {
        var sortable = col.hasOwnProperty('sortable') ? col.sortable : defaultSortable;
        var spanEl = _c.$refs['col_' + col.field];
        if (sortable) spanEl.parentElement.classList.add('sorting');
      });
    }
  },
  created: function created() {
    this.staticBaseUrl = staticBaseUrl;
  }
};