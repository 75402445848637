//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from '../lodash.custom.js';
import uiDialog from './ui-dialog.vue';
import { getMutationObserver } from 'vue-js-modal/src/utils';
import { dispatcher } from './../index';
export default {
  name: "ui-modal",
  components: {
    uiDialog: uiDialog
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      default: ''
    },
    classes: {
      type: [String, Array],
      default: function _default() {
        return [];
      }
    },
    hasButtons: {
      type: Boolean,
      default: true
    },
    hasCancel: {
      type: Boolean,
      default: true
    },
    action: {
      type: Function,
      default: null
    },
    actionTitle: {
      type: String,
      default: 'Ok'
    },
    actionDisabled: {
      type: Boolean,
      default: false
    },
    actionError: {
      type: Boolean,
      default: false
    },
    confirmClose: {
      type: Boolean,
      default: true
    },
    closeable: {
      type: Boolean,
      default: true
    },
    wide: {
      default: false
    },
    helpConfig: {
      type: Object,
      default: null
    },
    stickyActionButtons: {
      type: Boolean,
      default: true
    },
    pivotY: {
      type: Number,
      default: 0.3
    },
    draggable: {
      type: Boolean,
      default: false
    },
    opaqueBackground: {
      type: Boolean,
      default: false
    },
    actionLinkTexts: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      dispatcher: dispatcher,
      modalId: null,
      overlayClickElement: null,
      modalRef: null,
      actionButtonsHeight: 0,
      actionButtonsWidth: 0,
      stickyOverride: false,
      loading: false,
      dynamicAction: null,
      opened: false,
      zIndex: null,
      mutationObserver: null,
      modalContentEl: null,
      modalContentHeight: null
    };
  },
  computed: {
    modalClasses: function modalClasses() {
      return _.concat(['v--modal', 'vue-dialog'], this.classes);
    },
    modalWidth: function modalWidth() {
      return this.wide ? this.wide === true ? '80%' : this.wide : '40%';
    },
    windowHeight: function windowHeight() {
      return this.modalRef ? this.modalRef.window.height : null;
    },
    sticky: function sticky() {
      if (this.stickyOverride !== null) return this.stickyOverride;
      var modalHeight = this.modalContentHeight;
      var windowHeight = this.windowHeight;
      if (!modalHeight || !windowHeight) return false;
      return modalHeight > windowHeight;
    },
    actionButtonsStyle: function actionButtonsStyle() {
      var style = {};

      if (this.sticky) {
        style['width'] = this.actionButtonsWidth + 'px';
      }

      return style;
    },
    actionFunction: function actionFunction() {
      return this.action ? this.action : this.dynamicAction ? this.dynamicAction : function () {
        return true;
      };
    },
    draggableParam: function draggableParam() {
      return this.draggable ? 'header.draggable' : null;
    }
  },
  watch: {
    modalContentHeight: function modalContentHeight() {
      this._modalChanged();
    },
    windowHeight: function windowHeight() {
      this._modalChanged();
    }
  },
  methods: {
    open: function open(actionFunction) {
      if (this.opened) return;

      this._setZIndex();

      this.modalContentHeight = null;
      this.modalContentEl = null;
      this.opened = true;
      this.stickyOverride = false;
      this.dynamicAction = actionFunction || null;
      this.$modal.show(this.modalId);
      setTimeout(this._opened, 100);
      this.dispatcher.onIsLoading(this.setLoading);
      this.loading = this.dispatcher.loading;
    },
    _setZIndex: function _setZIndex() {
      this.zIndex = this.dispatcher.getIncreaseZIndex();
    },
    close: function close() {
      this.opened = false;
      this.$modal.hide(this.modalId);
      this.$emit('close');
      this.dispatcher.offIsLoading(this.setLoading);
    },
    doAction: function doAction() {
      if (this.actionFunction() === false) {
        return;
      }

      this.close();
    },
    setLoading: function setLoading(loading) {
      this.loading = loading;
    },
    _opened: function _opened() {
      this._addOverlayEvent(); // Override escape and help key bindings


      this.dispatcher.addEscapeHandler(this._handleEscape, this.modalId);
      if (this.helpConfig) this.dispatcher.addHelpConfig(this.helpConfig); // Determine action button height

      if (this.hasButtons) {
        this.actionButtonsHeight = this.$refs.actionButtons.offsetHeight;
        this.actionButtonsWidth = this.$refs.actionButtons.offsetWidth;
      }

      this.stickyOverride = null; // Connect mutation observer

      this.modalContentEl = this.$refs.header.parentElement;

      this._updateModalContentHeight();

      if (this.mutationObserver) {
        this.mutationObserver.observe(this.modalContentEl, {
          childList: true,
          attributes: true,
          subtree: true
        });
      }

      this.$emit('opened');
    },
    _closeButton: function _closeButton() {
      if (!this.closeable) return;

      if (this.confirmClose) {
        this._askClose();
      } else {
        this.close();
      }
    },
    _closed: function _closed() {
      this.dispatcher.popEscapeHandler(this.modalId);
      if (this.helpConfig) this.dispatcher.popHelpConfig(); // Disconnect mutation observer

      if (this.mutationObserver) {
        this.mutationObserver.disconnect();
      }

      this.$emit('closed');

      this._setModalId();
    },
    _addOverlayEvent: function _addOverlayEvent() {
      var overlayDiv = this.modalRef.$refs.overlay;
      var el = overlayDiv.getElementsByClassName('v--modal-background-click')[0];
      this.overlayClickElement = el;
      el.addEventListener('mousedown', this._overlayClick);
      el.addEventListener('touchstart', this._overlayClick);
    },
    _overlayClick: function _overlayClick(e) {
      if (e.target != this.overlayClickElement) return;
      if (!this.closeable) return;

      if (!this.confirmClose) {
        this.close();
        return;
      }

      this._askClose();
    },
    _askClose: function _askClose() {
      var _this = this;

      this.$refs.dialog.open(function () {
        return _this.close();
      });
    },
    _handleEscape: function _handleEscape() {
      if (this.modalRef.visible && this.closeable) {
        this.close();
      }
    },
    _setModalId: function _setModalId() {
      this.modalId = 'modal-' + Math.random().toString(36).slice(2);
    },
    _mutation: function _mutation() {
      this._updateModalContentHeight();
    },
    _updateModalContentHeight: function _updateModalContentHeight() {
      if (this.modalContentEl) {
        this.modalContentHeight = this.modalContentEl.clientHeight;
      }
    },
    _modalChanged: function _modalChanged() {
      this.$emit('change');
    },
    _keyPress: function _keyPress(e) {
      // Stop propagation of the space key: the app is also listening for this key (used for playing/pausing
      // the workflow process), and there e.preventDefault() is called, which prevents the space to be typed
      // in any input element
      if (_.includes([32, 37, 39], e.keyCode)) {
        // Space, left arrow, right arrow
        e.stopPropagation();
      }
    }
  },
  created: function created() {
    this._setModalId();
  },
  mounted: function mounted() {
    var _this2 = this;

    this.modalRef = this.$refs.modal; // Instantiate DOM mutation observer (code based on Modal.vue from vue-js-modal)

    var MutationObserver = getMutationObserver();

    if (MutationObserver) {
      this.mutationObserver = new MutationObserver(function () {
        return _this2._mutation();
      });
    }
  }
};