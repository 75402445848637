import { render, staticRenderFns } from "./connect-var.vue?vue&type=template&id=25cde1f8&scoped=true&"
import script from "./connect-var.vue?vue&type=script&lang=js&"
export * from "./connect-var.vue?vue&type=script&lang=js&"
import style0 from "./connect-var.vue?vue&type=style&index=0&id=25cde1f8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25cde1f8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\garg_sp\\Desktop\\Sparsh\\Sparsh\\MDAx\\06_Tool_Installation\\Cloned_20240827\\mdax\\mdax\\ui\\web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('25cde1f8', component.options)
    } else {
      api.reload('25cde1f8', component.options)
    }
    module.hot.accept("./connect-var.vue?vue&type=template&id=25cde1f8&scoped=true&", function () {
      api.rerender('25cde1f8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui-items/var-list/connect-var.vue"
export default component.exports